import { NgxsDispatchPluginModule } from "@ngxs-labs/dispatch-decorator";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";
import { NgxsResetPluginModule } from "ngxs-reset-plugin";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";

import { AppStateList, VolvoState } from "~/core/store";

@NgModule({
    imports: [
        CommonModule,
        NgxsModule.forRoot([...VolvoState]),
        NgxsStoragePluginModule.forRoot({
            key: [AppStateList.language], // add objects that need to store locally
            // storage: localStorage.getItem("persist") === "true" ? 0 : 1,
        }),
        NgxsDispatchPluginModule.forRoot(),
        NgxsResetPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
    ],
    exports: [
        NgxsModule,
        NgxsStoragePluginModule,
        NgxsDispatchPluginModule,
        NgxsResetPluginModule,
        NgxsReduxDevtoolsPluginModule,
        CommonModule
    ],
})
export class StoreModule { }

