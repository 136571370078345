import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { VfsStateInterface } from './vfs.model';
import { SetVfsStatus } from './vfs.actions';

@Injectable({ providedIn: 'root' })
export class VfsService {
    @Select((state) => state.vfsState)
    public vfsStatus$: Observable<VfsStateInterface>;

    @Dispatch()
    public setVfsStatus(payload: VfsStateInterface) {
        return new SetVfsStatus(payload);
    }
}

