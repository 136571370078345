import { Component, Inject, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

import { LocalesStateLang, LocalesStateLangList, LocalesStateRegion } from '~/core/store/locales/locales.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { MailApiService } from '~/core/api/mail-api/mail-api.service';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { CurrencyCode, Truck } from '~/core/store/trucks/trucks.model';
import { HtmlRoutePages } from '~/app-routing.model';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { CounterService } from '~/core/services/counter/counter-service';
import { TranslateService } from '@ngx-translate/core';
import { regionEnum } from '~/core/declaration/declaration';
import { AssetService } from '~/core/services/assets/asset.service';

export class SalesUser {
  name: string;
  info: string;
  adress: string;
  city: string;
  postalcode: string;
  phone: string;
  email: string;
  locationName: string;
  isExpanded = false;
}
export interface EmailUsDialogData {
  truck: Truck;
  salesPerson: SalesUser
}

class EmailPopupUserActions {
  emailButton: boolean = true;
  phoneButton: boolean = false
  financeQuote: boolean = false;
  close: boolean = false;
  send: boolean = false;
  public constructor(init?: Partial<EmailPopupUserActions>) {
    Object.assign(this, init);
  }
}
@AutoUnsubscribe()
@Component({
  selector: 'volvo-email-us-popup',
  templateUrl: './email-us-popup.component.html',
  styleUrls: ['./email-us-popup.component.scss'],
})
export class EmailUsPopupComponent implements OnInit, OnDestroy {
  HtmlRoutePages = HtmlRoutePages;
  name: string = '';
  messageBodyText: string = '';
  noSpecialCharRegEx = /^[\p{L}\- \.\?!@'\r\n,0-9\(\)]+$/u;

  form = this.fb.group({
    name: ['', [Validators.required]],
    phone: ['', [Validators.pattern(/\d/g)]],
    email: ['', [Validators.email, Validators.required]],
    financing: [false],
    financingCompanyName: [''],
    financingCity: [''],
    financingCountry: [''],
    message: ['', [Validators.pattern(this.noSpecialCharRegEx)]],
    truckId: [''],
    truckUrl: [''],
    licensePlate: [''],
    truckdescription: [''],
    privacy: [false],
    token: [''],
    postCodeOrLocation: [''],
    companyName: ['',[Validators.pattern('[^<>]+')]],
    country: ['',[Validators.pattern('[^<>]+')]]
  });

  
  curLang: LocalesStateLangList;
  checkbox: string;
  reCaptchaRes: string;
  showRecaptcha: boolean = false;
  recaptchaToken: string;

  private s0: Subscription;
  CurrencyCode = CurrencyCode;
  regionalSettings: RegionSettings;

  phoneButton: boolean = false;
  isSelectedSite: boolean = false;
  regionEnum = regionEnum;
  region: string;
  site: string;
  noSalesEmail: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EmailUsDialogData,
    public dialogRef: MatDialogRef<EmailUsPopupComponent>,
    private fb: UntypedFormBuilder,
    private fbMobile: UntypedFormBuilder,
    private mailApiService: MailApiService,
    private localesService: LocalesService,
    private sanitizer: DomSanitizer,
    private globalUtils: GlobalUtils,
    store: Store,
    private counterService: CounterService,
    private translateService: TranslateService,
    public assetService: AssetService
  ) {
    this.setTruckValues();
    
    this.s0 = this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
      this.curLang = lang.curLang;
      this.checkbox = '/' + this.curLang.id + '/privacy-policy';
    });
    
    const region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === region)[0]);
    this.region = this.regionalSettings?.region;
    this.site = this.regionalSettings?.site;
    if(this.regionalSettings.site === 'volvoselected')
      this.isSelectedSite = true;

    if(this.region === this.regionEnum.UK || this.region === this.regionEnum.TX)
      this.noSalesEmail = true;

    this.recaptchaToken = environment.recaptchaSitekey;
    this.updatePrivacyValidation();
  }

  ngOnInit(): void {
    this.messageBodyText = this.translateService.instant('form/email/messageText'.toLowerCase())
    this.form.controls['message'].setValue(this.messageBodyText);
  }

  ngOnDestroy(): void { }

  resolved(captchaResponse: string, res) {
    this.reCaptchaRes = captchaResponse;
  }

  recaptcha() {
    this.showRecaptcha = !this.showRecaptcha;
  }

  async onSubmit(): Promise<void> {
    if (this.form.valid && (this.reCaptchaRes || !this.isSelectedSite)){
      const body = this.transformDataForServer(this.form.value);
      if(this.isSelectedSite)
        await this.mailApiService.postMailEmail(body);
      else
        await this.mailApiService.postMailUKEmail(body);
      this.logUserActions("send");
      this.dialogRef.close();
    }
  }

  onClose(): void {
    this.logUserActions("close");
    this.dialogRef.close();
  }
  logUserActions(action: string) {
    let financing = false, emailButtonClick = false, phoneButtonClick = false;
    let userActions;

    if (this.form.value.financing) financing = true;
    if (this.form.value.phone) phoneButtonClick = true;
    if (this.form.value.email) emailButtonClick = true;

    if (this.data.truck) {
      switch (action) {
        case "close":
          userActions = new EmailPopupUserActions({ phoneButton: phoneButtonClick, emailButton: emailButtonClick, close: true, financeQuote: financing });
          break;
        case "send":
          userActions = new EmailPopupUserActions({ phoneButton: phoneButtonClick, emailButton: emailButtonClick, send: true, financeQuote: financing });
          break;
      }

      this.counterService.postEmailPopUpUserActions("Truck Ad", this.data.truck.truckId, userActions);
    }
  }
  private setTruckValues(): void {
    if (this.data.truck) {
      this.form.get('truckId').setValue(this.data.truck.stockNumber);
      this.form.get('licensePlate').setValue(this.data.truck.specification.licensePlate);
      this.form.get('truckdescription').setValue(this.data.truck.MetaDescription);
    }

    if (this.globalUtils.isBrowser()) {
      this.form.get('truckUrl').setValue(window.location.href);
    }
  }

  private transformDataForServer(data) {
    const salesEmail = this.isSelectedSite ? this.data.salesPerson.email : (this.noSalesEmail ? "" : this.data.salesPerson.email);
    const salesPersonName = this.isSelectedSite ? this.data.salesPerson.name : (this.noSalesEmail ? "Sales person" : this.data.salesPerson.name);
    const sanitizedName = this.sanitizer.sanitize(SecurityContext.NONE, data.name).trim();
    const sanitizedMessage = this.sanitizer.sanitize(SecurityContext.NONE, data.message).trim();
    const country = (this.region === this.regionEnum.TX && !this.isSelectedSite) ? data.country : "";
    
    return {
      salesEmail,
      salesPersonName,
      name: sanitizedName,
      phone: data.phone,
      email: data.email,
      truckid: data.truckId,
      countryoffinance: data.financingCountry,
      message: sanitizedMessage,
      token: this.reCaptchaRes,
      truckdescription: data.truckdescription,
      financing: data.financing,
      financingCompanyName: data.financingCompanyName,
      financingcity: data.financingCity,
      truckUrl: data.truckUrl,
      licensePlate: data.licensePlate,
      site: this.site,
      region: this.region,
      companyName: this.isSelectedSite ? '' : data.companyName,
      country: country,
      postCodeOrLocation: this.isSelectedSite ? '' : data.postCodeOrLocation
    };
  }
  
  changeMessageName() {

    // if(this.form.controls['name'].value!=='')
    // {
    //   this.messageBody=this.messageBody+this.form.controls['name'].value;
    //   this.form.controls['message'].setValue(this.messageBody);
    // }
    // else
    // {
    this.form.controls['message'].setValue(this.messageBodyText + this.form.controls['name'].value);
    // }

  }

  updatePrivacyValidation() {
    const privacyControl = this.form.get('privacy');
    const countryOrPostCodeControl = this.form.get('postCodeOrLocation');
    const companyControl = this.form.get('companyName');
    
    if (this.isSelectedSite) {
      countryOrPostCodeControl.clearValidators();
      privacyControl.setValidators([Validators.required, Validators.pattern('true')]);
    } else {
      if(this.region === regionEnum.TX){ 
        countryOrPostCodeControl.clearValidators();
        companyControl.clearAsyncValidators();
      }
      else{
        countryOrPostCodeControl.setValidators([Validators.required, Validators.pattern('[^<>]+')]);
        companyControl.setValidators([Validators.required, Validators.pattern('[^<>]+')]);
      }
      privacyControl.clearValidators();
    }

    privacyControl.updateValueAndValidity();
    countryOrPostCodeControl.updateValueAndValidity();
    companyControl.updateValueAndValidity();
  }
}
