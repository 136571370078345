import { Component, OnInit } from '@angular/core';
import { SalesTeam } from '../contact-page.model';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-centre-felixstowe',
  templateUrl: './centre-felixstowe.component.html',
  styleUrls: ['../centre-banbury/centre-banbury.component.scss']
})
export class CentreFelixstoweComponent implements OnInit {
    mapCentre: string = 'Volvo Truck and Bus Centre South & East Felixstowe';
    public isLoading = true
  constructor(private router: Router,
    private metaTagService: Meta,
    private titleService: Title,
    private translateService: TranslateService,
    private breadCrumbService: BreadcrumbserviceService,
    public assetService: AssetService) { }

    onImageLoad() {
        this.isLoading = false;
    }
    onError(): void {
        console.error('Image failed to load.');
        this.isLoading = false; // You may want to handle this differently
    }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('uk/contactus/felixstowemetatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:title',
        content: this.translateService.instant('uk/contactus/felixstowemetatitle'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('uk/contactus/felixstowemetadescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('uk/contactus/felixstowealtImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('uk/contactus/felixstowemetatitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/contactus/felixstowemetatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/contactus/felixstowemetadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/contactus/felixstowealtImage'.toLowerCase())
    });

    //this.breadCrumbService.updateBreadcrumb('Contact Us' , 'Felixstowe Used Truck Centre', HtmlRoutePages.contact);
  }
  salesTeamMangementDetails: SalesTeam[] = [
    {
      imageUrl: '/assets/pages/contactus/Karl Love.webp',
      imageName: 'Karl Love',
      imageDesignation: 'uk/pages/contact/salesExecutive1',
      imageCentre: 'uk/pages/contact/salesCentre1',
      imageEmail: 'karl.love@volvo.com',
      imageMobile: '07808 884583',
    },
    ]
}
