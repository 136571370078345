import { Component, OnInit } from '@angular/core';
import { SalesTeam } from '../contact-page.model';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-centre-reading',
  templateUrl: './centre-reading.component.html',
  styleUrls: ['../centre-banbury/centre-banbury.component.scss']
})
export class CentreReadingComponent implements OnInit {
    mapCentre: string = 'Volvo Truck and Bus Centre South & East Reading';
    public isLoading = true
  constructor(private router: Router,
    private metaTagService: Meta,
    private titleService: Title,
    private translateService: TranslateService,
    private breadCrumbService: BreadcrumbserviceService,
    public assetService: AssetService) { }

    onImageLoad() {
        this.isLoading = false;
    }
    onError(): void {
        console.error('Image failed to load.');
        this.isLoading = false;
    }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('uk/contactus/readingmetatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:title',
        content: this.translateService.instant('uk/contactus/readingmetatitle'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('uk/contactus/readingmetadescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('uk/contactus/readingaltImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('uk/contactus/readingmetatitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/contactus/readingmetatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/contactus/readingmetadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/contactus/readingaltImage'.toLowerCase())
    });

    //this.breadCrumbService.updateBreadcrumb('Contact Us' , 'Reading Used Truck Centre', HtmlRoutePages.contact);
  }
  salesTeamMangementDetails: SalesTeam[] = [
  {
    imageUrl: '/assets/pages/contactus/Phil Wheeler.webp',
    imageName: 'Phil Wheeler',
    imageDesignation: 'uk/pages/contact/salesExecutive1',
    imageCentre: 'uk/pages/contact/salesCentre3',
    imageEmail: 'phil.wheeler@volvo.com',
    imageMobile: '07771 946787',
  },
  ]
}
