import { Component, OnInit} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { regionEnum } from '../../../core/declaration/declaration';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';

@Component({
  selector: 'volvo-utCustomisation-page',
    templateUrl: './utCustomisation-page.component.html',
    styleUrls: ['./utCustomisation-page.component.scss']
})
export class UTCustomisationPageComponent implements OnInit{
  HtmlRoutePages: HtmlRoutePages;
  region:string;
  regionEnum=regionEnum;
  financeList=[];
  constructor(private translateService: TranslateService,private metaTagService: Meta, 
    private regionalSettingsService:RegionalSettingsService,
    private breadCrumbService: BreadcrumbserviceService,) {}
  ngOnInit(): void {
  //   this.regionalSettingsService.getRegion$.subscribe((res:any) => {
  //     this.region=res;
  // });
    this.metaTagService.updateTag({
      name: 'og:title',
        content: this.translateService.instant('services/customisation/metatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
        content: this.translateService.instant('services/customisation/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
        content: this.translateService.instant('services/customisation/altimage'.toLowerCase())
    });
    // Additional traditional meta tags for search engines
    this.metaTagService.updateTag({
      name: 'description',
        content: this.translateService.instant('services/customisation/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'keywords',
        content: this.translateService.instant('services/customisation/metakeywords'.toLowerCase())
    });

    //this.breadCrumbService.updateBreadcrumb('Services' , 'Used Truck Customisation', HtmlRoutePages.services);

    this.financeList = [
      {
        img: 'assets/pages/services/tx1.webp',
        type: 'uk/pages/services/Financing/title1',
        name: 'uk/pages/services/Financing/title1/text1',
      },
      {
        img:  'assets/pages/services/tx2.webp',
        type: 'uk/pages/services/Financing/title2',
        name: 'uk/pages/services/Financing/title2/text1',
      },
      {
        img: 'assets/pages/services/tx3.webp',
        type: 'uk/pages/services/Financing/title3',
        name: 'uk/pages/services/Financing/title3/text1',
      },
    ];
  }

  financeList2 = [
    {
      type: 'uk/pages/services/Financing/title4',
      name: 'uk/pages/services/Financing/title4/text1',
    },
    {
      type: 'uk/pages/services/Financing/title5',
      name: 'uk/pages/services/Financing/title5/text1',
    },
    {
      type: 'uk/pages/services/Financing/title6',
      name: 'uk/pages/services/Financing/title6/text1',
    },
  ];
 }
