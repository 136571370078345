import {Directive, HostListener, Input} from '@angular/core';

import {GlobalUtils} from '~/core/utils/global-utils/global-utils';


@Directive({
    selector: '[volvoScrollTo]'
})
export class ScrollToDirective {
    el: HTMLElement;
    scrollId:string;
    constructor(
        private globalUtils: GlobalUtils
    ) {

    }

    @Input() set volvoScrollTo(el: string) {
        if (this.globalUtils.isBrowser()) {
            this.scrollId =el;
        }
    }

    @HostListener('click') onClick() {
        if (this.scrollId) {
            this.el=document.getElementById(this.scrollId);
            if(this.el)
            {
                this.el.scrollIntoView({behavior: 'smooth', block: 'start'});
            }      
        }
    }
}
