import { Component} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';
@Component({
  selector: 'volvoFMX-page',
  templateUrl: './volvoFMX-page.component.html',
  styleUrls: ['./volvoFMX-page.component.scss']
})
export class BGvolvoFMXPageComponent{
  constructor(private router: Router,private titleService: Title,
    private translateService: TranslateService,
    private metaTagService: Meta,
    private breadCrumbService: BreadcrumbserviceService
  ) {}
  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('uk/buyersguide/fmx/metatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:title',
        content: this.translateService.instant('uk/buyersguide/fmx/metatitle'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('uk/buyersguide/fmx/metadescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('uk/buyersguide/fmx/altImage'.toLowerCase())
      });
      // Additional traditional meta tags for search engines
      this.metaTagService.updateTag({
        name: 'description',
        content: this.translateService.instant('uk/buyersguide/fmx/metadescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'keywords',
        content: this.translateService.instant('uk/buyersguide/fmx/metakeywords'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('uk/buyersguide/fmx/metatitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/buyersguide/fmx/metatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/buyersguide/fmx/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/buyersguide/fmx/altImage'.toLowerCase())
    });
    // Additional traditional meta tags for search engines
    this.metaTagService.updateTag({
      name: 'description',
      content: this.translateService.instant('uk/buyersguide/fmx/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.translateService.instant('uk/buyersguide/fmx/metakeywords'.toLowerCase())
    });

    //this.breadCrumbService.updateBreadcrumb('Buyer' + "'s " + 'Guide','Volvo FMX',HtmlRoutePages.buyersGuide);
  }
 }
