<mat-selection-list [multiple]="false" #selectionListVM (selectionChange)="onChangeVehicleType($event)"
  [placeholder]="_step?.title" class="Filter-TypeSelect customBgColor displayType">
  <mat-list-option #allSelectedVM *ngFor="let item of _step.list" [value]="item.name"
    [selected]="_step.value === item.name">
    <div style="text-align: left" data-cy="filter-trucktype" *ngIf = "!isPhone; else others">
      <span *ngIf="item.icon" class="Autocomplete-optionImg">
        <img [src]="item.icon" alt="item.name"/>
      </span>
      <span class="Autocomplete-optionTitle CPrimary">{{item.name === 'Trailer' ? ('globalMiss/trailer' | lowercase |
        translate) : null}}</span>
      <span class="Autocomplete-optionTitle CPrimary">{{item.name === 'Truck' ? ('globalMiss/truck' | lowercase |
        translate) : null}}</span>
      <span class="Autocomplete-optionTitle CPrimary">{{item.name === 'Tractor' ? ('globalMiss/tractor' | lowercase |
        translate) : null}}</span>
    </div>
    <ng-template #others>
      <div >
        <span *ngIf="item.icon" class="Autocomplete-optionImg">
          <img [src]="item.icon" alt="item.name"/><br>
          <span class="Autocomplete-optionTitle CPrimary">{{item.name === 'Trailer' ? ('globalMiss/trailer' | lowercase |
              translate) : null}}</span>
            <span class="Autocomplete-optionTitle CPrimary">{{item.name === 'Truck' ? ('globalMiss/truck' | lowercase |
              translate) : null}}</span>
            <span class="Autocomplete-optionTitle CPrimary">{{item.name === 'Tractor' ? ('globalMiss/tractor' | lowercase |
              translate) : null}}</span>
        </span>
      </div>
    </ng-template>
  </mat-list-option>
</mat-selection-list>
