import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, HostListener } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { EmailUsPopupComponent } from '~/popups/email-us-popup/email-us-popup.component';
import { LocalesStateLang, LocalesStateLangList, LocalesStateRegion, LocalesStateRegionList } from '~/core/store/locales/locales.model';
import { PhoneUsPopupComponent } from '~/popups/call-us-popup/phone-us-popup.component';
import { LocalesService } from '~/core/store/locales/locales.service';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { SalesUser, SalesUsers } from '~/pages/truck-page/truck-page.component';
import { Truck } from '~/core/store/trucks/trucks.model';
import { LocationStrategy } from '@angular/common';
import { CounterService } from '~/core/services/counter/counter-service';
import { ScreenUtils } from '~/core/utils/screen-utils/screen-utils';

@AutoUnsubscribe()
@Component({
  selector: 'volvo-sales-slider',
  templateUrl: './sales-slider.component.html',
  styleUrls: ['./sales-slider.component.scss'],
})
export class SalesSliderComponent implements OnInit, OnDestroy {
  @ViewChild('slider', { read: DragScrollComponent, static: true }) slider: DragScrollComponent;
  @Input() set users(users: SalesUser[]) { this._users = users; }
  @Input() truck: Truck;

  _users: SalesUser[];
  _multipleUsers: SalesUsers[];
  curLang: LocalesStateLangList;
  curRegion: LocalesStateRegionList;
  private s0: Subscription;
  private s1: Subscription;
  public index = 0;
  leftNavDisabled = false;
  rightNavDisabled = false;
  isPhone: boolean = false;

  constructor(public dialog: MatDialog, public globalUtils: GlobalUtils, private localesService: LocalesService, private location: LocationStrategy, private counterService: CounterService) {
    if (ScreenUtils.isPhone()) this.isPhone = true;
    this.initSubscriptions();
    // history.pushState(null, null, window.location.href);
    // // check if back or forward button is pressed.
    // this.location.onPopState(() => {
    //   this.dialog.closeAll();
    //   //event.preventDefault();
    //   
    //   //this.stepper.previous();
    // });
  }
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   this.dialog.closeAll();
  //   event.preventDefault();
  //   
  // }
  ngOnInit(): void {
  }

  ngOnDestroy(): void { }

  togglePhone(index: number): void {
    if (document.getElementById("phId" + "_" + index)) {
      let phoneDiv = document.getElementById("phId" + "_" + index);
      if (phoneDiv.className === "Carousel-itemPhone Colordark Carousel-showNumber")
        phoneDiv.className = "Carousel-itemPhone Colordark Carousel-hideNumber";
      else {
        phoneDiv.className = "Carousel-itemPhone Colordark Carousel-showNumber";
        this.logButtonClick("Phone number");
      }
    }
    if (this.isPhone)
      this.logButtonClick("Phone number");
  }

  logButtonClick(buttonType: string): void {
    var buttonClick = this.counterService.returnButtonClickObj(buttonType, this.truck.truckId);
    this.counterService.postEmailPhoneButtonClicks("Truck Ad", buttonClick);
  }

  onContactsClick(index: number): void {
    this.logButtonClick("Send Message");
    this.dialog.open(EmailUsPopupComponent, {
      panelClass: 'volvo-popup-email',
      data: { truck: this.truck, salesPerson: this._users[index] },
    });
  }

  private initSubscriptions(): void {
    this.s0 = this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
      this.curLang = lang.curLang;
    });
    this.s1 = this.localesService.region$.subscribe((region: any) => {
      this.curRegion = region.curRegion;
    });
  }


  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }
}
