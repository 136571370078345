import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    Renderer2,
    SimpleChanges
} from '@angular/core';

import {GlobalUtils} from '~/core/utils/global-utils/global-utils';


@Directive({
    selector: 'img[volvoLazyImage]'
})
export class LazyImageDirective implements AfterViewInit, OnChanges {
    @HostBinding('attr.src') srcAttr = null;
    @Input() src: string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private globalUtils: GlobalUtils
    ) {
    }

    @HostListener('load') onLoad() {
        this.renderer.setAttribute(this.el.nativeElement, 'volvoLazyImage', 'true');
    }

    @HostListener('error') onError() {
        this.srcAttr = 'https://via.placeholder.com/1x1';
    }

    ngAfterViewInit() {
        this.updateImage();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes && changes.src && changes.src.currentValue && changes.src.previousValue &&
            changes.src.currentValue.changingThisBreaksApplicationSecurity !==
            changes.src.previousValue.changingThisBreaksApplicationSecurity
        ) {
            // triggers only when src has different value as before
            this.updateImage();
        }
    }

    private updateImage(): void {
        this.canLazyLoad() ? this.lazyLoadImage() : this.loadImage();
    }

    private canLazyLoad(): boolean {
        if (this.globalUtils.isBrowser()) {
            return Boolean(window && 'IntersectionObserver' in window);
        } else {
            return false;
        }
    }

    private lazyLoadImage(): void {
        const obs = new IntersectionObserver((entries) => {
            entries.forEach(({isIntersecting}) => {
                if (isIntersecting) {
                    this.loadImage();
                    obs.unobserve(this.el.nativeElement);
                }
            });
        });
        obs.observe(this.el.nativeElement);
    }

    private loadImage(): void {
        this.srcAttr = this.src;
    }
}
