import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface AssetMap {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private assetMap: AssetMap = {};

  constructor(private http: HttpClient) {
    this.loadAssetMap();
  }

  public loadAssetMap(): void {
    this.http.get<AssetMap>('assets/asset-map.json').subscribe(
      (data) => {
        this.assetMap = data;
      },
      (error) => {
        console.error('Error loading asset map:', error);
      }
    );
  }

  getAssetUrl(originalUrl: string): string {
    var retval = this.assetMap[originalUrl] || originalUrl;
    return retval;
  }
}
