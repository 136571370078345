import {Component, Input, OnInit,Output,EventEmitter} from '@angular/core';

import {GlobalUtils} from '~/core/utils/global-utils/global-utils';
import {SearchStateFilter} from '~/core/store/search/search.model';
import {SearchService} from '~/core/store/search/search.service';


@Component({
    selector: 'volvo-stock-pagination',
    templateUrl: './stock-pagination.component.html',
    styleUrls: ['./stock-pagination.component.scss']
})
export class StockPaginationComponent implements OnInit {
    @Input() total: number;
    @Input() search: SearchStateFilter;
    @Output() loading = new EventEmitter();
    Math = Math;

    constructor(
        private searchService: SearchService,
        private globalUtils: GlobalUtils
    ) {
    }

    ngOnInit(): void {
    }

    onPageChange(page: number): void {
        this.loading.emit({
          loading:false
          });
        if (this.globalUtils.isBrowser()) {
            window.scrollTo({top: 0, left: 0});
        }
        this.search = {
            ...this.search,
            page
        };
        this.searchService.updateSearch(this.search);
        this.loading.emit({
            loading:true
            });
    }

}
