<form [formGroup]="form" (ngSubmit)="onSubmit()" class="PopupOLD-form">
  <div class="PopupOLD-input">
    <h6 class="Vfs-input-title" data-cy="contactpage-maintitle">
      {{ 'contact/form/subject/title' | lowercase | translate }}
    </h6>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch" data-cy="contactpage-subject">
      <mat-select formControlName="subject" class="fontcolor"
        [placeholder]="'contact/form/subject/placeholder' | lowercase | translate">
        <mat-option class="Option" value="Sales and Product Inquiries" data-cy="contactpage-subjectitem1">
          {{ 'contact/form/subject/SalesAndProduct' | lowercase | translate }}</mat-option>
        <mat-option class="Option" value="Financing Service" data-cy="contactpage-subjectitem2">
          {{ 'contact/form/subject/financingService' | lowercase | translate }}</mat-option>
        <mat-option class="Option" value="Product and Service Support" data-cy="contactpage-subjectitem3">
          {{ 'contact/form/subject/productAndServiceSupport' | lowercase | translate }}</mat-option>
        <mat-option class="Option" value="Feedback" data-cy="contactpage-subjectitem4">{{
          'contact/form/subject/feedback' | lowercase | translate
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="PopupOLD-input">
    <h6 class="Vfs-input-title" data-cy="contactpage-countrylable">
      {{ 'form/country' | lowercase | translate }}
    </h6>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <mat-select formControlName="location" [(ngModel)]="autoSelectCountry.id"
        [placeholder]="'form/dropdownCountry' | lowercase | translate" (selectionChange)="onSelect($event)"
        data-cy="contactpage-countrydropdown">
        <mat-option class="volvofont CPrimary" *ngFor="let country of dealershipCountries" [value]="country.id"
          data-cy="contactpage-countrydditems">{{ country.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="PopupOLD-input">
    <h6 class="Vfs-input-title" data-cy="contactpage-dealershiplable">
      {{ 'form/dealership' | lowercase | translate }} &nbsp;<span>({{ 'form/optional' | lowercase | translate }})</span>
    </h6>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <mat-select [disabled]="currentLang === undefined"
        [placeholder]="'form/dropdownDealership' | lowercase | translate" formControlName="city"
        data-cy="contactpage-dealershipdropdown">
        <mat-option class="Option" *ngFor="let city of showCities" [value]="city"
          data-cy="contactpage-dealershipdditems">
          {{ city }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="PopupOLD-input">
    <h6 class="Vfs-input-title">{{ 'form/fullName' | lowercase | translate }}</h6>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <input formControlName="fullName" matInput data-cy="contactpage-fullname" />
    </mat-form-field>
  </div>
  <div class="PopupOLD-input">
    <h6 class="Vfs-input-title">{{ 'form/phoneNumber' | lowercase | translate }}</h6>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <input formControlName="phone" type="tel" matInput data-cy="contactpage-phnumber" />
    </mat-form-field>
  </div>

  <div class="PopupOLD-input">
    <h6 class="Vfs-input-title">{{ 'form/email' | lowercase | translate }}</h6>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <input formControlName="email" type="email" matInput data-cy="contactpage-email" />
    </mat-form-field>
  </div>

  <div class="PopupOLD-input">
    <h6 class="Vfs-input-title">
      {{ 'form/message' | lowercase | translate }} &nbsp;<span>({{ 'form/optional' | lowercase | translate }})</span>
    </h6>
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <textarea formControlName="message" matInput rows="5"></textarea>
    </mat-form-field>
  </div>

  <div class="MatInputSearchVfs checkboxPadding">
    <mat-checkbox formControlName="privacy" (click)="recaptcha()" class="big" data-cy="contactpage-privacychkbox">
      <div class="Vfs-privacy">
        {{ 'form/contactingVolvoPrivacyPolicy' | lowercase | translate }}
        <a class="regularFont" href="{{ checkbox }}" target="_blank" style="text-decoration: underline;">
          <span>{{ 'form/privacyPolicy' | lowercase | translate }}</span>
        </a>
      </div>
    </mat-checkbox>
  </div>
  
  <div *ngIf="showRecaptcha" class="PopupOLD-input" style="margin-bottom: 15px;display: inline-block;">
    <re-captcha (resolved)="resolved($event)" siteKey="{{ recaptchaToken }}" required></re-captcha>
  </div>

  <br />
  <div class="buttonCss">
    <button  type="submit" 
       data-cy="contactpage-submitbtn" [ngClass]="this.form.invalid || !reCaptchaRes?'buttonCss-btnDisbaleColor':''" >
      {{ 'form/sendMessage' | lowercase | translate }}
    </button>
  </div>
</form>
