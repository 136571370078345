import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ScreenUtils {
    static isTablet(): boolean {
        const topMd = 1068;
        return Boolean(window.innerWidth < topMd);
    }

    static isDesktop(): boolean {
        const topMd = 1068;
        return Boolean(window.innerWidth > topMd);
    }

    static isPhone(): boolean {
        const topMd = 768;
        return Boolean(window.innerWidth < topMd);
    }
}
