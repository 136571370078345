import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { DefaultHttpClient, HttpRequest, HttpResponse } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../auth/token.service';
import { TranslationService } from '../translations/translation-service';
import { Location } from '@angular/common';
import { LocalesService } from '~/core/store/locales/locales.service';

@Injectable({
    providedIn: 'root'
})
export class SignalrService {
    connection: signalR.HubConnection;
    UpdateLanguageAsync: BehaviorSubject<string>;

    tokenService: TokenService;

    constructor(
        httpClient: HttpClient,
        private translationService: TranslationService,
        private translateService: TranslateService,
        private location: Location,
        private localesService: LocalesService
    ) {
        this.UpdateLanguageAsync = new BehaviorSubject<string>(null)
        this.tokenService = new TokenService(httpClient, location, localesService)
    }

    getCurrentLanguage(): string {
        let lang: any;
        lang = JSON.parse(localStorage.getItem('language'));
        if (!lang) {
            lang = JSON.parse(sessionStorage.getItem('language'));
        }
        if (!lang) {
            lang = { lang: { curLang: { id: localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE') } } };
        }

        const langCode = lang.lang.curLang.id === null ? 'en' : lang.lang.curLang.id;
        return langCode;
    }

    async loadTrans() {
        const langCode = this.getCurrentLanguage();

        var data = await lastValueFrom(this.translationService.getLanguage(langCode));

        this.translateService.setTranslation(langCode, data, true);
    }

    public initiateSignalrConnection(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.tokenService.load(false).then(() => {
                this.connection = new signalR.HubConnectionBuilder()
                    .withUrl(environment.signalrUrl + 'translationHub/', { accessTokenFactory: () => this.tokenService.getToken() })
                    .configureLogging(signalR.LogLevel.Information)
                    .build();

                this.setSignalrClientMethods();

                this.connection
                    .start()
                    .then(() => {
                        
                        resolve();
                    })
                    .catch((error) => {
                        
                        resolve();
                    });
            });
        });
    }

    private setSignalrClientMethods(): void {
        this.connection.on('UpdateLanguageAsync', (langCode: string) => {
            if (this.getCurrentLanguage() === langCode) {
                
                this.loadTrans();
            }
            //this.UpdateLanguageAsync.next(message);
        });
    }
}
