import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HtmlRoutePages } from '../../../app-routing.model';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { ScreenUtils } from 'src/app/core/utils/screen-utils/screen-utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-vehicle-offer-selected-page',
  templateUrl: './vehicle-offer-volvo-selected-page.component.html',
  styleUrls: ['./vehicle-offer-volvo-selected-page.component.scss']
})
export class VolvoVehicleOfferSelectedPageComponent implements OnInit {
  HtmlRoutePages: HtmlRoutePages;
  isMobileOrTablet: boolean = false;
  constructor(private router: Router,private titleService: Title,
    private translateService: TranslateService,
    private metaTagService: Meta,
    private breadCrumbService: BreadcrumbserviceService,
    private globalUtils: GlobalUtils,
    private deviceService: DeviceDetectorService,
    public assetService: AssetService) {
      if (this.globalUtils.isBrowser() && (this.deviceService.isMobile() || this.deviceService.isTablet())) {
        this.isMobileOrTablet = true;
      }

      
    }
  ngOnInit(): void {

    this.updateMetaTags('uk/vehicleoffers/selected/');
    
    //if(this.isMobileOrTablet)
      //this.breadCrumbService.updateBreadcrumb('Vehicle Offers' , 'Volvo Selected', HtmlRoutePages.vehicleOffers);
  }
  selectedList = [
    {
      type: 'uk/pages/vehicleOffer/selected/title1',
      label: [
        { name: 'uk/pages/vehicleOffer/selected/title1/text1'},
        { name: 'uk/pages/vehicleOffer/selected/title1/text2' },
        { name: 'uk/pages/vehicleOffer/selected/title1/text3' },
        { name: 'uk/pages/vehicleOffer/selected/title1/text4' },
        { name: 'uk/pages/vehicleOffer/selected/title1/text5' },
        { name: 'uk/pages/vehicleOffer/selected/title1/text6' },
        { name: 'services/premiumquality/predelivery' },
        { name: 'services/premiumquality/vospschedule' }
      ]
    },
    {
      type:'uk/pages/vehicleOffer/selected/title2',
      label:[
        {name:'uk/pages/vehicleOffer/selected/title2/text1'},
        {name:'uk/pages/vehicleOffer/selected/title2/text2'},
        {name:'uk/pages/vehicleOffer/selected/title2/text3'},
        {name:'uk/pages/vehicleOffer/selected/title2/text4'},
      ]
    },
    {
      type:'uk/pages/vehicleOffer/selected/title3',
      label:[
        {name:'uk/pages/vehicleOffer/selected/title3/text1'},
        {name:'uk/pages/vehicleOffer/selected/title3/text2'},
        {name:'uk/pages/vehicleOffer/selected/title3/text3'},
        {name:'uk/pages/vehicleOffer/selected/title3/text4'},
      ]
    },
  ];

  updateMetaTags(path: string): void {
    const fullPath = `${path.toLowerCase()}metatitle`;
    const metatitle = this.translateService.instant(fullPath);
    const metadescription = this.translateService.instant(`${path.toLowerCase()}metadescription`);
    const altImage = this.translateService.instant(`${path.toLowerCase()}altimage`);
    const metakeywords = this.translateService.instant(`${path.toLowerCase()}metakeywords`);

    this.titleService.setTitle(metatitle);

    this.metaTagService.updateTag({ name: 'og:title', content: metatitle });
    this.metaTagService.updateTag({ name: 'og:description', content: metadescription });
    this.metaTagService.updateTag({ name: 'og:image', alt: 'alt', content: altImage });

    // Additional traditional meta tags for search engines
    this.metaTagService.updateTag({ name: 'description', content: metadescription });
    this.metaTagService.updateTag({ name: 'keywords', content: metakeywords });
  }
}
