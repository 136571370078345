import { Component, OnInit } from '@angular/core';
import { SalesTeam } from '../contact-page.model';

@Component({
  selector: 'volvo-centre-washington',
  templateUrl: './centre-washington.component.html',
  styleUrls: ['../centre-banbury/centre-banbury.component.scss']
})
export class CentreWashingtonComponent implements OnInit {
  mapCentre:string = 'Volvo Truck and Bus Centre Washington';
  public isLoading = true;
  constructor() { }

  onImageLoad() {        
    this.isLoading = false;
  }
  onError(): void {
      console.error('Image failed to load.');
      this.isLoading = false; // You may want to handle this differently
  }
  ngOnInit(): void {
  }
  salesTeamMangementDetails: SalesTeam[] = [
    {
      imageUrl: '/assets/pages/contactus/ns/Barry.webp',
        imageName: 'Barry Knight',
        imageDesignation: 'uk/pages/contact/salesExecutive6',
        imageCentre: '',
        imageEmail: 'barry.knight@volvo.com',
        imageMobile: '07843 803973',
    },
 ]
}
