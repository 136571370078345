import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'volvo-truck-model-skeleton',
  templateUrl: './truck-model-skeleton.component.html',
  styleUrls: ['./truck-model-skeleton.component.scss']
})
export class TruckModelSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
