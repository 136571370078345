import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {Injectable} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';

import {GetBucketAggregations} from '~/core/store/buckets/buckets.actions';
import {BucketAggregations} from '~/core/store/buckets/buckets.model';


@Injectable({providedIn: 'root'})
export class BucketsService {

    @Select((state) => state.buckets.buckets)
    public buckets$: Observable<BucketAggregations>;

    @Dispatch()
    public getBucketAggregations() {
        return new GetBucketAggregations();
    }
}
