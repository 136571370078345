<header class="Popup-header bgColor CPrimary">
  <b>{{ 'popups/search/title' | lowercase | translate }}</b>
</header>

<div class="Popup-wp Popup-WithFooter">
  <mat-form-field class="Search-input">
    <input (change)="onSearch()" [(ngModel)]="searchInput" matInput
      [placeholder]="'popups/search/placeholder' | lowercase | translate" />

    <button (click)="onSearch()" mat-icon-button matSuffix><i class="fas fa-search CPrimary"></i></button>
  </mat-form-field>

  <mat-radio-group (change)="onSearch()" [(ngModel)]="searchInput" class="SelectList">
    <mat-radio-button *ngFor="let item of makeBuckets?.buckets" [value]="item.key" class="volvofont CPrimary">
      {{ item.key }}

      <i class="fas fa-check"></i>
    </mat-radio-button>
  </mat-radio-group>
</div>

<div class="Popup-footer">
  <button (click)="onClose()" mat-button class="Popup-close">
    {{ 'global/close' | lowercase | translate }}
  </button>
</div>
