<div class="Truck IsSkeleton skeleton-marginBottom">
    <div class="Skeleton skeleton-large-rect"></div>
    <div class="SkeletonLight skeleton-half-rect"></div>
    <div class="SkeletonLight skeleton-half-small-rect"></div>
    <div class="SkeletonLight skeleton-middle-rect skeleton-marginBottom"></div>


    <div class="SkeletonLight skeleton-half-rect"></div>
    <div class="container">
        <div class="box">
            <div class="Skeleton skeleton-box-rect"></div>
            <div class="SkeletonLight skeleton-middle-rect"></div>
        </div>
        <div class="box">
            <div class="Skeleton skeleton-box-rect"></div>
            <div class="SkeletonLight skeleton-middle-rect"></div>
        </div>
        <div class="box">
            <div class="Skeleton skeleton-box-rect"></div>
            <div class="SkeletonLight skeleton-middle-rect"></div>
        </div>
    </div>
    <div class="SkeletonLight skeleton-half-rect"></div>
    <div class="container">
        <div class="box">
            <div class="Skeleton skeleton-box-rect"></div>
            <div class="SkeletonLight skeleton-middle-rect"></div>
        </div>
        <div class="box">
            <div class="Skeleton skeleton-box-rect"></div>
            <div class="SkeletonLight skeleton-middle-rect"></div>
        </div>
        <div class="box">
            <div class="Skeleton skeleton-box-rect"></div>
            <div class="SkeletonLight skeleton-middle-rect"></div>
        </div>
    </div>

</div>
