<i (click)="onClose()" class="PopupOLD-close material-icons SoldPopupIcon showForMobile">close</i>
<div *ngIf="truckRemoved; else others">
  <div class="SoldPopup">
    <div class="textIconDisplay">
      <h5 class="SoldPopup-title">{{ 'truck/soldtext1' | lowercase | translate }}</h5>
      <i (click)="onClose()" class="PopupOLD-close material-icons SoldPopupIcon showForDesktop">close</i>
    </div>
      <div class="SoldPopup-subtitle">
        {{ 'truck/soldtext2' | lowercase | translate }}
      </div>

      <div class="SoldPopup-subtitle">
        {{ 'truck/browseourstock1' | lowercase | translate }}
      </div>
      <div class="SoldPopup-buttons">
        <!-- <button (click)="onClose()" mat-button class="MatBtn SoldPopup-buttons-light">
            {{ 'global/close' | lowercase | translate }}
        </button> -->
        <button (click)="navigateToStockPage()" mat-button color="primary" class="MatBtn SoldPopup-buttons-dark">
          {{ 'truck/browse' | lowercase | translate }}
        </button>
      </div>
    </div>
  </div>
<ng-template #others>
  <div class="SoldPopup">
    <div class="textIconDisplay">
      <h5 class="SoldPopup-title">{{ 'truck/soldtext' | lowercase | translate }}</h5>
      <i (click)="onClose()" class="PopupOLD-close material-icons SoldPopupIcon showForDesktop">close</i>
    </div>
    
    
    <!--
    <div class="Title-maintitle">{{ 'SoldPopup/title' | lowercase | translate }}</div> -->

    <div class="SoldPopup-subtitle">
      {{ 'truck/browseourstock' | lowercase | translate }}
    </div>
    <div class="SoldPopup-buttons">
      <!-- <button (click)="onClose()" mat-button class="MatBtn SoldPopup-buttons-light">
          {{ 'global/close' | lowercase | translate }}
      </button> -->
      <button (click)="navigateToStockPage()" mat-button color="primary" class="MatBtn SoldPopup-buttons-dark">
        {{ 'truck/browse' | lowercase | translate }}
      </button>
    </div>
  </div>
</ng-template>