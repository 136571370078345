import { Injectable } from '@angular/core';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbserviceService {

  constructor(private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private localizeRouterService: LocalizeRouterService) { }

    updateBreadcrumb(label1: string, label2: string, route1: string): void {
      const breadcrumbsConts  =  [
        {
          label: label1,
          url:this.localizeRouterService.translateRoute(route1) as string
        },
        {
          label: label2,
          url:""
        }
      ];
      this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbsConts);
    }
}
