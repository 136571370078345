import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import {
    LocalesStateLang,
    LocalesStateLangList,
} from "~/core/store/locales/locales.model";
import { LocalesService } from "~/core/store/locales/locales.service";

@Component({
    selector: "volvo-privacy-policy-page",
    templateUrl: "./privacy-policy-page.component.html",
    styleUrls: ["./privacy-policy-page.component.scss"],
})
export class PrivacyPolicyPageComponent implements OnInit {
    private s0: Subscription;
    lang: LocalesStateLangList;
    privacyHref: string;

    privacyLinks = [
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/data-subject-rights.html",
            id: "en",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-dk/data-subject-rights.html",
            id: "dk",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-cz/data-subject-rights.html",
            id: "cz",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-ru/data-subject-rights.html",
            id: "ru",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-lt/data-subject-rights.html",
            id: "lt",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-fr/data-subject-rights.html",
            id: "fr",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-es/data-subject-rights.html",
            id: "es",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-no/data-subject-rights.html",
            id: "no",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-it/data-subject-rights.html",
            id: "it",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-fi/data-subject-rights.html",
            id: "fi",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-pl/data-subject-rights.html",
            id: "pl",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-hu/data-subject-rights.html",
            id: "hu",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-sk/data-subject-rights.html",
            id: "sk",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-si/data-subject-rights.html",
            id: "si",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-at/data-subject-rights.html",
            id: "at",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-ro/data-subject-rights.html",
            id: "ro",
        },
        {
            href:
                "https://www.volvotrucks.ba/bs-ba/tools/privacy/data-subject-rights.html",
            id: "bs",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-mk/data-subject-rights.html",
            id: "mk",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-sr/data-subject-rights.html",
            id: "rs",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-hr/data-subject-rights.html",
            id: "hr",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-de/data-subject-rights.html",
            id: "de",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-nl.html",
            id: "be-nl",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-fr.html",
            id: "be-fr",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-nl/data-subject-rights.html",
            id: "nl",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-bg/data-subject-rights.html",
            id: "bg",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-se/data-subject-rights.html",
            id: "se",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-ee/data-subject-rights.html",
            id: "ee",
        },
        {
            href:
                "https://www.volvogroup.com/en-en/privacy/privacy-lv/data-subject-rights.html",
            id: "lv",
        },
        {
            href:
                "https://www.volvotrucks.ch/de-ch/tools/privacy/data-subject-rights.html",
            id: "ch-de",
        },
        {
            href:
                "https://www.volvotrucks.ch/fr-ch/tools/privacy/data-subject-rights.html",
            id: "ch-fr",
        },
    ];

    constructor(private localesService: LocalesService) {}

    ngOnInit(): void {
        this.s0 = this.localesService.lang$.subscribe(
            (lang: LocalesStateLang) => {
                this.lang = lang.curLang;
                this.privacyHref = this.privacyLinks.find(
                    (x) => x.id === this.lang.id
                ).href;
            }
        );
    }
}
