import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

import { SearchService } from '~/core/store/search/search.service';
import { SearchStateFilter } from '~/core/store/search/search.model';
import { HtmlRoutePages } from '~/app-routing.model';


@AutoUnsubscribe()
@Component({
  selector: 'volvo-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.scss']
})
export class HomeSearchComponent implements OnInit, OnDestroy {
  HtmlRoutePages = HtmlRoutePages;

  inputSearch: string;
  search: SearchStateFilter;

  private s1: Subscription;

  constructor(
    private searchService: SearchService,
  ) {
    this.initSubscriptions();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void { }

  async onSearch(): Promise<void> {
    this.searchService.updateStep({
      key: 'majorCategory',
      value: {
        ...this.search.steps.majorCategory,
        isSelected: true,
        value: this.inputSearch
      },
      expandingFilter: '',
      reloadFlag: false
    });
  }

  private initSubscriptions(): void {
    this.s1 = this.searchService.filter$.subscribe((searchOptions: SearchStateFilter) => {
      this.inputSearch = searchOptions.steps.majorCategory.value as string;
      this.search = searchOptions;
    });
  }
}
