import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { HtmlRoutePages } from '~/app-routing.model';
import { toggleHeight } from '~/animations';

export enum CookiesStatus {
    allow = 'allow',
    dismiss = 'dismiss',


}

@Component({
    selector: 'volvo-superstructure-details-popup',
    templateUrl: './cookies-popup.component.html',
    styleUrls: ['./cookies-popup.component.scss'],
    animations: [toggleHeight]
})
export class CookiesPopupComponent implements OnInit {
    HtmlRoutePages = HtmlRoutePages;
    CookiesStatus = CookiesStatus;
    toggleDetails = false;

    allowPref = false;
    allowStatistics = false;
    allowMarketing = false;

    constructor(
        public dialogRef: MatDialogRef<CookiesPopupComponent>
    ) { }

    ngOnInit(): void {
    }

    setCookies(status: CookiesStatus): void {
        if (status === CookiesStatus.allow) {
            this.allowPref = true;
            this.allowMarketing = true;
            this.allowStatistics = true;
        }
        const data = {
            status,
            allowPref: this.allowPref,
            allowStatistics: this.allowStatistics,
            allowMarketing: this.allowMarketing
        }

        this.dialogRef.close(data);
    }
}
