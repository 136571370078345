import { Component, OnInit,AfterViewInit , ElementRef, ViewChild, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CentreBanburyComponent } from '../centre-banbury/centre-banbury.component';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '../../../core/declaration/declaration';
@Component({
  selector: 'volvo-map-centre',
  templateUrl: './map-centre.component.html',
  styleUrls: ['./map-centre.component.scss']
})
export class MapCentreComponent implements AfterViewInit,OnInit  {
  title = 'angular-gmap';
  mapJson: string;
  jsonObj: any;
  lat :any;// = 52.03299218345177;
  lng : any;// = -1.2914043732215004;
  street:string;
  city:string;
  centreName:string;
  country:string;
  postalCode:string;
  @Input() centre;
  @Input() dynamicClassFlag: boolean;
  regionEnum=regionEnum;
  region:string;
  constructor(private http: HttpClient,private regionSettingService:RegionalSettingsService){
    this.mapJson = "../../../assets/pages/contactus/centres.json";
    this.getJSON().subscribe(data => {
    this.jsonObj = data.filter(e=>e.name == this.centre);
    if(this.jsonObj.length > 0){
      this.lat = this.jsonObj[0].lat;
      this.lng = this.jsonObj[0].lng;
      this.street = this.jsonObj[0].centreLocation.address;
      this.city =this.jsonObj[0].centreLocation.city;
      this.centreName =  this.jsonObj[0].centreLocation.centreName;
      this.country = this.jsonObj[0].centreLocation.country;
      this.postalCode = this.jsonObj[0].centreLocation.postalCode;
      this.mapInitializer();
    }
    });
   
  }
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
    map: any;
 
    ngOnInit(){ 
      this.regionSettingService.getRegion$.subscribe((res:any) => {
        this.region=res;
      });
    }
    ngAfterViewInit() {
      
      //this.mapInitializer();
    }

    mapInitializer() {
      const coordinates = new google.maps.LatLng(this.lat, this.lng);
      this.map = new google.maps.Map(this.gmap.nativeElement, {
        center: coordinates,
        zoom: 10,
        gestureHandling: 'cooperative'});

      if (!this.map) return;
      this.map.setCenter(coordinates);

      const  marker = new google.maps.Marker({
        position: new google.maps.LatLng(this.lat, this.lng),
        map: this.map,
      });
      
      marker.setMap(this.map);
    }
    public getJSON(): Observable<any> {
      return this.http.get(this.mapJson);
    }
}
