import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";

import {
    LocalesStateData,
    LocalesStateInterface,
    LocalesStateLang,
    LocalesStateRegion,
    LocalesStateCountry,
} from "./locales.model";
import { InitLanguage, SetLocales } from "./locales.actions";

@Injectable({ providedIn: "root" })
export class LocalesService {
    @Select((state) => state.language.lang)
    public lang$: Observable<LocalesStateLang>;

    @Select((state) => state.language.data)
    public data$: Observable<LocalesStateData>;

    @Select((state) => state.language.region)
    public region$: Observable<LocalesStateRegion>;

    @Select((state) => state.language.country)
    public country$: Observable<LocalesStateCountry>;

    @Dispatch()
    public setLocales(payload: LocalesStateInterface) {
        return new SetLocales(payload);
    }

    @Dispatch()
    public initLanguage() {
        return new InitLanguage();
    }
}
