import { Component, OnInit } from '@angular/core';
import { HtmlRoutePages } from '../../../../app-routing.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ScreenUtils } from '../../../../core/utils/screen-utils/screen-utils';
import { BreadcrumbService } from 'angular-crumbs-2';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'volvo-economy-service-page',
  templateUrl: './faq-economy-page.component.html',
  styleUrls: ['./faq-economy-page.component.scss']
})
export class VolvoEconomyFAQPageComponent implements OnInit {
  HtmlRoutePages: HtmlRoutePages;
  isTablet: boolean = false;
  faqList = [
    {
      title: 'uk/pages/services/faq/title1',
      description: 'uk/pages/services/faq/title1/text2'
    },
    {
      title: 'uk/pages/services/faq/title2',
      description: 'uk/pages/services/faq/title2/text2'
    },
    {
      title: 'uk/pages/services/faq/title4',
      description: 'uk/pages/services/faq/title4/text1'
    },
    {
      title: 'uk/pages/services/faq/title5',
      description: 'uk/pages/services/faq/title5/text1'
    },
    {
      title: 'uk/pages/services/faq/title6',
      description: 'uk/pages/services/faq/title6/text2'
    },
    {
      title: 'uk/pages/services/faq/title7',
      description: 'uk/pages/services/faq/title7/text2'
    },
    {
      title: 'uk/pages/services/faq/title8',
      description: 'uk/pages/services/faq/title8/text2'
    },
    {
      title: 'uk/pages/services/faq/title13',
      description: 'uk/pages/services/faq/title13/text1'
    },
    {
      title: 'uk/pages/services/faq/title14',
      description: 'uk/pages/services/faq/title14/text1'
    },
    {
      title: 'uk/pages/services/faq/title15',
      description: 'uk/pages/services/faq/title15/text1'
    },
    {
      title: 'uk/pages/services/faq/title16',
      description: 'uk/pages/services/faq/title16/text1'
    },
    {
      title: 'uk/pages/services/faq/title17',
      description: 'uk/pages/services/faq/title17/text1'
    },
    {
      title: 'uk/pages/services/faq/title18',
      description: 'uk/pages/services/faq/title18/text1'
    },
    {
      title: 'uk/pages/services/faq/title19',
      description: 'uk/pages/services/faq/title19/text1'
    },
    {
      title: 'uk/pages/services/faq/title10',
      description: 'uk/pages/services/faq/title10/text1'
    }
  ];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private titleService: Title,private translateService: TranslateService,private metaTagService: Meta
  ) {}
  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('uk/services/economyfaq/metatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:title',
        content: this.translateService.instant('uk/services/economyfaq/metatitle'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('uk/services/economyfaq/metadescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('uk/services/economyfaq/altImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('uk/services/economyfaq/metatitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/services/economyfaq/metatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/services/economyfaq/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/services/economyfaq/altImage'.toLowerCase())
    });
    if (ScreenUtils.isTablet()) this.isTablet = true;
    if (!this.isTablet) {
      this.breadcrumbService.changeBreadcrumb(
        this.route.snapshot,
        'FAQ - Volvo Economy'
      );
    } else {
      this.breadcrumbService.changeBreadcrumb(this.route.snapshot, 'FAQ');
    }
  }
}
