import { Injectable } from '@angular/core';
import cleanDeep from 'clean-deep';
import { Base64 } from 'js-base64';
import qs from 'qs';

import { SearchStateParams, SearchSteps, SortOptions, TruckCardTypes } from '~/core/store/search/search.model';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';

export interface SearchParams {
  q?: string;
  page?: number;
  sort: SortOptions;
  category?: string;
  superstructureType?: string;
  model?: string;
  make?: string;
  horsePower?: SliderRange;
  priceExclVatEuro?: SliderRange;
  mileageKm?: SliderRange;
  modelYear?: SliderRange;
  axleConfiguration?: string;
  fuelType?: string;
  chassisHeight?: string;
  requestingFilter?: string;
  expandingFilter?: string;
}

interface SliderRange {
  min: number;
  max: number;
}

export interface EncodedParams {
  q?: string;
  page?: number;
  sort?: SortOptions;
  cardsType?: TruckCardTypes;
  steps?: SearchSteps;
  requestingFilter?: string;
  expandingFilter?: string;
}

export interface DecodedParams {
  q?: string;
  page?: number;
  sort?: SortOptions;
  cardsType?: TruckCardTypes;
  steps?: { [key: string]: { value: string } };
  requestingFilter?: string;
  expandingFilter?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiUtils {
  constructor(private globalUtils: GlobalUtils) { }

  static removeEmptyValues(obj: { [key: string]: any }) {
    // clean object from empty values
    return cleanDeep(obj);
  }

  static encodeParams(q: string): string {
    return Base64.encode(q);
  }

  static decodeParams(q: string): any {
    return Base64.decode(q);
  }

  private static toParams(params: SearchSteps): any {
    const allValue = 'All';
    return Object.keys(params).reduce((result, key) => {
      if (params[key].isSelected && params[key].value !== allValue) {
        result[key] = params[key].value;
      }
      return result;
    }, {});
  }

  private static fromParams(params: any): { [key: string]: { value: string } } {
    const key = 0;
    const value = 1;

    return Object.entries(params).reduce((res, item) => {
      res[item[key]] = {
        value: item[value],
        isSelected: true,
        isExpanded: true,
      };
      return res;
    }, {});
  }

  getDecodedParamsforPageReload(searchParams: SearchStateParams): any {
    if (searchParams && searchParams.qf && this.globalUtils.isBrowser()) {
      const stringParams = ApiUtils.decodeParams(searchParams.qf);
      const { page, ...params } = qs.parse(stringParams, {
        allowDots: true,
      });
      const steps: {
        [key: string]: { value: string };
      } = ApiUtils.fromParams(params);
      const requestingFilter = stringParams.requestingFilter;
      const expandingFilter = stringParams.expandingFilter;
      return { page };
    }
    return {
      page: 1
    };
  }

  getDecodedParams(searchParams: SearchStateParams): any {
    if (searchParams && searchParams.qf && this.globalUtils.isBrowser()) {
      const stringParams = ApiUtils.decodeParams(searchParams.qf);
      const { q, page, sort, ...params } = qs.parse(stringParams, {
        allowDots: true,
      });
      const steps: {
        [key: string]: { value: string };
      } = ApiUtils.fromParams(params);
      const requestingFilter = stringParams.requestingFilter;
      const expandingFilter = stringParams.expandingFilter;
      return { q, page, sort, steps };
    }

    return {
      q: '',
      page: 1,
      sort: SortOptions.oldestAge,
      steps: {},
    };
  }

  getEncodedParams(params?: EncodedParams): string | null {
    if (!this.globalUtils.isBrowser()) {
      return null;
    }

    let searchParams: SearchParams;

    if (params) {
      searchParams = {
        q: params.q,
        page: params.page,
        sort: params.sort,
        ...ApiUtils.toParams(params.steps),
      };

      searchParams.requestingFilter = params.requestingFilter;
      searchParams.expandingFilter = params.expandingFilter;

      const defaultPage = 1;
      if (searchParams.page === defaultPage) {
        // hide default page number value from search params
        searchParams.page = null;
      }

      if (searchParams.sort === SortOptions.oldestAge) {
        // hide default sort options from search params
        searchParams.sort = null;
      }

      // clean object from empty values
      searchParams = cleanDeep(searchParams);
    }

    // convert object to dot string notation
    const searchStringParams: string = qs.stringify(searchParams, {
      allowDots: true,
    });

    return searchStringParams ? ApiUtils.encodeParams(searchStringParams) : null;
  }
}
