<section class="volvo" *ngIf="this.region!==regionEnum.TX">
    <div class="tabMobSidePadding">
        <div class="volvo-volvoDiv" id="economy">
            <div class="tabMobHeroMargin">
                <img class="volvo-volvoImg" [src]="assetService.getAssetUrl('assets/pages/services/6Months.webp')" alt=""/>
            </div>
            <div class="volvo-title">
                <span>{{ "uk/pages/services/volvoEconomy" | lowercase | translate }}</span>
            </div>
            <div class="volvo-text">
                <span>{{ "uk/pages/services/volvoEconomy/text1" | lowercase | translate }}</span>
            </div>
            <div class="volvo-text">
                <span>{{ "uk/pages/services/volvoEconomy/text2" | lowercase | translate }}</span>
            </div>
            <div class="volvo-text">
                <div class="volvo-text-listdiv">
                    <ul class="volvo-text-medium" *ngFor="let list of economyList">
                        <span>{{ list.type | lowercase | translate }}</span>
                        <li *ngFor="let label of list.label">
                            <span>{{ label.name | lowercase | translate }}</span>
                        </li>
                        <div class="volvo-text-listdiv-excluded">
                            <span>{{ list.excluding | lowercase | translate }}</span>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="volvo-disClaimer">
                {{ "uk/pages/services/volvoSelected/disclaimer" | lowercase | translate }}
            </div>
            <div class="volvo-text" id="financing">
                <button class="navigation-button faqmargin" (click)="routePage()">
                    <span>
                        <div>
                            <div>
                                <span class="navigation-button-text">
                                    {{ "uk/components/footer/faq" | lowercase | translate }}
                                </span>
                            </div>
                            <div>
                                <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')" alt=""/>
                            </div>
                        </div>
                    </span>
                </button>

                <a href="assets/files/UT Volvo Economy Fact Sheet - April 2024.pdf" target="_blank">
                    <button class="navigation-button">
                        <div>
                            <div>
                                <span class="navigation-button-text">
                                    {{ "uk/pages/services/economyfact" | lowercase | translate }}
                                </span>
                            </div>
                            <div>
                                <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')" alt=""/>
                            </div>
                        </div>
                    </button>
                </a>

            </div>
        </div>
        </div>
</section>
<volvo-footer></volvo-footer>
