import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { GetTruckById, GetLatestTrucks, SetActiveTruck, GetOldestTrucks, SetEmailFlag, GetNewestTrucks } from '~/core/store/trucks/trucks.actions';
import { Truck, EmailFlagStateInterface } from '~/core/store/trucks/trucks.model';


@Injectable({ providedIn: 'root' })
export class TrucksService {

    @Select((state) => state.trucks.activeTruck)
    public activeTruck$: Observable<Truck>;

    @Select((state) => state.trucks.truckById)
    public truckById$: Observable<Truck>;

    @Select((state) => state.trucks.latestTrucks)
    public latestTrucks$: Observable<Truck[]>;

    @Select((state) => state.trucks.newestTrucks)
    public newestTrucks$: Observable<Truck[]>;

    @Select((state) => state.trucks.oldestTrucks)
    public oldestTrucks$: Observable<Truck[]>;

    @Select((state) => state.emailFlagState)
    public emailFlag$: Observable<EmailFlagStateInterface>;

    @Dispatch()
    public setActiveTruck(payload: Truck) {
        return new SetActiveTruck(payload);
    }

    @Dispatch()
    public getTruckById(payload: string) {
        return new GetTruckById(payload);
    }

    @Dispatch()
    public getLatestTrucks() {
        return new GetLatestTrucks();
    }

    @Dispatch()
    public getOldestTrucks() {
        return new GetOldestTrucks();
    }

    @Dispatch()
    public getNewestTrucks() {
        return new GetNewestTrucks();
    }
}
