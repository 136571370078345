import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'volvo-contact-skeleton',
  templateUrl: './contact-skeleton.component.html',
  styleUrls: ['./contact-skeleton.component.scss']
})
export class ContactSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
