import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Subscription} from 'rxjs';

import {SortOptions, SearchStateFilter} from '~/core/store/search/search.model';
import {SearchService} from '~/core/store/search/search.service';


@AutoUnsubscribe()
@Component({
    selector: 'volvo-filters-popup',
    templateUrl: './sort-popup.component.html',
    styleUrls: ['./sort-popup.component.scss']
})
export class SortPopupComponent implements OnInit, OnDestroy {
    SortOptions = SortOptions;

    search: SearchStateFilter;

    private s1: Subscription;

    constructor(
        public dialogRef: MatDialogRef<SortPopupComponent>,
        private searchService: SearchService,
    ) {
        this.initSubscriptions();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    onClose() {
        this.dialogRef.close();
    }

    onSortChange(sortOption: SortOptions): void {
        this.searchService.updateSearch({
            ...this.search,
            sort: sortOption
        });
        this.dialogRef.close();
    }

    private initSubscriptions(): void {
        this.s1 = this.searchService.filter$
            .subscribe((searchOptions: SearchStateFilter) => {
                this.search = searchOptions;
            });
    }
}
