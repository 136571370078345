<section class="Stock">
  <div class="Stock-links">
    <button
      *ngFor="let link of links; let i = index"
      class="Stock-linkBtn"
      (click)="routePage(link)"
    >
      <span class="stock-button" data-cy="ourStocks-links">
        <div class="Stock-linkBtn-Wrapper">
          <div class="Stock-linkBtn-Wrapper-fontDiv">
            <span class="Stock-linkBtn-Wrapper-stockSpan"
              >{{"uk/pages/readMore" | lowercase | translate}}</span
            >
            <span class="Stock-linkBtn-Wrapper-stockInnerSpan">
              {{ link.label | lowercase | translate }}
            </span>
          </div>
          <div>
            <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')" alt="{{ 'pages/truckmodel/readmore' | lowercase | translate}}">          
          </div>
        </div>
      </span>
    </button>
  </div>
</section>
