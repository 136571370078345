import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

import { DealershipsService } from '~/core/store/dealerships/dealerships.service';
import { ContactInterface } from '~/core/store/dealerships/dealerships.model';
import { HtmlRoutePages } from '~/app-routing.model';
import { LocalesStateLang } from '~/core/store/locales/locales.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { RegionalSettingsService } from '~/core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '~/core/declaration/declaration';
import { CentreNavLinks } from '~/pages/contact-page/contact-page.model';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AssetService } from '~/core/services/assets/asset.service';

@AutoUnsubscribe()
@Component({
  selector: 'volvo-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit, OnDestroy {
  HtmlRoutePages = HtmlRoutePages;

  dealershipLocations: ContactInterface[] = null;
  dealershipLocationsFiltered: ContactInterface[] = null;

  private s0: Subscription;
  private currentLang: String;
  public isGenericLocale = false;
  errorMessage;
  regionEnum=regionEnum;
  region:string;
  site: string;
  isSelectedSite: boolean;
  isTXSite: boolean;
  
  constructor(
    private dealershipsService: DealershipsService,
    public localesService: LocalesService,
    private titleService: Title,
    private metaTagService: Meta,
    private translateService: TranslateService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private regionSettingService:RegionalSettingsService,
    private localizeRouterService: LocalizeRouterService,
    public assetService: AssetService
  ) {
    this.dealershipsService.getDealerships();

    this.initSubscriptions();
  }

  ngOnInit(): void {
    this.regionSettingService.regionalSettings$.subscribe(r => {
      this.region = r.regionsSettings[0].region;
      this.site = r.regionsSettings[0].site;
      if(this.site === 'volvoselected')
        this.isSelectedSite = true;
      else if (this.site === 'TX')
            this.isTXSite = true
      else
        this.isSelectedSite = false;
    });
    this.router.events.subscribe(() => {
      this.dealershipLocations = [];
      this.dealershipLocationsFiltered = [];
      this.titleService.setTitle(this.translateService.instant('contact/metaTitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('contact/metaDescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('contact/altImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('contact/metaTitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('contact/metaDescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('contact/altImage'.toLowerCase())
    });
  }

  ngOnDestroy(): void { }

  private setCentersByLocale(): void {
    this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
      if (lang.curLang.id === 'en' || lang.curLang.id === 'ru') {
        this.isGenericLocale = true;
        this.dealershipLocations = [];
        this.dealershipLocationsFiltered = [];
      } else {
        this.isGenericLocale = false;
        if (this.dealershipLocations) {
          this.dealershipLocationsFiltered = this.dealershipLocations;
        }
      }
    });
  }

  initSubscriptions(): void {
    this.setCentersByLocale();
    this.errorMessage = '';
    this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
      this.currentLang = lang.curLang.id;
      if (this.currentLang === 'en' || this.currentLang === 'ru') {
        this.dealershipLocationsFiltered = [];
      } else {
        const url = environment.apiUrl + this.currentLang + '/regions';
        this.s0 = this.http.get(url).subscribe(
          (dealershipLocations: ContactInterface[]) => {
            this.dealershipLocations = dealershipLocations;
            this.dealershipLocationsFiltered = this.dealershipLocations;
          },
          error => {
            this.dealershipLocations = [];
            this.dealershipLocationsFiltered = [];
            this.errorMessage = error;
            throw error;
          }
        );
      }
    });
  }
  routePage(link): void {
   this.router.navigate([this.localizeRouterService.translateRoute(link.path)], { relativeTo: this.route });
  }
  navlinks: CentreNavLinks[] = [
    {
        label: 'uk/pages/contact/goTo',
        name: 'uk/pages/contact/contactTitle',
        path: HtmlRoutePages.meetTeam,
  
    },
    {
      label: 'uk/pages/contact/goTo',
      name: 'uk/pages/contact/ourUserTruckCentre',
      path: HtmlRoutePages.ourCentres,
    },
  ];
}


