import {Component, Input, OnInit} from '@angular/core';


@Component({
    selector: 'volvo-phone',
    templateUrl: './phone.component.html',
    styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {
    @Input() phone: string;


    constructor() {
    }

    ngOnInit(): void {
    }
}
