import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hasValue'
})
export class HasValuePipe implements PipeTransform {

    public transform(value: string | number, hasValueSuffix = '', nullValue = '----'): string {
        if (!value) {
            return nullValue;
        } else {
            return `${value} ${hasValueSuffix}`;
        }
    }
}
