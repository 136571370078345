import { Component,OnInit } from '@angular/core';
import { HtmlRoutePages } from '../../../app-routing.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ScreenUtils } from '../../../core/utils/screen-utils/screen-utils';
import { BreadcrumbService } from 'angular-crumbs-2';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { regionEnum } from '../../../core/declaration/declaration';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-export-page',
    templateUrl: './volvo-export-page.component.html',
    styleUrls: ['./volvo-export-page.component.scss']
})

export class VolvoExportPageComponent implements OnInit{
  HtmlRoutePages:HtmlRoutePages;
  isTablet: boolean = false;
  toogleClass:boolean=true;
  region:string;
  regionEnum=regionEnum;
  constructor(private route: ActivatedRoute, private router: Router,
    private breadcrumbService: BreadcrumbService,private translateService: TranslateService,private metaTagService: Meta, 
    private regionalSettingsService:RegionalSettingsService,
    private breadCrumbService: BreadcrumbserviceService,
    public assetService: AssetService) {}
  
  ngOnInit(): void {
    // this.regionalSettingsService.getRegion$.subscribe((res:any) => {
    //   this.region=res;
    // });
    if (ScreenUtils.isTablet()) this.isTablet = true;
    if(this.isTablet)
    {
      this.breadcrumbService.changeBreadcrumb(this.route.snapshot, 'Export (Worldwide)');
    }
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('pages/services/export/metatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('pages/services/export/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('pages/services/export/altImage'.toLowerCase())
    });
    // Additional traditional meta tags for search engines
    this.metaTagService.updateTag({
      name: 'description',
      content: this.translateService.instant('pages/services/export/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.translateService.instant('pages/services/export/metakeywords'.toLowerCase())
    });
    
    //this.breadCrumbService.updateBreadcrumb('Services' , 'Export (Worldwide)', HtmlRoutePages.services);

  }

  exportObject = [
    {
      image: 'assets/pages/services/rorotx.webp',
      title:'uk/pages/services/volvoeconomy/title1',
      text:'uk/pages/services/volvoeconomy/text2'
    },
    {
      image: 'assets/pages/services/Containerisation.webp',
      title:'uk/pages/services/volvoeconomy/title2',
      text:'uk/pages/services/volvoeconomy/title2/text1'
    },
  ];
}
