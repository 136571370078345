import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SiteCheckService } from '~/core/services/siteName/site-check.service';

@Directive({
  selector: '[isUKSite]',
})
export class IsUKSiteDirective {
  private subscription: Subscription = new Subscription();
  @Input() validSites: string[] = ['ukwebportal'];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private siteCheckService: SiteCheckService
  ) {}

  ngOnInit(): void {
    this.checkAndDisplay();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private checkAndDisplay(): void {
    this.subscription.add(
      this.siteCheckService.getCurrentSiteName().subscribe(siteName => {
        if (siteName && this.validSites.includes(siteName)) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      })
    );
  }
}
