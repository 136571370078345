import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Router } from '@angular/router';

import { BucketAggregations, BucketsData } from '~/core/store/buckets/buckets.model';
import { BucketsService } from '~/core/store/buckets/buckets.service';
import { SearchStateFilter } from '~/core/store/search/search.model';
import { SearchService } from '~/core/store/search/search.service';
import { ApiUtils } from '~/core/utils/api-utils/api-utils';
import { HtmlRoutePages } from '~/app-routing.model';
import { Subscription } from 'rxjs';


@AutoUnsubscribe()
@Component({
    selector: 'volvo-search-popup',
    templateUrl: './search-popup.component.html',
    styleUrls: ['./search-popup.component.scss']
})
export class SearchPopupComponent implements OnInit, OnDestroy {
    HtmlRoutePages = HtmlRoutePages;

    search: SearchStateFilter;
    searchInput: string;
    makeBuckets: BucketsData;

    private s1: Subscription;
    private s2: Subscription;

    constructor(
        public dialogRef: MatDialogRef<SearchPopupComponent>,
        private router: Router,
        private apiUtils: ApiUtils,
        private searchService: SearchService,
        private bucketsService: BucketsService,
        private localizeRouterService: LocalizeRouterService
    ) {
        this.initSubscriptions();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    onClose() {
        this.dialogRef.close();
    }

    async onSearch(): Promise<void> {
        this.searchService.updateSearch({
            ...this.search,
            q: this.searchInput
        });

        this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.search)]);

        this.onClose();
    }

    private initSubscriptions(): void {
        this.s1 = this.searchService.filter$
            .subscribe((searchOptions: SearchStateFilter) => {
                this.searchInput = searchOptions.q;
                this.search = searchOptions;
            });

        this.s2 = this.bucketsService.buckets$
            .subscribe((buckets: BucketAggregations) => {
                this.makeBuckets = buckets.make;
            });
    }
}
