import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

export enum AfterSubmitPopupComponentType {
    success = 'Success',
    error = 'Error'
}

export interface AfterSubmitPopupComponentData {
    title: string;
    subtitle: string;
    duration: number;
    type: AfterSubmitPopupComponentType;
}

@Component({
    selector: 'volvo-after-submit-popup',
    templateUrl: './after-submit-popup.component.html',
    styleUrls: ['./after-submit-popup.component.scss']
})
export class AfterSubmitPopupComponent implements OnInit {
    AfterSubmitPopupComponentType = AfterSubmitPopupComponentType;
    afterSubmitPopup: AfterSubmitPopupComponentData;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: AfterSubmitPopupComponentData,
        public dialogRef: MatDialogRef<AfterSubmitPopupComponent>,
    ) {
        setTimeout(() => {
            // this.onClose();
        }, this.data.duration);
        this.afterSubmitPopup = this.data;
    }

    ngOnInit(): void {
    }

    onClose() {
        this.dialogRef.close();
    }
}
