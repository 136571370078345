<div class="tabMobSidePadding">
    <section class="sectionA tabMobHeroMargin">
    </section>
    <section class="sectionB">
        <div id="selectedAdvance"></div>
        <div id="selectedPro"></div>
        <div>
            Volvo Selected - {{'pages/services/volvoselected/title'| lowercase | translate}}
        </div>
        <div class="sectionB-sub">
            <div [innerHTML]="'pages/services/volvoselected/text' | lowercase | translate"></div>
        </div>
    </section>

    <section [ngClass]="{'sectionC' : true}" [style.padding]="removePadding===true ? '0' : '80'">
        <!-- <section class="sectionC"> -->
        <div [ngClass]="volvoSelectedProEnabled?'sectionC-info1':'sectionC-info'"
             [style.padding]="removePadding===true ? '0' : ''">
            <div *ngIf="volvoSelectedAdvancedEnabled" class="sectionC-info-title"
                 [style.padding]="removePadding===true ? '0' : ''">
                <div [innerHTML]="'pages/services/volvoselected/advanced' | lowercase | translate"></div>
                <div [ngClass]="volvoSelectedProEnabled?'sectionC-info-text':'sectionC-info-textGermany'">
                    <div [innerHTML]="'pages/services/volvoselected/advanced/text' | lowercase | translate"></div>
                </div>
            </div>
            <div *ngIf="volvoSelectedProEnabled" class="sectionC-info-title">
                <span>
                    {{'components/footer/selectedplus'| lowercase | translate}}
                </span>
                <div [ngClass]="volvoSelectedProEnabled?'sectionC-info-text':'sectionC-info-textGermany'">
                    <div [innerHTML]="'pages/services/volvoselected/pro/text'| lowercase | translate"></div>
                </div>
            </div>
        </div>
        <div id="additionalservices"></div>
        <div *ngIf="volvoSelectedAdvancedEnabled" class="sectionC-btn">
            <a target="_blank"
               href="assets/files/VolvoSelectedProAdvanced.pdf">
                {{
'pages/services/volvoselected/downloadsheet'| lowercase |
            translate
                }}
            </a>
        </div>
    </section>
    <section class="sectionD">
        <div class="sectionD-info">
            <span>{{'pages/services/volvoselected/additionalservices' | lowercase | translate}}</span>
        </div>
        <div class="sectionD-items sectionD-isDesktop">
            <div class="sectionD-item" *ngFor="let list of volvoselectedlist;let i=index;">
                <img class="sectionD-itemImg" [src]="list.url" alt="" />
                <div class="sectionD-itemTitle" [innerHTML]="list.title| lowercase | translate">
                </div>
                <div class="sectionD-itemText" [innerHTML]="list.text | lowercase | translate">
                </div>
                <div class="sectionD-itemText-anchorDiv" *ngIf="i===0 && isFinanceEnabled">
                    <a [routerLink]="HtmlRoutePages.financing | localize">
                        {{
'pages/services/volvoselected/readmore' |
                    lowercase | translate
                        }}
                    </a><img [src]="assetService.getAssetUrl('assets/pages/volvoselected/arrow.webp')" alt="{{'pages/services/volvoselected/readmore' | lowercase | translate }}">
                </div>
            </div>
        </div>
        <div class="sectionD-isMobile">
            <drag-scroll #slider class="dragscroll" (reachesLeftBound)="leftBoundStat($event)"
                         (reachesRightBound)="rightBoundStat($event)">
                <div drag-scroll-item *ngFor="let list of volvoselectedlist;let i=index;" class="dragscroll-item">
                    <img class="item-image" [src]="list.url" alt="" />
                    <div class="item-title" [innerHTML]="list.title| lowercase | translate">
                    </div>
                    <div class="item-text" [innerHTML]="list.text | lowercase | translate">
                    </div>
                    <div class="item-anchorDiv" *ngIf="i===0  && isFinanceEnabled">
                        <a [routerLink]="HtmlRoutePages.financing | localize">
                            {{
'pages/services/volvoselected/readmore' |
                        lowercase | translate
                            }}
                        </a><img [src]="assetService.getAssetUrl('assets/pages/volvoselected/arrow.webp')" alt="{{'pages/services/volvoselected/readmore' | lowercase | translate }}">
                    </div>
                </div>
            </drag-scroll>
            <div class="sectionD-dragScrollbtn">
                <button (click)="slider.moveLeft()" [disabled]="leftNavDisabled"
                        class="sectionD-sliderButton sectionD-moveLeft">
                    <i class="fal fa-chevron-left"></i>
                </button>
                <button (click)="slider.moveRight()" [disabled]="rightNavDisabled"
                        class="sectionD-sliderButton sectionD-right">
                    <i class="fal fa-chevron-right"></i>
                </button>
            </div>
        </div>
    </section>
    <section class="sectioE" id="ourDealerships">
        <ng-container *ngIf="dealershipLocations; else loading">
            <div class="sectioE-wp">
                <h2 class="volvoSelectedTitle-title CPrimary">
                    {{ 'aboutUs/aboutTitleB' | lowercase | translate }}
                </h2>
                <volvo-contact-page-dealership class="sectioE-dealership"></volvo-contact-page-dealership>
            </div>
        </ng-container>

        <ng-template #loading>
            <div class="sectioE-tabGroup">
                <volvo-loading></volvo-loading>
            </div>
        </ng-template>
    </section>
</div>
    <volvo-footer></volvo-footer>

