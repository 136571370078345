<div class="axelImage">
      <div class="axleDetails">
            <dyn-svg [type]="pictureId" [tyreTread]="tyreTread" ></dyn-svg>
      </div>
      
      <div class="mmDetails">
            <b>[mm]</b>
      </div>
                  
</div>
