<section class="sectionC">
    <div class="sectionC-close">
        <i (click)="onClose()" class="material-icons">close</i>
    </div>
    <div>
        <div class="sectionC-title">
            {{engineobj.name | lowercase |translate}} {{engineobj.type | lowercase |translate}}
        </div>
        <div class="sectionC-engine">
            <div *ngFor="let category of engineobj.categories">
                <div class="sectionC-engine-title">
                    {{category.title| lowercase |translate}}
                </div>
                <div *ngFor="let column of category.colums;let i=index;" class="sectionC-engine-columns" [ngClass]="(i%2)===0?'sectionC-engine-columns-even':'sectionC-engine-columns-odd'">
                    <div>
                        {{column.column1 | lowercase |translate}}
                    </div>
                    <div>
                        {{column.column2}}{{column.column3 | lowercase |translate}}
                    </div>
                </div>
            </div>
        </div>
        <div class="sectionC-engine-text" *ngIf="engineobj.text">
            {{engineobj.text | lowercase |translate}}
        </div>
    </div>
</section>