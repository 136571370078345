<div class="StockNull" *ngIf="site === 'volvoselected';else others;">
  <!-- <h1 class="StockNull-title">{{ 'globalMiss/sorryText' | lowercase | translate }}</h1> -->
  <h4 class="StockNull-titleSm">{{ 'globalMiss/noTruckFound' | lowercase | translate }}:</h4>
  <volvo-search-chip [fromNotification]="false"></volvo-search-chip>

  <h6 class="StockNull-fortTitle">{{ 'globalMiss/resetSearchOrContact' | lowercase | translate }}.</h6>
  <div style="margin-bottom: 1rem">
    <button (click)="resetAllFilters()" class="StockNull-ctaBtn">
      {{ 'globalMiss/resetFilter' | lowercase | translate }}
    </button>
    <button (click)="gotoContact()" class="StockNull-ctaBtn">
      {{ 'popups/contact/title' | lowercase | translate }}
    </button>
  </div>
</div>
<ng-template #others>
  <div class="noStock" *ngIf="(searchService.totalTrucks$ | async) === 0"> 
    <div class="noStock-title">
      {{ 'globalMiss/novehiclestock' | lowercase | translate }}
    </div>
    <div class="noStock-text">
      {{ 'globalMiss/novehiclestocktext' | lowercase | translate }}
    </div>
</div>
</ng-template>