import { Injectable } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Truck } from "../../../../app/core/store/trucks/trucks.model";
import { HtmlRoutePages } from '../../../app-routing.model';
import {
  LocalizeRouterService,
} from '@gilsdav/ngx-translate-router';
import { ScreenUtils } from '../../utils/screen-utils/screen-utils';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;
  truck: Truck;
  vfsData: number[];
  HtmlRoutePages: HtmlRoutePages;
  langId: any;
  hasEmptyDimensions:boolean;
  hasEmptyTyreTread:boolean;
  isTablet: boolean;
  truckUrl:string='';
  breadCrumbName:string;
  originalUrl: string;
  country: string;
  constructor(private router: Router, private localizeRouterService: LocalizeRouterService,private route:ActivatedRoute,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
  ) { }
  printDocument(documentName: string, dataObj: Truck, langId,hasEmptyTyreTread,hasEmptyDimensions:boolean,vfsValues: number[],breadCrumbName:string, country: string) {
    this.truck = dataObj;
    this.isPrinting = true;
    this.langId = langId;
    this.country = country;
    this.hasEmptyTyreTread=hasEmptyTyreTread;
    this.hasEmptyDimensions=hasEmptyDimensions;
    this.vfsData = vfsValues;
    this.breadCrumbName=breadCrumbName;
    if (ScreenUtils.isTablet()) this.isTablet = true;
    this.router.navigate([this.localizeRouterService.translateRoute("/"),
    {
      outlets: {
        'print': ['print', documentName]
      }
    }],{ skipLocationChange:true });
  }
  onDataReady() {
    if(this.isTablet)
    {
      setTimeout(() => {
        window.print();
        //setTimeout(()=>{    
          this.isPrinting = false;
          this.truckUrl = this.originalUrl;
          this.resetComponentState();
          //this.router.navigateByUrl(this.truckUrl,{skipLocationChange:true}); 
          //window.location.reload();
       // },5500);
      }, 1500); 

    }
    else{
      setTimeout(() => {
        window.print();
        this.isPrinting = false;
        this.truckUrl = this.originalUrl;
        this.resetComponentState();
        //this.router.navigateByUrl(this.truckUrl,{skipLocationChange:true});
        //window.location.reload();
      }, 500);
    }
   
  }

  resetComponentState() {
    this.truckUrl = this.originalUrl;
    this.router.navigateByUrl(this.truckUrl, { skipLocationChange: true }).then(() => {
      this.updateBreadcrumb();
    });
  }
  updateBreadcrumb(): void {
    const breadcrumbsConts  =  [
      {
        label: 'Our Stock',
        url:this.localizeRouterService.translateRoute(HtmlRoutePages.search) as string
      },
      {
        label: this.breadCrumbName,
        url:''
      }
    ];
    this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbsConts);
  }

  getTruckDetails() {
    return this.truck;
  }
  getLanguage() {
    return this.langId;
  }
  getCountry() {
    return this.country;
  }
  getTyreDimensions()
  {
    return this.hasEmptyDimensions;
  }
  getEmptyTyre()
  {
    return this.hasEmptyTyreTread;
  }
  getVfsDetails() {
    return this.vfsData;
  }
  getBreadCrumb() {
    return this.breadCrumbName;
  }
  getTruckUrl(truckUrl:string,queryString:string, originalUrl: string)
  {
    this.originalUrl = originalUrl;
    // var qstring= queryString.replace(/%2F/gi, '/');
    this.truckUrl=truckUrl + "?" + queryString;
  }
}
