import { Action, State, StateContext } from '@ngxs/store';

import { Company, DealerPlaceResult, DealershipsStateInterface, Map, PlacesService, StockLocation, StocklocationStateInterface } from './dealerships.model';
import { GetDealerships, GetStocklocations, GetUserLocation, SetDealerships } from '~/core/store/dealerships/dealerships.actions';
// import { StockLocationCountry } from '~/core/store/buckets/buckets.model';
import { CacheApiService } from '~/core/api/cache/cache-api.service';
import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';

@State<DealershipsStateInterface>({
  name: 'dealerships',
  defaults: {
    dealerships: null,
    dealershipsCountries: null,
    userLocation: null,
  },
})
@Injectable()
export class DealershipsState {
  private map: Map;
  private service: PlacesService;

  constructor(private cacheApiService: CacheApiService) { }

  useCompanies = true;

  @Action(GetDealerships)
  async getDealerships({ getState, patchState }: StateContext<DealershipsStateInterface>) {
    if (this.useCompanies) {
      const stocklocations = await lastValueFrom(this.cacheApiService.getStocklocations()) as DealerPlaceResult[];
      // .catch((error) => {
      //   console.error("Failed to get Stocklocations");
      patchState({
        dealerships: stocklocations,
        dealershipsCountries: stocklocations.map((dealership) => dealership.stockLocation.country).filter((value, index, self) => self.indexOf(value) === index).sort(),
      });
    } else {
      const dealershipLocations = await lastValueFrom(this.cacheApiService.getDealershipsInfo()) as DealerPlaceResult[];
      dealershipLocations.forEach((d) => (d.isSelected = false));
      patchState({
        dealerships: dealershipLocations,
        dealershipsCountries: dealershipLocations.map((dealership) => dealership.stockLocation.country).filter((value, index, self) => self.indexOf(value) === index).sort(),
      });
      // .catch((error) => {
      //   console.error("Failed to get Dealerships");
      // });

    }
  }

  @Action(GetStocklocations)
  async getStocklocations({ getState, patchState }: StateContext<DealershipsStateInterface>) {
    var stocklocations = await lastValueFrom(this.cacheApiService.getStocklocations()) as DealerPlaceResult[];
    patchState({
      dealerships: stocklocations,
      dealershipsCountries: stocklocations.map((dealership) => dealership.stockLocation.country).sort(),
    });
    // })
    // .catch((error) => {
    //   console.error("Failed to get Stocklocations");
    // });
  }

  @Action(GetUserLocation)
  getUserLocation({ patchState }: StateContext<DealershipsStateInterface>) {
    // const disabled = {
    //   isAllowed: false,
    //   lat: 0,
    //   lng: 0,
    // };

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (userPosition) => {
    //       const allowed = {
    //         isAllowed: true,
    //         lat: userPosition.coords.latitude,
    //         lng: userPosition.coords.longitude,
    //       };
    //       patchState({ userLocation: allowed });
    //     },
    //     (error) => {
    //       console.warn('Geolocation was canceled by user');
    //       patchState({ userLocation: disabled });
    //     }
    //   );
    // } else {
    //   console.warn('Geolocation is not supported by this browser.');
    //   patchState({ userLocation: disabled });
    // }
  }

}

