import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

import { LocalesStateLang, LocalesStateLangList } from '~/core/store/locales/locales.model';
import { MailApiService } from '~/core/api/mail-api/mail-api.service';
import { LocalesService } from '~/core/store/locales/locales.service';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { Truck } from '~/core/store/trucks/trucks.model';
import { HtmlRoutePages } from '~/app-routing.model';
import { environment } from 'src/environments/environment';

export interface PhoneUsPopupComponentData {
  truck: Truck;
}

@AutoUnsubscribe()
@Component({
  selector: 'volvo-popup-us-popup',
  templateUrl: './phone-us-popup.component.html',
  styleUrls: ['./phone-us-popup.component.scss'],
})
export class PhoneUsPopupComponent implements OnInit, OnDestroy {
  HtmlRoutePages = HtmlRoutePages;
  noSpecialCharRegEx = /^[\p{L}\- \.\?!'\r\n,0-9\(\)]+$/u;
  form = this.fb.group({
    name: ['', [Validators.required]],
    phone: ['', [Validators.pattern('^[+]?\\d*$'), Validators.required]],
    email: ['request@without.email'],
    financing: [false],
    financingCompanyName: [''],
    financingCity: [''],
    financingCountry: [''],
    message: ['', [Validators.pattern(this.noSpecialCharRegEx)]],
    truckId: [''],
    truckUrl: [''],
    licensePlate: [''],
    privacy: [false, Validators.pattern('true')],
    token: [''],
  });
  curLang: LocalesStateLangList;
  checkbox: string;
  reCaptchaRes: string;
  showRecaptcha: boolean = false;
  recaptchaToken: string;

  private s0: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoneUsPopupComponentData,
    public dialogRef: MatDialogRef<PhoneUsPopupComponent>,
    private fb: UntypedFormBuilder,
    private mailApiService: MailApiService,
    private localesService: LocalesService,
    private globalUtils: GlobalUtils
  ) {
    this.setTruckValues();

    this.s0 = this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
      this.curLang = lang.curLang;
      this.checkbox = '/' + this.curLang.id + '/privacy-policy';
    });
    this.recaptchaToken = environment.recaptchaSitekey;
  }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  resolved(captchaResponse: string, res) {
    this.reCaptchaRes = captchaResponse;
  }
  recaptcha() {
    this.showRecaptcha = !this.showRecaptcha;
  }

  async onSubmit(): Promise<void> {
    if (this.form.valid && this.reCaptchaRes) {
      const body = this.transformDataForServer(this.form.value);
      await this.mailApiService.postMailMobile(body);

      this.dialogRef.close();

      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        event: 'FormsubmittedPhone',
      });
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private setTruckValues(): void {
    if (this.data.truck) {
      this.form.get('truckId').setValue(this.data.truck.stockNumber);
      this.form.get('licensePlate').setValue(this.data.truck.specification.licensePlate);
    }

    if (this.globalUtils.isBrowser()) {
      this.form.get('truckUrl').setValue(window.location.href);
    }
  }

  private transformDataForServer(data) {
    // TODO: ask someone to make nice API for this
    return {
      name: data.name,
      phone: data.phone,
      truckid: data.truckId,
      countryoffinance: data.financingCountry,
      message: data.message,
      token: this.reCaptchaRes,
      other: [
        {
          name: 'Financing City',
          value: data.financingCity,
        },
        {
          name: 'Company Name',
          value: data.financingCompanyName,
        },
        {
          name: 'Truck Url',
          value: data.truckUrl,
        },
        {
          name: 'License Plate',
          value: data.licensePlate,
        },
        {
          name: 'Date',
          value: '2020-01-01T22:00:00.000Z',
        },
        {
          name: 'Privacy',
          value: data.privacy,
        },
      ],
    };
  }
}
