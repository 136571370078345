import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router } from '@angular/router';
import { HtmlRoutePages } from '~/app-routing.model';

export enum SoldPopupComponentType {
    success = 'Success',
    error = 'Error'
}

export interface SoldPopupComponentData {
    title: string;
    subtitle: string;
    duration: number;
    type: SoldPopupComponentType;
    truckRemoved:boolean;
}

@Component({
    selector: 'volvo-sold-popup',
    templateUrl: './sold-popup.component.html',
    styleUrls: ['./sold-popup.component.scss']
})
export class SoldPopupComponent implements OnInit {
    SoldPopupComponentType = SoldPopupComponentType;
    soldPopup: SoldPopupComponentData;
    truckRemoved: boolean;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SoldPopupComponentData,
        public dialogRef: MatDialogRef<SoldPopupComponentData>,
        private router: Router,
        private localizeRouterService: LocalizeRouterService
    ) {
        this.truckRemoved = data.truckRemoved;
        this.soldPopup = data;
    }

    ngOnInit(): void {
    }

    onClose() {
        this.dialogRef.close();
    }

    navigateToStockPage() {
        this.dialogRef.close();
        this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.search)]);
    }
}
