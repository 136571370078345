import {
  DealerPlaceResult,
  GetDealershipsPayload,
  SetDealershipsPayload,
} from '~/core/store/dealerships/dealerships.model';

export class GetDealerships {
  static readonly type = '[GetDealerships]: action';

  // constructor(public payload: GetDealershipsPayload) {}
}

export class GetStocklocations {
  static readonly type = '[GetStocklocations]: action';
}

export class SetDealerships {
  static readonly type = '[SetDealerships]: action';

  constructor(public payload: DealerPlaceResult[]) { }
}

export class GetUserLocation {
  static readonly type = '[GetUserLocation]: action';

  constructor() { }
}
