<section class="complaint">
    <div class="complaint-complaintDiv">
        <div class="complaint-title">
          <span>{{ "uk/components/footer/complaints" | lowercase | translate }}</span>
        </div>
        <div class="complaint-text complaint-display">
          <span class="complaint-text-medium">{{
            "uk/components/footer/complaints/address" | lowercase | translate
          }}</span>
          <span>{{ "uk/components/footer/complaints/address1" | lowercase | translate }}</span>
          <span>{{ "uk/components/footer/complaints/address2" | lowercase | translate }}</span>
          <span>{{ "uk/components/footer/complaints/address3" | lowercase | translate }}</span>
          <span>{{ "uk/components/footer/complaints/address4" | lowercase | translate }}</span>
          <span>{{ "uk/components/footer/complaints/address5" | lowercase | translate }}</span>
        </div>
        <div class="complaint-text">
          <span class="complaint-text-medium"
            >{{ "uk/components/footer/complaints/phone" | lowercase | translate }}: </span
          ><span>01452 671560</span>
        </div>
        <div class="complaint-text">
          <span class="complaint-text-medium">{{ "uk/form/email" | lowercase | translate }}: </span> <span
          ><a href="mailto:complaints@automotive-compliance.co.uk"
            >complaints@automotive-compliance.co.uk</a
          ></span>
        </div>
        <div class="complaint-sectionDiv">
            <div class="complaint-text">
              <span>{{ "uk/components/footer/complaints/text1" | lowercase | translate }} </span>
            </div>
            <div class="complaint-text">
              <span>{{ "uk/components/footer/complaints/text2" | lowercase | translate }}</span>
              <a href="https://www.financial-ombudsman.org.uk" target="_blank" class="complaint-text-textdecoration">www.financial-ombudsman.org.uk</a>
            </div>
          </div>
      </div>

</section>

<volvo-footer></volvo-footer>
