<section  class="salesContent" [ngClass]="isMasterContactPageFlag? 'salesContentWidthAdjust' : ''" >
    <div   class="salesPerson" *ngFor="let sales of salesTeamData"  >
        <div class="salesPerson-mobile">
            <div>
                <img [src]="sales.imageUrl" alt="" class="salesImage"/> 
            </div>
            <div>
                <div class="salesPerson-name">
                <span>{{sales.imageName}}</span>
                </div>
                <div class="salesPerson-designation" *ngIf="sales.imageDesignation">
                    <span>{{sales.imageDesignation | lowercase | translate }}</span>
                </div>
                <div class="salesPerson-designation" *ngIf="sales.imageCentre">
                    <span>{{sales.imageCentre | lowercase | translate}}</span>
                </div>
                <div class="salesPerson-designation" *ngIf="sales.imageEmail">
                    <span>{{ "uk/pages/contact/contactEmail" | lowercase | translate }}: </span>
                    <a href="mailto:{{sales.imageEmail }}">{{sales.imageEmail }}</a>
                </div>
                <div class="salesPerson-designation" *ngIf="sales.imageMobile">
                    <span>{{ "uk/pages/contact/contactPhone" | lowercase | translate }}: </span>
                    <span><a href="tel:{{sales.imageMobile}}">{{sales.imageMobile}}</a></span>
                </div>
                <div class="salesPerson-designation" *ngIf="sales.imageLinkedin">
                    <span><a href="{{sales.imageLinkedin}}" target="_blank">{{ "uk/components/footer/linkedin" | lowercase | translate }}</a></span>
                </div>
            </div>
        </div>
    </div>
</section>
