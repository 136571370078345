<section class="tradeExport">
  <div class="tradeExport-bg" 
  [ngStyle]="getBackgroundStyle()"></div>
</section>
<section>
  <div class="tradeExport-tradeDiv">
    <div class="tradeExport-title">
      <span>{{ "uk/components/footer/tradeExport" | lowercase | translate }}</span>
    </div>
    <div class="tradeExport-text">
      <span>{{ "uk/pages/tradeExport/Text1" | lowercase | translate }}</span>
    </div>
    <div class="tradeExport-text">
      <span class="tradeExport-text-medium">{{
        "uk/pages/tradeExport/Text2" | lowercase | translate
      }}</span
      >{{ "uk/pages/tradeExport/Text3" | lowercase | translate }}
    </div>
    <div class="tradeExport-text">
      <span>{{ "uk/pages/tradeExport/Text4" | lowercase | translate }}</span>
    </div>
    <div class="tradeExport-text">
      <span>{{ "uk/pages/tradeExport/Text5" | lowercase | translate }}</span>
    </div>
  </div>
</section>
<section>
  <div class="tradeExport-img-div">
    <img
      src="assets/pages/contactus/TE/PaulFleet.webp"
      class="tradeExport-tradeImg" *ngIf="!isTablet"
    />
    <div class="tradeExport-imgtext-medium">
      <span>{{ "uk/pages/tradeExport/title1" | lowercase | translate }}</span>
    </div>
    <div class="tradeExport-imgtext">
      <span>{{ "uk/pages/tradeExport/title1/text1" | lowercase | translate }}</span>
    </div>
    <div class="tradeExport-imgtext">
      <span>{{ "uk/pages/tradeExport/title1/text2" | lowercase | translate }}</span>
    </div>
    <div class="tradeExport-imgtext">
      <span>{{"uk/form/email" | lowercase | translate}}: <a href="mailto:{{'uk/pages/tradeExport/title1/text3' | lowercase | translate }}">{{'uk/pages/tradeExport/title1/text3' | lowercase | translate }}</a></span>
    </div>
    <div class="tradeExport-imgtext">
      <span>{{ "uk/pages/tradeExport/title1/text4" | lowercase | translate }}</span>
    </div>
    <div class="tradeExport-imgtext">
      <span>{{"uk/components/website" | lowercase | translate}}:  <a href="https://{{'uk/pages/tradeExport/title1/text5' | lowercase | translate }}" target="_blank">{{'uk/pages/tradeExport/title1/text5' | lowercase | translate }}</a></span>
    </div>
  </div>
</section>
<volvo-footer></volvo-footer>
