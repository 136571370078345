import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { TyreTread } from '~/core/store/trucks/trucks.model.base';

@Component({
    selector: 'dyn-svg',
    template: `<ng-template>  </ng-template>`,
    styleUrls: [
        './svg.component.scss'
    ]
})

export class SvgComponent implements AfterViewInit {
    @Input() type: string;
    @Input() tyreTread: TyreTread[];

    fs: FileReader = new FileReader();

    constructor(private el: ElementRef, private http: HttpClient) {
    }

    tyres = {
        "4_1": "",
        "4_2": "",
        "6_1": "",
        "6_2": "",
        "6_3": "",
        "8_1": "",
        "8_2": "",
        "8_3": "",
        "8_4": "",
        "8_5": "",
        "10_1": "",
        "10_2": ""
    }

    tyre: string;

    tyreCoordinates = {
        "11": { x: "0.5", y: "2.5" },
        "12": { x: "27.5", y: "2.5" },
        "13": { x: "65.5", y: "2.5" },
        "14": { x: "92.5", y: "2.5" },
        "21": { x: "0.5", y: "37.5" },
        "22": { x: "27.5", y: "37.5" },
        "23": { x: "65.5", y: "37.5" },
        "24": { x: "92.5", y: "37.5" },
        "31": { x: "0.5", y: "82.5" },
        "32": { x: "27.5", y: "82.5" },
        "33": { x: "65.5", y: "82.5" },
        "34": { x: "92.5", y: "82.5" },
        "41": { x: "0.5", y: "117.5" },
        "42": { x: "27.5", y: "117.5" },
        "43": { x: "65.5", y: "117.5" },
        "44": { x: "92.5", y: "117.5" },
        "51": { x: "0.5", y: "152.5" },
        "52": { x: "27.5", y: "152.5" },
        "53": { x: "65.5", y: "152.5" },
        "54": { x: "92.5", y: "152.5" },
        "61": { x: "0.5", y: "187.5" },
        "62": { x: "27.5", y: "187.5" },
        "63": { x: "65.5", y: "187.5" },
        "64": { x: "92.5", y: "187.5" },
    };

    tyreTable: any;

    ngOnInit(): void {
        const n = 'n';
        this.tyreTable = {
            "4_1": [[0, 2], [n, 0], [n, 0], [1, 2]],
            "4_2": [[0, 2], [n, 0], [n, 0], [1, 4]],
            "6_1": [[0, 2], [n, 0], [1, 4], [2, 2]],
            "6_2": [[0, 2], [n, 0], [1, 2], [2, 4]],
            "6_3": [[0, 2], [n, 0], [1, 4], [2, 4]],
            "8_1": [[0, 2], [n, 0], [1, 4], [2, 4], [3, 2]],
            "8_2": [[0, 2], [n, 0], [1, 2], [2, 4], [3, 4]],
            "8_3": [[0, 2], [n, 0], [1, 2], [2, 4], [3, 2]],
            "8_4": [[0, 2], [1, 2], [n, 0], [2, 4], [3, 2]],
            "8_5": [[0, 2], [1, 2], [n, 0], [2, 4], [3, 4]],
            "10_1": [[0, 2], [1, 2], [n, 0], [2, 4], [3, 4], [4, 2]],
            "10_2": [[0, 2], [1, 2], [n, 0], [2, 2], [3, 4], [4, 4]]
        }

        this.http.get('assets/svg/axle/tyre.svg', { responseType: 'text' }).subscribe(svg => {
            this.tyre = svg;
            this.el.nativeElement.innerHTML = this.tyres[this.type];
            this.http.get('assets/svg/axle//ID_' + this.type + '.svg', { responseType: 'text' }).subscribe(svg => {
                this.tyres[this.type] = svg;
                this.processLayout();
                this.el.nativeElement.innerHTML = this.tyres[this.type];
            });
        });
    }

    processLayout() {
        var tyreSum = this.tyreTread.map(tt => tt.WheelNo1 + tt.WheelNo2 + tt.WheelNo3 + tt.WheelNo4);
        var reduction = tyreSum.reduce((sum, current) => sum + current, 0);

        var hasTyreTread =
            this.tyreTread
            && this.tyreTread.length > 0
            && reduction > 0;

        if (hasTyreTread) {
            this.drawTyreTread();
        } else {
            this.drawEmptyTyreTread();
        }
    }

    drawTyreTread() {
        var wheelNumbers = ["WheelNo1", "WheelNo2", "WheelNo3", "WheelNo4"];
        var svgTyres: string[] = [];
        var tyreTreadNumber = 0;

        for (var i = 0; i < this.tyreTable[this.type].length; i++) {
            if (this.tyreTable[this.type][i][0] == 'n') {
                continue;
            }

            if (this.tyreTable[this.type][i][1] === 2) {
                var coordinate1 = this.tyreCoordinates[(i + 1).toString() + '1'];
                var coordinate2 = this.tyreCoordinates[(i + 1).toString() + '4'];
                svgTyres.push(this.tyre
                    .replace('[X]', coordinate1.x)
                    .replace('[Y]', coordinate1.y)
                    .replace('[TREAD]', this.tyreTread[tyreTreadNumber][wheelNumbers[0]]));
                svgTyres.push(this.tyre
                    .replace('[X]', coordinate2.x)
                    .replace('[Y]', coordinate2.y)
                    .replace('[TREAD]', this.tyreTread[tyreTreadNumber][wheelNumbers[3]]));
            }
            if (this.tyreTable[this.type][i][1] === 4) {
                var coordinate1 = this.tyreCoordinates[(i + 1).toString() + '1'];
                var coordinate2 = this.tyreCoordinates[(i + 1).toString() + '2'];
                var coordinate3 = this.tyreCoordinates[(i + 1).toString() + '3'];
                var coordinate4 = this.tyreCoordinates[(i + 1).toString() + '4'];

                svgTyres.push(this.tyre
                    .replace('[X]', coordinate1.x)
                    .replace('[Y]', coordinate1.y)
                    .replace('[TREAD]', this.tyreTread[tyreTreadNumber][wheelNumbers[0]]));
                svgTyres.push(this.tyre
                    .replace('[X]', coordinate2.x)
                    .replace('[Y]', coordinate2.y)
                    .replace('[TREAD]', this.tyreTread[tyreTreadNumber][wheelNumbers[1]]));
                svgTyres.push(this.tyre
                    .replace('[X]', coordinate3.x)
                    .replace('[Y]', coordinate3.y)
                    .replace('[TREAD]', this.tyreTread[tyreTreadNumber][wheelNumbers[2]]));
                svgTyres.push(this.tyre
                    .replace('[X]', coordinate4.x)
                    .replace('[Y]', coordinate4.y)
                    .replace('[TREAD]', this.tyreTread[tyreTreadNumber][wheelNumbers[3]]));
            }

            tyreTreadNumber++;
        }
        
        this.tyres[this.type] = this.tyres[this.type].replace("[TYRES]", svgTyres);
        this.tyres[this.type] = this.tyres[this.type].replace("[STYLE]", "<style> .white { fill: white; } </style>");
        
    }

    drawEmptyTyreTread() {
        var wheelNumbers = ["WheelNo1", "WheelNo2", "WheelNo3", "WheelNo4"];
        var svgTyres: string[] = [];
        var tyreTreadNumber = 0;

        for (var i = 0; i < this.tyreTable[this.type].length; i++) {
            if (this.tyreTable[this.type][i] == 'n') {
                continue;
            }

            if (this.tyreTable[this.type][i][1] === 2) {
                var coordinate1 = this.tyreCoordinates[(i + 1).toString() + '1'];
                var coordinate2 = this.tyreCoordinates[(i + 1).toString() + '4'];
                svgTyres.push(this.tyre
                    .replace('[X]', coordinate1.x)
                    .replace('[Y]', coordinate1.y)
                    .replace('[TREAD]', " "));
                svgTyres.push(this.tyre
                    .replace('[X]', coordinate2.x)
                    .replace('[Y]', coordinate2.y)
                    .replace('[TREAD]', " "));
            }
            if (this.tyreTable[this.type][i][1] === 4) {
                var coordinate1 = this.tyreCoordinates[(i + 1).toString() + '1'];
                var coordinate2 = this.tyreCoordinates[(i + 1).toString() + '2'];
                var coordinate3 = this.tyreCoordinates[(i + 1).toString() + '3'];
                var coordinate4 = this.tyreCoordinates[(i + 1).toString() + '4'];
                svgTyres.push(this.tyre
                    .replace('[X]', coordinate1.x)
                    .replace('[Y]', coordinate1.y)
                    .replace('[TREAD]', " "));
                svgTyres.push(this.tyre
                    .replace('[X]', coordinate2.x)
                    .replace('[Y]', coordinate2.y)
                    .replace('[TREAD]', " "));
                svgTyres.push(this.tyre
                    .replace('[X]', coordinate3.x)
                    .replace('[Y]', coordinate3.y)
                    .replace('[TREAD]', " "));
                svgTyres.push(this.tyre
                    .replace('[X]', coordinate4.x)
                    .replace('[Y]', coordinate4.y)
                    .replace('[TREAD]', " "));
            }

            tyreTreadNumber++;
        }
        
        this.tyres[this.type] = this.tyres[this.type].replace("[TYRES]", svgTyres);
        this.tyres[this.type] = this.tyres[this.type].replace("[STYLE]", "<style> .white { fill: white; } </style>");
        
    }

    ngAfterViewInit(): void {
        
        
    }
}
