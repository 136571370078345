import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

import { MailApiService } from '~/core/api/mail-api/mail-api.service';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';


@Component({
    selector: 'volvo-contact-popup',
    templateUrl: './contact-popup.component.html',
    styleUrls: ['./contact-popup.component.scss']
})
export class ContactPopupComponent implements OnInit {
    noSpecialCharRegEx = /^[\p{L}\- \.\?!'\r\n,0-9\(\)]+$/u;
    form = this.fb.group({
        name: ['', [Validators.required]],
        phone: ['', [Validators.pattern(/\d/g), Validators.required]],
        email: ['', [Validators.email, Validators.required]],
        message: ['', [Validators.pattern(this.noSpecialCharRegEx)]],
        subscribe: [false],
        privacy: [false, Validators.pattern('true')],
    });

    constructor(
        public dialogRef: MatDialogRef<ContactPopupComponent>,
        private fb: UntypedFormBuilder,
        private mailApiService: MailApiService,
        private globalUtils: GlobalUtils,
    ) {
    }

    ngOnInit(): void {
    }

    async onSubmit(): Promise<void> {
        if (this.form.valid) {
            if (this.form.value.subscribe) {
                await this.mailApiService.postSubscribe({
                    ...this.form.value,
                    mail: {
                        body: `<p>To confirm your subscription please click this <a  href='http://{{url}}'>{{url}}</a  href='http://{{url}}'></p>`,
                        subject: `Please confirm your newsletter subscription`
                    }
                });
            }
            await this.mailApiService.postMail(this.form.value);

            this.dialogRef.close();
        }
    }

    onClose(): void {
        this.dialogRef.close();
    }
}
