<div [ngClass]="data.type" class="Title">
  <!-- <i *ngIf="AfterSubmitPopupComponentType.error === data.type" class="fas fa-exclamation-triangle"></i>
    <i *ngIf="AfterSubmitPopupComponentType.success === data.type" class="fas fa-check-circle"></i> -->

  <span *ngIf="AfterSubmitPopupComponentType.success === data.type" class="fa-stack"
    style="position: relative; top: -25px; left: calc(25% - 50px);">
    <i class="fas fa-circle fa-stack-2x" style="color: white"></i>
    <i class="fas fa-check-circle fa-stack-2x"></i>
  </span>
  <span *ngIf="AfterSubmitPopupComponentType.error === data.type" class="fa-stack"
    style="position: relative; top: -25px; left: calc(25% - 80px);">
    <i class="fas fa-circle fa-stack-1x" style="color: white"></i>
    <i class="fas fa-exclamation-triangle fa-stack-2x"></i>
  </span>
  <!--
  <div class="Title-maintitle">{{ 'afterSubmitPopup/title' | lowercase | translate }}</div> -->

  <div *ngIf="AfterSubmitPopupComponentType.success === data.type" class="Title-maintitle">
    {{ 'afterSubmitPopup/title' | lowercase | translate }}
  </div>

  <div *ngIf="AfterSubmitPopupComponentType.success === data.type" class="Title-sub">
    {{ 'afterSubmitPopup/subtitle' | lowercase | translate }}
  </div>

  <div *ngIf="AfterSubmitPopupComponentType.error === data.type" class="Title-maintitle">{{ 'afterSubmitPopup/wrongtitle' | lowercase | translate }}</div>

  <div *ngIf="AfterSubmitPopupComponentType.error === data.type" class="Title-sub">{{ 'aftersubmitpopup/wrongsubtitle' | lowercase | translate }}</div>
</div>
<div class="buttonCss">
  <button (click)="onClose()">
    Ok
  </button>
</div>
