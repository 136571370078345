<section class="Offer" *ngIf = "!mobileFlag; else others">
  <div *ngFor="let link of links; let i = index" >
    <div class="Offer-offerCard">
      <div class="Offer-offerHeight">
        <div *ngIf='link.image !== ""'>
            <img src='{{link.image}}' class="Offer-offerImage" alt="{{link.text}}">
        </div>
        <div class="Offer-offerHeading">
            {{link.label | lowercase | translate}}
        </div>
          
        <div class="Offer-offerText">
            {{link.text | lowercase | translate}}
        </div>
    </div>
      <div>
        <button class="Offer-offerButton" (click)="routePage(link)">
          <span class="Offer-offerButtonText">
              {{ 'uk/pages/search/viewStock' | lowercase | translate}}
              <div>
                <i class="fal fa-chevron-right fa-lg" ></i>
              </div>
          </span>
        </button>
      </div>
    </div>
  </div>
</section>
<ng-template #others>
<section class="Stock">
  <div class="Stock-links" *ngFor="let link of links; let i = index" > 
      <button class="Offer-offerButton" (click)="routePage(link)">
          <span class="Offer-offerButtonText">
              {{ 'uk/pages/search/viewStock' | lowercase | translate}} of {{link.label | lowercase | translate}}
              <div>
                <i class="fal fa-chevron-right fa-lg" ></i>
              </div>
          </span>
        </button>
  </div>
</section>
</ng-template>