import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  HostBinding,
  OnDestroy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { distinctUntilChanged, filter, map, pairwise, share, switchMap, take, throttleTime } from 'rxjs/operators';
import { LocalizeRouterService, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { NavigationEnd, Router, RouterLinkActive, RoutesRecognized } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, lastValueFrom, Subscription } from 'rxjs';
import { NavLinks, Sidenavs, SidenavService } from '~/core/services/sidenav/sidenav.service';
import { TruckCollationService } from '~/core/store/truck-collation/truck-collation.service';
import { SearchPopupComponent } from '~/popups/search-popup/search-popup.component';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { ScreenUtils } from '~/core/utils/screen-utils/screen-utils';
import { SearchStateFilter } from '~/core/store/search/search.model';
import { SearchService } from '~/core/store/search/search.service';
import { toggle, VisibilityState } from '~/animations';
import { HtmlRoutePages } from '~/app-routing.model';
import { Location } from '@angular/common';
import { CounterService } from '~/core/services/counter/counter-service';
import { regionEnum } from '../../core/declaration/declaration';
import { LocalesService } from '~/core/store/locales/locales.service';
import { RegionalSettingsService } from '~/core/store/regionalSettings/regionalSettings.service';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
import { SiteCheckService } from '~/core/services/siteName/site-check.service';
import { AssetService } from '~/core/services/assets/asset.service';
enum Direction {
  Up = 'Up',
  Down = 'Down',
}

export interface SubHeader {
  type: string;
  links: SubHeaderLinks[];
}

export interface SubHeaderLinks {
  label: string;
  path: string;
}

@AutoUnsubscribe()
@Component({
  selector: 'volvo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [toggle],
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  HtmlRoutePages = HtmlRoutePages;
  @ViewChild('header', { static: true }) header: ElementRef;
  @Output() showBreadCrumbs = new EventEmitter<boolean>();
  @HostBinding('@toggle')
  get toggle(): VisibilityState {
    return this.isHeaderVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }

  activeSubheader: NavLinks;
  navLinks: NavLinks[];
  search: SearchStateFilter;

  private isHeaderVisible = true;
  private s0: Subscription;
  private s1: Subscription;
  private s2: Subscription;
  private s3: Subscription;
  isViewInitialized = false;
  private countrySetting: String;
  showFinancePage: Boolean;
  routeurl: any;
  showLangBanner: any;
  sub: any;
  isTablet: boolean = false;
  regionEnum=regionEnum;
  region:string;
  site: string;
  isSelectedSite: boolean = false;
  regionalSettings: RegionSettings;
  isSelectedMap: { [key: string]: number } = {};

  constructor(
    public truckCollationService: TruckCollationService,
    private router: Router,
    private dialog: MatDialog,
    private sidenavService: SidenavService,
    private globalUtils: GlobalUtils,
    private searchService: SearchService,
    private localizeRouterService: LocalizeRouterService,
    private changeDetector: ChangeDetectorRef,
    private location: Location,
    private settings: LocalizeRouterSettings,
    private counterService: CounterService,
    public localesService: LocalesService,
    public regionSettingService: RegionalSettingsService,
    public store: Store,
    public siteCheckService: SiteCheckService,
    public assetService: AssetService
  ) {
    this.navLinks = this.sidenavService.getNavLinks();
    

    //TO DO: need to work on this code
    // const region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    // this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === region)[0]);
    // 

    // //TO DO: need to work on this code
    // if(this.regionalSettings){
    //   
    //   this.region = this.regionalSettings?.region;
    //   if(this.regionalSettings?.site === "volvoselected")
    //     this.isSelectedSite = true;
    // }  
    var langObj = JSON.parse(localStorage.getItem('language'));

    if (langObj && langObj.region && langObj.region.curRegion) {
        this.region = langObj.region.curRegion.id;
        if(this.region === "EU" || this.region === "ZA")
        this.isSelectedSite = true;
    }
    else {
        console.error("Error: Header - Unable to retrieve region ID from local storage.");
    }

   // this.subscribeToLatestSettings();

    if(this.region===regionEnum.TX)
    {
        this.navLinks=this.navLinks.filter(x=>x.path != HtmlRoutePages.vehicleOffers);
        //  this.navLinks.pop();
    }
    this.initSubscriptions();
    this.sub = this.router.events.subscribe((val) => {
      if (val instanceof RoutesRecognized) {
        this.routeurl = val.state.url;
      }
    });
  }

  getAssetUrl(path: string): string {
    return this.assetService.getAssetUrl(path);
  }
  // headerLink() {
  //   const allLangRegEx = /^(\/cz$|\/cz\/|\/dk$|\/dk\/|\/lt$|\/lt\/|\/ru$|\/ru\/|\/fr$|\/fr\/|\/es$|\/es\/|\/no$|\/no\/|\/it$|\/it\/|\/fi$|\/fi\/|\/pl$|\/pl\/|\/hu$|\/hu\/|\/sk$|\/sk\/|\/si$|\/si\/|\/at$|\/at\/|\/ro$|\/ro\/|\/ba$|\/ba\/|\/mk$|\/mk\/|\/rs$|\/rs\/|\/hr$|\/hr\/|\/en$|\/en\/|\/de$|\/de\/|\/be-fr$|\/be-fr\/|\/be-nl$|\/be-nl\/|\/nl$|\/nl\/|\/bg$|\/bg\/|\/se$|\/se\/|\/ee$|\/ee\/|\/lv$|\/lv\/|\/ch-de$|\/ch-de\/|\/ch-fr$|\/ch-fr\/)/;

  //   if (this.routeurl === '/regions') {
  //     if (localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE') === 'en') {
  //       this.router.navigate(['/'], { queryParamsHandling: 'merge' });
  //       var path = this.location.path().toString().replace('/regions', '');
  //       this.location.go(path);
  //     } else {
  //       this.settings.alwaysSetPrefix = true;
  //       var temppath = this.location.path().toString().replace(allLangRegEx, '/');
  //       var path = temppath.replace('/regions', '');
  //       var routepath = this.localizeRouterService.parser.addPrefixToUrl(path);
  //       this.location.go(routepath);
  //       location.reload();
  //     }
  //   }
  // }
  private  subscribeToLatestSettings() {
    const site = this.siteCheckService.getCurrentSiteName().subscribe(siteName => {
      if (siteName) {
        this.site = siteName;
        if (siteName === "volvoselected") {
          this.isSelectedSite = true;
        }
      }
    });
  }

  activeLink() {
    this.activeSubheader = undefined;
  }

  async ngOnInit(){
  }
  
  ngAfterViewInit(): void {  
    if (this.globalUtils.isBrowser() && ScreenUtils.isTablet()) {
      this.isTablet = true;
      return;
    }

    // this.toggleHeaderOnScroll();
    this.isViewInitialized = true;
    this.changeDetector.detectChanges();
  }

  isLinkActive(rla: RouterLinkActive): boolean {
    this.initSubscriptions();
    if (rla.linksWithHrefs) {
      const routerLink = rla.linksWithHrefs.first;
      return this.router.isActive(routerLink.urlTree, false);
    }
    return true;
  }

  ngOnDestroy(): void {
   }

  onMenuClick(): void {
    this.sidenavService.toggle(Sidenavs.menu);
  }

  scrollToElement(el: HTMLElement): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  async goToSearch(): Promise<void> {
    await this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.search)]);

    this.dialog.open(SearchPopupComponent, {
      width: '680px',
      panelClass: 'volvo-popup-fs',
    });
  }
  private initSubscriptions(): void {
    this.s0 = this.searchService.filter$.subscribe((searchOptions: SearchStateFilter) => {
      this.search = searchOptions;
    });

    this.s1 = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        if (this.globalUtils.isBrowser()) {
          // window.scroll({
          //     top: 0,
          //     left: 0,
          //     behavior: 'smooth'
          // });
        }
        this.activeSubheader = undefined;
          for (let i = 0; i <= this.navLinks.length - 1; i++) {
          if(this.isSelectedSite)
          {
            if ((e.url.match(HtmlRoutePages.services) && this.navLinks[i].path === HtmlRoutePages.services)) {
              this.activeSubheader = this.navLinks[i];
              break;
            }
            if (e.url.match(HtmlRoutePages.truckModels) || e.url.match(HtmlRoutePages.search)) {
              this.showBreadCrumbs.emit(true);
              break;
            }
            else {
              if (this.isTablet && (e.url.match(HtmlRoutePages.services))) {
                this.showBreadCrumbs.emit(true);
                break;
              }
              else{
                if(e.url.includes('print'))
                  this.showBreadCrumbs.emit(true);
                else
                  this.showBreadCrumbs.emit(false);
              }
            }
          }
          else{
            if (this.region === regionEnum.TX) {
              if (this.navLinks[i].children) {
                  this.navLinks[i].children = this.navLinks[i]?.children.filter(x => x.path !== HtmlRoutePages.serviceApproved && x.path !== HtmlRoutePages.serviceEconomy
                      && x.path !== HtmlRoutePages.financing && x.path !== HtmlRoutePages.serviceContract && x.path !== HtmlRoutePages.customisation && x.path !== HtmlRoutePages.volvoConnect);
              }
            }
            else {
                 if (this.navLinks[i].children) {
                     this.navLinks[i].children = this.navLinks[i]?.children.filter(x => x.path !== HtmlRoutePages.serviceExport && x.path !== HtmlRoutePages.serviceCustomisation);
                 }
            }
            if (e.url.match(HtmlRoutePages.search) && this.navLinks[i].path===HtmlRoutePages.search) {
              //this.activeSubheader = this.navLinks[i];
              //this.showBreadCrumbs.emit(true);
              break;
            }
            else if(e.url.match(HtmlRoutePages.vehicleOffers) && this.navLinks[i].path===HtmlRoutePages.vehicleOffers)
            {
              this.activeSubheader = this.navLinks[i];
              break;
            }
            else if(e.url.match(HtmlRoutePages.buyersGuide) && this.navLinks[i].path===HtmlRoutePages.buyersGuide)
            {
              //this.activeSubheader = this.navLinks[i];
              break;
            }
            else if(e.url.match(HtmlRoutePages.services) && this.navLinks[i].path===HtmlRoutePages.services)
            { 
                this.activeSubheader = this.navLinks[i];
              break;
            }
            else if(e.url.match(HtmlRoutePages.aboutUs) && this.navLinks[i].path===HtmlRoutePages.aboutUs)
            {
              if(this.region===regionEnum.TX)
              {
                this.navLinks[i].children= this.navLinks[i].children.filter(x=>x.path===HtmlRoutePages.whoweare);
              }
              this.activeSubheader = this.navLinks[i];
              break;
            }
            else if(e.url.match(HtmlRoutePages.contact) && this.navLinks[i].path===HtmlRoutePages.contact)
            {
              this.activeSubheader = this.navLinks[i];
              break;
            }
            else if(e.url.match(HtmlRoutePages.faq) && this.navLinks[i].path===HtmlRoutePages.faq)
            {
              this.activeSubheader = this.navLinks[i];
              break;
            }
          }
        }

      if(!this.isSelectedSite){
        if(e.url==="/" || e.url.match(HtmlRoutePages.tradeExport) || e.url.match(HtmlRoutePages.complaint) || e.url.match(HtmlRoutePages.vehicleOffers) || e.url.match(HtmlRoutePages.aboutUs) ||e.url.match(HtmlRoutePages.unsubscribe) || e.url.match(HtmlRoutePages.faq))
        {
          if(this.isTablet && (e.url.match(HtmlRoutePages.vehicleOffers) ||e.url.match(HtmlRoutePages.aboutUs)))
          {
            this.showBreadCrumbs.emit(true);
          }
          else{
            this.showBreadCrumbs.emit(false);
          }
        }
        else if(this.region===this.regionEnum.TX && e.url.match(HtmlRoutePages.contact))
        {
            this.showBreadCrumbs.emit(false);
        }
        else{
          this.showBreadCrumbs.emit(true);
        }
      }
      //The below condition is checked because we started to get breadcrumb bar on homepage when we have /en-gb, /en-ns and /en-tx in the url
      if(e.url.match(/^\/en-(gb|ns|tx)$/)){
        this.activeSubheader = undefined;
        this.showBreadCrumbs.emit(false);
      }
    });
  }

  private toggleHeaderOnScroll(): void {
    if (this.globalUtils.isBrowser()) {
      const scroll$ = fromEvent(window, 'scroll').pipe(
        throttleTime(10),
        map(() => window.pageYOffset),
        pairwise(),
        map(
          ([y1, y2]): Direction => {
            if (y2 <= this.header.nativeElement.clientHeight) {
              return Direction.Up;
            }

            return y2 < y1 ? Direction.Up : Direction.Down;
          }
        ),
        distinctUntilChanged(),
        share()
      );

      const scrollUp$ = scroll$.pipe(filter((direction) => direction === Direction.Up));

      const scrollDown$ = scroll$.pipe(filter((direction) => direction === Direction.Down));

      this.s2 = scrollUp$.subscribe(() => (this.isHeaderVisible = true));
      this.s3 = scrollDown$.subscribe(() => (this.isHeaderVisible = false));
    }
  }
  logTruckModelButtonClick(path:string){
    if(path===this.HtmlRoutePages.truckModels)
    {
      this.counterService.postTruckModelClick('Truck Model Home');
    }
  }
 
}
