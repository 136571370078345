import {Component, OnInit} from '@angular/core';

import {GlobalService} from '~/core/services/global/global.service';
import {HtmlRoutePages} from '~/app-routing.model';


@Component({
    selector: 'volvo-cookies-page',
    templateUrl: './cookies-page.component.html',
    styleUrls: ['./cookies-page.component.scss']
})
export class CookiesPageComponent implements OnInit {
    HtmlRoutePages = HtmlRoutePages;

    constructor(
        private globalService: GlobalService,
    ) {
    }

    ngOnInit(): void {
    }

    editCookiesClick(): void {
        this.globalService.editCookies.next();
    }
}
