import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { HtmlRoutePages } from '../../app-routing.model';
import { SidenavService, NavLinks } from '../../core/services/sidenav/sidenav.service'
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { GlobalUtils } from '../../core/utils/global-utils/global-utils';
import { ScreenUtils } from '../../core/utils/screen-utils/screen-utils';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { regionEnum } from '../../core/declaration/declaration';
import { RegionalSettingsService } from '../../core/store/regionalSettings/regionalSettings.service';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
    selector: 'volvo-service-page',
    templateUrl: './service-page.component.html',
    styleUrls: ['./service-page.component.scss'],
})
export class ServicePageComponent implements OnInit {
    HtmlRoutePages: HtmlRoutePages
    navlinks: NavLinks[];
    isTablet: boolean = false
    region: string;
    regionEnum = regionEnum;
    path: string = '';
    regionalSettings: RegionSettings;
    site: string;
    isSelectedSite: boolean = false;
    constructor(private sidenavService: SidenavService, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService, public assetService: AssetService,
        public store: Store,
        private titleService: Title, private translateService: TranslateService, private metaTagService: Meta, private regionalSettingsService: RegionalSettingsService,
        private localizeRouterService: LocalizeRouterService, private globalUtils: GlobalUtils, private router: Router, private route: ActivatedRoute) {
        this.navlinks = sidenavService.getNavLinks();
        this.region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
        this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === this.region)[0]);
        if (this.regionalSettings && this.regionalSettings.site === "volvoselected") this.isSelectedSite = true;


    }
    ngOnInit() {
        

        if (this.globalUtils.isBrowser() && ScreenUtils.isTablet()) {
            this.isTablet = true;
        }
        this.updateBreadcrumb();
        this.updateMetaTags('uk/services/');
    }
    updateBreadcrumb(): void {
        const breadcrumbsConts = [
            {
                label: 'Services',
                url: ""
            },
            {
                label: '',
                url: ""
            }
        ];
        //this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbsConts);
    }
    routePage(link): void {
        this.router.navigate([this.localizeRouterService.translateRoute(link)], {
            relativeTo: this.route
        });
    }
    ngAfterViewInit(): void {
        this.scrollToElement();
    }
    updateMetaTags(path: string): void {
        const fullPath = `${path.toLowerCase()}metatitle`;
        const metatitle = this.translateService.instant(fullPath);
        const metadescription = this.translateService.instant(`${path.toLowerCase()}metadescription`);
        const altImage = this.translateService.instant(`${path.toLowerCase()}altimage`);
        const metakeywords = this.translateService.instant(`${path.toLowerCase()}metakeywords`);

        this.titleService.setTitle(metatitle);

        this.metaTagService.updateTag({ name: 'og:title', content: metatitle });
        this.metaTagService.updateTag({ name: 'og:description', content: metadescription });
        this.metaTagService.updateTag({ name: 'og:image', alt: 'alt', content: altImage });

        // Additional traditional meta tags for search engines
        this.metaTagService.updateTag({ name: 'description', content: metadescription });
        this.metaTagService.updateTag({ name: 'keywords', content: metakeywords });
    }
    scrollToElement(): void {
        if (this.path) {
            const el = document.getElementById(this.path);
            if (el) {
                el.scrollIntoView({ behavior: 'auto', inline: 'start' });
            }
            switch (this.path) {
                case 'export'://Export(Worldwide) tab clicked
                    this.updateMetaTags('pages/services/export/');
                    break;
                // case 'customisation'://Used Truck Customisation tab clicked
                //     this.updateMetaTags('services/customisation/');
                //     break;
                case 'financing':
                    this.updateMetaTags('financing/');
                    break;
                case 'selected':
                    this.updateMetaTags('uk/services/volvoselected/');
                    break;
                case 'economy':
                    this.updateMetaTags('uk/services/volvoeconomy/');
                    break;
                case 'finance':
                    this.updateMetaTags('uk/services/financing/');
                    break;
                case 'serviceContract':
                    this.updateMetaTags('uk/services/servicecontract/');
                    break;
                case 'customisation':
                    this.updateMetaTags('uk/services/customisation/');
                    break;
                case 'volvoConnect':
                    this.updateMetaTags('uk/services/volvoconnect/');
                    break;
            }
        }
        else {
            this.updateMetaTags('services/');
        }
    }
}
