<div class="Loading1">
    <div class="Loading1-DealearDiv" >
        
    </div>
    <div class="Loading1-DealearDiv"></div>
    <div class="Loading1-DealearDiv"></div>
    <div class="Loading1-DealearDiv"></div>
    <div class="Loading1-DealearDiv"></div>
    <script>
        if (window) {
            window.onload = () => {
                document.querySelector('.Loading1').remove();
            };
        }
    </script>
</div>