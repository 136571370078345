import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { RegionalSettingsService } from '../../core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '../../core/declaration/declaration';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
@Component({
  selector: 'volvo-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
 
  isTablet: boolean = false;
  region:string;
  regionEnum=regionEnum;
  faqGroup = [
    {
      title: 'uk/pages/faq/mainQuestion1',
      description:  [
        {
          title: 'uk/pages/faq/question1',
          text:  'uk/pages/faq/answer1' 
        },
        {
          title: 'uk/pages/faq/question2',
          text:  'uk/pages/faq/answer2' 
        },
        {
          title: 'uk/pages/faq/question3',
          text:  'uk/pages/faq/answer3' 
        },
        {
          title: 'uk/pages/faq/question4',
          text:  'uk/pages/faq/answer4' 
        },
        {
          title: 'uk/pages/faq/question6',
          text:  'uk/pages/faq/answer6' 
        },
        {
          title: 'uk/pages/faq/question7',
          text:  'uk/pages/faq/answer7' 
        },
        {
          title: 'uk/pages/faq/question8',
          text:  'uk/pages/faq/answer8' 
        },
        {
          title: 'uk/pages/faq/question9',
          text:  'uk/pages/faq/answer9' 
        },
        {
          title: 'uk/pages/faq/question11',
          text:  'uk/pages/faq/answer11' 
        },
        {
          title: 'uk/pages/faq/question13',
          text:  'uk/pages/faq/answer13' 
        },
        {
          title: 'uk/pages/faq/question14',
          text:  'uk/pages/faq/answer14' 
        },
        {
          title: 'uk/pages/faq/question15',
          text:  'uk/pages/faq/answer15' 
        },
        {
          title: 'uk/pages/faq/question16',
          text:  'uk/pages/faq/answer16' 
        },
        {
          title: 'uk/pages/faq/question17',
          text:  'uk/pages/faq/answer17' 
        },
        {
          title: 'uk/pages/faq/question18',
          text:  'uk/pages/faq/answer18' 
        },
        
        {
          title: 'uk/pages/faq/question20',
          text:  'uk/pages/faq/answer20' 
        },
        {
          title: 'uk/pages/faq/question21',
          text:  'uk/pages/faq/answer21' 
        },
        {
          title: 'uk/pages/faq/question22',
          text:  'uk/pages/faq/answer22' 
        },
        {
          title: 'uk/pages/faq/question23',
          text:  'uk/pages/faq/answer23' 
        }
       ]
    },
    {
      title: 'uk/components/footer/tradeExport',
      description:  [{
        
        title: 'uk/pages/faq/question27',
        text:  'uk/pages/faq/answer27' 
      },
      {
        title: 'uk/pages/faq/question19',
        text:  'uk/pages/faq/answer19' 
      },
     ]
    },
    {
      title: 'uk/pages/faq/mainQuestion3',
      description:  [{
        title: 'uk/pages/faq/question24',
        text:  'uk/pages/faq/answer24' 
      },
      {
        title: 'uk/pages/faq/question25',
        text:  'uk/pages/faq/answer25' 
      },
      {
        title: 'uk/pages/faq/question26',
        text:  'uk/pages/faq/answer26' 
      },
     ]
    }
  ]
  txfaqGroup=[];
  regionalSettings: RegionSettings;
  isSelectedSite: boolean = false;
  constructor(private titleService: Title,
    private translateService: TranslateService,
    private regionSettingService:RegionalSettingsService,
    store: Store) { 
      const region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
      this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === region)[0]);
      this.region = this.regionalSettings?.region;
      if(this.regionalSettings?.site === 'volvoselected') 
        this.isSelectedSite = true;

    }

  ngOnInit(): void {
    this.titleService.setTitle(this.translateService.instant('uk/pages/faq/metatitle'.toLowerCase()));

    
  if(this.region===this.regionEnum.TX)
    {
      this.txfaqGroup = [
            {
              title: 'uk/pages/faq/question1',
              text:  'uk/pages/faq/answer1' 
            },
            {
              title: 'uk/pages/faq/question2',
              text:  'uk/pages/faq/answer2' 
            },
            {
              title: 'uk/pages/faq/question3',
              text:  'uk/pages/faq/answer3' 
            },
            {
              title: 'uk/pages/faq/question4',
              text:  'uk/pages/faq/answer4' 
            },
            {
              title: 'uk/pages/faq/question6',
              text:  'uk/pages/faq/answer6' 
            },
            {
              title: 'uk/pages/faq/question7',
              text:  'uk/pages/faq/answer7' 
            },
            {
              title: 'uk/pages/faq/question8',
              text:  'uk/pages/faq/answer8' 
            },
            {
              title: 'uk/pages/faq/question9',
              text:  'uk/pages/faq/answer9' 
            },
            {
              title: 'uk/pages/faq/question11',
              text:  'uk/pages/faq/answer11' 
            },
            {
              title: 'uk/pages/faq/question13',
              text:  'uk/pages/faq/answer13' 
            },
            {
              title: 'uk/pages/faq/question14',
              text:  'uk/pages/faq/answer14' 
            },
            {
              title: 'uk/pages/faq/question15',
              text:  'uk/pages/faq/answer15' 
            },
            {
              title: 'uk/pages/faq/question16',
              text:  'uk/pages/faq/answer16' 
            },
            {
              title: 'uk/pages/faq/question17',
              text:  'uk/pages/faq/answer17' 
            },
            {
              title: 'uk/pages/faq/question18',
              text:  'uk/pages/faq/answer18' 
            },
            {
              title: 'uk/pages/faq/question19',
              text:  'uk/pages/faq/answer19' 
            },
            {
              title: 'uk/pages/faq/question20',
              text:  'uk/pages/faq/answer20' 
            },
            {
              title: 'uk/pages/faq/question21',
              text:  'uk/pages/faq/answer21' 
            },
            {
              title: 'uk/pages/faq/question22',
              text:  'uk/pages/faq/answer22' 
            },
            {
              title: 'uk/pages/faq/question23',
              text:  'uk/pages/faq/answer23' 
            },
            {
              title: 'uk/pages/faq/question25',
              text:  'uk/pages/faq/answer25' 
            }
      ]
    }
  }

}
