<section class="volvo">
    <div class="txvolvoDiv" id="export">
        <div>
            <img [src]="assetService.getAssetUrl('assets/pages/services/txservice.webp')" alt="">
        </div>
        <div class="txvolvoDiv-title">
            <span>{{ "pages/services/export" | lowercase | translate }}</span>
        </div>
        <div class="txvolvoDiv-text">
            <span [innerHTML]="'pages/services/export/text1' | lowercase | translate"></span>
        </div>
        <div>
            <div class="tximgdiv txpadding">
                <div *ngFor="let obj of exportObject">
                    <img [src]="assetService.getAssetUrl(obj.image)" alt="{{obj.title | lowercase | translate}}"/>
                    <div class="tximgdiv-divWidth">
                        <div class="txvolvoDiv-text-medium">{{obj.title | lowercase | translate}}</div>
                        <div class="txtext" id="utcustomisation" [innerHTML]="obj.text | lowercase | translate"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<volvo-footer></volvo-footer>
