import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MapsService {
    private isLoaded = false;
    private loadScriptPromise: Promise<void>;

    constructor(private http: HttpClient) { }

    loadGoogleMapsScript(): Promise<void> {
        if (this.isLoaded) {
            return Promise.resolve(); // Return a resolved promise if the script is already loaded
        }

        if (this.loadScriptPromise) {
            return this.loadScriptPromise; // Return the existing promise if the script is in the process of loading
        }

        this.loadScriptPromise = new Promise((resolve, reject) => {
            this.http.get(environment.apiUrl + 'Maps/maps-script', { responseType: 'text' }).subscribe(
                (scriptContent) => {
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.defer = true;
                    script.async = true;
                    script.text = scriptContent;

                    // Set up the callback when the script is fully loaded
                    script.onload = () => {
                        this.isLoaded = true;
                        resolve();  // Resolve the promise when the script has loaded
                    };

                    script.onerror = (error) => reject(error); // Handle script loading error

                    document.head.appendChild(script);
                },
                (error) => {
                    reject(error);  // Handle HTTP error
                }
            );
        });

        return this.loadScriptPromise;
    }
}
