export class SalesUser {
    name: string;
    info: string;
    adress: string;
    city: string;
    postalcode: string;
    phone: string;
    email: string;
    locationName: string;
    isExpanded = false;
}

export interface SalesUsers { }

export enum regionEnum {
  UK = 'UK',
  TX = 'TX',
  ZA = "ZA",
  Selected = "Selected"
}