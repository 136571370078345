import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VIEWER } from '~/core/constants/constants';
import { TokenService } from '../auth/token.service';
import { Location } from '@angular/common';
import { LocalesService } from '~/core/store/locales/locales.service';
import { lastValueFrom } from 'rxjs';
import { detect } from 'detect-browser';
import { TrucksState } from '../../store/trucks/trucks.state';
import { DeviceDetectorService } from 'ngx-device-detector';

class Guid {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}

class sessionBase {
  sessionId: string;
  sessionStartTime: string;
  langCode: string;
  region: string;
  country: string;
  site: string;
  userAgent: string;
  browserName: string;
  browserVersion: string;
  device: string;
  deviceType: string;
  CampaignId: string;
  campaignSource: string;
  campaignMedium: string;
}

class EmailUserActions {
  emailButton: boolean = false;
  phoneButton: boolean = false;
  financeQuote: boolean = false;
  close: boolean = false;
  send: boolean = false;
}
class EmailPhoneClick {
  contactMode: string;
  UTAHTruckId: string;
  email: boolean;
}
class VfsRequestQuotePopUpUserActions {
  vfsClose: boolean = false;
  vfsSend: boolean = false;
}
class DriverGuideCount extends sessionBase {
  constructor(init?: Partial<DriverGuideCount>) {
    super();
    Object.assign(this, init);
  }

  truckId: string;
}
class EmailPopUp extends EmailUserActions {
  constructor(init?: Partial<EmailPopUp>) {
    super();
    Object.assign(this, init);
  }

  //truckId: string;
}

class EmailPhoneButton extends EmailPhoneClick {
  constructor(init?: Partial<EmailPhoneButton>) {
    super();
    Object.assign(this, init);
  }

  //truckId: string;
}
class GoogleTranslateButtonCount extends sessionBase {
  constructor(init?: Partial<DriverGuideCount>) {
    super();
    Object.assign(this, init);
  }
  fromLang: string;
  toLang: string;
}

class PrintButtonCount extends sessionBase {
  constructor(init?: Partial<DriverGuideCount>) {
    super();
    Object.assign(this, init);
  }

  truckId: string;
}
class ShareButtonCount extends sessionBase {
  constructor(init?: Partial<DriverGuideCount>) {
    super();
    Object.assign(this, init);
  }

  socialHandler: string;
}

class postRegionSelection extends sessionBase {
  constructor(init?: Partial<DriverGuideCount>) {
    super();
    Object.assign(this, init);
  }

  sRegion: string;
  sCountry: string;
  sLanguage: string;
}

class EmailPhoneButtonClick extends sessionBase {
  constructor(init?: Partial<DriverGuideCount>, init1?: Partial<EmailPhoneButton>) {
    super();
    Object.assign(this, init);
    Object.assign(this, init1);
  }
  contactFrom: string;
  emailPhoneButton: EmailPhoneClick;
}

class VfsPopUpUserAction extends sessionBase {
  constructor(init?: Partial<DriverGuideCount>, init1?: Partial<VfsRequestQuotePopUpUserActions>) {
    super();
    Object.assign(this, init);
    Object.assign(this, init1);
  }
  truckId: string;
  stockCountry: string;
  contactFrom: string;
  email: boolean;
}

class EmailPopUpUserAction extends sessionBase {
  constructor(init?: Partial<DriverGuideCount>, init1?: Partial<EmailPopUp>) {
    super();
    Object.assign(this, init);
    Object.assign(this, init1);
  }
  contactFrom: string;
  UTAHTruckId: string;
  emailUserActions: EmailUserActions;
  email: boolean = false;
}

class VfsRequestQuoteButtonClick extends sessionBase {
  constructor(init?: Partial<DriverGuideCount>) {
    super();
    Object.assign(this, init);
  }
  contactFrom: string;
  truckId: string;
  contactMode: string;
}
class GenericFinanceRequest extends sessionBase {
  constructor(init?: Partial<DriverGuideCount>) {
    super();
    Object.assign(this, init);
  }
  contactFrom: string;
  contactMode: string;
  financeCountry: string;
  dealershipLocation: string;
}
class TruckModelCount extends sessionBase {
  constructor(init?: Partial<DriverGuideCount>) {
    super();
    Object.assign(this, init);
  }

  modelName: string;
}
@Injectable({
  providedIn: 'root'
})
export class CounterService {
  tokenService: TokenService;
  sessionBaseUrl = environment.apiUrl + 'session';
  driverguideBaseUrl = environment.apiUrl + 'session/driversguide';
  googleTranslteButtonBaseUrl = environment.apiUrl + 'session/googletranslatebutton';
  printButtonBaseUrl = environment.apiUrl + 'session/printbutton';
  regionSelectionBaseUrl = environment.apiUrl + 'session/regionSelection';
  shareButtonBaseUrl = environment.apiUrl + 'session/sharebutton';
  emailPhoneButtonBaseUrl = environment.apiUrl + 'session/emailphonebutton';
  emailPopUpBaseUrl = environment.apiUrl + 'session/emailPopUp';
  vfsRequestQuoteBaseUrl = environment.apiUrl + 'session/vfsRequestQuote';
  vfsPopUpBaseUrl = environment.apiUrl + 'session/vfsRequestQuotePopUp';
  truckModelBaseUrl = environment.apiUrl + 'session/truckmodel';
  genericFinanceRequestBaseUrl = environment.apiUrl + 'session/genericFinanceRequest';
  emailFlag: boolean = false;
  deviceInfo: any;
  constructor(
    private http: HttpClient,
    private location: Location,
    private localesService: LocalesService,
    private trucksState: TrucksState,
    private deviceService: DeviceDetectorService,
    @Optional() @Inject(VIEWER) private viewer
  ) {
    this.tokenService = new TokenService(http, location, localesService);
    this.deviceInfo = this.deviceService.getDeviceInfo();
    console.log(this.deviceInfo);
  }

  generateSessionBase() {
    const sessionId = Guid.newGuid();
    const date = new Date().getUTCDate();

    var lang = JSON.parse(localStorage.getItem('language'));
    if (!lang.lang.curLang) {
      return null;
    }
    const langCode = lang === null ? 'en' : lang.lang.curLang.id;
    const region = lang === null ? 'EU' : lang.region.curRegion.id;
    const country = lang === null ? 'en' : lang.country.curCountry.id === null ? 'en' : lang.country.curCountry.id;
    let d = new Date();
    const timeStamp = [`${d.getUTCFullYear()}`, `0${d.getUTCMonth()}`.substr(-2), `0${d.getUTCDate()}`.substr(-2)].join(
      '-'
    );

    let site: string;

    switch (region) {
      case 'EU':
        site = 'volvoselected';
        break;
      case 'UK':
        site = 'ukwebportal';
        break;
      default:
        site = 'TX';
    }

    const sessionObject: sessionBase = {
      sessionId: sessionId,
      sessionStartTime: timeStamp,
      langCode: langCode,
      region: region,
      country: country,
      site: site,
      userAgent: this.viewer,
      browserName: this.deviceInfo.browser.toLowerCase(),
      browserVersion: this.deviceInfo.browser_version,
      device: this.deviceInfo.device,
      deviceType: this.deviceInfo.deviceType,
      CampaignId: sessionStorage.getItem('campaignId'),
      campaignSource: sessionStorage.getItem('campaignSource'),
      campaignMedium: sessionStorage.getItem('campaignMedium')
    };

    return sessionObject;
  }

  async postSession() {
    var sessionObject = this.generateSessionBase();

    if (sessionObject === null) {
      return null;
    }

    await lastValueFrom(this.http.post(this.sessionBaseUrl, sessionObject));

    localStorage.setItem('jaeger-debug-id', sessionObject.sessionId);
    localStorage.setItem('jaeger-debug-id-time', sessionObject.sessionStartTime.toString());
  }

  async postDriverGuide(truckId: string) {
    var driversGuideCount = new DriverGuideCount(this.generateSessionBase());
    driversGuideCount.truckId = truckId;

    await lastValueFrom(this.http.post(this.driverguideBaseUrl, driversGuideCount));
  }

  async postGoogleTranslateButton(fromLang: string, toLang: string) {
    var googleTranslateButtonCount = new GoogleTranslateButtonCount(this.generateSessionBase());
    googleTranslateButtonCount.fromLang = fromLang;
    googleTranslateButtonCount.toLang = toLang;

    await lastValueFrom(this.http.post(this.googleTranslteButtonBaseUrl, googleTranslateButtonCount));
  }

  async postPrintButton(truckId: string) {
    var printButtonCount = new PrintButtonCount(this.generateSessionBase());
    printButtonCount.truckId = truckId;

    await lastValueFrom(this.http.post(this.printButtonBaseUrl, printButtonCount));
  }

  async postRegionSelection(region: string, country: string, language: string) {
    var RegionSelectionCount = new postRegionSelection(this.generateSessionBase());
    RegionSelectionCount.sRegion = region;
    RegionSelectionCount.sCountry = country;
    RegionSelectionCount.sLanguage = language;

    await lastValueFrom(this.http.post(this.regionSelectionBaseUrl, RegionSelectionCount));
  }

  async postShareButton(socialHandlerName: string) {
    var shareButtonCount = new ShareButtonCount(this.generateSessionBase());
    shareButtonCount.socialHandler = socialHandlerName;

    await lastValueFrom(this.http.post(this.shareButtonBaseUrl, shareButtonCount));
  }

  async postEmailPhoneButtonClicks(contactFrom: string, buttonClickObj: EmailPhoneClick) {
    var emailPhoneButton = new EmailPhoneButtonClick(
      this.generateSessionBase(),
      this.getEmailPhoneClick(buttonClickObj)
    );
    emailPhoneButton.contactFrom = contactFrom;

    await lastValueFrom(this.http.post(this.emailPhoneButtonBaseUrl, emailPhoneButton));
  }

  getEmailPhoneClick(epa: EmailPhoneButton) {
    const emailObject = {
      contactMode: epa.contactMode,
      UTAHTruckId: epa.UTAHTruckId,
      email: epa.email
    };
    return emailObject;
  }

  getUserAction(emailUserAction: EmailUserActions) {
    const emailObject = {
      emailButton: emailUserAction.emailButton,
      phoneButton: emailUserAction.phoneButton,
      financeQuote: emailUserAction.financeQuote,
      close: emailUserAction.close,
      send: emailUserAction.send
    };
    return emailObject;
  }

  getVfsUserAction(vfsUserAction: VfsRequestQuotePopUpUserActions) {
    const vfsObject = { vfsClose: vfsUserAction.vfsClose, vfsSend: vfsUserAction.vfsSend };
    return vfsObject;
  }

  async postEmailPopUpUserActions(
    contactFrom: string,
    UTAHTruckId: string,
    emailUserAction: EmailUserActions,
  ) {
    var emailPopUpUserActions = new EmailPopUpUserAction(
      this.generateSessionBase(),
      this.getUserAction(emailUserAction)
    );
    emailPopUpUserActions.contactFrom = contactFrom;
    emailPopUpUserActions.UTAHTruckId = UTAHTruckId;
    if (sessionStorage.getItem('email') === 'true')
      emailPopUpUserActions.email = true;
    else
      emailPopUpUserActions.email = false;
    await lastValueFrom(this.http.post(this.emailPopUpBaseUrl, emailPopUpUserActions));
  }

  async postVfsRequestQuote(contactFrom: string, contactMode: string, truckId: string) {
    var vfsRequestQuoteButton = new VfsRequestQuoteButtonClick(this.generateSessionBase());
    vfsRequestQuoteButton.contactFrom = contactFrom;
    vfsRequestQuoteButton.contactMode = contactMode;
    vfsRequestQuoteButton.truckId = truckId;

    await lastValueFrom(this.http.post(this.vfsRequestQuoteBaseUrl, vfsRequestQuoteButton));
  }

  async postGenericFinanceRequest(contactFrom: string, contactMode: string, financeCountry: string, dealershipLocation: string) {
    var genericFinanceRequest = new GenericFinanceRequest(this.generateSessionBase());
    genericFinanceRequest.contactFrom = contactFrom;
    genericFinanceRequest.contactMode = contactMode;
    genericFinanceRequest.financeCountry = financeCountry;
    genericFinanceRequest.dealershipLocation = dealershipLocation;

    await lastValueFrom(this.http.post(this.genericFinanceRequestBaseUrl, genericFinanceRequest));
  }
  async postRequestQuotePopUpUserActions(
    contactFrom: string,
    truckId: string,
    stockCountry: string,
    vfsRequestQuoteUserAction: VfsRequestQuotePopUpUserActions
  ) {
    var vfsPopUpUserActions = new VfsPopUpUserAction(
      this.generateSessionBase(),
      this.getVfsUserAction(vfsRequestQuoteUserAction)
    );

    vfsPopUpUserActions.contactFrom = contactFrom;
    vfsPopUpUserActions.truckId = truckId;
    vfsPopUpUserActions.stockCountry = stockCountry;

    if (sessionStorage.getItem('email') === 'true')
      vfsPopUpUserActions.email = true;
    else
      vfsPopUpUserActions.email = false;

    await lastValueFrom(this.http.post(this.vfsPopUpBaseUrl, vfsPopUpUserActions));
  }

  returnButtonClickObj(buttonType: string, UTAHTruckId: string): EmailPhoneButton {
    if (sessionStorage.getItem('email') === 'true') this.emailFlag = true;

    return {
      contactMode: buttonType,
      UTAHTruckId: UTAHTruckId,
      email: this.emailFlag
    };
  }

  getBrowserNameAndVersion() {
    const browserDetails = detect();

    return browserDetails;
  }
  async postTruckModelClick(modelName: string) {
    var truckModelCount = new TruckModelCount(this.generateSessionBase());
    truckModelCount.modelName = modelName;

    await lastValueFrom(this.http.post(this.truckModelBaseUrl, truckModelCount));
  }
}
