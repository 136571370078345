import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

import { SearchStateFilter } from '~/core/store/search/search.model';
import { SearchService } from '~/core/store/search/search.service';
import { toggleHeight } from '~/animations';


@AutoUnsubscribe()
@Component({
    selector: 'volvo-stock-search',
    templateUrl: './stock-search.component.html',
    styleUrls: ['./stock-search.component.scss'],
    animations: [toggleHeight]
})
export class StockSearchComponent implements OnInit, OnDestroy {
    showChips = false;

    private s1: Subscription;

    constructor(
        private searchService: SearchService,
    ) {
        this.initSubscriptions();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    private initSubscriptions(): void {
        this.s1 = this.searchService.filter$
            .subscribe((search: SearchStateFilter) => {
                const isStepSelected = Object.values(search.steps).some((item) => item.isSelected);
                const isSearchQSelected = Boolean(search.q);
                this.showChips = isStepSelected || isSearchQSelected;
            });
    }
}
