<section class="FinanceA">
  <div class="FinanceA-request">
    <ul class="js-link-container">
      <!-- <li><a class="button blue" href="http://www.volvoselected.com" target="_self">
                <span>Visit Volvo Selected</span></a>
            </li> -->
      <li><span class="choose CPrimary">CHOOSE MARKET OR LANGUAGE</span></li>
      <li class="region volvofontmedium CPrimary">
        <strong>Languages: </strong>
        <a
          class="item-content country-listing-selectedLang CPrimary volvofont"
          *ngFor="let list of SouthernObj.listEn"
          (click)="onLangChange(list.id)"
          >{{ list.label }}
        </a>
        <a
          class="item-content CPrimary volvofont"
          *ngFor="let list of SouthernObj.listRu"
          (click)="onLangChange(list.id)"
          >{{ list.label }}
        </a>
      </li>
    </ul>
  </div>
</section>

<section class="sectionB">
  <div class="module-listing" #south>
    <hr />
    <div class="module-listing-innerModule">
      <div class="multiColumn box CPrimary">
        <div class="country-listing-title">B</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listB">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="country-listing-title">C</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listC">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="country-listing-title">D</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listD">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="multiColumn box CPrimary">
        <div class="country-listing-title">E</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listE">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="country-listing-title">F</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listF">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="country-listing-title">H</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listH">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="country-listing-title">I</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listI">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="multiColumn box CPrimary">
        <div class="country-listing-title">L</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listL">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="country-listing-title">M</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listM">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="country-listing-title">N</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listN">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="country-listing-title">Ö</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listO">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="multiColumn box CPrimary">
        <div class="country-listing-title">P</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listP">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="country-listing-title">R</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listR">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="country-listing-title">S</div>
        <div class="country-listing">
          <ul class="cellpadding" *ngFor="let list of SouthernObj.listS">
            <li>
              <a class="item-content" (click)="onLangChange(list.id)">
                <span>{{ list.label }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<volvo-footer></volvo-footer>
