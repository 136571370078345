import { SearchStateFilter, SearchStateParams, UpdateStepOptions } from '~/core/store/search/search.model';

export class GetSearch {
  static readonly type = '[GetSearch]: action';

  constructor(public payload: SearchStateParams) { }
}

export class GetAggregations {
  static readonly type = '[GetAggregations]: action';

  constructor(public payload: SearchStateParams) { }
}

export class SetSearchFromUrl {
  static readonly type = '[SetSearchFromUrl]: action';

  constructor(public payload: SearchStateParams) { }
}

export class UpdateSearch {
  static readonly type = '[UpdateSearch]: action';

  constructor(public payload: SearchStateFilter) { }
}

export class ResetStep {
  static readonly type = '[ResetStep]: action';

    constructor(public payload: string, public removedChip: any) { }
}

export class UpdateStep {
  static readonly type = '[UpdateStep]: action';

  constructor(public payload: UpdateStepOptions) { }
}

export class SetDefaultCategory{
  static readonly type = '[SetDefaultCategory]: action';

  constructor(public payload: string) {}
}
