import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { VfsCalculate } from '~/core/api/vfs-api/vfs-api.model';
import { AppState } from '~/core/store';
import { LocalesStateCountry, LocalesStateLang, LocalesStateRegion } from '~/core/store/locales/locales.model';
import { environment } from '../../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class VfsApiService {
    private calculatePath = 'Vfs/:langId';
    site: string;
    region: string;
    constructor(
        private http: HttpClient,
        private store: Store,
    ) {
    }

    getTotalFinancePayment(calc: VfsCalculate): Observable<any> {
        const lang = this.store.selectSnapshot<LocalesStateCountry>((state: AppState) => state.language.country);
        const region = this.store.selectSnapshot<LocalesStateRegion>((state: AppState) => state.language.region);

        const stockCountry = calc.stockCountry;
        let stockCountryIsoCode = calc.stockCountryIsoCode.toLowerCase();
        if(lang.curCountry?.id){ //pass user selection country if exists else pass stock location country;
            stockCountryIsoCode = lang.curCountry.id;
        }
 
        let shouldNotCalculate;
        if(region.curRegion.id === 'EU' || region.curRegion.id === 'ZA'){
            this.site = "volvoselected";
            this.region = region.curRegion.id;
            shouldNotCalculate = calc.sales_price < 1000 || calc.sales_price === undefined || calc.truck_age > 7 || stockCountryIsoCode == 'null' || stockCountryIsoCode == 'ba' || stockCountryIsoCode == 'ru' || stockCountryIsoCode == null || calc.truck_make != 'Volvo';
        }
        else{
            this.site = "ukwebportal";
            this.region = "UK";
            shouldNotCalculate = calc.sales_price < 1000 || calc.sales_price === undefined || calc.truck_age > 7 || calc.truck_make != 'Volvo';
        }
        
        
        if (shouldNotCalculate)
            return new Observable<any>();

        return this.http.get(this.langUrl(this.calculatePath,stockCountryIsoCode) + `?sales_price=${Math.round(calc.sales_price)}&down_payment=${Math.round(calc.down_payment)}&no_of_terms=${calc.no_of_terms}&site=${this.site}&region=${this.region}`);
    }

    private langUrl(path: string, stockCountryIsoCode: string): string {
        if(this.site !== 'volvoselected')
        {
            const pathWithLang = path.replace(':langId', 'uk');
            return `${environment.apiUrl}${pathWithLang}`;
        }
        const lang = this.store.selectSnapshot<LocalesStateCountry>((state: AppState) => state.language.country);
        const countryId = stockCountryIsoCode === 'sr' ? 'rs' : stockCountryIsoCode;

        const pathWithLang = path.replace(':langId', countryId);
        return `${environment.apiUrl}${pathWithLang}`;
    }
}
