import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';
import { Injectable } from '@angular/core';

import { HtmlRoutePages, LangRoutePages } from '~/app-routing.model';
import { RegionalSettingsService } from '~/core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '~/core/declaration/declaration';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RegionCheckService } from '../siteName/region-check.service';
import { SiteCheckService } from '../siteName/site-check.service';
import { pairwise } from 'rxjs';

export interface NavLinks {
    label: string;
    path: string;
    isActive: false;
    children?: subHeaderLinks[];
}

export enum Sidenavs {
    menu,
    product
}

export interface subHeaderLinks {
    label: string;
    path: string;
}
@Injectable({
    providedIn: 'root'
})
export class SidenavService {
    isSelectedSite: boolean = false;
    navLinks: NavLinks[] = [];
    regionalSettings: RegionSettings;
    region: string;
    regionEnum = regionEnum;
    isMobile: boolean = false;
    deviceInfo: any;
    constructor(
        public regionSettingService: RegionalSettingsService,
        store: Store,
        private deviceService: DeviceDetectorService,
        private regionCheckService: RegionCheckService,
        private siteCheckService: SiteCheckService) {
        //this.isSelectedSite = this.regionSettingService.checkForSelectedWebsite();

        // const region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
        // this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === region)[0]);
        //

        if (this.regionalSettings) {

            this.region = this.regionalSettings?.region;
            if (this.regionalSettings?.site === "volvoselected")
                this.isSelectedSite = true;

            this.initializeNavLinks();
        }

        var langObj = JSON.parse(localStorage.getItem('language'));

        if (langObj && langObj.region && langObj.region.curRegion) {
            this.region = langObj.region.curRegion.id;
            if (this.region === "EU" || this.region === "ZA")
                this.isSelectedSite = true;
        }
        else {
            console.error("Error: Unable to retrieve region ID from local storage.");
        }
        this.initializeNavLinks();

        // this.regionCheckService.getCurrentRegionName().pipe(pairwise()).subscribe(([oldRegion, region]) => {
        //     this.region = region;
        //     if(this.region === "EU" || this.region === "ZA"){
        //         this.isSelectedSite = true;
        //
        //     }
        //     this.initializeNavLinks();
        // });
    }

    private menu: MatSidenav;
    private product: MatSidenav;
    getNavLinks(): NavLinks[] {
        return this.navLinks;
    }

    setSidenav(sidenav: MatSidenav, type: Sidenavs): void {
        switch (type) {
            case Sidenavs.menu:
                this.menu = sidenav;
                break;
            case Sidenavs.product:
                this.product = sidenav;
                break;
        }
    }

    closeAllSidenavs(): void {
        this.menu.close();
        this.product.close();
    }

    open(type: Sidenavs): Promise<MatDrawerToggleResult> {
        switch (type) {
            case Sidenavs.menu:
                return this.menu.open();
            case Sidenavs.product:
                return this.product.open();
        }
    }
    close(type: Sidenavs): Promise<MatDrawerToggleResult> {
        switch (type) {
            case Sidenavs.menu:
                return this.menu.close();
            case Sidenavs.product:
                return this.product.close();
        }
    }

    toggle(type: Sidenavs): Promise<MatDrawerToggleResult> {
        switch (type) {
            case Sidenavs.menu:
                return this.menu.toggle();
            case Sidenavs.product:
                return this.product.toggle();
        }
    }

    private initializeNavLinks() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            this.isMobile = true;
        }
        if (this.deviceInfo.os.toLowerCase() === 'ios' || this.deviceInfo.os.toLowerCase() === 'mac') {
            if (this.deviceInfo.device.toLowerCase() === 'iphone' || this.deviceInfo.device.toLowerCase() === 'ipad'
            || this.deviceInfo.orientation.toLowerCase() === 'portrait') {
                // Set isMobile to true if the device is iPhone or iPad
                this.isMobile = true;
            } else {

                // For any other iOS device with orientation as 'landscape' , set isMobile to false
                this.isMobile = false;
            }
        }
        // if (this.deviceInfo.os.toLowerCase() === 'mac') {
        //     if (this.deviceInfo.device === 'Macintosh') {
        //         this.isMobile = true;
        //     }
        // }
        if (this.isSelectedSite) {
            this.navLinks = this.buildSelectedSiteNavLinks();
        } else {
            this.navLinks = this.buildNonSelectedSiteNavLinks();
        }
    }

    private buildSelectedSiteNavLinks(): NavLinks[] {
        return [
            { label: 'pages/search', path: HtmlRoutePages.search, isActive: false },
            {
                label: 'components/footer/service', path: HtmlRoutePages.services, isActive: false, children: [
                    { label: 'pages/Services/nav/volvoselected', path: HtmlRoutePages.volvoSelected },
                    { label: 'pages/financing', path: HtmlRoutePages.financing }
                ]
            },
            {
                label: 'components/footer/truckRange', path: HtmlRoutePages.truckModels, isActive: false, children: [
                    { label: 'pages/truckModel/volvoFH16', path: HtmlRoutePages.volvoFH16 },
                    { label: 'pages/truckModel/volvoFH', path: HtmlRoutePages.volvoFH },
                    { label: 'pages/truckModel/volvoFM', path: HtmlRoutePages.volvoFM },
                    { label: 'pages/truckModel/volvoFMX', path: HtmlRoutePages.volvoFMX },
                    { label: 'pages/truckModel/volvoFE', path: 'https://www.volvotrucks.com/en-en/trucks/trucks/volvo-fe.html' },
                    { label: 'pages/truckModel/volvoFL', path: 'https://www.volvotrucks.com/en-en/trucks/trucks/volvo-fl.html' }
                ]
            },
            { label: 'pages/aboutUs', path: HtmlRoutePages.aboutUs, isActive: false },
            { label: 'pages/contact', path: this.isMobile? HtmlRoutePages.contactMob : HtmlRoutePages.contact, isActive: false }
        ];
    }

    private buildNonSelectedSiteNavLinks(): NavLinks[] {
        const nonSelectedNavLinks: NavLinks[] = [
            { label: 'pages/search', path: HtmlRoutePages.search, isActive: false },
        ];

        if (this.region !== regionEnum.TX) {
            const vehiclecategoriesMenu: NavLinks[] = [
                { label: 'uk/components/footer/remainingwarranty', path: HtmlRoutePages.vehicleWarranty, isActive: false },
                { label: 'uk/components/footer/volvoselected', path: HtmlRoutePages.vehicleSelected, isActive: false },
                { label: 'uk/components/footer/volvoapproved', path: HtmlRoutePages.vehicleApproved, isActive: false },
                { label: 'uk/components/footer/volvoeconomy', path: HtmlRoutePages.vehicleEconomy, isActive: false }
            ];
            nonSelectedNavLinks.push({ label: 'uk/pages/vehiclecategories', path: HtmlRoutePages.vehicleOffers, isActive: false, children: vehiclecategoriesMenu });
        }

        const buyersguideMenu: NavLinks[] = [
            { label: 'uk/pages/buyersguide/volvofh16', path: HtmlRoutePages.bgvolvoFH16, isActive: false },
            { label: 'uk/pages/buyersguide/volvofh', path: HtmlRoutePages.bgvolvoFH, isActive: false },
            { label: 'uk/pages/buyersguide/volvofm', path: HtmlRoutePages.bgvolvoFM, isActive: false },
            { label: 'uk/pages/buyersguide/volvofmx', path: HtmlRoutePages.bgvolvoFMX, isActive: false },
            { label: 'uk/pages/buyersguide/volvofe', path: HtmlRoutePages.bgvolvoFE, isActive: false },
            { label: 'uk/pages/buyersguide/volvofl', path: HtmlRoutePages.bgvolvoFL, isActive: false }
        ];
        nonSelectedNavLinks.push({ label: 'uk/pages/buyersguide', path: HtmlRoutePages.buyersGuide, isActive: false, children: buyersguideMenu });

        if (this.region === regionEnum.TX) {
            const servicesMenu: NavLinks[] = [
                { label: 'pages/services/export', path: HtmlRoutePages.serviceExport, isActive: false },
                { label: 'pages/services/utcustomisation', path: HtmlRoutePages.serviceCustomisation, isActive: false },
            ];
            nonSelectedNavLinks.push({ label: 'uk/pages/services', path: HtmlRoutePages.services, isActive: false, children: servicesMenu });
        }
        else {
            const servicesMenu: NavLinks[] = [
                { label: 'uk/pages/services/volvoselected', path: HtmlRoutePages.serviceApproved, isActive: false },
                { label: 'uk/pages/services/volvoeconomy', path: HtmlRoutePages.serviceEconomy, isActive: false },
                { label: 'uk/components/footer/financing', path: HtmlRoutePages.finance, isActive: false },
                { label: 'uk/components/footer/servicecontracts', path: HtmlRoutePages.serviceContract, isActive: false },
                { label: 'uk/components/footer/customisation', path: HtmlRoutePages.customisation, isActive: false },
                { label: 'uk/components/footer/volvoconnect', path: HtmlRoutePages.volvoConnect, isActive: false }
            ];
            nonSelectedNavLinks.push({ label: 'uk/pages/services', path: HtmlRoutePages.services, isActive: false, children: servicesMenu });
        }



        if (this.region === regionEnum.TX) {
            const aboutUsMenu: NavLinks[] = [
                { label: 'uk/pages/aboutus/whoweare', path: HtmlRoutePages.whoweare, isActive: false },
            ];
            nonSelectedNavLinks.push({ label: 'uk/pages/aboutus', path: HtmlRoutePages.aboutUs, isActive: false, children: aboutUsMenu });

            const contactMenu: NavLinks[] = [
                { label: 'uk/pages/tradeexport/title1/text2', path: HtmlRoutePages.tradeExportSub, isActive: false }
            ];
            if (this.isMobile) {
                nonSelectedNavLinks.push({ label: 'uk/pages/contact', path: HtmlRoutePages.contactMob, isActive: false });

            }
            else {
                nonSelectedNavLinks.push({ label: 'uk/pages/contact', path: HtmlRoutePages.contact, isActive: false, children: contactMenu });

            }
        }
        else {

            const aboutUsMenu: NavLinks[] = [
                { label: 'uk/pages/aboutus/whoweare', path: HtmlRoutePages.whoweare, isActive: false },
                { label: 'uk/pages/aboutus/whatwehave', path: HtmlRoutePages.whatwehave, isActive: false },
                { label: 'uk/pages/aboutus/whatcustomersay', path: HtmlRoutePages.whatcustomersay, isActive: false }
            ];
            nonSelectedNavLinks.push({ label: 'uk/pages/aboutus', path: HtmlRoutePages.aboutUs, isActive: false, children: aboutUsMenu });

            const contactMenu: NavLinks[] = [
                { label: 'uk/pages/contact/contacttitle', path: HtmlRoutePages.meetTeam, isActive: false }
            ];

            if (this.region === regionEnum.UK) {
                contactMenu.push(
                    { label: 'uk/pages/contact/contactcentre1', path: HtmlRoutePages.centreBanbury, isActive: false },
                    { label: 'uk/pages/contact/contactcentre3', path: HtmlRoutePages.centreEly, isActive: false },
                    { label: 'uk/pages/contact/UserTruckCentre9', path: HtmlRoutePages.centreEdinburgh, isActive: false },
                    { label: 'uk/pages/contact/contactcentre5', path: HtmlRoutePages.centreFelixStowe, isActive: false },
                    { label: 'uk/pages/contact/UserTruckCentre10', path: HtmlRoutePages.centreGlasgowWest, isActive: false },
                    { label: 'uk/pages/contact/contactcentre2', path: HtmlRoutePages.centreBardon, isActive: false },
                    { label: 'uk/pages/contact/contactcentre6', path: HtmlRoutePages.centreHayes, isActive: false },
                    { label: 'uk/pages/contact/contactcentre4', path: HtmlRoutePages.centreEnfield, isActive: false },
                    { label: 'uk/pages/contact/contactcentre7', path: HtmlRoutePages.centreReading, isActive: false },
                    { label: 'uk/pages/contact/UserTruckCentre11', path: HtmlRoutePages.centreWashington, isActive: false },
                    { label: 'uk/pages/contact/contactcentre8', path: HtmlRoutePages.centreWellingBorough, isActive: false },
                );
            }
            else {

                contactMenu.push(
                    { label: 'uk/pages/contact/UserTruckCentre9', path: HtmlRoutePages.contact, isActive: false }
                );
            }
            if (this.isMobile) {
                const contactMobileMenu: NavLinks[] = [
                    { label: 'uk/pages/contact/contacttitle', path: HtmlRoutePages.meetTeam, isActive: false },
                    { label: 'uk/pages/contact/ourusertruckcentre', path: HtmlRoutePages.ourCentres, isActive: false }
                ];
                if (this.region !== regionEnum.TX && !this.isSelectedSite)
                    nonSelectedNavLinks.push({ label: 'uk/pages/contact', path: HtmlRoutePages.contactMob, isActive: false, children: contactMobileMenu });
            }
            else {
                nonSelectedNavLinks.push({ label: 'uk/pages/contact', path: HtmlRoutePages.contact, isActive: false, children: contactMenu });
            }
        }
        return nonSelectedNavLinks;
    }
}
