import { Component,OnInit } from '@angular/core';
import { TruckModelService } from '../../../core/services/truckmodel/truckmodel-service';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvoFM-page',
  templateUrl: '../model-page.component.html',
  styleUrls: ['../model.scss']
})
export class volvoFMPageComponent implements OnInit{ 
  leftNavDisabled = false;
  rightNavDisabled = false;
  leftCabNavDisabled = false;
  rightCabNavDisabled = false;
  modelName:string='pages/truckModel/volvoFM';
  imagePath:string='assets/pages/truckModel/volvoFM.webp';
  modelTitle:string="pages/truckModel/volvoFM/title";
  modelText:string="pages/truckModel/volvoFM/classic/text"
    model: string = 'FM';
    isLoading: boolean = true;
  modelObj = [
    {
      url: 'assets/pages/truckModel/volvoFH16/image1.webp',
      title: 'pages/truckModel/classic/highlights/title1',
      text: 'pages/truckModel/classic/highlights/title1/text1'
    },
    {
      url: 'assets/pages/truckModel/volvoFM/image3.webp',
      title: 'pages/truckModel/classic/highlights/title8',
      text: 'pages/truckModel/classic/highlights/title8/text1'
    },
    {
      url: 'assets/pages/truckModel/volvoFM/image2.webp',
      title: 'pages/truckModel/classic/highlights/title4',
      text: 'pages/truckModel/classic/highlights/title4/text2'
    },
    {
      url: 'assets/pages/truckModel/volvoFM/image6.webp',
      title: 'pages/truckModel/classic/highlights/title7',
      text: 'pages/truckModel/classic/highlights/title7/text1'
    },
    {
      url: 'assets/pages/truckModel/volvoFM/image7.webp',
      title: 'pages/truckModel/classic/highlights/title2',
      text: 'pages/truckModel/classic/highlights/title2/text1'
    },
    {
      url: 'assets/pages/truckModel/volvoFM/image8.webp',
      title: 'pages/truckModel/classic/highlights/title9',
      text: 'pages/truckModel/classic/highlights/title9/text1'
    }
  ];
  designObj = [
    {
      url: 'assets/pages/truckModel/volvoFM/image4.webp',
      title: 'pages/truckModel/exterior',
      text: 'pages/truckModel/exterior/FM'
    },
    {
      url: 'assets/pages/truckModel/volvoFM/image5.webp',
      title: 'pages/truckModel/interior',
      text: 'pages/truckModel/interior/FM'
    },
  ]
  cabObj = [
    {
      url: 'assets/pages/truckModel/volvoFM/engine1.webp',
      title: 'pages/truckModel/cab/option8',
      text: 'pages/truckModel/cab/option8/text1'
    },
    {
      url: 'assets/pages/truckModel/volvoFM/engine3.webp',
      title: 'pages/truckModel/cab/option1',
      text: 'pages/truckModel/cab/option1/text2'
    },
    {
      url: 'assets/pages/truckModel/volvoFM/engine2.webp',
      title: 'pages/truckModel/cab/option2',
      text: 'pages/truckModel/cab/option2/text2'
    },
    {
      url: 'assets/pages/truckModel/volvoFM/engine4.webp',
      title: 'pages/truckModel/cab/option3',
      text: 'pages/truckModel/cab/option3/text2'
    },
    {
      url: 'assets/pages/truckModel/volvoFM/engine5.webp',
      title: 'pages/truckModel/cab/option6',
      text: 'pages/truckModel/cab/option6/text1'
    }
  ]
  engineObj = [
    {
      name: 'pages/truckModel/engines',
      categories: [
        {
          title: 'pages/truckModel/engine4',
          colums: [
            { column1: 'pages/truckModel/engine4/power1', column2: '330 ', column3:'pages/truckModel/engines/hp'},
            { column1: 'pages/truckModel/engine4/power2', column2: ' 1600 ', column3:'pages/truckModel/engines/nm' }
          ]
        },
        {
          title: 'pages/truckModel/engine5',
          colums: [
            { column1: 'pages/truckModel/engine5/power1', column2: '380 ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine4/power2', column2: '1800  ', column3:'pages/truckModel/engines/nm' }
          ]
        },
        {
          title: 'pages/truckModel/engine6',
          colums: [
            { column1: 'pages/truckModel/engine5/power1', column2: '430  ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine6/power1', column2: '2050 ', column3:'pages/truckModel/engines/nm'}
          ]
        },
        {
          title: 'pages/truckModel/engine7',
          colums: [
            { column1: 'pages/truckModel/engine5/power1', column2: '460  ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine15/power3', column2: '2200 ', column3:'pages/truckModel/engines/nm'}
          ]
        },
        {
          title: 'pages/truckModel/engine8',
          colums: [
            { column1: 'pages/truckModel/engine7/power1', column2: '420  ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine6/power1', column2: '2100 ', column3:'pages/truckModel/engines/nm'}
          ]
        },
        {
          title: 'pages/truckModel/engine9',
          colums: [
            { column1: 'pages/truckModel/engine7/power1', column2: '460  ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine9/power1', column2: '2300 ', column3:'pages/truckModel/engines/nm'}
          ]
        },
        {
          title: 'pages/truckModel/engine10',
          colums: [
            { column1: 'pages/truckModel/engine7/power1', column2: '500  ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine10/power1', column2: '2500 ', column3:'pages/truckModel/engines/nm'}
          ]
        }
      ],
      text:'pages/truckModel/engine/text/fm',
      type:'pages/truckModel/volvoFM'
    },
    {
      name: 'pages/truckModel/lngengines',
      categories: [
        {
          title: 'pages/truckModel/lngengine1',
          colums: [
            { column1: 'pages/truckModel/engine7/power1', column2: '420 ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine6/power1', column2: '2100 ', column3:'pages/truckModel/engines/nm' }
          ]
        },
        {
          title: 'pages/truckModel/lngengine2',
          colums: [
            { column1: 'pages/truckModel/engine5/power1', column2: '460 ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine15/power3', column2: '2300 ', column3:'pages/truckModel/engines/nm' }
          ]
        },
      ],
      text:'pages/truckModel/engine/text/fm',
      type:'pages/truckModel/volvoFM'
    }
  ]
  constructor(
    public TruckModelService: TruckModelService,
    private titleService: Title,
    private metaTagService: Meta,
    private translateService: TranslateService,
    private router: Router,
    private breadCrumbService: BreadcrumbserviceService,
    public assetService: AssetService
  ) {
    
  }
  ngOnInit()
  {
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('truckModel/volvofm/metatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('truckModel/volvofm/metaDescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('truckModel/volvofm/altImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('truckModel/volvofm/metatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('truckModel/volvofm/metaDescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('truckModel/volvofm/altImage'.toLowerCase())
      });

      //this.breadCrumbService.updateBreadcrumb('Truck Models' , 'Volvo FM', HtmlRoutePages.truckModels);
    }
    onImageLoad(): void {
        this.isLoading = false
    }
}
