import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '../../../environments/environment';

@Pipe({ name: 'volvoCdn' })
export class VolvoCdnPipe implements PipeTransform {
  transform(str: string): any {
    if (str) {
      return environment.cdnUrl + str;
    } else {
      return str;
    }
  }
}
