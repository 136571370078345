import {AfterViewInit, Directive, ElementRef, OnDestroy} from '@angular/core';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {fromEvent, Subscription} from 'rxjs';

import {GlobalUtils} from '~/core/utils/global-utils/global-utils';


@AutoUnsubscribe()
@Directive({
    selector: '[volvoVisible]',
})
export class ScrollVisibleDirective implements AfterViewInit, OnDestroy {

    private s1: Subscription;

    constructor(
        public elementRef: ElementRef,
        private globalUtils: GlobalUtils
    ) {

    }

    ngAfterViewInit() {
        this.streamScrollEvents();
    }

    ngOnDestroy(): void {
    }

    streamScrollEvents() {
        if (this.globalUtils.isBrowser() && this.elementRef) {
            this.s1 = fromEvent(window, 'scroll').subscribe((e) => {
                if (this.isElementInViewport(this.elementRef.nativeElement)) {
                    this.s1.unsubscribe();
                    this.elementRef.nativeElement.classList.add('InViewport');
                }
            });
        }
    }

    private isElementInViewport(el: HTMLElement) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
}
