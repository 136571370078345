import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ScreenUtils } from '../../core/utils/screen-utils/screen-utils';
import {
  ActivatedRoute,
  Router,
  Event,
  NavigationEnd
} from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'volvo-vehicle-offer-page',
  templateUrl: './vehicle-offer-page.component.html',
  styleUrls: ['./vehicle-offer-page.component.scss']
})
export class VehicleOfferPageComponent implements OnInit, AfterViewInit {
  mainImage: string = '/assets/pages/vehicleOffers/MainTruck.webp';
  imageObject: [
    {
      image: '/assets/pages/vehicleOffers/MainTruck.webp';
    }
  ];
  isTablet: boolean = false;
  path: string = '';
  currentRoute = '';
  constructor(private route: ActivatedRoute, private router: Router,private titleService: Title,private translateService: TranslateService,private metaTagService: Meta) {

  }
  ngOnInit(): void {
    if (ScreenUtils.isTablet()) this.isTablet = true;
    //this.path = this.route.snapshot.queryParamMap.get('path');
    
    this.updateMetaTags('uk/vehicleoffers/');
  }
  ngAfterViewInit(): void {
      this.scrollToElement();
  }
    scrollToElement(): void {
    if (this.path) {
      const el = document.getElementById(this.path);
      if (el) {
          el.scrollIntoView({ behavior: 'auto', inline: 'start' });
      }
      switch (this.path) {
          case 'selected':
              this.updateMetaTags('uk/vehicleoffers/selected/');
              break;
          case 'approved':
              this.updateMetaTags('uk/vehicleoffers/approved/');
              break;
          case 'economy':
              this.updateMetaTags('uk/vehicleoffers/economy/');
              break;
          case 'warranty':
              this.updateMetaTags('uk/vehicleoffers/warranty/');
              break;
      }
    }
    else {
        this.updateMetaTags('uk/vehicleoffers/');
    }
  }


  updateMetaTags(path: string): void {
    const fullPath = `${path.toLowerCase()}metatitle`;
    const metatitle = this.translateService.instant(fullPath);
    const metadescription = this.translateService.instant(`${path.toLowerCase()}metadescription`);
    const altImage = this.translateService.instant(`${path.toLowerCase()}altimage`);
    const metakeywords = this.translateService.instant(`${path.toLowerCase()}metakeywords`);

    this.titleService.setTitle(metatitle);

    this.metaTagService.updateTag({ name: 'og:title', content: metatitle });
    this.metaTagService.updateTag({ name: 'og:description', content: metadescription });
    this.metaTagService.updateTag({ name: 'og:image', alt: 'alt', content: altImage });

    // Additional traditional meta tags for search engines
    this.metaTagService.updateTag({ name: 'description', content: metadescription });
    this.metaTagService.updateTag({ name: 'keywords', content: metakeywords });
  }
}
