export const environment = {
    development: false,
    qa: false,
    production: true,
    name: "prod",
    domain: 'localhost',
    apiUrl: 'https://selected-managed-prod.azurewebsites.net/api/proxy/volvoselected/api/',
    signalrUrl: '/volvoselected/api/',
    cdnUrl: 'https://volvoselectedprod.blob.core.windows.net/',
    tokenProxyUrl: 'https://tokenproxy-managed.azurewebsites.net/api/TokenProxyV2FunctionProd',
    recaptchaSitekey: '6Lerhu8ZAAAAAInwCsVI6H0YAwswscbJBbgEieAA',
  
    ukSiteUrl: 'volvousedtrucks.co.uk',
    txSiteUrl:'tradeandexport.volvotrucks.com',
    txGreenSiteUrl: 'tradeandexport.volvotrucks.com',
    selectedSiteUrl: 'volvoselected.com', 
    //selectedSiteUrl: 'selected-managed-prod.azurewebsites.net',

    ukwebsite: "https://volvousedtrucks.co.uk",
    txwebsite: "https://tradeandexport.volvotrucks.com",

};

