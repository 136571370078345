import { Truck, EmailFlagStateInterface } from '~/core/store/trucks/trucks.model';

export class SetActiveTruck {
    static readonly type = '[SetActiveTruck]: action';

    constructor(public payload: Truck) { }
}

export class GetTruckById {
    static readonly type = '[GetTruckById]: action';

    constructor(public payload: string) { }
}

export class GetLatestTrucks {
    static readonly type = '[GetLatestTrucks]: action';

    constructor() { }
}
  
export class GetNewestTrucks {
    static readonly type = '[GetNewestTrucks]: action';

    constructor() {}
}
  
export class GetOldestTrucks {
    static readonly type = '[GetOldestTrucks]: action';

    constructor() {}
}

export class SetEmailFlag {
    static readonly type = '[SetEmailFlag]: action';

    constructor(public payload: EmailFlagStateInterface) { }
}