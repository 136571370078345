import { Component,OnInit } from '@angular/core';
import { ScreenUtils } from 'src/app/core/utils/screen-utils/screen-utils';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-trade-export-page',
  templateUrl: './trade-export-page.component.html',
  styleUrls: ['./trade-export-page.component.scss']
})
export class TradeExportPageComponent implements OnInit{
  isTablet:boolean=false
  constructor(
    private titleService: Title,
    private translateService: TranslateService,
    private metaTagService: Meta,
    public assetService: AssetService){}
  ngOnInit(): void {
    if (ScreenUtils.isTablet()) this.isTablet = true;

    this.updateMetaTags('tx/');
  }
  getBackgroundStyle(): { [key: string]: string } {
    const style = `url(${this.assetService.getAssetUrl('assets/pages/aboutUs/AboutusimageTX.webp')}) center 44% / cover no-repeat`;
    return { 'background': style };
  }

  updateMetaTags(path: string): void {
    const fullPath = `${path.toLowerCase()}metatitle`;
    const metatitle = this.translateService.instant(fullPath);
    const metadescription = this.translateService.instant(`${path.toLowerCase()}metadescription`);
    const altImage = this.translateService.instant(`${path.toLowerCase()}altimage`);
    const metakeywords = this.translateService.instant(`${path.toLowerCase()}metakeywords`);
    this.titleService.setTitle(metatitle);
    this.metaTagService.updateTag({ name: 'og:title', content: metatitle });
    this.metaTagService.updateTag({ name: 'og:description', content: metadescription });
    this.metaTagService.updateTag({ name: 'og:image', alt: 'alt', content: altImage });
    // Additional traditional meta tags for search engines
    this.metaTagService.updateTag({ name: 'description', content: metadescription });
    this.metaTagService.updateTag({ name: 'keywords', content: metakeywords });
  }
}
