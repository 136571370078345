import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { Store } from '@ngxs/store';
import { combineLatest, Subscription } from 'rxjs';

import { MailApiService } from '~/core/api/mail-api/mail-api.service';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { SearchService } from '~/core/store/search/search.service';
import { SearchStateFilter } from '~/core/store/search/search.model';
import { AppState } from '~/core/store';
import { Map, GeocoderResult, GeocoderStatus, DealerPlaceResult } from '~/core/store/dealerships/dealerships.model';
import { DealershipsService } from '~/core/store/dealerships/dealerships.service';
import { MatSelectionList } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import { AssetService } from '~/core/services/assets/asset.service';

@AutoUnsubscribe()
@Component({
  selector: 'volvo-dealership-popup',
  templateUrl: './dealership-popup.component.html',
  styleUrls: ['./dealership-popup.component.scss'],
})
export class DealershipPopupComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('mapContainer', { static:false }) gmap: ElementRef;
  @ViewChild('selectedOption') selectedOption: MatSelectionList;

  map: Map;
  searchInput: string;
  checkbox: any;
  isBrowser: boolean;
  userLocation: { lat: number; lng: number };
  dealershipLocations: DealerPlaceResult[] = null;
  dealershipLocationsDump: DealerPlaceResult[] = null;
  search: SearchStateFilter;
  selectedCountry: string;
  loading : boolean = true;
  geolocationWasCancelByUser = false;
  public selectedFilterCountry: string = 'All markets';

  private s0: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DealershipPopupComponent>,
    private store: Store,
    private mailApiService: MailApiService,
    private globalUtils: GlobalUtils,
    public dealershipsService: DealershipsService,
    private searchService: SearchService,
    private ref: ChangeDetectorRef,
    public assetService: AssetService
  ) {
    this.isBrowser = this.globalUtils.isBrowser();
  }

  ngOnInit(): void {
    this.search = this.store.selectSnapshot<SearchStateFilter>((state: AppState) => state.search.filter);
  }

  ngOnDestroy(): void { }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this.mapInitializer();
      this.initSubscriptions();
      this.ref.detectChanges(); // update component
      this.filterByCountry(this.selectedFilterCountry);
    }
  }

  onClose(): void {
    this.dealershipLocations = this.dealershipLocationsDump.slice();
    const selectedDealerships = this.dealershipLocations.filter((item: DealerPlaceResult) => item.isSelected);
    if (Array.isArray(selectedDealerships) && selectedDealerships.length) {
      this.updateDealership(true, selectedDealerships);
    }
    else {//If none of the dealearship location is selected, then update isSelected attribute to false;
      this.updateDealership(false, selectedDealerships);
    }
    this.dialogRef.close();
  }

  goToCountry(country, zoom): void {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ address: country }, (results: GeocoderResult[], status: GeocoderStatus) => {
      if (status == google.maps.GeocoderStatus.OK) {
        let county = results[0].geometry.location;
        this.map.setCenter(results[0].geometry.location);
        this.map.setZoom(zoom);
      }
    });
  }

  filterByCountry(country: string,city:string=''): void {
    if (country === 'All markets') {
      if(this.dealershipLocationsDump)
        this.dealershipLocations = this.dealershipLocationsDump.slice();
      this.selectedCountry = 'All markets';
      this.goToCountry('europe', 4);
    } else {
      this.selectedCountry = country.toLowerCase();
      this.dealershipLocations = this.dealershipLocationsDump.filter((item) =>
        Boolean(item.stockLocation.country.toLowerCase().indexOf(this.selectedCountry) >= 0)
      );
      this.goToCountry(this.selectedCountry, 6);
    }
    //Below lines of code is used to show selected dealearship locations by user.
    var stockLocationAddressObj = this.search.steps.stockLocationAddress;
    let indexValue;
    if (stockLocationAddressObj.title === "Dealership Location" && stockLocationAddressObj.value) {
      var selectedAddress = stockLocationAddressObj.value.toString().split(';');
      if (selectedAddress.length > 0) {
        for (var selectedItem of selectedAddress) {
          indexValue = this.dealershipLocations.findIndex(x => x.stockLocation.city === selectedItem);
          if (indexValue >= 0 && !this.dealershipLocations[indexValue].isSelected)
            this.dealershipLocations[indexValue].isSelected = true;
        }
      }
    if(city)
    {
      let selectedDealer = this.dealershipLocations.find(x => x.stockLocation.city.toLowerCase() === city.toLowerCase());
      if (selectedDealer)
        selectedDealer.isSelected = true;
    }
    }
    else if(city)
    {
      let selectedDealer = this.dealershipLocations.find(x => x.stockLocation.city.toLowerCase() === city.toLowerCase());
      if (selectedDealer)
        selectedDealer.isSelected = true;
    }
  }

  onFilterByCountry(opened: boolean): void {
    if (!opened && this.selectedFilterCountry) {
      this.filterByCountry(this.selectedFilterCountry);
    }
  }

  mapInitializer() {

    this.getUserLocation();
    const goteborg = { lat: 57.7089, lng: 11.9746 };
    const mapConfig = {
      center: goteborg,
      zoom: 8,
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, mapConfig);
    google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
      this.loading = false;
      document.querySelector('.loading-overlay').remove();
    });
  }

  goToAddress(dealer): void {
    //dealer.isSelected = !dealer.isSelected;
    if (dealer.isSelected) {
      // move google map only when user click to expend
      this.map.setCenter(dealer.geometry.location);
      this.map.setZoom(10);
    }
  }

  onSearch(): void {
    if (this.searchInput.length === 0) {
      this.dealershipLocations = [...this.dealershipLocationsDump];
    } else if (this.searchInput && this.dealershipLocationsDump) {
      const filterValue = this.searchInput.toLowerCase();
      this.dealershipLocations = this.dealershipLocationsDump.filter((item) => {
        const dealershipItem = (item.name + item.formatted_address).toLowerCase();
        // search by name and address
        return dealershipItem.indexOf(filterValue) >= 0;
      });
    }
  }

  private getUserLocation() {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (userPosition) => this.onUserAllowGeolocation(userPosition),
    //     (error) => this.onUserCancelGeolocation(error)
    //   );
    // } else {
    //   console.warn('Geolocation is not supported by this browser.');
    // }
  }

  private onUserAllowGeolocation(userPosition): void {
    this.userLocation = {
      lat: userPosition.coords.latitude,
      lng: userPosition.coords.longitude,
    };

    new google.maps.Marker({
      map: this.map,
      position: this.userLocation,
      icon: 'assets/svg/userMarker.svg',
    });

    // update map center if we have user position
    this.map.setCenter(this.userLocation);
    this.map.setZoom(4);
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: this.userLocation }, (results: GeocoderResult[], status: GeocoderStatus) => {
      if (status === google.maps.GeocoderStatus.OK && results[0]) {
        const country = this.getCountry(results);

        if (country) {
          // sort dealerships by user country
          this.sortDealershipByUserCountry(country);
        }
      }
    });
  }

  private onUserCancelGeolocation(error): void {
    if (error.PERMISSION_DENIED) {
      console.warn('Geolocation was canceled by user');
      this.geolocationWasCancelByUser = true;
    }
  }

  private getCountry(results: GeocoderResult[]): string {
    for (const item of results) {
      if (item.types.indexOf('country') !== -1) {
        return item.formatted_address;
      }
    }
  }

  private sortDealershipByUserCountry(country: string): void {

  }

  private setMapMarkers(dealershipLocations: DealerPlaceResult[]): void {
    dealershipLocations.forEach((dealership) => {
      dealership.marker = new google.maps.Marker({
        map: this.map,
        position: dealership.geometry.location,
        icon: 'assets/svg/marker.svg',
      });

      google.maps.event.addListener(dealership.marker, 'click', () => {
        dealership.isExpanded = true;
        this.searchInput = dealership.formatted_address;
        this.selectedFilterCountry = dealership.stockLocation.country;
        this.filterByCountry(this.selectedFilterCountry,dealership.stockLocation.city);
      });

      if (this.geolocationWasCancelByUser) {
        this.map.setCenter(dealership.geometry.location);
        this.geolocationWasCancelByUser = false;
      }
    });
  }

  private initSubscriptions(): void {
    this.s0 = combineLatest(this.searchService.filter$, this.dealershipsService.dealerships$).subscribe(([filter, dealershipLocations]) => {

      if (filter && dealershipLocations) {
        if (filter.steps.stockLocationCountry.isSelected) {
          var stringLocation = filter.steps.stockLocationCountry.value.toString().split(';');
          if (stringLocation.length === 1) {
            if (stringLocation[0])
              this.selectedFilterCountry = stringLocation[0];
            else
              this.selectedFilterCountry = 'All markets';
          }
        }
        // DO NOT REMOVE These comments are in preparation for only displaying the selected countries in the filter
        // The outstanding question is how to show all countries when no country is selected in a proper way
        // var countries = filter.steps.stockLocationCountry.value.toString().split(';');
        // if (countries.length > 0 && filter.steps.stockLocationCountry.value !== "") {
        //   this.dealershipLocations = cloneDeep(dealershipLocations.filter(d => countries.includes(d.stockLocation.country)));
        //   this.dealershipLocationsDump = cloneDeep(this.dealershipLocations);
        // } else {
        this.dealershipLocations = cloneDeep(dealershipLocations);
        this.dealershipLocationsDump = cloneDeep(dealershipLocations);
        // }
        this.setMapMarkers(dealershipLocations);
      }
    });
  }

  updateDealership(selectedFlag: boolean, selectedDealershipValues: DealerPlaceResult[]) {
    //const dealershipsName = selectedDealershipValues.map((item) => item.stockLocation.address).join(';');
    const cities = selectedDealershipValues.map((item) => item.stockLocation.city).join(';');
    this.searchService.updateStep({
      key: 'stockLocationAddress',
      value: {
        ...this.search.steps.stockLocationAddress,
        isSelected: selectedFlag,
        isVisible: selectedFlag,
        value: cities,
      },
      expandingFilter: '',
      reloadFlag: false
    });
    this.dealershipsService.setDealerships(this.dealershipLocations);
  }
}

