import { Pipe, PipeTransform } from '@angular/core';
import { SearchStepSliderValue } from '../store/search/search.model';

@Pipe({
  name: 'asSlider'
})
export class AsSliderPipe implements PipeTransform {

  public transform(value: any): SearchStepSliderValue {
      return (value as SearchStepSliderValue);
  }
}

@Pipe({
  name: 'asString'
})
export class AsStringPipe implements PipeTransform {

  public transform(value: any): string {
      return (value as string);
  }
}
