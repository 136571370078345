<div *ngIf="truck" class="Dealership">
  <div class="Dealership-left">
    <div class="Dealership-title">{{ truck.stockLocation.stockLocationName }}</div>
    <div class="Dealership-info">
      <div class="Dealership-subtitle">
        {{ truck.stockLocation.name }}
      </div>
      <div class="Dealership-text">{{ truck.stockLocation.address }}</div>
      <div class="Dealership-text">{{ truck.stockLocation.postalCode }} {{ truck.stockLocation.city }}</div>
      <div class="Dealership-text">
        {{ truck.stockLocation.countryCodeISO2 ? (truck?.stockLocation.countryCodeISO2 | lookupcountry) :
        truck.stockLocation.country }}
      </div>
    </div>
  </div>
  <div class="Dealership-right">
    <div #mapContainer id="map"></div>
  </div>
</div>