import {Truck} from '~/core/store/trucks/trucks.model';

export class AddTruck {
    static readonly type = '[AddTruck]: action';

    constructor(public payload: Truck) {}
}



