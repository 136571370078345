import { Component, OnInit } from '@angular/core';
import { SalesTeam } from '../contact-page.model';

@Component({
  selector: 'volvo-centre-edinburgh',
  templateUrl: './centre-edinburgh.component.html',
  styleUrls: ['../centre-banbury/centre-banbury.component.scss']
})
export class CentreEdinburghComponent implements OnInit {
  mapCentre:string = 'Volvo Truck and Bus Centre Edinburgh';
  public isLoading = true
  constructor() { }

  onImageLoad() {        
    this.isLoading = false;
  }
  onError(): void {
      console.error('Image failed to load.');
      this.isLoading = false; // You may want to handle this differently
  }
  ngOnInit(): void {

  }


  salesTeamMangementDetails: SalesTeam[] = [
    {
      imageUrl: '/assets/pages/contactus/ns/Ian.webp',
        imageName: 'Ian Laverie',
        imageDesignation: 'uk/pages/contact/salesExecutive7',
        imageCentre: '',
        imageEmail: 'ian.laverie@volvo.com',
        imageMobile: '07845 124126',
    },
 ]
}
