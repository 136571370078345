import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SiteCheckService {
    constructor(private store: Store) { }

    getCurrentSiteName(): Observable<string | null> {
        return this.store.select(state => state.language.region.curRegion.id).pipe(
            switchMap(region =>
                this.store.select(state => state.regionalSettings.regionsSettings.find(r => r.region === region))
            ),
            map(settings => settings?.site || null)
        );
    }
}

