import { Component,OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-vehicle-offer-remaining-warranty-page',
  templateUrl: './vehicle-offer-remaining-warranty-page.component.html',
  styleUrls: ['./vehicle-offer-remaining-warranty-page.component.scss']
})
export class VehicleOfferRemainingWarrantyPageComponent implements OnInit{
  HtmlRoutePages: HtmlRoutePages;
  isMobileOrTablet: boolean = false;
  constructor(private router: Router,private titleService: Title,
    private translateService: TranslateService,
    private metaTagService: Meta,
    private breadCrumbService: BreadcrumbserviceService,
    private globalUtils: GlobalUtils,
    private deviceService: DeviceDetectorService,
    public assetService: AssetService) {
      if (this.globalUtils.isBrowser() && (this.deviceService.isMobile() || this.deviceService.isTablet())) {
        this.isMobileOrTablet = true;
      }
    }
  ngOnInit(): void {
    
    this.titleService.setTitle(this.translateService.instant('uk/vehicleoffers/warranty/metatitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/vehicleoffers/warranty/metatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/vehicleoffers/warranty/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/vehicleoffers/warranty/altImage'.toLowerCase())
    });

    //if(this.isMobileOrTablet)
      //this.breadCrumbService.updateBreadcrumb('Vehicle Offers' , 'Remaining Warranty', HtmlRoutePages.vehicleOffers);
  }
 }
