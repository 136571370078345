<header class="Popup-header CPrimary volvofontmedium">
  <i class="fas fa-filter font"></i>&nbsp;&nbsp;
  <b>{{ 'popups/filter/title' | lowercase | translate }}</b>

  <!-- <div *ngIf="selectedFiltersCount > 0" class="Filter-headerDel"></div> -->
  <i (click)="onClose()" class="PopupOLD-close material-icons paddingTop">close</i>

</header>

<div class="Popup-WithFooter">
  <div class="sort">
    <mat-accordion class="VolvoAccordion">
      <ng-container>
        <mat-expansion-panel [expanded]="true" (opened)="true">
          <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
            <mat-panel-title>
              <div class="sort-title">
                <span>{{ 'popups/sort/title' | lowercase | translate }}</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div style="display: table-caption;">
            <mat-radio-button value="SortOptions.oldestAge" (click)="onSortChange(SortOptions.oldestAge)"
              [checked]="SortOptions.oldestAge === search.sort" style="padding-top:0">
              {{ 'globalMiss/newInStock' | lowercase | translate | capitalize }}</mat-radio-button>
            <mat-radio-button value="SortOptions.highestPrice" (click)="onSortChange(SortOptions.highestPrice)"
              [checked]="SortOptions.highestPrice === search.sort">{{ 'globalMiss/price' | lowercase | translate }} {{
              'globalMiss/highToLow' | lowercase | translate }}</mat-radio-button>
            <mat-radio-button value="SortOptions.lowestPrice" (click)="onSortChange(SortOptions.lowestPrice)"
              [checked]="SortOptions.lowestPrice === search.sort">{{ 'globalMiss/price' | lowercase | translate }} {{
              'globalMiss/lowToHigh' | lowercase | translate }} </mat-radio-button>
          </div>
        </mat-expansion-panel>
      </ng-container>

    </mat-accordion>
    <!-- <div style="display:flex;justify-content: space-between;" (click)="toggleSort()">
      <div class="sort-title">
        <span>{{ 'popups/sort/title' | lowercase | translate }}</span>
      </div>
      <div  [ngClass]="this.isSortOpened?'sort-sortImageY':'sort-sortImageX'">
        <img [src]="assetService.getAssetUrl('assets/icons/chevronDown.png')">
      </div>
    </div>
    <div style="display: table-caption;" *ngIf="this.isSortOpened">

      <mat-radio-button value="SortOptions.oldestAge" (click)="onSortChange(SortOptions.oldestAge)" [checked]="SortOptions.oldestAge === search.sort">
        {{ 'globalMiss/newInStock' | lowercase | translate }}</mat-radio-button>
      <mat-radio-button value="SortOptions.highestPrice" (click)="onSortChange(SortOptions.highestPrice)" [checked]="SortOptions.highestPrice === search.sort">{{ 'globalMiss/price' | lowercase | translate }} {{ 'globalMiss/highToLow' | lowercase | translate }}</mat-radio-button>
      <mat-radio-button value="SortOptions.lowestPrice" (click)="onSortChange(SortOptions.lowestPrice)" [checked]="SortOptions.lowestPrice === search.sort">{{ 'globalMiss/price' | lowercase | translate }} {{ 'globalMiss/lowToHigh' | lowercase | translate }} </mat-radio-button>
    </div> -->
    <mat-divider></mat-divider>
  </div>
  <volvo-filter></volvo-filter>
</div>

<div class="Popup-footer Filter-footer" *ngIf="selectedFiltersCount > 0">
  <div class="buttonAlign">
    <button *ngIf="selectedFiltersCount > 0" (click)="onClose()" mat-button class="Popup-results volvofont">
      {{ 'global/view' | lowercase | translate }} {{ totalTrucks }}
    </button>
  </div>
  <div>
    <button *ngIf="selectedFiltersCount > 0" (click)="resetAllFilters()" class="Filter-btn volvofont" mat-button>
      {{ 'popups/filter/clearFilters' | lowercase | translate }} ({{ selectedFiltersCount }})
    </button>
  </div>
</div>