import { Component, OnInit} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { LocalesService } from '~/core/store/locales/locales.service';
import { HtmlRoutePages } from '../../../app-routing.model';

@Component({
  selector: 'volvo-service-contract-page',
  templateUrl: './service-contract-page.component.html',
  styleUrls: ['./service-contract-page.component.scss']
})
export class ServiceContractPageComponent implements OnInit{
  HtmlRoutePages: HtmlRoutePages;
  constructor(private translateService: TranslateService,private metaTagService: Meta,
    private breadCrumbService: BreadcrumbserviceService,
    public localesService: LocalesService,) {}
  ngOnInit(): void {
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/services/servicecontract/metatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/services/servicecontract/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/services/servicecontract/altImage'.toLowerCase())
    });

    //this.breadCrumbService.updateBreadcrumb('Services' , 'Service contract', HtmlRoutePages.services);
  }
  contractList = [
    {
      img: 'assets/pages/services/serviceContract1.webp',
      type: 'uk/pages/services/serviceContracts/title1',
      name: 'uk/pages/services/serviceContracts/title1/text1',
    },
    {
      img: 'assets/pages/services/serviceContract2.webp',
      type: 'uk/pages/services/serviceContracts/title2',
      name: 'uk/pages/services/serviceContracts/title2/text1',
    }
  ];
 }
