import { Component} from '@angular/core';
import { HtmlRoutePages } from '../../app-routing.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { CounterService } from '~/core/services/counter/counter-service';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-buyers-guide-page',
  templateUrl: './buyers-guide-page.component.html',
  styleUrls: ['./buyers-guide-page.component.scss']
})
export class BuyersGuidePageComponent{
  HtmlRoutePages:HtmlRoutePages;
  link:string="";
  constructor(private route: ActivatedRoute, private router: Router,private metaTagService: Meta,
    private titleService: Title,
    private translateService: TranslateService,
    private localizeRouterService: LocalizeRouterService,
    private counterService: CounterService,
    public assetService: AssetService) {}
  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('uk/buyersguide/metatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:title',
        content: this.translateService.instant('uk/buyersguide/metadescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('uk/buyersguide/metadescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('uk/buyersguide/altImage'.toLowerCase())
      });
      // Additional traditional meta tags for search engines
      this.metaTagService.updateTag({
        name: 'description',
        content: this.translateService.instant('uk/buyersguide/metadescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'keywords',
        content: this.translateService.instant('uk/buyersguide/metakeywords'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('uk/buyersguide/metatitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/buyersguide/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/buyersguide/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/buyersguide/altImage'.toLowerCase())
    });
    // Additional traditional meta tags for search engines
    this.metaTagService.updateTag({
      name: 'description',
      content: this.translateService.instant('uk/buyersguide/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.translateService.instant('uk/buyersguide/metakeywords'.toLowerCase())
    });
  }
  
  routePage(type:string): void {
    
    switch(type)
    {
      case 'volvoFH16':this.link=HtmlRoutePages.bgvolvoFH16;break;
      case 'volvoFH':this.link=HtmlRoutePages.bgvolvoFH;break;
      case 'volvoFM':this.link=HtmlRoutePages.bgvolvoFM;break;
      case 'volvoFMX':this.link=HtmlRoutePages.bgvolvoFMX;break;
      case 'volvoFE':this.link=HtmlRoutePages.bgvolvoFE;break;
      case 'volvoFL':this.link=HtmlRoutePages.bgvolvoFL;break;
    }
    this.router.navigate([this.localizeRouterService.translateRoute(this.link)], { relativeTo: this.route });
  }
  

  logTruckModelButtonClick(model:string) {
    this.counterService.postTruckModelClick(model);
  }
 }

 