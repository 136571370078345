export class UnsubscribeResponse
{
  NotificationId:string;
  Response1 : string="";
  Response2 : string="";
  Response3 : string="";
  Response4 : string="";
  Response5 : string="";
  Response6 : string="";
  OtherDescription:string="";
}