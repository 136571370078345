import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { LocalesStateLang } from '~/core/store/locales/locales.model';
import { environment } from '../../../../environments/environment';
import { AppState } from '~/core/store';
import { SearchQuery } from '../../models/search-query.model';
@Injectable({
  providedIn: 'root',
})
export class SearchApiService {
  private searchPath = 'Truck?langCode=:langId';
  private aggregationsPath = 'Truck/aggregations?langCode=:langId';

  constructor(private http: HttpClient, private store: Store) { }

  getSearch(searchQuery: SearchQuery = undefined) {
    if (!searchQuery) {
      var searchQuery = new SearchQuery();
    }
    return this.http.post(this.langUrl(this.searchPath), searchQuery);
  }

  getAggregations(searchQuery: SearchQuery = undefined) {
    if (!searchQuery) {
      var searchQuery = new SearchQuery();
    }
    return this.http.post(this.langUrl(this.aggregationsPath), searchQuery);
  }

  private langUrl(path: string): string {
    const lang = this.store.selectSnapshot<LocalesStateLang>((state: AppState) => state.language.lang);

    const pathWithLang = path.replace(':langId', lang.curLang.id);
    return `${environment.apiUrl}${pathWithLang}`;
  }
}
