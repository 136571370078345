import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler, HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import isObject from 'lodash/isObject';
import { Router } from '@angular/router';
import { of, throwError } from 'rxjs';

import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import {
    AfterSubmitPopupComponent,
    AfterSubmitPopupComponentData,
    AfterSubmitPopupComponentType
} from '~/popups/after-submit-popup/after-submit-popup.component';
import { HtmlRoutePages } from '~/app-routing.model';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TokenService } from '../services/auth/token.service';

class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    private showSuccessOnlyFor = [
        'truckMailContact/',
        'ukTruckMailContact/',
        'truckpage-make-offer/',
        'financeContact/',
        'searchpage-moretrucks-contact/',
        'genericContact/',
        'vfsMailContact/'
    ];

    constructor(
        public dialog: MatDialog,
        public router: Router,
        public globalUtils: GlobalUtils,
        private localizeRouterService: LocalizeRouterService,
        private tokenService: TokenService,
    ) { }

    ensureCorrelationId() {
        const correlationId = localStorage.getItem('jaeger-debug-id');

        if (correlationId === null) {
            // localStorage.setItem('jaeger-debug-id', Guid.newGuid())
            // const date = new Date().getUTCDate();
            // localStorage.setItem('jaeger-debug-id-time', date.toString());
        }

        return localStorage.getItem('jaeger-debug-id');
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.get('No-Auth') === 'True') {
            return next.handle(request.clone());
        }

        let updatedRequest: HttpRequest<any>;
        let headers = {
            requestingsite: 'selected',
            authorization: 'Bearer ' + this.tokenService.getToken(),
            'Cache-Control': 'no-store, no-cache'
        }
        if (this.ensureCorrelationId()) {
            headers['jaeger-debug-id'] = this.ensureCorrelationId();
        }

        updatedRequest = request.clone({

            headers: new HttpHeaders(headers)
        });

        return next.handle(updatedRequest)
            .pipe(
                tap(evt => this.handleSuccessHttpResponse(evt)),
                catchError(err => this.handleErrorHttpResponse(err))
            );
    }

    private handleSuccessHttpResponse(evt): void {
        if (evt instanceof HttpResponse) {
            this.handleEmailResponse(evt);
        }
    }

    private handleErrorHttpResponse(err: HttpErrorResponse): Observable<any> {
        if (err.statusText === 'Not Found' && err.url.indexOf('/api/truck/') >= 0) {
            // handle wrong truck path and
            // redirect user to not found page without throwing an error
            // this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.pageNotFound)]);

            console.error(err);
            return of(err);
        }

        return throwError(err);
    }

    private handleEmailResponse(evt: HttpResponse<any>): void {
        const allowSuccessPopup = this.showSuccessOnlyFor.some(item => Boolean(evt.url.indexOf(item) >= 0));
        const successField = 'success';

        if (allowSuccessPopup && this.globalUtils.isBrowser() && evt.body && isObject(evt.body) && successField in evt.body) {
            this.dialog.closeAll();

            if (evt.body.success) {
                const data: AfterSubmitPopupComponentData = {
                    title: 'Success',
                    subtitle: 'We\'ll contact you soon!',
                    duration: 5000,
                    type: AfterSubmitPopupComponentType.success
                };

                this.dialog.open(AfterSubmitPopupComponent, {
                    panelClass: 'volvo-popup-notification',
                    data
                });
            } else {
                const data: AfterSubmitPopupComponentData = {
                    title: 'Something went wrong',
                    subtitle: 'We are already working on it!',
                    duration: 5000,
                    type: AfterSubmitPopupComponentType.error
                };

                this.dialog.open(AfterSubmitPopupComponent, {
                    panelClass: 'volvo-popup-notification',
                    data
                });
            }
        }
    }
}
