import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { regionEnum } from '../../../core/declaration/declaration';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import { HtmlRoutePages } from '../../../app-routing.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { Subscription } from 'rxjs';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
@Component({
    selector: 'volvo-finance-page',
    templateUrl: './finance-page.component.html',
    styleUrls: ['./finance-page.component.scss']
})
export class FinancePageComponent implements OnInit {
    HtmlRoutePages: HtmlRoutePages;
    region: string;
    regionEnum = regionEnum;
    financeList = [];
    private s0: Subscription;
    constructor(private translateService: TranslateService, private metaTagService: Meta, 
        private regionalSettingsService: RegionalSettingsService, 
        private breadCrumbService: BreadcrumbserviceService,
        public localesService: LocalesService,) { }
    ngOnInit(): void {
        this.s0 = this.localesService.region$.subscribe(results => {
            this.region = results.curRegion.id;
        })
        this.metaTagService.updateTag({
            name: 'og:title',
            content: this.translateService.instant('uk/services/financing/metatitle'.toLowerCase())
        });
        this.metaTagService.updateTag({
            name: 'og:description',
            content: this.translateService.instant('uk/services/financing/metadescription'.toLowerCase())
        });
        this.metaTagService.updateTag({
            name: 'og:image',
            alt: 'alt',
            content: this.translateService.instant('uk/services/financing/altimage'.toLowerCase())
        });
        // Additional traditional meta tags for search engines
        this.metaTagService.updateTag({
            name: 'description',
            content: this.translateService.instant('uk/services/financing/metadescription'.toLowerCase())
        });
        this.metaTagService.updateTag({
            name: 'keywords',
            content: this.translateService.instant('uk/services/financing/metakeywords'.toLowerCase())
        });

        this.updateBreadcrumb();

        this.financeList = [
            {
                img: (this.region !== this.regionEnum.TX) ? 'assets/pages/services/Icon Shape.png' : 'assets/pages/services/tx1.webp',
                type: 'uk/pages/services/Financing/title1',
                name: 'uk/pages/services/Financing/title1/text1',
            },
            {
                img: (this.region !== this.regionEnum.TX) ? 'assets/pages/services/Group 104.png' : 'assets/pages/services/tx2.webp',
                type: 'uk/pages/services/Financing/title2',
                name: 'uk/pages/services/Financing/title2/text1',
            },
            {
                img: (this.region !== this.regionEnum.TX) ? 'assets/pages/services/Group 103.png' : 'assets/pages/services/tx3.webp',
                type: 'uk/pages/services/Financing/title3',
                name: 'uk/pages/services/Financing/title3/text1',
            },
        ];
    }

    financeList2 = [
        {
            type: 'uk/pages/services/Financing/title4',
            name: 'uk/pages/services/Financing/title4/text1',
        },
        {
            type: 'uk/pages/services/Financing/title5',
            name: 'uk/pages/services/Financing/title5/text1',
        },
        {
            type: 'uk/pages/services/Financing/title6',
            name: 'uk/pages/services/Financing/title6/text1',
        },
    ];
    updateBreadcrumb(): void {
        if(this.region === this.regionEnum.TX){
            //this.breadCrumbService.updateBreadcrumb('Services' , 'Used Truck Customisation', HtmlRoutePages.services);
        }
        else{
            //this.breadCrumbService.updateBreadcrumb('Services' , 'Financing', HtmlRoutePages.services);
        }
    }
}
