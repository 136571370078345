import { BucketAggregations } from '~/core/store/buckets/buckets.model';
import { Truck } from '~/core/store/trucks/trucks.model';

export interface SearchResponse {
  aggregations: BucketAggregations;
  hits: Truck[];
  otherHits: Truck[];
  total: number;
  grandTotal: number;
}

export interface OnSearchResponse {
  total: number;
  trucks: Truck[];
}

export interface SearchStepList {
  name: string;
  id: string;
  icon?: string;
  isCity?: boolean;
}

export enum TruckCardTypes {
  card,
  list,
}

export interface SearchStateFilter {
  q?: string;
  steps?: SearchSteps;
  page?: number;
  sort?: SortOptions;
  requestingFilter: string;
  expandingFilter: string;
  defaultCategory: string;
}

export interface SearchSteps {
  stockLocationAddress: SearchStep;
  stockLocationCountry: SearchStep;
  superstructureType: SearchStep;
  majorCategory: SearchStep;
  make: SearchStep;
  model: SearchStep;
  priceExclVatEuro: SearchStep;
  horsePower: SearchStep;
  mileageKm: SearchStep;
  dateFirstRegistration: SearchStep;
  axleConfiguration: SearchStep;
  emission: SearchStep;
  fuelType: SearchStep;
  stockLocationCity: SearchStep;
  cabType: SearchStep;
  offerAndWarranty: SearchStep;
  advertisementCategory: SearchStep;
  warranty: SearchStep;
  chassisHeight: SearchStep;
}

export interface SearchStep {
  id: string;
  title: string;
  inputPlaceholder: string;
  isSelected: boolean;
  isVisible: boolean;
  isExpanded: boolean;
  initValue?: string | SearchStepSliderValue;
  value: string | SearchStepSliderValue;
  viewMoreLimit?: number;
  initViewMoreLimit?: number;
  options?: SearchStepSliderOptions;
  list?: SearchStepList[];
}

export interface SearchStepSliderValue {
  min: number;
  max: number;
}

export interface SearchStepSliderOptions {
  units: string;
  step: number;
}


export enum SearchStepKeys {
  stockLocationCountry = 'stockLocationCountry',
  majorCategory = 'majorCategory',
  superstructureType = 'superstructureType',
  make = 'make',
  model = 'model',
  priceExclVatEuro = 'priceExclVatEuro',
  horsePower = 'horsePower',
  mileageKm = 'mileageKm',
  dateFirstRegistration = 'dateFirstRegistration',
  axleConfiguration = 'axleConfiguration',
  emission = 'emission',
  fuelType = 'fuelType',
  stockLocationAddress = 'stockLocationAddress',
  stockLocationCity = 'stockLocationCity',
  cabType = 'cabType',
  offerAndWarranty = 'offerAndWarranty',
  location = 'stockLocationAddress',
  advertisementCategory = 'advertisementCategory',
  warranty = 'warranty',
  requestingFilter = 'requestingFilter',
  expandingFilter = 'expandingFilter',
  chassisHeight = 'chassisHeight',
}

export enum SortOptions {
  newestAge = 'newestAge',
  highestPrice = 'highestPrice',
  lowestPrice = 'lowestPrice',
  oldestAge = 'oldestAge',
}

export interface UpdateStepOptions {
  key: string;
  value: SearchStep;
  expandingFilter: string;
  reloadFlag: boolean;
}

export interface SearchStateParams {
  qf: string | null;
}
export interface SearchStateInterface {
  params: SearchStateParams;
  filter: SearchStateFilter;
  aggregations: BucketAggregations;
  totalTrucks: number;
  trucksList: Truck[];
  loaded: boolean;
}
export interface SearchStateInterface {
  params: SearchStateParams;
  filter: SearchStateFilter;
  aggregations: BucketAggregations;
  totalTrucks: number;
  totalOtherTrucks: number;
  grandTotalTrucks: number;
  trucksList: Truck[];
  trucksListOthers: Truck[];
  loaded: boolean;
}
