import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification/notification-service';
import { UnsubscribeResponse } from '../../core/models/unsubscribe-feeback-query.model'
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
@Component({
    selector: 'volvo-unsubscribe-page',
    templateUrl: './unsubscribe-page.component.html',
    styleUrls: ['./unsubscribe-page.component.scss'],
})

export class UnsubscribePageComponent implements OnInit {
    notificationId: string;
    emailId: string = '';
    hasUnSubscribed: boolean = false;
    unsubsribeResponseList: UnsubscribeResponse;
    otherDesc: string
    hasFeedback = false
    showOtherDescTextbox: boolean = false;
    responseList = [
        { text: 'search/notification/unsubscribe/response/text2', selected: false, value: 'The filters are no longer relevant to me' },
        { text: 'search/notification/unsubscribe/response/text3', selected: false, value: 'I received too many emails' },
        { text: 'search/notification/unsubscribe/response/text4', selected: false, value: 'I have found a vehicle on this used trucks website' },
        { text: 'search/notification/unsubscribe/response/text5', selected: false, value: 'I have found a vehicle somewhere else' },
        { text: 'search/notification/unsubscribe/response/text6', selected: false, value: 'I no longer use this email address' },
        { text: 'search/notification/unsubscribe/response/text7', selected: false, value: 'Other' }];
    constructor(private route: ActivatedRoute, private notificationService: NotificationService, private router: Router, private titleService: Title, private metaTagService: Meta, private translateService: TranslateService ) { }
    ngOnInit() {
        this.titleService.setTitle(this.translateService.instant('unsubscribe/metatitle'.toLowerCase()));
        this.metaTagService.updateTag({
            name: 'og:title',
            content: this.translateService.instant('unsubscribe/metatitle'.toLowerCase())
          });
          this.metaTagService.updateTag({
            name: 'og:description',
            content: this.translateService.instant('unsubscribe/metaDescription'.toLowerCase())
          });
          this.metaTagService.updateTag({
            name: 'og:image',
            alt: 'alt',
            content: this.translateService.instant('unsubscribe/altImage'.toLowerCase())
          });
        this.route.queryParams
            .subscribe(params => {
                this.notificationId = params.id;
                this.emailId = params.emailId;
            }
            );

            //Saving in session to persist the value of "hasUnSubscribed" on refresh or on changing the language.
            // Check if there's a saved value in session storage
            const savedState = sessionStorage.getItem('hasUnsubscribed');
            this.hasUnSubscribed = savedState === 'true'; // Convert to boolean

            const savedFeedbackState = sessionStorage.getItem('hasFeedback');
            this.hasFeedback = savedFeedbackState === 'true'; // Convert to boolean
    }
    unsubscribeNotificationData() {
        if(this.notificationId)
        {
            this.notificationService.unsubscribeNotificationData(this.notificationId).subscribe(response => {
                if (response) {
                    this.hasUnSubscribed = true;
                    sessionStorage.setItem('hasUnsubscribed', 'true'); // Save the state in session storage
                }
            });
        }
    }
    checkSelected(response, last) {
        response.selected = !response.selected;
        if (last && response.selected) {
            this.showOtherDescTextbox = true
        }
        else if (last && !response.selected) {
            this.showOtherDescTextbox = false;
            this.otherDesc = "";
        }
    }
    submitUserFeedbackData() {
        if(this.notificationId)
        {
            if (this.responseList.filter(x => x.selected === true).length > 0) {
                this.getFeedBackData();
                this.notificationService.submitUnsubscribeFeeback(this.unsubsribeResponseList).subscribe(response => {
                    if (response.ok) {
                        this.hasFeedback = true;
                        sessionStorage.setItem('hasFeedback', 'true'); // Save the state in session storage
                    }
                });
            }
        }
    }
    getFeedBackData() {
        this.unsubsribeResponseList = new UnsubscribeResponse();
        this.unsubsribeResponseList.NotificationId = this.notificationId;
        this.responseList.forEach((item, index) => {
            if (item.selected) {
                switch (index) {
                    case 0: this.unsubsribeResponseList.Response1 = item.value; break;
                    case 1: this.unsubsribeResponseList.Response2 = item.value; break;
                    case 2: this.unsubsribeResponseList.Response3 = item.value; break;
                    case 3: this.unsubsribeResponseList.Response4 = item.value; break;
                    case 4: this.unsubsribeResponseList.Response5 = item.value; break;
                    case 5: this.unsubsribeResponseList.Response6 = item.value; this.unsubsribeResponseList.OtherDescription = this.otherDesc; break;
                }
            }
        })
    }
}