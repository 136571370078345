import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import {PrintService} from '../../../core/services/print/print.service';
import { CurrencyCode,Truck } from '../../../core/store/trucks/trucks.model';
import { TranslateService } from '@ngx-translate/core';
import { SearchState } from '../../../core/store/search/search.state';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { HtmlRoutePages } from 'src/app/app-routing.model';
import { DealershipsService } from '~/core/store/dealerships/dealerships.service';
import { DealerPlaceResult } from '~/core/store/dealerships/dealerships.model';
import { AssetService } from '~/core/services/assets/asset.service';
import html2canvas from 'html2canvas';
interface Place {
  hasOpenHours: boolean;
  isOpen: boolean;
  weekdayText: string[];
}
@Component({
  selector: 'volvo-print-truck-page',
  templateUrl: './print-truck-page.component.html',
  styleUrls: ['./print-truck-page.component.scss']
})
export class PrintTruckPageComponent implements OnInit {
  truck: Truck;
  isTrailer: boolean;
  vfsObj: number[];
  monthVal: number;
  depositVal: number;
  totalFinancePayment: number;
  showVfs: boolean = false;
  langId:any;
  hasEmptyTyreTread:boolean;
  hasEmptyDimensions:boolean;
  CurrencyCode = CurrencyCode;
  regionSettings:any; 
  country: string;
    defaultDisclaimer: boolean = false;
    @ViewChild('content', { static: true }) content: ElementRef;
  constructor(private printService: PrintService,private translateService: TranslateService,
    private searchState: SearchState,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private dealershipsService: DealershipsService,
    public assetService: AssetService) { }
  info:any;
  salespersons=[];
  advertisementTitle:string='';
  breadCrumbName:string
  isSelectedSite: boolean = false;
  isOtherCategory: boolean = false;
  @ViewChild('mapContainer') gmap: ElementRef;
  map: any;
  place: Place = {
    hasOpenHours: false,
    isOpen: false,
    weekdayText: [],
    };
    mapImage: string;
  ngOnInit() {
      this.truck = this.printService.getTruckDetails();
      this.langId=this.printService.getLanguage();
      this.country = this.printService.getCountry();
      this.hasEmptyTyreTread=this.printService.getTyreDimensions();
      this.hasEmptyDimensions=this.printService.getEmptyTyre();
      this.vfsObj = this.printService.getVfsDetails();
      
      this.regionSettings = this.searchState.getCurrentRegion();
      
      if(this.regionSettings === 'EU' || this.regionSettings === 'ZA')
        this.isSelectedSite = true;
      this.breadCrumbName = this.printService.getBreadCrumb();
      if(this.truck.specification.advertisementTitle)
      {
        this.advertisementTitle=this.truck.specification.advertisementTitle;
      }
      if(!this.country) this.defaultDisclaimer = true;
    if (this.vfsObj) {
      this.monthVal = this.vfsObj[0];
      this.depositVal = this.vfsObj[1];
      this.totalFinancePayment = this.vfsObj[2];

      if(this.totalFinancePayment !== this.vfsObj[3])
        this.showVfs = true;
    } 
      const CategorySemiTrailer = 'semi-trailer';
      const CategoryTrailer = 'trailer';
      const CategoryValue = this.truck.specification.category;
      this.isTrailer =
      CategoryValue.toLowerCase() === CategorySemiTrailer.toLowerCase() ||
      CategoryValue.toLowerCase() === CategoryTrailer.toLowerCase();
      if(this.truck.salespersons)
      {
        this.truck.salespersons=  this.truck.salespersons.filter(o =>o.isMainSalesperson===1);
        const phoneNumber =this.truck.salespersons[0].salespersonName && this.truck.salespersons[0].telephoneNumber ? this.truck.salespersons[0].telephoneNumber : this.truck.stockLocation.phoneNumber;
        let salesperson={
          salespersonName:this.truck.salespersons[0].salespersonName ? this.truck.salespersons[0].salespersonName : this.truck.stockLocation.stockLocationName,
          email:this.truck.salespersons[0].email === "" ? this.truck.stockLocation.email : this.truck.salespersons[0].email,
          telephoneNumber:phoneNumber
        } 
        this.salespersons.push(salesperson)
        this.info=this.translateService.instant('truck/salesRepTitle'.toLowerCase());    
      }
      else if(this.truck.salesperson)
      {
        let salesperson={
          salespersonName:this.truck.salesperson ? this.truck.salesperson.salespersonName : this.truck.stockLocation.stockLocationName,
          email:this.truck.stockLocation.email,
          telephoneNumber:this.truck.salesperson && this.truck.salesperson.telephoneNumber
          ? this.truck.salesperson.telephoneNumber
          : this.truck.stockLocation.phoneNumber
        };
        this.salespersons.push(salesperson)
        this.info=this.translateService.instant('truck/salesRepTitle'.toLowerCase());  
      }
      Promise.all([this.truck])
      .then(() => this.printService.onDataReady());
      this.updateBreadcrumb();

      if(this.truck.specification.category === 'Other'){
        this.isOtherCategory = true;
      }
      
    }
    ngAfterViewInit(){
      if(this.regionSettings === 'TX')
        this.mapInitializer();
    }
    updateBreadcrumb(): void {
      const breadcrumbsConts  =  [
        {
          label: 'Our Stock',
          url:HtmlRoutePages.search as string
        },
        {
          label: this.breadCrumbName,
          url:''
        }
      ];
      this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbsConts);
    }
    ngOnDestroy(): void {
      this.updateBreadcrumb();
    }

    captureMap() {
        const mapElement = document.getElementById('map') as HTMLElement;

        // Capture the map as an image
        html2canvas(mapElement, { useCORS: true }).then((canvas) => {
            this.mapImage = canvas.toDataURL();
        });
        
    }    
    mapInitializer() {
      if (!this.gmap || !this.gmap.nativeElement) {
        console.error('Map container element not found');
        //return;
        }
        const goteborg = { lat: 57.7089, lng: 11.9746 };
      this.map = new google.maps.Map(this.gmap.nativeElement, {
        center: goteborg,
        zoom: 8,
        gestureHandling: 'cooperative',
      });
        
      //const query = `${this.truck.stockLocation.country} ${this.truck.stockLocation.city} ${this.truck.stockLocation.address}`;
        this.getDealership();
        this.runDelayedLoop();
        this.captureMap();        
        
    }
    delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async runDelayedLoop() {
        for (let i = 0; i < 5; i++) { // arbitrary number of iterations
            console.log(`Iteration ${i + 1}: Start`);
            await this.delay(1300); // wait for 2 seconds
            console.log(`Iteration ${i + 1}: End after 2 seconds delay`);
        }
        console.log('Loop completed');
    }
    private createOverlayWithMarker(dealership:any) {

        this.map.setCenter(dealership.geometry.location);

        // add marker to the map
        new google.maps.Marker({
            map: this.map,
            position: dealership.geometry.location,
            icon: 'assets/svg/marker.svg',
        });

        const overlay = new google.maps.OverlayView();
        overlay.onAdd = function () {
            const layer = document.createElement('div');
            layer.style.borderStyle = 'none';
            layer.style.borderWidth = '0px';
            layer.style.position = 'absolute';
            this.div = layer;
            this.getPanes().overlayLayer.appendChild(layer);
        };

        overlay.draw = function () {
            const projection = this.getProjection();
            const bounds = this.map.getBounds();
            const sw = projection.fromLatLngToDivPixel(bounds.getSouthWest());
            const ne = projection.fromLatLngToDivPixel(bounds.getNorthEast());

            const div = this.div;
            div.style.left = sw.x + 'px';
            div.style.top = ne.y + 'px';
            div.style.width = ne.x - sw.x + 'px';
            div.style.height = sw.y - ne.y + 'px';
        };

        overlay.onRemove = function () {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
        };

        overlay.setMap(this.map);
        

    }
    private getDealership(): void {
      //Use the input to load the dealership from the file
      this.dealershipsService.dealerships$.subscribe((result: DealerPlaceResult[]) => {
        if (!result) return;
        const dealership = result.filter(
          (r) =>
            r.stockLocation.address === this.truck.stockLocation.address &&
            r.stockLocation.city === this.truck.stockLocation.city
        )[0];
        if (!dealership || !this.map) return;       
  
        this.createOverlayWithMarker(dealership)
  
        if (dealership.opening_hours) {
          this.place = {
            hasOpenHours: true,
            isOpen: true,
            weekdayText: dealership.opening_hours.weekday_text,
          };
        }
      });
    }
}
