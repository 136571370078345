import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'volvo-truck-model-home-skeleton',
  templateUrl: './truck-model-home-skeleton.component.html',
  styleUrls: ['./truck-model-home-skeleton.component.scss']
})
export class TruckModelHomeSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
