<i (click)="onClose()" class="email-close material-icons paddingTop">close</i>

<div class="email">
  <div class="VolvoLogo email-logo">
    <img alt="Volvo Selected" [src]="assetService.getAssetUrl('assets/logo.png')" />
    <span class="email-logo-text">
        <b>
          Selected
        </b>
      <br>
      <span class="email-logo-subText">
        Used Trucks
      </span>
      
    </span>
  </div>
  <div class="email-header">
    <span class="email-header-title">
      {{ 'popups/contactDealership/title' | lowercase | translate }}
    </span>
    
  </div>
  <div>
    <span class="email-EmailUs-Text" data-cy="contactpopup-subtitle">
      {{ data.stockLocation.stockLocationName }}
    </span>
  </div>

  <div>
    <div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="email-input">
          <div>
            <div>
              <h6 class="email-input-title" data-cy="contactpopup-maintitle">
                {{ 'contact/form/subject/title' | lowercase | translate }}
              </h6>
              <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch" data-cy="contactpopup-subject">
                <mat-select formControlName="subject" class="volvofont"
                  [placeholder]="'contact/form/subject/placeholder' | lowercase | translate">
                  <mat-option class="Option volvofont CPrimary" value="Sales and Product Inquiries"
                    data-cy="contactpopup-subjectitem1">
                    {{ 'contact/form/subject/SalesAndProduct' | lowercase | translate }}</mat-option>
                  <mat-option class="Option volvofont CPrimary" value="Financing Service" data-cy="contactpopup-subjectitem2">
                    {{ 'contact/form/subject/financingService' | lowercase | translate }}</mat-option>
                  <mat-option class="Option volvofont CPrimary" value="Product and Service Support"
                    data-cy="contactpopup-subjectitem3">
                    {{ 'contact/form/subject/productAndServiceSupport' | lowercase | translate }}</mat-option>
                  <mat-option class="Option volvofont CPrimary" value="Feedback" data-cy="contactpopup-subjectitem4">{{
                    'contact/form/subject/feedback' | lowercase | translate
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <h6 class="email-input-title">{{ 'form/fullName' | lowercase | translate }} </h6>
                <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                  <input formControlName="fullName" matInput [(ngModel)]="name"  />
                </mat-form-field>
            </div>

            <div>
              <h6 class="email-input-title">{{ 'form/email' | lowercase | translate }}</h6>
              <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                <input formControlName="email" matInput />
              </mat-form-field>
            </div>

            <div>
              <h6 class="email-input-title">
                {{ 'form/phoneNumber' | lowercase | translate }}
              </h6>
              <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                <input formControlName="phone" matInput />
              </mat-form-field>
            </div>

          </div>
          <div class="divWidth">
            <div style="text-align: left;">
              <h6 class="email-input-title">
                {{ 'form/message' | lowercase | translate }}
                <span class="grey3">({{ 'form/optional' | lowercase | translate }})</span>
              </h6>
              <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearchEmail">
                <textarea formControlName="message" matInput rows="5"  placeholder="" value=""></textarea>
              </mat-form-field>
            </div>

            <div class="MatInputSearchEmail privacyCheckboxPadding">
              <mat-checkbox (click)="recaptcha()" formControlName="privacy">
                <div class="email-privacy grey3">
                  {{ 'form/contactingVolvoPrivacyPolicy' | lowercase | translate }}
                  <a class="PopupOLD-newLink privacyPolicy" href="https://www.volvogroup.com/en-en/privacy.html" target="_blank">
                    <span>{{ 'form/privacyPolicy' | lowercase | translate }}</span>
                  </a>
                </div>
              </mat-checkbox>
            </div>
            
          </div>
        </div>
        <div class="recaptcha">
            <div class="email-submit">
              <div class="submitdiv">
                <div *ngIf="showRecaptcha" style="display: inline-block">
                  <re-captcha (resolved)="resolved($event)" siteKey="{{ recaptchaToken }}" required></re-captcha>
                </div>
              </div>
              <button [disabled]="form.invalid && (!reCaptchaRes)" type="submit" [ngClass]="(!form.invalid && reCaptchaRes) ? 'email-submit-btnColor' : ''">
                {{ 'form/send' | lowercase | translate }}
              </button>
            </div>
        </div>
      </form>
    </div>
  </div>
</div>