<section class="finance">
    <div class="tabMobSidePadding">
        <div class="finance-financeDiv" id="finance">
            <div class="tabMobHeroMargin">
                <img src="assets/pages/services/volvoFinancingService.webp"
                     class="finance-financeImg" alt="{{ 'uk/components/footer/financing' | lowercase | translate }}"/>
            </div>
            <div class="finance-title">
                <span>{{ "uk/components/footer/financing" | lowercase | translate }}</span>
            </div>
            <div class="finance-text">
                <span>{{ "uk/pages/services/Financing/text1" | lowercase | translate }}</span>
            </div>
            <div class="finance-text">
                <span>{{ "uk/pages/services/Financing/text2" | lowercase | translate }}</span>
            </div>
        </div>
        <div class="finance-list">
            <ul class="finance-list-title" *ngFor="let list of financeList">
                <div class="finance-list-display">
                    <div>
                        <div *ngIf="list.type === 'uk/pages/services/Financing/title1'" class="finance-list-title-tractor">
                            <img [src]="list.img" alt="{{ 'uk/pages/services/Financing/title1' | lowercase | translate }}"/>
                        </div>
                        <div *ngIf="list.type === 'uk/pages/services/Financing/title2'"
                             class="finance-list-title-handShake">
                            <img [src]="list.img" alt="{{ 'uk/pages/services/Financing/title2' | lowercase | translate }}"/>
                        </div>
                        <div *ngIf="list.type === 'uk/pages/services/Financing/title3'"
                             class="finance-list-title-graph">
                            <img [src]="list.img" alt="{{ 'uk/pages/services/Financing/title3' | lowercase | translate }}"/>
                        </div>
                    </div>
                    <div class="finance-list-display-padding">
                        <span> {{ list.type | lowercase | translate }}</span>
                <li>
                    {{ list.name | lowercase | translate }}
                </li>
        </div>
    </div>
    </ul>
    </div>
    <div class="finance-text-fontSize18">
        <span>{{ "uk/pages/services/Financing/text3" | lowercase | translate }}</span>
    </div>
    <div class="finance-list finance-paddingtop">
        <ul class="finance-list-title" *ngFor="let list of financeList2">
            <span> {{ list.type | lowercase | translate }}</span>
            <li>
                {{ list.name | lowercase | translate }}
            </li>
        </ul>
        <div id="serviceContract"></div>
    </div>
    </div>
</section>
<volvo-footer></volvo-footer>
