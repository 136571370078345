import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToggleFeatureService {
  constructor(private http: HttpClient) { }

  async getFeatureToggle(featureName: string): Promise<boolean> {
    const url = `${environment.apiUrl}featuretoggles/${featureName}_${environment.name}`;
    try {
      const response = await firstValueFrom(this.http.get(url));
      if (typeof response === 'boolean') {
        return response;
      } else {
        throw new Error('Invalid response type');
      }
    } catch (error) {
      console.error('Error fetching feature toggle', error);
      throw error;
    }
  }
}
