<section class="whoweare">
    <div class="whoweare-title">
        <span>{{ "uk/pages/aboutUs/whatwehave" | lowercase | translate }}</span>
    </div>
    <div class="whoweare-text">
        <span>{{ "uk/pages/aboutUs/whatwehavetext" | lowercase | translate }}</span>
        <span ><a class="fbClass" href='https://www.facebook.com/VolvoUsedTrucks/' target='_blank'>{{"uk/components/footer/facebook" | lowercase | translate}}</a></span>
        <span> {{ "uk/pages/contact/and" | lowercase | translate }} </span>
        <span><a class="fbClass" href='https://twitter.com/volvousedtrucks?lang=en' target='_blank'>{{"uk/components/footer/twitter" | lowercase | translate}}</a></span>
        <span>{{ "uk/pages/aboutUs/whatwehavetext1" | lowercase | translate }}</span>
    </div>
    <div class="whoweare-dragImages">
    <drag-scroll #nav class="drag-scroll-style">
        <lightgallery [settings]="settings">
            <a *ngFor="let item of items" class="container"  drag-scroll-item 
            [attr.data-src]="item.data.thumb"  [attr.data-sub-html]="item.data.caption" [attr.data-responsive]="item.data.thumb"
            >
            <img class="img-responsive"
            src="{{item.data.thumb}}" alt=""/>
            </a>
        </lightgallery>
    </drag-scroll>
    </div>
   <br>
   <div class="whoweare-arrows" style="float:right"> 
       <button (click)="nav.moveLeft();" class="sliderButton" data-cy="besttruck-moveleft">
           <i class="fal fa-chevron-left" ></i>
       </button>
           &nbsp;&nbsp;&nbsp;
       <button (click)="nav.moveRight()"  class="sliderButton" data-cy="besttruck-moveright">
           <i class="fal fa-chevron-right" ></i>
       </button>
   </div>
</section>
<div class="mobile">
    <volvo-footer ></volvo-footer>
</div>