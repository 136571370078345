import { Vehicle } from './trucks.model.base';

export class Truck extends Vehicle {
  constructor(init?: Partial<Truck>) {
    super();
    Object.assign(this, init);
  }

  public get MetaTitle(): string {
    var metaTitle = '';
    const majorCategorySemiTrailer = 'semi-trailer';
    const majorCategoryTrailer = 'trailer';
    const majorCategoryValue = this.specification.category.toLowerCase();
    const isTrailer = majorCategoryValue === majorCategorySemiTrailer || majorCategoryValue === majorCategoryTrailer;

    if (this.specification.make != null) {
      metaTitle = metaTitle + this.specification.make + ' ';
    }
    if (this.specification.model != null) {
      metaTitle = metaTitle + this.specification.model + ' ';
    }
    if (this.specification.horsePower != null && !isTrailer && !this.specification.isElectric) {
      metaTitle = metaTitle + this.specification.horsePower + ' hp ';
    }
    if (this.specification.superstructure?.type != null) {
      metaTitle = metaTitle + this.specification?.superstructure.type + ' ';
    }
    if (this.specification.axleConfiguration && this.specification.axleConfiguration.engPhrase != null && !isTrailer) {
      metaTitle = metaTitle + this.specification.axleConfiguration.engPhrase + ' ';
    }
    if (this.specification.modelYear != null && this.specification.modelYear != 0) {
      metaTitle = metaTitle + this.specification.modelYear + ' ';
    }
    return metaTitle;
  }

  public get MetaDescription(): string {
    const majorCategorySemiTrailer = 'semi-trailer';
    const majorCategoryTrailer = 'trailer';
    const majorCategoryValue = this.specification.category.toLowerCase();
    const isTrailer = majorCategoryValue === majorCategorySemiTrailer || majorCategoryValue === majorCategoryTrailer;

    var metaDescription = '';
    if (this.specification.make != null) {
      metaDescription = metaDescription + this.specification.make + ' ';
    }
    if (this.specification.model != null) {
      metaDescription = metaDescription + this.specification.model + ' ';
    }
    if (this.specification.horsePower != null && !isTrailer) {
      metaDescription = metaDescription + this.specification.horsePower + ' hp ';
    }
    if (this.specification.superstructure?.type != null) {
      metaDescription = metaDescription + this.specification.superstructure?.type + ' ';
    }
    if (this.specification.axleConfiguration && this.specification.axleConfiguration.engPhrase != null && !isTrailer) {
      metaDescription = metaDescription + this.specification.axleConfiguration.engPhrase + ' ';
    }
    if (this.specification.mileageKm != null && this.specification.mileageKm != 0) {
      metaDescription = metaDescription + this.specification.mileageKm + ' km ';
    }
    if (this.specification.modelYear != null && this.specification.modelYear != 0) {
      metaDescription = metaDescription + this.specification.modelYear + ' ';
    }

    return metaDescription;
  }

  public get MetaAltImage(): string {
    return this.MetaTitle;
  }

  public get ShowPrice(): boolean {
    if (this.pricing === undefined)
      return false;

    if (this.pricing && this.pricing.priceExclVatEuro === 0) {
      this.pricing.priceExclVat.value = 0;
      this.pricing.priceExclVat.currencyIsoCode3 = 'N/A';
      return false;
    }

    return true;
  }
}

export enum CurrencyCode {
  EUR = 'EUR',
  CZK = 'CZK',
  DKK = 'DKK',
  LTL = 'LTL',
  ZAR = 'ZAR'
}

export interface TrucksStateInterface {
  activeTruck: Truck;
  truckById: Truck;
  latestTrucks: Truck[];
}

export interface TruckSlides {
  filename: string;
  filenameSmall: string;
  filenameLarge: string;
  filenameMedium: string;
  contentType: string;
  filenameThumbnail: string;
}
export interface EmailFlagStateInterface {
  emailFlag: boolean;
}