import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    editCookies: Subject<void> = new Subject();
}

