import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {TruckCardTypes} from '~/core/store/search/search.model';


@Component({
    selector: 'volvo-stock-toggle-view',
    templateUrl: './stock-toggle-view.component.html',
    styleUrls: ['./stock-toggle-view.component.scss']
})
export class StockToggleViewComponent implements OnInit {
    @Input() cardsType: TruckCardTypes;
    @Output() typeChange: EventEmitter<TruckCardTypes> = new EventEmitter();

    TruckCardTypes = TruckCardTypes;

    constructor() {
    }

    ngOnInit(): void {
    }

    changeViewType(truckCardTypes: TruckCardTypes): void {
        this.cardsType = truckCardTypes;
        this.typeChange.emit(truckCardTypes);
    }
}
