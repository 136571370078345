<!--Section for selected-->
<section id="selectedSection" *isSelectedSite>
    <volvo-selected-page *ngIf="!isTablet"></volvo-selected-page>
    <div class="service" *ngIf="isTablet">
        <div *ngFor="let list of this.navlinks[1].children;" class="routeButton" (click)="routePage(list.path)">
            {{ list.label | lowercase | translate }} <img [src]="assetService.getAssetUrl('assets/pages/truckModel/right.png')" />
        </div>
    </div>
    <volvo-footer *ngIf="isTablet"></volvo-footer>
</section>

<!--Section for Trade and Export -->
<section id="tnxSection" *isTXSite>
    <div *ngIf="!isTablet" class="services">
        <div class="services-div">
            <div>
                <volvo-export-page></volvo-export-page>
                <volvo-utCustomisation-page></volvo-utCustomisation-page>
            </div>
        </div>
    </div>
    <div *ngIf="isTablet" class="mobileDiv">
        <volvo-service-link-page></volvo-service-link-page>
    </div>
    <volvo-footer></volvo-footer>
</section>

<!--Section for Uk and NS-->
<section id="uknsSection" *isUKNSSite>
    <div *ngIf="!isTablet"  class="services">
        <div class="services-div">
            <div>
                <volvo-approved-service-page></volvo-approved-service-page>
                <volvo-service-economy-page></volvo-service-economy-page>
                <volvo-finance-page></volvo-finance-page>
                <volvo-service-contract-page></volvo-service-contract-page>
                <volvo-customisation-page></volvo-customisation-page>
                <volvo-connect-page></volvo-connect-page>
            </div>
        </div>
    </div>
    <div *ngIf="isTablet" class="mobileDiv">
        <volvo-service-link-page></volvo-service-link-page>
    </div>
    <volvo-footer></volvo-footer>
</section>

