<div (click)="onClose()" class="DealerShip-close"><i class="fal fa-times"></i></div>

<div class="DealerShip"> 
  <div class="DealerShip-left">
    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <mat-select [(value)]="selectedFilterCountry" (openedChange)="onFilterByCountry($event)"
        [placeholder]="'globalMiss/filterbycountry' | lowercase | translate">
        <mat-option class="Option CPrimary volvofontmedium" (click)="filterByCountry('All Markets')"
          value="All markets">
          {{ 'search/filter/country/allmarkets' | lowercase | translate }}
        </mat-option>
        <mat-option class="Option CPrimary volvofont"
          *ngFor="let item of dealershipsService.dealershipsCountries$ | async" [value]="item">{{
          'search/filter/country/' + item | lowercase | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-divider class="DealerShip-left-divider"></mat-divider>

    <div class="DealerShip-scroll">
      <mat-selection-list *ngIf="dealershipLocations; else loading1" style="padding-right: 14px">
        <mat-list-option [(selected)]="item.isSelected"
          *ngFor="let item of dealershipLocations" class="DealerShip-item" (click)="goToAddress(item)"  [value]="item.stockLocation.stockLocationName">
          <div matLine class="DealerShip-itemTitle">
            <div class="DealerShip-namesize">
              {{ item.stockLocation.stockLocationName }}
            </div>
          </div>

          <div matLine class="DealerShip-itemDescription">{{ item.formatted_address }}</div>
        </mat-list-option>
      </mat-selection-list>

      <ng-template #loading1>
        <volvo-loading></volvo-loading>
      </ng-template>
    </div>

    <button (click)="onClose()" class="MatBtn DealerShip-apply volvofontmedium" [ngClass]="dealershipLocations? 'btnLoaded':'btnLoading'" mat-button color="primary">
      {{ 'popups/dealership/applyFilter' | lowercase | translate }}
    </button>
  </div>

  <div class="DealerShip-right">
    <div #mapContainer id="map"></div>
      <div *ngIf="loading" class="loading-overlay">
        <div style="display:flex;">
          <div class="loading-overlay-map"></div>
          <div class="loading-overlay-toggle"></div>
        </div>
        <div>
            <img  [src]="assetService.getAssetUrl('assets/icons/worldmap.webp')" alt="Loading...">
        </div>
        <div style="float: right;">
          <div class="loading-overlay-pegman"></div> 
          <div class="loading-overlay-zoom"></div>
        </div>
      </div>
  </div>
</div>