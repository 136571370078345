import { Injectable } from '@angular/core';
import { url } from 'inspector';
@Injectable({
  providedIn: 'root'
})
export class BreadCrumbUrlService {
  url: string;
  top:number;
  left:number
  getUrl(): string {
    return this.url;
  }

  setUrl(pUrl: string): void {
    this.url = pUrl;
  }

  getPageposition(){
    return {screenTop:this.top,screenLeft:this.left};
  }
  setPageposition(ptop:number,pleft:number): void {
    this.top = ptop;
    this.left = pleft;
  }
}
