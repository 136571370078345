import { ViewChild } from '@angular/core';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { MatOption } from '@angular/material/core';
import { MatSelectionList } from '@angular/material/list';

import { SearchStep } from '~/core/store/search/search.model';
import { ScreenUtils } from '~/core/utils/screen-utils/screen-utils';

@Component({
    selector: 'volvo-filter-type',
    templateUrl: './filter-type.component.html',
    styleUrls: ['./filter-type.component.scss']
})
export class FilterTypeComponent implements OnInit, AfterViewInit {
    stepInput: string;
    _step: SearchStep;
    initValue: boolean;
    isPhone: boolean = false;
    @Input() set step(step: SearchStep) {
        this._step = step;

        this.stepInput = step.value === "All" ? "" : (step.value as string);
        if (this.stepInput === '' && this.initValue) {
            this.selectionListVM.deselectAll();
        }
    }

    @Input() isSuperstructure = false;
    @Output() filterChange = new EventEmitter();
    @ViewChild('allSelected') private allSelected: MatOption;
    @ViewChild('allSelectedVM') private allSelectedVM: MatOption;
    @ViewChild('selectionList') selectionList: MatSelectionList;
    @ViewChild('selectionListVM') selectionListVM: MatSelectionList;

    constructor() {
        if (ScreenUtils.isPhone() || ScreenUtils.isTablet()) this.isPhone = true;
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.stepInput = this._step.value === "All" ? "" : (this._step.value as string);

        if (this.stepInput === '') {
            this.selectionListVM.deselectAll();
            this.initValue = true;
        }
    }

    onChangeVehicleType(event): void {
        this.stepInput = event.options[0].value;
        this.filterChange.emit({
            ...this._step,
            isSelected: !this._step.isSelected,
            value: (this._step.value === this.stepInput ? 'All' : this.stepInput)
        });
        this.selectionListVM.deselectAll();
        this.stepInput = "";
    }

    toggleAllSelection() {
        if (this.allSelected.selected) {
            this.selectionList.deselectAll();
            this.stepInput = "";
            this.filterChange.emit({
                ...this._step,
                isSelected: true,
                value: ''
            });
        }
    }
}
