<div class="tabMobSidePadding">
    <section class="warranty">
        <div class="warranty-remainingWarranty" id="warranty">
            <div class="tabMobHeroMargin">
                <img [src]="assetService.getAssetUrl('assets/pages/vehicleOffers/volvoFH.webp')" class="warranty-warrantyImg" alt="volvo FH"/>
            </div>
            <div class="warranty-title">
                {{ "uk/components/footer/remainingWarranty" | lowercase | translate }}
            </div>
            <div class="warranty-text">
                <span>{{ "uk/pages/vehicleOffer/remainingWarrantyText1" | lowercase | translate }}</span>
            </div>
            <div class="warranty-text">
                <span>{{ "uk/pages/vehicleOffer/remainingWarrantyText2" | lowercase | translate }}</span>
            </div>
            <div class="warranty-text">
                <span class="warranty-text-medium">{{ "uk/pages/vehicleOffer/remainingWarrantyText3" | lowercase | translate }}</span>{{ "uk/pages/vehicleOffer/remainingWarrantyText4" | lowercase | translate }}
            </div>
            <div class="warranty-text">
                <span id="selected">{{ "uk/pages/vehicleOffer/remainingWarrantyText5" | lowercase | translate }}</span>
            </div>
        </div>
    </section>
    </div>
    <volvo-footer></volvo-footer>
