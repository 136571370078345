import { Component,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'volvo-truck-model-popup',
  templateUrl: './truck-model-popup.component.html',
  styleUrls: ['./truck-model-popup.component.scss']
})
export class TuckModelPopupComponent{
  engineobj:any;
  constructor(
    public dialogRef: MatDialogRef<TuckModelPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data ,
  ) {
    this.engineobj = data;
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
