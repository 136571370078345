import { Component, Input, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

import { Bucket, BucketAggregations } from '~/core/store/buckets/buckets.model';
import { BucketsService } from '~/core/store/buckets/buckets.service';
import { SearchStateFilter } from '~/core/store/search/search.model';
import { SearchService } from '~/core/store/search/search.service';
import { ApiUtils } from '~/core/utils/api-utils/api-utils';
import { HtmlRoutePages } from '~/app-routing.model';
import { DomSanitizer } from '@angular/platform-browser';

@AutoUnsubscribe()
@Component({
  selector: 'volvo-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit, OnDestroy {
  @Input() searchButtonVisible = true;
  @Input() redirectOnSearch = true;
  HtmlRoutePages = HtmlRoutePages;

  search: SearchStateFilter;
  searchInput: string;
  buckets: Bucket[];
  autocompleteBuckets: Bucket[];

  private s1: Subscription;
  private s2: Subscription;

  constructor(
    private apiUtils: ApiUtils,
    private searchService: SearchService,
    private bucketsService: BucketsService,
    private sanitizer: DomSanitizer
  ) {
    this.initSubscriptions();
  }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  onSearchInputKeyup(): void {
    if (this.searchInput.length === 0) {
      this.autocompleteBuckets = [...this.buckets];
    } else if (this.searchInput && this.buckets) {
      const filterValue = this.searchInput.toLowerCase();
      this.autocompleteBuckets = this.buckets.filter((state) => state.key.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  async onSearch(): Promise<void> {
    this.searchService.updateSearch({
      ...this.search,
      q: this.sanitizer.sanitize(SecurityContext.HTML, this.searchInput),
    });
  }

  private initSubscriptions(): void {
    this.s1 = this.searchService.filter$.subscribe((searchOptions: SearchStateFilter) => {
      this.searchInput = searchOptions.q;
      this.search = searchOptions;
    });

    this.s2 = this.bucketsService.buckets$.subscribe((aggregations: BucketAggregations) => {
      if (aggregations) {
        this.buckets = aggregations.make.buckets;
        this.autocompleteBuckets = aggregations.make.buckets;
      }
    });
  }
}
