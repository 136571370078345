import { Component, OnInit} from '@angular/core';
import { HtmlRoutePages } from '../../../../app-routing.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ScreenUtils } from '../../../../core/utils/screen-utils/screen-utils';
import { BreadcrumbService } from 'angular-crumbs-2';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'volvo-selected-FAQ',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class VolvoSelectedFAQPageComponent implements OnInit{
  HtmlRoutePages:HtmlRoutePages;
  isTablet: boolean = false;
  faqList = [
    {
      title: 'uk/pages/services/faq/title1',
      description:  'uk/pages/services/faq/title1/text1' 
    },
    {
      title: 'uk/pages/services/faq/title2',
      description:  'uk/pages/services/faq/title2/text1' 
    },
    {
      title: 'uk/pages/services/faq/title3',
      description:  'uk/pages/services/faq/title3/text1' 
    },
    {
      title: 'uk/pages/services/faq/title4',
      description:  'uk/pages/services/faq/title4/text1' 
    },
    {
      title: 'uk/pages/services/faq/title5',
      description:  'uk/pages/services/faq/title5/text1' 
    },
    {
      title: 'uk/pages/services/faq/title6',
      description:  'uk/pages/services/faq/title6/text1' 
    },
    {
      title: 'uk/pages/services/faq/title7',
      description:  'uk/pages/services/faq/title7/text1' 
    },
    {
      title: 'uk/pages/services/faq/title8',
      description:  'uk/pages/services/faq/title8/text1' 
    },
    {
      title: 'uk/pages/services/faq/title9',
      description:  'uk/pages/services/faq/title9/text1' 
    },
    {
      title: 'uk/pages/services/faq/title10',
      description:  'uk/pages/services/faq/title10/text1' 
    },
    {
      title: 'uk/pages/services/faq/title11',
      description:  'uk/pages/services/faq/title11/text1' 
    },
    {
      title: 'uk/pages/services/faq/title12',
      description:  'uk/pages/services/faq/title12/text1' 
    },
  ];
  constructor(private route: ActivatedRoute, private router: Router,private breadcrumbService: BreadcrumbService,private titleService: Title,private translateService: TranslateService,private metaTagService: Meta) {}
  ngOnInit(): void {
      this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('uk/services/volvoselectedfaq/metatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:title',
        content: this.translateService.instant('uk/services/volvoselectedfaq/metatitle'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('uk/services/volvoselectedfaq/metadescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('uk/services/volvoselectedfaq/altImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('uk/services/volvoselectedfaq/metatitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/services/volvoselectedfaq/metatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/services/volvoselectedfaq/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/services/volvoselectedfaq/altImage'.toLowerCase())
    });

    if (ScreenUtils.isTablet()) this.isTablet = true;
    if(!this.isTablet)
    {
      this.breadcrumbService.changeBreadcrumb(this.route.snapshot, 'FAQ - Volvo Selected & Approved ');
    }
    else{
      this.breadcrumbService.changeBreadcrumb(this.route.snapshot, 'FAQ');
    }
  }
 }