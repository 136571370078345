import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Subscription} from 'rxjs';

import {LocalesStateLang, LocalesStateLangList} from '~/core/store/locales/locales.model';
import {LocalesService} from '~/core/store/locales/locales.service';
import {Truck} from '~/core/store/trucks/trucks.model';


export interface SuperstructureDetailsData {
    product: Truck;
}

@AutoUnsubscribe()
@Component({
    selector: 'volvo-superstructure-details-popup',
    templateUrl: './superstructure-details-popup.component.html',
    styleUrls: ['./superstructure-details-popup.component.scss']
})
export class SuperstructureDetailsPopupComponent implements OnInit, OnDestroy {
    product: Truck;
    curLang: LocalesStateLangList;

    private s0: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SuperstructureDetailsData,
        public dialogRef: MatDialogRef<SuperstructureDetailsPopupComponent>,
        private localesService: LocalesService,
    ) {
        this.product = this.data.product;

        this.s0 = this.localesService.lang$
            .subscribe((lang: LocalesStateLang) => {
                this.curLang = lang.curLang;
            });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    onClose(): void {
        this.dialogRef.close();
    }
}
