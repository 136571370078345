import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import * as copy from 'copy-to-clipboard';

import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { CounterService } from '~/core/services/counter/counter-service';
import { RegionalSettingsService } from '../../core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '../../core/declaration/declaration';
import { AssetService } from '~/core/services/assets/asset.service';

export interface ProductDetailsData {
    truckUrl: string;
}

@AutoUnsubscribe()
@Component({
    selector: 'volvo-share-popup',
    templateUrl: './share-popup.component.html',
    styleUrls: ['./share-popup.component.scss']
})
export class SharePopupComponent implements OnInit, OnDestroy {
    region: string;
    regionEnum = regionEnum;
    isSelectedSite: boolean = false;
    site: string;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ProductDetailsData,
        public dialogRef: MatDialogRef<SharePopupComponent>,
        private snackBar: MatSnackBar,
        private globalUtils: GlobalUtils,
        private counterService: CounterService,
        private regionalSettingsService: RegionalSettingsService,
        public assetService: AssetService
    ) {
    }

    ngOnInit(): void {
        this.regionalSettingsService.regionalSettings$.subscribe((res: any) => {
            this.region = res.regionsSettings[0].region;
            this.site = res.regionsSettings[0].site;
            if (this.site === "volvoselected")
                this.isSelectedSite = true;
        })
    }

    ngOnDestroy(): void {
    }

    onClose(): void {
        this.dialogRef.close();
    }

    copyToClipBoard(): void {
        copy(this.data.truckUrl);

        this.snackBar.open('Copied!', '', {
            duration: 1000
        });
    }

    shareInstagram(): void {
        if (this.globalUtils.isBrowser()) {
            const win = window.open(`https://www.instagram.com/${encodeURIComponent(this.data.truckUrl)}`, '_blank');
            win.focus();
        }
    }

    shareLinkedIn(): void {
        if (this.globalUtils.isBrowser()) {
            const win = window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(this.data.truckUrl)}`, '_blank');
            win.focus();
        }
    }

    shareFaceBook(): void {
        if (this.globalUtils.isBrowser()) {
            const win = window.open(`https://www.facebook.com/sharer.php?u=${this.data.truckUrl}`, '_blank');
            win.focus();
        }
    }

    shareWhatsApp(): void {
        if (this.globalUtils.isBrowser()) {
            const win = window.open(`https://api.whatsapp.com/send?phone=whatsappphonenumber&text=You%20might%20be%20interested%20in%20this%20truck%20I%20found%20on%20Volvo%20Selected!%20%20%20${this.data.truckUrl}`, '_blank');
            win.focus();
        }
    }

    shareViber(): void {
        if (this.globalUtils.isBrowser()) {
            const win = window.open(`https://3p3x.adj.st/?adjust_t=u783g1_kw9yml&adjust_fallback=https%3A%2F%2Fwww.viber.com%2F%3Futm_source%3DPartner%26utm_medium%3DSharebutton%26campaignId%3DDefualt&adjust_campaign=Sharebutton&adjust_deeplink=" + encodeURIComponent("viber://forward?text="${this.data.truckUrl}`, '_blank');
            win.focus();
        }
    }
    shareCount(socialHandler: any): void {
        this.counterService.postShareButton(socialHandler);
    }

    shareEmail(): void {
        const subject = encodeURIComponent('You might be interested in this truck');
        const body = encodeURIComponent(this.data.truckUrl);
        const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
        window.location.href = mailtoLink;
    }

    shareTwitter(): void {
        if (this.globalUtils.isBrowser()) {
            const win = window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(this.data.truckUrl)}`, '_blank');
            win.focus();
        }
    }

}
