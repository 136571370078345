import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import {
    SearchStateFilter,
    SortOptions,
} from "~/core/store/search/search.model";
import { SearchService } from "~/core/store/search/search.service";
import { SortPopupComponent } from "../../popups/sort-popup/sort-popup.component";
import { AssetService } from "~/core/services/assets/asset.service";

@AutoUnsubscribe()
@Component({
    selector: "volvo-filters-popup",
    templateUrl: "./filters-popup.component.html",
    styleUrls: ["./filters-popup.component.scss"],
})
export class FiltersPopupComponent implements OnInit, OnDestroy {
    search: SearchStateFilter;
    totalTrucks: number;
    selectedFiltersCount: number;
    SortOptions = SortOptions;
    //isSortOpened:boolean=true;
    private s1: Subscription;
    constructor(
        private searchService: SearchService,
        public dialogRef: MatDialogRef<FiltersPopupComponent>,
        public dialog: MatDialog,
        public assetService: AssetService
    ) {
        this.initSubscriptions();
    }

    ngOnInit(): void { }

    onClose() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void { }

    resetAllFilters(): void {
        //TODO CLEAR LOCAL STORAGE
        localStorage.setItem('searchParam', '');
        sessionStorage.setItem('resetCountry', 'true');
        localStorage.setItem('offerType', '');
        this.searchService.resetSearch();
    }

    private initSubscriptions(): void {
        this.s1 = this.searchService.filter$.subscribe(
            (searchOptions: SearchStateFilter) => {
                this.search = searchOptions;
                this.selectedFiltersCount = 0;
                 if (SortOptions.oldestAge !== searchOptions.sort) {
                    this.selectedFiltersCount++;
                }
                Object.values(searchOptions.steps).forEach((item) => {
                    if (item.isSelected && item.isVisible) {
                        this.selectedFiltersCount++;
                    }
                });
            }
        );
        this.s1 = this.searchService.totalTrucks$.subscribe(
            (totalTrucks: number) => {
                this.totalTrucks = totalTrucks;
            }
        );
    }
    onSortChange(sortOption: SortOptions): void {
            this.searchService.updateSearch({
                ...this.search,
                sort: sortOption
            });
    }
    // toggleSort()
    // {
    //     this.isSortOpened=!this.isSortOpened;
    // }
}
