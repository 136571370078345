import { Component, OnInit, Input } from '@angular/core';
import { Truck } from '../../../core/store/trucks/trucks.model';
import { GlobalUtils } from '../../../core/utils/global-utils/global-utils';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '../../../core/declaration/declaration';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { AppService } from '~/app.service';
@Component({
  selector: 'volvo-best-in-stock',
  templateUrl: './best-in-stock.component.html',
  styleUrls: ['./best-in-stock.component.scss']
})
export class BestInStockComponent implements OnInit {
  isLoaded = false;
  leftNavDisabled = false;
  rightNavDisabled = false;
  region: string;
  regionEnum = regionEnum;
  site: string;
  regionalSettings: RegionSettings;
  isSelectedSite: boolean = false;
  userNavigationText = 'Highlighted vehicles';
  @Input() set trucks(trucks: Truck[]) {
    if (trucks) {
      if(this.region){
        this.region===regionEnum.TX?this.bestTrucks = trucks.filter(res => res.specification.make === 'Volvo' && res.advertisementCategory === 'Trade and Export'):this.bestTrucks = trucks.filter(res => res.specification.make === 'Volvo');
        this.isLoaded = true;
      }
    }
  }
  @Input() title: string;
  @Input() isTruckPage: boolean;

  bestTrucks: Truck[];
  constructor(public globalUtils: GlobalUtils,private appService: AppService) {
    this.regionalSettings = appService.getRegionalSettings();
    if(this.regionalSettings){
      this.region = this.regionalSettings?.region;
      if(this.regionalSettings?.site === "volvoselected")
        this.isSelectedSite = true;
    }
   }

  ngOnInit(): void {
    
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }
}
