import { InjectionToken } from "@angular/core";

export const VIEWER = new InjectionToken<string>('viewer');
export const CRAWLER_AGENTS = [
    'googlebot', 'yandexbot', 'yahoo', 'bingbot',
    'baiduspider', 'facebookexternalhit', 'twitterbot', 'rogerbot',
    'linkedinbot', 'embedly', 'quora link preview', 'showyoubot', 'outbrain',
    'pinterest/0.', 'developers.google.com/+/web/snippet',
    'slackbot', 'vkshare', 'w3c_validator', 'redditbot', 'applebot',
    'whatsapp', 'flipboard', 'tumblr', 'bitlybot', 'skypeuripreview',
    'nuzzel', 'discordbot', 'google page speed'
];

export const langList = ['/en/', '/cz/', '/dk/', '/lt/', '/ru/', '/fr/', '/es/', '/no/', '/it/', '/fi/', '/pl/', '/hu/', '/sk/', '/si/', '/at/', '/ro/', '/ba/', '/mk/', '/rs/', '/hr/', '/de/', '/be-nl/', '/be-fr/', '/nl/', '/bg/', '/se/', '/ee/', '/lv/', '/ch-de/', '/ch-fr/', '/ch-it/', '/en-za/'];

export const allLangRegEx = /^(\/cz$|\/cz\/|\/dk$|\/dk\/|\/lt$|\/lt\/|\/ru$|\/ru\/|\/fr$|\/fr\/|\/es$|\/es\/|\/no$|\/no\/|\/it$|\/it\/|\/fi$|\/fi\/|\/pl$|\/pl\/|\/hu$|\/hu\/|\/sk$|\/sk\/|\/si$|\/si\/|\/at$|\/at\/|\/ro$|\/ro\/|\/ba$|\/ba\/|\/mk$|\/mk\/|\/rs$|\/rs\/|\/hr$|\/hr\/|\/en$|\/en\/|\/de$|\/de\/|\/be-fr$|\/be-fr\/|\/be-nl$|\/be-nl\/|\/nl$|\/nl\/|\/bg$|\/bg\/|\/se$|\/se\/|\/ee$|\/ee\/|\/lv$|\/lv\/|\/ch-de$|\/ch-de\/|\/ch-fr$|\/ch-fr\/|\/ch-it$|\/ch-it\/|\/en-za$|\/en-za\/|\/en-gb$|\/en-gb\/|\/en-tx$|\/en-tx\/)/;

export const defaultRegionList = [
    // { label: "Australia", id: "AU", isSelected: "false" },
    { label: 'Europe', id: 'EU', isSelected: false },
    { label: 'South Africa', id: 'ZA', isSelected: false },
    { label: 'United Kingdom', id: 'UK', isSelected: false },
    { label: 'Trade & Export', id: 'TX', isSelected: false }
  ];