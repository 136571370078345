<div *ngIf="!hasUnSubscribed">
    <div class="unSubscribe">
        <div class="unSubscribe-title">
            {{ 'search/notification/unsubscribe/title' | lowercase | translate }}
        </div>
        <div class="unSubscribe-text">
            {{'search/notification/unsubscribe/text1' | lowercase | translate}}
            <b>{{emailId}}</b>{{'search/notification/unsubscribe/text5'| lowercase | translate}}
        </div>
        <div class="unSubscribe-text" [innerHTML]=" 'search/notification/unsubscribe/text2' | lowercase | translate"></div>
        <div>
            <button type="button" (click)="unsubscribeNotificationData()">
                {{'search/notification/unsubscribe' | lowercase | translate}}
            </button>
        </div>
    </div>
</div>
<div *ngIf="hasUnSubscribed">
    <div class="unSubscribe">
        <div class="unSubscribe-title">
            {{'search/notification/unsubscribe/text3' | lowercase | translate}}
        </div>
        <div class="unSubscribe-text">
            {{'search/notification/unsubscribe/text4' | lowercase | translate}}
        </div>
        <div *ngIf="hasFeedback" class="unSubscribe-text" style="font-family: 'volvo novum medium'">
            {{'search/notification/unsubscribe/response/feedback' | lowercase | translate}}
        </div>
        <div class="unSubscribe-response" *ngIf="!hasFeedback">
            <div class="unSubscribe-response-subtitle">
                {{'search/notification/unsubscribe/response/text1'| lowercase | translate}}
            </div>
            <div class="unSubscribe-response-list">
                <mat-selection-list #list>
                    <div *ngFor="let response of responseList;let last=last" >
                        <mat-list-option checkboxPosition="before" (click)="checkSelected(response,last)"  [selected]=response.selected class="unSubscribe-response-text">
                            {{response.text | lowercase | translate}}
                        </mat-list-option>
                    </div>
                </mat-selection-list>
                <div *ngIf="showOtherDescTextbox">
                    <input #textbox [(ngModel)]="otherDesc" class="unSubscribe-response-other" type="text" (change)="textbox.focus();"  />
                </div>
            </div>
            <div>
                <button type="button" (click)="submitUserFeedbackData()">{{'search/notification/unsubscribe/response/submit'| lowercase | translate}}</button>
            </div>
        </div>
    </div>

</div>