import { Component, OnInit } from '@angular/core';
import { LocalesStateLang, LocalesStateRegion, LocalesStateCountry } from '~/core/store/locales/locales.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { RegionalPopupComponent } from '~/popups/regional-popup/regional-popup.component';
import { Sidenavs, SidenavService } from '~/core/services/sidenav/sidenav.service';
import { Router } from '@angular/router';
import { LocalizeRouterService, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { Location } from '@angular/common';
import { HtmlRoutePages, LangRoutePages } from '~/app-routing.model';
@Component({
  selector: 'volvo-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {
  private lang: LocalesStateLang;
  private region: LocalesStateRegion;
  private country: LocalesStateCountry;
  private menu: MatSidenav;
  isMobile: boolean;
  path: string = HtmlRoutePages.regions;
  constructor(
    public localesService: LocalesService,
    public dialog: MatDialog,
    private sidenavService: SidenavService,
    private router: Router,
    private localizeRouterService: LocalizeRouterService,
    private location: Location
  ) {
    this.initSubscriptions();
  }

  private initSubscriptions() {
    this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
      this.lang = lang;
    });

    this.localesService.region$.subscribe((region: LocalesStateRegion) => {
      this.region = region;
    });

    this.localesService.country$.subscribe((country: LocalesStateCountry) => {
      this.country = country;
    });
  }

  ngOnInit(): void { }

  onLangChange(): void {
    this.dialog.open(RegionalPopupComponent, {
      panelClass: 'volvo-popup-region',
      autoFocus: false,
      data: {
        truck: ''
      },
      position: {
        right: '50px',
        top: '70px'
      }
    });
  }
  closeMenu(): void {
    this.menu.close();
  }
}
