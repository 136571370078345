// export const  countries = [
//     { path:'search/filter/country/czech republic',label: 'Czech Republic', id: 'cz' },
//     { path:'search/filter/country/denmark',label: 'Danmark', id: 'dk' },
//     { path:'search/filter/country/lithuania',label: 'Lietuva', id: 'lt' },
//     { path:'search/filter/country/france',label: 'France', id: 'fr' },
//     { path:'search/filter/country/spain', label: 'España', id: 'es' },
//     { path:'search/filter/country/norway',label: 'Norsk', id: 'no' },
//     { path:'search/filter/country/italy',label: 'Italia', id: 'it' },
//     { path:'search/filter/country/finland',label: 'Suomi', id: 'fi' },
//     { path:'search/filter/country/poland',label: 'Polska', id: 'pl' },
//     { path:'search/filter/country/hungary',label: 'Magyarország', id: 'hu' },
//     { path:'search/filter/country/slovakia',label: 'Slovensko', id: 'sk' },
//     { path:'search/filter/country/slovenia',label: 'Slovenija', id: 'si' },
//     { path:'search/filter/country/austria',label: 'Austria', id: 'at' },
//     { path:'search/filter/country/romania',label: 'România', id: 'ro' },
//     { path:'search/filter/country/russia',label: 'Bosna', id: 'ba' },
//     { path:'search/filter/country/macedonia',label: 'Makedonija', id: 'mk' },
//     { path:'search/filter/country/serbia',label: 'Serbia', id: 'rs' },
//     { path:'search/filter/country/croatia',label: 'Hrvatska', id: 'hr' },
//     { path:'search/filter/country/germany',label: 'Deutschland', id: 'de' },
//     { path:'search/filter/country/belgium(NL)',label: 'België(NL)', id: 'be-nl' },
//     { path:'search/filter/country/belgium(FR)',label: 'Belgique(FR)', id: 'be-fr' },
//     { path:'search/filter/country/netherlands',label: 'Nederland', id: 'nl' },
//     { path:'search/filter/country/bulgaria',label: 'Bulgariya', id: 'bg' },
//     { path:'search/filter/country/sweden',label: 'Sweden', id: 'se' },
//     { path:'search/filter/country/estonia',label: 'Eesti', id: 'ee' },
//     { path:'search/filter/country/latvia',label: 'Latvija', id: 'lv' },
//     { path:'search/filter/country/switzerland(DE)',label: 'Schweiz(DE)', id: 'ch-de' },
//     { path:'search/filter/country/switzerland(FR)',label: 'Suisse(FR)', id: 'ch-fr' }
//   ].sort((a, b) => {
//     return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
//   });
export const  countries =  [
    { label: 'Austria', id: 'at' },
    { label: 'Belgium', id: 'be-nl' },
    { label: 'Bosnia and Herzegovina', id: 'ba' },
    { label: 'Bulgaria', id: 'bg' },
    { label: 'Czech Republic', id: 'cz' },
    { label: 'Croatia', id: 'hr' },
    { label: 'Denmark', id: 'dk' },
    { label: 'Estonia', id: 'ee' },
    { label: 'Finland', id: 'fi' },
    { label: 'France', id: 'fr' },
    { label: 'Germany', id: 'de' },
    { label: 'Hungary', id: 'hu' },
    { label: 'Italy', id: 'it' },
    { label: 'Latvia', id: 'lv' },
    { label: 'Lithuania', id: 'lt' },
    { label: 'Macedonia', id: 'mk' },
    { label: 'Netherlands', id: 'nl' },
    { label: 'Norway', id: 'no' },
    { label: 'Poland', id: 'pl' },
    { label: 'Romania', id: 'ro' },
    { label: 'Switzerland', id: 'ch-de' },
    { label: 'Slovenia', id: 'si' },
    { label: 'Slovakia', id: 'sk' },
    { label: 'Sweden', id: 'se' },
    { label: 'Spain', id: 'es' },
    { label: 'Serbia', id: 'rs'},
    { label: "South Africa", id: "za" }
  ].sort((a, b) => {
         return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
  });