import { Injectable } from '@angular/core';
import { regionEnum } from '~/core/declaration/declaration';
import { RegionalSettingsService } from '~/core/store/regionalSettings/regionalSettings.service';
import { AppState } from '~/core/store';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { Store } from '@ngxs/store';
@Injectable({
  providedIn: 'root'
})
export class AppService {
  regionalSettings: RegionSettings;
  constructor(public regionalSettingsService: RegionalSettingsService, public store: Store) {
    const region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === region)[0]);
    
   }

  getRegionalSettings(): RegionSettings {
    return this.regionalSettings;
  }

}
