import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { GetCountrySettings, GetRegionalSettings, SetRegion } from "./regionalSettings.actions";
import { CountrySettingsStateInterface, RegionSettings, RegionalSettingsStateInterface } from "./regionalSettings.model";
import { AppState } from "..";
import { regionEnum } from '~/core/declaration/declaration';
@Injectable({ providedIn: "root" })
export class RegionalSettingsService {
    @Select((state: AppState) => state.regionalSettings)
    public regionalSettings$: Observable<RegionalSettingsStateInterface>;

    @Select((state: AppState) => state.countrySettings)
    public countrySettings$: Observable<CountrySettingsStateInterface>;

    @Dispatch()
    public getRegionalSettings() {
        return new GetRegionalSettings();
    }

    @Dispatch()
    public getCountrySettings() {
        return new GetCountrySettings();
    }

    @Select((state) => state.regionalSettings.region.region)
    public getRegion$: Observable<RegionSettings>;


    @Dispatch()
    public setRegion(payload: string) {;
        return new SetRegion(payload);
    }

    @Select((state) => state.regionalSettings.region.site)
    public getPortalName$: Observable<RegionSettings>;    

    region: string;
    site: string;

    private fetchRegionalSettings(){
        this.regionalSettings$.subscribe(
            (regionalSettings) => {
                
              this.region = regionalSettings.regionsSettings[0].region;
              this.site =regionalSettings.regionsSettings[0].site;
            },
            (error) => {
              console.error('Error fetching regional settings:', error);
            }
        );
    }

    public checkForSelectedWebsite() : boolean {
        this.fetchRegionalSettings();
        if(this.site === "volvoselected")
            return true;
        else
            return false;
    }

    public getSiteName() : string {
        this.fetchRegionalSettings();
        return this.site;
    }

    public getRegionName() : string {
        this.fetchRegionalSettings();
        return this.region;
    }
}
