<section class="volvo">
  <div class="volvo-volvoDiv">
    <div class="volvo-imgDiv">
      <img [src]="assetService.getAssetUrl('assets/pages/buyersGuide/volvoFH.webp')" class="volvo-imgDiv-volvoImg" alt="volvo FH"/>
    </div>
    <div class="volvo-title">
      <span>{{ "uk/pages/buyersGuide/volvoFH" | lowercase | translate }}</span>
    </div>
    <div class="volvo-text">
      <span>{{ "uk/pages/buyersGuide/volvoFH/text1" | lowercase | translate }}</span>
    </div>
    <div class="volvo-text">
      <span>
        {{ "uk/pages/buyersGuide/volvoFH/text2" | lowercase | translate }}
      </span>
    </div>
    <div class="volvo-text">
      <span>
        {{ "uk/pages/buyersGuide/volvoFH/text3" | lowercase | translate }}
      </span>
    </div>
    <div class="volvo-text">
      <span class="volvo-text-medium">{{
        "uk/pages/buyersGuide/volvoFH/text4" | lowercase | translate
      }}</span
      >{{ "uk/pages/buyersGuide/volvoFH/text5" | lowercase | translate }}
    </div>
    <div class="volvo-text">
      <span>
        {{ "uk/pages/buyersGuide/volvoFH/text6" | lowercase | translate }}
      </span>
    </div>
    <div class="volvo-text">
      <span class="volvo-text-medium">{{
        "uk/pages/buyersGuide/volvoFH/text7" | lowercase | translate
      }}</span
      >{{ "uk/pages/buyersGuide/volvoFH/text8" | lowercase | translate }}
    </div>
    <div class="volvo-text">
      <span class="volvo-text-medium">{{
        "uk/pages/buyersGuide/volvoFH/text9" | lowercase | translate
      }}</span
      >{{ "uk/pages/buyersGuide/volvoFH/text10" | lowercase | translate }}
    </div>
    <div class="volvo-text">
      <ul>
        <li>{{ "uk/pages/buyersGuide/volvoFH/list1" | lowercase | translate }}</li>
        <li>{{ "uk/pages/buyersGuide/volvoFH/list2" | lowercase | translate }}</li>
        <li>{{ "uk/pages/buyersGuide/volvoFH/list3" | lowercase | translate }}</li>
        <li>{{ "uk/pages/buyersGuide/volvoFH/list4" | lowercase | translate }}</li>
        <li>{{ "uk/pages/buyersGuide/volvoFH/list5" | lowercase | translate }}</li>
        <li>{{ "uk/pages/buyersGuide/volvoFH/list6" | lowercase | translate }}</li>
        <li>{{ "uk/pages/buyersGuide/volvoFH/list7" | lowercase | translate }}</li>
        <li>{{ "uk/pages/buyersGuide/volvoFH/list8" | lowercase | translate }}</li>
      </ul>
    </div>
    <div class="volvo-text">
      <span>
        {{ "uk/pages/buyersGuide/volvoFH/text11" | lowercase | translate }}
      </span>
    </div>
  </div>
</section>
<volvo-footer></volvo-footer>
