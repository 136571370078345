import {animate, animateChild, query, stagger, state, style, transition, trigger} from '@angular/animations';


export enum VisibilityState {
    Visible = 'visible',
    Hidden = 'hidden'
}


export const routerTransition =
    trigger('routerTransition', [
        transition('* => *', [
            query(
                ':enter',
                [style({opacity: 0})],
                {optional: true}
            ),
            query(
                ':leave',
                [style({opacity: 1}), animate('0.4s', style({opacity: 0}))],
                {optional: true}
            ),
            query(
                ':enter',
                [style({opacity: 0}), animate('0.4s', style({opacity: 1}))],
                {optional: true}
            )
        ])
    ]);


export const toggle =
    trigger('toggle', [
        state(
            VisibilityState.Hidden,
            style({transform: 'translateY(-100%)'})
        ),
        state(
            VisibilityState.Visible,
            style({transform: 'translateY(0)'})
        ),
        transition('* => *', animate('200ms ease-in'))
    ]);


export const toggleOpacity =
    trigger('toggleOpacity', [
        state(
            VisibilityState.Hidden,
            style({display: 'none', height: '0px', opacity: '0'})
        ),
        state(
            VisibilityState.Visible,
            style({height: '*', opacity: '1'})
        ),


        transition(`${VisibilityState.Hidden} => ${VisibilityState.Visible}`, [
            style({display: 'block'}),
            animate('200ms ease-in')
        ]),


        transition('* => *', animate('200ms ease-in'))
    ]);


export const listItem =
    trigger('listItem', [
        transition(':enter', [
            style({opacity: '0.5', filter: 'blur(5px)'}),
            animate('500ms ease-out', style({opacity: '1', filter: 'blur(0)'})),
        ]),
        transition(':leave', [
            style({opacity: '1', filter: 'blur(0)'}),
            animate('500ms ease-out', style({opacity: '0.5', filter: 'blur(5px)'})),
        ])
    ]);


export const toggleHeight =
    trigger('toggleHeight', [
        transition(
            ':enter',
            [
                style({height: 0, opacity: 0}),
                animate('300ms ease-out',
                    style({height: '*', opacity: 1}))
            ]
        ),
        transition(
            ':leave',
            [
                style({height: '*', opacity: 1}),
                animate('300ms ease-in',
                    style({height: 0, opacity: 0}))
            ]
        )
    ]);


export const listEnterLeave =
    trigger('listEnterLeave', [
        transition(':enter', [
            query('@listEnterLeaveItem', stagger(200, animateChild()))
        ]),
    ]);


export const listEnterLeaveItem =
    trigger('listEnterLeaveItem', [
        transition(':enter', [
            style({transform: 'scale(0.5)', opacity: 0}),  // initial
            animate('200ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
                style({transform: 'scale(1)', opacity: 1}))  // final
        ]),
        transition(':leave', [
            style({transform: 'scale(1)', opacity: 1, height: '*'}),
            animate('200ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
                style({
                    transform: 'scale(0.5)', opacity: 0,
                    height: '0px', margin: '0px'
                }))
        ])
    ]);
