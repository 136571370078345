<!-- <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch Search-input">
  <input (keyup)="onSearchInputKeyup()" (change)="onSearch()" [matAutocomplete]="auto" [(ngModel)]="searchInput"
    matInput [placeholder]="'components/searchInput' | lowercase | translate" data-cy="search-text"
    class="searchTruck" />

</mat-form-field> -->
<!-- 
<button *ngIf="searchButtonVisible" (click)="onSearch()" mat-raised-button color="primary"
  class="MatBtn SearchInput-btn">
  <i class="fas fa-search"></i>
</button> -->
<mat-autocomplete #auto="matAutocomplete">
  <mat-option *ngFor="let item of autocompleteBuckets" (click)="onSearch()" [value]="item.key"
    class="SearchInput-option" data-cy="search-items">
    <span>{{ item.key }}</span>
    <span>{{ item.doc_count }}</span>
  </mat-option>
</mat-autocomplete>
