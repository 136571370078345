import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationQuery } from 'src/app/core/models/notification-query.model';
import { UnsubscribeResponse }from 'src/app/core/models/unsubscribe-feeback-query.model';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private httpClient: HttpClient) {}
  saveNotificationData(notificationQuery: NotificationQuery) {
    return this.httpClient.post<any>(environment.apiUrl + 'NotificationRule/notificationrule', notificationQuery, {
      observe: 'response'
    });
  }
  unsubscribeNotificationData(notificationId:string) {
    return this.httpClient.put<any>(environment.apiUrl + 'NotificationRule/UnSubscribeNotification/'+notificationId, {
      observe: 'response'
    });
  }
  submitUnsubscribeFeeback(unsubscribeResponseList: UnsubscribeResponse) {
    return this.httpClient.put<any>(environment.apiUrl + 'NotificationRule/SaveUnsubscribeResponses', unsubscribeResponseList, {
      observe: 'response'
    });
  }
}
