import { Component } from '@angular/core';
import { SearchStateFilter } from '../../../core/store/search/search.model';
import { SearchService } from '../../../core/store/search/search.service';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TuckModelPopupComponent } from '../../../popups/truck-model-popup/truck-model-popup.component';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})

export class TruckModelService {
  search: SearchStateFilter;
  private s1: Subscription;
  constructor(
    private searchService: SearchService,
    private dialog: MatDialog
  ) {
    this.initSubscriptions();
  }
  searchTruck(make:string,model:string): void {
    this.search.steps.make.isSelected = true;
    this.search.steps.make.value = make;
    this.search.steps.make.isExpanded = true;
    this.search.steps.model.isSelected = true;
    this.search.steps.model.value = model;
    this.search.steps.model.isExpanded = true;

    this.searchService.updateSearch(this.search);
  }
  private initSubscriptions(): void {
    this.s1 = this.searchService.filter$
      .subscribe((searchOptions: SearchStateFilter) => {
        this.search = cloneDeep(searchOptions);
      });
  }
  leftBoundStat(reachesLeftBound: boolean) {
    return reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    return reachesRightBound;
  }

  openDialog(type:string,engineObj:any) {
    if(type==='engine')
    {
      this.dialog.open(TuckModelPopupComponent, {
        panelClass: 'volvo-notification-popup',
        position: { top: 'top' },
        data:engineObj
      });
    }
    else{
      this.dialog.open(TuckModelPopupComponent, {
        panelClass: 'volvo-notification-popup',
        position: { top: 'top' },
        data:engineObj
      });
    }
  }
}