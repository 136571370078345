<i (click)="onClose()" class="PopupOLD-close material-icons">close</i>

<div class="PopupOLD">
  <h3 class="PopupOLD-title">{{ 'popups/contact/title' | lowercase | translate }}</h3>
  <h5 class="PopupOLD-subtitle">{{ 'popups/contact/subtitle' | lowercase | translate }}</h5>

  <h3 class="PopupOLD-phone">
    <volvo-phone phone="+46 31-666000"></volvo-phone>
  </h3>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="PopupOLD-form">
    <div class="PopupOLD-input">
      <h6 class="PopupOLD-inputTitle">{{ 'form/fullName' | lowercase | translate }}</h6>
      <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
        <input formControlName="name" matInput />
      </mat-form-field>
    </div>

    <div class="PopupOLD-input">
      <h6 class="PopupOLD-inputTitle">{{ 'form/phoneNumber' | lowercase | translate }}</h6>
      <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
        <input formControlName="phone" matInput />
      </mat-form-field>
    </div>

    <div class="PopupOLD-input">
      <h6 class="PopupOLD-inputTitle">{{ 'form/email' | lowercase | translate }}</h6>
      <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
        <input formControlName="email" matInput />
      </mat-form-field>
    </div>

    <div class="PopupOLD-input">
      <h6 class="PopupOLD-inputTitle">
        {{ 'form/whatCanWeHelpYouWith' | lowercase | translate }} <span>({{ 'form/optional' | lowercase | translate
          }})</span>
      </h6>
      <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
        <textarea formControlName="message" matInput rows="5"></textarea>
      </mat-form-field>
    </div>

    <div class="PopupOLD-privacy tal" data-cy="subscribe-newsletter">
      <mat-checkbox formControlName="subscribe" class="big"></mat-checkbox>
      <span class="PopupOLD-link">{{ 'form/subscribeToOurNewsletter' | lowercase | translate }}</span>
    </div>
    <div class="PopupOLD-privacy tal">
      <mat-checkbox formControlName="privacy" class="big"></mat-checkbox>
      <a href="https://www.volvogroup.com/en-en/privacy.html" target="_blank" class="PopupOLD-link">
        {{ 'form/contactingVolvoPrivacyPolicy' | lowercase | translate }}
      </a>
    </div>

    <div class="PopupOLD-submit">
      <button [disabled]="form.invalid" type="submit" mat-button color="primary" class="BigBtn">
        {{ 'form/send' | lowercase | translate }}
      </button>
    </div>
  </form>
</div>
