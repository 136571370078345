<header class="Popup-header CPrimary">
  <b>{{ 'popups/sort/title' | lowercase | translate }}</b>
</header>

<div class="Popup-wp Popup-WithFooter">
  <button (click)="onSortChange(SortOptions.highestPrice)" [class.Active]="SortOptions.highestPrice === search.sort"
    mat-button class="MatBtn w100p CPrimary">
    {{ 'popups/sort/highestPrice' | lowercase | translate }}
  </button>
  <button (click)="onSortChange(SortOptions.lowestPrice)" [class.Active]="SortOptions.lowestPrice === search.sort"
    mat-button class="MatBtn w100p CPrimary">
    {{ 'popups/sort/lowestPrice' | lowercase | translate }}
  </button>
  <button (click)="onSortChange(SortOptions.oldestAge)" [class.Active]="SortOptions.oldestAge === search.sort"
    mat-button class="MatBtn w100p CPrimary">
    {{ 'popups/sort/newInStock' | lowercase | translate }}
  </button>
</div>

<div class="Popup-footer">
  <button (click)="onClose()" mat-button class="Popup-close">
    {{ 'global/close' | lowercase | translate }}
  </button>
</div>
