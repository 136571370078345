import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  filterJson: any;
  defaultFilterObj: any;
  defaultFilterTotals: any;
  filterTotalsJson: any;
  constructor() { }

  checkFilterBuckets(aggregations: any, filterName: string): void{
    this.InitializeDefaults(aggregations, filterName);
   
    // if(aggregations.requestingFilter === "model"){
    //   if(this.filterTotalsJson.length > 0){
    //     this.filterTotalsJson.forEach(res => {
    //       this.filterJson.push({"key":res.key, "doc_count":res.doc_count});
    //     });
    //     }
    // }
    this.AddDefaultFilters(aggregations, filterName);
  }
  AddDefaultFilters(aggregations: any, filterName: string): void{
    for (let i = 0; i < this.defaultFilterObj.length; i++) {
      
      const hasValue = this.filterJson.some(m => m.key === this.defaultFilterObj[i].key);
       if(!hasValue)
       {
         this.filterJson.push({"key":this.defaultFilterObj[i].key, "doc_count":0});
         this.filterTotalsJson.push({"key":this.defaultFilterObj[i].key, "doc_count":0});
       }
     }
  }
  InitializeDefaults(aggregations: any, filterName: string) : void{
    switch (filterName) {
      case "model":
        this.defaultFilterObj = jsonStringModel;
        this.defaultFilterTotals = jsonStringTotalModels;
        this.filterJson = aggregations.model.buckets;
        this.filterTotalsJson = aggregations.model.totals;
        break;
      case "axleConfiguration":
        this.defaultFilterObj = jsonStringAxle;
        this.defaultFilterTotals = jsonStringTotalAxles;
        this.filterJson = aggregations.axleConfiguration.buckets;
        this.filterTotalsJson = aggregations.axleConfiguration.totals;
        break;
      case "cabType":
        this.defaultFilterObj = jsonStringcabType;
        this.defaultFilterTotals = jsonStringTotalcabTypes;
        this.filterJson = aggregations.cabType.buckets;
        this.filterTotalsJson = aggregations.cabType.totals;
        break;
    }
  }
  checkFilterTotals(aggregations: any, filterName: string): void{
    this.InitializeDefaults(aggregations, filterName);
    if(this.filterTotalsJson.length > 0){
      this.filterTotalsJson.forEach(res => {
        this.filterJson.push({"key":res.key, "doc_count":res.doc_count});
      });
      this.AddDefaultFilters(aggregations, filterName);
     }
  }
}

const jsonStringModel = [
  {key: 'FH', doc_count: 0},
  {key: 'FM', doc_count: 0},
  {key: 'FMX', doc_count: 0},
  {key: 'FE', doc_count: 0},
  {key: 'FL', doc_count: 0}
]
const jsonStringTotalModels = [
  {key: 'FH', doc_count: 0},
  {key: 'FM', doc_count: 0},
  {key: 'FMX', doc_count: 0},
  {key: 'FE', doc_count: 0},
  {key: 'FL', doc_count: 0}
]
const jsonStringAxle = [
  {key: '4x2', doc_count: 0},
  {key: '6x2', doc_count: 0},
  {key: '6x4', doc_count: 0},
  {key: '8x2', doc_count: 0},
  {key: '8x4', doc_count: 0}
]
const jsonStringTotalAxles = [
  {key: '4x2', doc_count: 0},
  {key: '6x2', doc_count: 0},
  {key: '6x4', doc_count: 0},
  {key: '8x2', doc_count: 0},
  {key: '8x4', doc_count: 0}
]
const jsonStringcabType = [
  {key: 'Day cab', doc_count: 0},
  {key: 'Sleeper cab', doc_count: 0},
  {key: 'Sleeper cab high', doc_count: 0},
  {key: 'Sleeper cab X-tra high', doc_count: 0}
]
const jsonStringTotalcabTypes = [
  {key: 'Day cab', doc_count: 0},
  {key: 'Sleeper cab', doc_count: 0},
  {key: 'Sleeper cab high', doc_count: 0},
  {key: 'Sleeper cab X-tra high', doc_count: 0}
]