<section class="vehicleOffer">
      <div *ngIf="!isTablet">
            <div class="vehicleOffer-div">
                  <volvo-vehicle-offer-remaining-warranty-page id="remainingWarranty"></volvo-vehicle-offer-remaining-warranty-page>
                  <volvo-vehicle-offer-selected-page ></volvo-vehicle-offer-selected-page>
                  <volvo-vehicle-offer-approved-page></volvo-vehicle-offer-approved-page>
                  <volvo-vehicle-offer-economy-page ></volvo-vehicle-offer-economy-page>
            </div>
      </div>
</section>
<div *ngIf="isTablet" class="mobileDiv">
      <volvo-vehicle-offer-link-page></volvo-vehicle-offer-link-page>
</div>

<volvo-footer></volvo-footer>