import { ErrorHandler, Injectable } from '@angular/core';

import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { environment } from '../../../environments/environment';


@Injectable()
export class ErrorInterceptor implements ErrorHandler {

    constructor(
        private globalUtils: GlobalUtils
    ) {
    }

    handleError(error: any): void {
        // if (this.globalUtils.isBrowser() && environment.production) {
        // }

        throw error;
    }
}
