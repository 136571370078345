import { Component,OnInit } from '@angular/core';
import { HtmlRoutePages } from '../../../app-routing.model';
import { ActivatedRoute, Router } from '@angular/router';
import { regionEnum } from '../../../core/declaration/declaration';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-service-link-page',
  templateUrl: './service-link-page.component.html',
  styleUrls: ['./service-link-page.component.scss']
})
export class ServiceLinkPageComponent implements OnInit {
  showWarrantyText: boolean = false;
  showSelectedText: boolean = false;
  showEconomyText: boolean = false;
  showText:boolean=false;
  rightImage:string='';
  region:string;
  regionEnum=regionEnum;
    txLinks = [];
    selectedLinks = [];
    uknsLinks = [];

  constructor(private route: ActivatedRoute, private router: Router, private regionalSettingsService:RegionalSettingsService,private localizeRouterService: LocalizeRouterService, public assetService: AssetService) {}
  routePage(link): void {
    this.router.navigate([this.localizeRouterService.translateRoute(link.path)], { relativeTo: this.route });
  }
  ngOnInit(): void {
      this.uknsLinks = [
          { label: 'uk/pages/services/volvoSelected', path: HtmlRoutePages.serviceApproved },
          { label: 'uk/pages/services/volvoEconomy', path: HtmlRoutePages.serviceEconomy },
          { label: 'uk/components/footer/financing', path: HtmlRoutePages.finance },
          { label: 'uk/components/footer/serviceContracts', path: HtmlRoutePages.serviceContract },
          { label: 'uk/components/footer/customisation', path: HtmlRoutePages.customisation },
          { label: 'uk/components/footer/volvoconnect', path: HtmlRoutePages.volvoConnect }
      ];
    this.txLinks = [
        { label: 'pages/services/export', path: HtmlRoutePages.serviceExport },
          { label: 'pages/services/utcustomisation', path: HtmlRoutePages.serviceCustomisation }];
    this.selectedLinks = [
        { label: 'uk/pages/services/volvoSelected', path: HtmlRoutePages.volvoSelected },
        { label: 'uk/components/footer/financing', path: HtmlRoutePages.financing }];
  
}
}
