import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, delay, switchMap, map } from 'rxjs/operators';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { BreadCrumbLabels } from '../../../app-routing.model';
import { Observable, of, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class CommonBreadcrumbService {

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private breadcrumbService: NgDynamicBreadcrumbService,
        private translateService: TranslateService
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            switchMap(() => of(this.router.url).pipe(delay(100)))
        ).subscribe(() => {
            this.updateBreadcrumbs();
        });
    }

    private updateBreadcrumbs() {
        const path = this.router.url;        
        //If it is trucks page dont update breadcrumb from here, the logic is differnt
        if (this.checkTruckModelUrl(path)) {
            //do nothing
        }
        else {            
            this.createBreadcrumbs(path).subscribe(breadcrumbs => {
                this.breadcrumbService.updateBreadcrumb(breadcrumbs);
            });
        }
    }

    //private oldCreateBreadcrumbs(url: string): Array<{ label: string, url: string }> {
    //    const breadcrumbs: Array<{ label: string, url: string }> = [];
    //    const pathSegments = url.split('/').filter(segment => segment);

    //    pathSegments.forEach((segment, index) => {
    //        const breadcrumbURL = '/' + pathSegments.slice(0, index + 1).join('/');
    //        const breadcrumbLabel = this.getBreadcrumbLabel(segment);
    //        breadcrumbs.push({ label: breadcrumbLabel, url: breadcrumbURL });
    //    });

    //    return breadcrumbs.slice(1);
    //}
    private createBreadcrumbs(url: string): Observable<Array<{ label: string, url: string }>> {
        const pathSegments = url.split('/').filter(segment => segment);

        const breadcrumbObservables = pathSegments.map((segment, index) => {
            const breadcrumbURL = '/' + pathSegments.slice(0, index + 1).join('/');
            return this.getBreadcrumbLabel(segment).pipe(
                map(label => ({ label, url: breadcrumbURL }))
            );
        });

        return forkJoin(breadcrumbObservables).pipe(
            map(breadcrumbsArray => breadcrumbsArray.slice(1))
        );
    }

    private getBreadcrumbLabel(segment: string): Observable<string> {        
        const cleanSegment = segment.split('?')[0];  
        const segmentLabel = BreadCrumbLabels[cleanSegment] || cleanSegment;
        if (segmentLabel.includes('/')) {            
            return of(this.translateService.instant(segmentLabel.toLowerCase()));   
        }
        else {
            return of(segmentLabel);
        }
        
    }


    private checkTruckModelUrl(path: string): boolean {
        return (path.includes('/search/truck/') || path.includes("?make=") || path.includes("print"));
    }
}
