import { Component } from '@angular/core';
import { HtmlRoutePages } from '../../../app-routing.model';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-vehicle-offer-link-page',
  templateUrl: './vehicle-offer-link-page.component.html',
  styleUrls: ['./vehicle-offer-link-page.component.scss']
})
export class VehicleOfferLinkPageComponent {
  showWarrantyText: boolean = false;
  showSelectedText: boolean = false;
  showEconomyText: boolean = false;
  showText:boolean=false;
  rightImage:string='';
  constructor(private route: ActivatedRoute, private router: Router,private localizeRouterService: LocalizeRouterService, public assetService: AssetService) {}
  links = [
    {label: 'uk/components/footer/remainingWarranty', path:HtmlRoutePages.vehicleWarranty},
    { label: 'uk/components/footer/volvoSelected', path: HtmlRoutePages.vehicleSelected},
    { label: 'uk/components/footer/volvoApproved', path: HtmlRoutePages.vehicleApproved },
    { label: 'uk/components/footer/volvoEconomy', path:HtmlRoutePages.vehicleEconomy }
  ];
  routePage(link): void {
    this.router.navigate([this.localizeRouterService.translateRoute(link.path)], { relativeTo: this.route });
  }
}
