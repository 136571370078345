<div class="tabMobSidePadding">
    <section class="FinanceTitleA tabMobHeroMargin">
        <span data-cy="finance-insurance">{{ 'financing/sectionA/title' | lowercase | translate }}</span>
    </section>

    <section class="FinanceA">
        <div class="FinanceA-text" data-cy="finance-insurancetext">
            {{ 'financing/sectionA/text' | lowercase | translate }}
        </div>
        <div class="buttonCss">
            <button volvoScrollTo="quote"
                    data-cy="finance-insurancequote">
                {{ 'financing/sectionA/btn' | lowercase | translate }}
            </button>
        </div>
    </section>

    <section class="FinanceB">
        <div class="FinanceB-item">
            <div class="FinanceB-itemInfo">
                <div>
                    <div [innerHTML]="'financing/sectionB/itemA/title' | lowercase | translate" class="FinanceB-itemTitle"></div>
                    <div [innerHTML]="'financing/sectionB/itemA/text' | lowercase | translate" class="FinanceB-itemText"></div>
                </div>
            </div>

            <div class="FinanceB-itemImg">
                <img [src]="assetService.getAssetUrl('assets/pages/finance/finance1.webp')" alt="" />
            </div>
        </div>

        <div class="FinanceB-item isOdd" *ngIf="autoSelectCountry.id != 'fi' && autoSelectCountry.id != 'mk' && autoSelectCountry.id != 'no' && autoSelectCountry.id != 'rs'">
            <div class="FinanceB-itemImg">
                <img [src]="assetService.getAssetUrl('assets/pages/finance/finance2.webp')" alt="" />
            </div>

            <div class="FinanceB-itemInfo">
                <div>
                    <div [innerHTML]="'financing/sectionB/itemB/title' | lowercase | translate" class="FinanceB-itemTitle"></div>
                    <div [innerHTML]="'financing/sectionB/itemB/text' | lowercase | translate" class="FinanceB-itemText"></div>
                </div>
            </div>
        </div>

        <div class="FinanceB-item">
            <div class="FinanceB-itemInfo">
                <div>
                    <div [innerHTML]="'financing/sectionB/itemC/title' | lowercase | translate" class="FinanceB-itemTitle"></div>
                    <div [innerHTML]="'financing/sectionB/itemC/textA' | lowercase | translate" class="FinanceB-itemText"></div>
                    <div [innerHTML]="'financing/sectionB/itemC/textB' | lowercase | translate" class="FinanceB-itemText"></div>
                    <div [innerHTML]="'financing/sectionB/itemC/textC' | lowercase | translate" class="FinanceB-itemText"></div>
                    <div [innerHTML]="'financing/sectionB/itemC/textD' | lowercase | translate" class="FinanceB-itemText"></div>
                </div>
            </div>

            <div class="FinanceB-itemImg">
                <img [src]="assetService.getAssetUrl('assets/pages/finance/finance3.webp')" alt="" />
            </div>
        </div>
    </section>

    <section id="quote" class="FinanceC tabMobHeroMarginwithPadding">
        <div class="FinanceC-title CPrimary" data-cy="finance-quotetitle">
            {{ 'financing/sectionC/title' | lowercase | translate }}
        </div>
        <div class="FinanceC-text" data-cy="finance-quotetext">{{ 'financing/sectionC/text' | lowercase | translate }}</div>
        <mat-horizontal-stepper linear #stepper>
            <mat-step [stepControl]="formOne" errorMessage="Name is required.">
                <form [formGroup]="formOne">
                    <ng-template matStepLabel class="volvofontweight">
                        <span class="volvofontmedium CPrimary">
                            {{ 'form/companyInformation' | lowercase | translate }}
                        </span>
                    </ng-template>
                    <div class="FinanceC-formTitle"></div>

                    <div class="PopupOLD-input">
                        <h6 class="PopupOLD-inputTitle" data-cy="finance-companyfullname">
                            {{ 'form/fullCompanyName' | lowercase | translate }}
                        </h6>
                        <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
                            <input formControlName="companyName" matInput data-cy="finance-companynameinput" />
                        </mat-form-field>
                    </div>
                    <div class="PopupOLD-input">
                        <h6 class="PopupOLD-inputTitle" data-cy="finance-companyaddress">
                            {{ 'form/address' | lowercase | translate }}
                        </h6>
                        <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
                            <input formControlName="address" matInput data-cy="finance-companyaddressinput" />
                        </mat-form-field>
                    </div>
                    <div class="PopupOLD-input">
                        <h6 class="PopupOLD-inputTitle" data-cy="finance-countryname">
                            {{ 'form/country' | lowercase | translate }}
                            <span class="volvofont">
                                <i matTooltip="{{ 'form/country/info' | lowercase | translate }}" class="fas fa-info-circle CPrimary"></i>
                            </span>
                        </h6>
                        <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
                            <mat-select class="volvofont CPrimary"
                                        placeholder="{{ 'form/dropdownCountry' | lowercase | translate }}"
                                        formControlName="country"
                                        data-cy="finance-dealershipcountry"
                                        [(ngModel)]="autoSelectCountry.id">
                                <mat-option class="Option volvofont CPrimary"
                                            *ngFor="let country of dealershipCountries"
                                            [value]="country.id"
                                            data-cy="finance-dealershipname">
                                    {{ country.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="buttonCss" style="text-align: right;">
                        <button matStepperNext
                                data-cy="finance-btnnext" [ngClass]="formOne.invalid?'buttonCss-btnDisbaleColor':''">
                            {{ 'globalMiss/next' | lowercase | translate }}
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="formTwo" errorMessage="These fields are required.">
                <form [formGroup]="formTwo" (ngSubmit)="onSubmit()">
                    <ng-template matStepLabel class="volvofontweight">
                        <span class="volvofontmedium CPrimary">
                            {{ 'form/contactInformation' | lowercase | translate }}
                        </span>
                    </ng-template>

                    <div class="PopupOLD-input">
                        <h6 class="PopupOLD-inputTitle" data-cy="finance-contactfullname">
                            {{ 'form/fullName' | lowercase | translate }}
                        </h6>
                        <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
                            <input formControlName="fullName" matInput data-cy="finance-contactnameinput" />
                        </mat-form-field>
                    </div>
                    <div class="PopupOLD-input">
                        <h6 class="PopupOLD-inputTitle" data-cy="finance-contactphnumber">
                            {{ 'form/phoneNumber' | lowercase | translate }}
                        </h6>
                        <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
                            <input formControlName="phone" matInput data-cy="finance-contactphnumberinput" />
                        </mat-form-field>
                    </div>
                    <div class="PopupOLD-input">
                        <h6 class="PopupOLD-inputTitle" data-cy="finance-contactemail">
                            {{ 'form/email' | lowercase | translate }}
                        </h6>
                        <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
                            <input formControlName="email" matInput data-cy="finance-contactemailinput" />
                        </mat-form-field>
                    </div>
                    <div class="PopupOLD-input">
                        <h6 class="PopupOLD-inputTitle" data-cy="finance-contactinfo">
                            {{ 'form/additionalInformation' | lowercase | translate }}
                            <span>({{ 'form/optional' | lowercase | translate }})</span>
                        </h6>
                        <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
                            <textarea formControlName="message" matInput rows="5" data-cy="finance-contactinfoinput"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="MatInputSearchVfs checkboxPadding">
                        <mat-checkbox formControlName="privacy" (click)="recaptcha()" class="big" data-cy="contactpage-privacychkbox">
                            <div class="Vfs-privacy">
                                {{ 'form/contactingVolvoPrivacyPolicy' | lowercase | translate }}
                                <a class="regularFont" href="{{ checkbox }}" target="_blank" style="text-decoration: underline;">
                                    <span>{{ 'form/privacyPolicy' | lowercase | translate }}</span>
                                </a>
                            </div>
                        </mat-checkbox>
                    </div>

                    <div *ngIf="showRecaptcha"
                         class="PopupOLD-input"
                         style="margin-bottom: 15px;display: inline-block;padding-left: 6rem;">
                        <re-captcha (resolved)="resolved($event)" siteKey="{{ recaptchaToken }}" required data-cy="finance-recaptcha">
                        </re-captcha>
                    </div>

                    <div class="buttonCss">
                        <button type="submit"
                                data-cy="finance-submitbtn" [ngClass]="formTwo.invalid || !reCaptchaRes?'buttonCss-btnDisbaleColor':''">
                            {{ sendStatus ? 'Sending...' : ('form/sendMessage' | lowercase | translate) }}
                        </button>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </section>
    <section class="FinanceD"></section>
</div>
    <volvo-footer></volvo-footer>

