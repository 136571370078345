import { AfterViewInit, Component, OnDestroy, OnInit, Input } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

import { SearchStateFilter, SearchStepKeys } from '~/core/store/search/search.model';
import { SearchService } from '~/core/store/search/search.service';
import { HtmlRoutePages } from '~/app-routing.model';
import { SearchState } from '../../../core/store/search/search.state';
import { Store } from '@ngxs/store';
import { AppState } from 'src/app/core/store';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { v4 as uuidv4 } from 'uuid';

@AutoUnsubscribe()
@Component({
  selector: 'volvo-search-chip',
  templateUrl: './search-chip.component.html',
  styleUrls: ['./search-chip.component.scss']
})
export class SearchChipComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() fromNotification: boolean;

  HtmlRoutePages = HtmlRoutePages;
  SearchStepKeys = SearchStepKeys;

  search: SearchStateFilter;

  region: string;
  regionSettings: RegionSettings;
  private s1: Subscription;
  selectedFiltersCount: number = 0;
  dealershipLocationCount: number = 0;
  truckLocations: string = '';
  constructor(private searchService: SearchService, private searchState: SearchState, private store: Store) {
    this.region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    this.regionSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === this.region)[0]);
    
   }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void { }

  ngAfterViewInit() { }

  resetStep(stepKey: string, chipToRemove: string): void {
    if (stepKey === 'stockLocationCountry')
      sessionStorage.setItem('resetCountry', 'true');
      this.searchService.resetStep(stepKey, chipToRemove);
  }

  resetAllFilters(): void {
    //TODO CLEAR LOCAL STORAGE
    localStorage.setItem('searchParam', '');
    sessionStorage.setItem('resetCountry', 'true');
    localStorage.setItem('offerType', '');
    this.searchService.resetSearch();

    //The qf in url is set to empty to solve the refresh issue on click of reset filter. The filters were retained after reset and refresh as qf in url was not set to empty.
    this.searchState.updateUrlParams({ qf: '' });
  }

  resetSearchQ(): void {
    this.searchService.updateSearch({
      ...this.search,
      q: ''
    });
  }

  private initSubscriptions(): void {
    this.s1 = this.searchService.filter$.subscribe((searchOptions: SearchStateFilter) => {
      this.search = searchOptions;
      if (this.regionSettings.region === 'ZA') {
        var searchObj;
        searchObj = JSON.parse(JSON.stringify(this.search));
        searchObj.steps.priceExclVatEuro.options.units = 'R';
        this.search = searchObj;
      }
      this.selectedFiltersCount = 0;

      Object.values(searchOptions.steps).forEach(item => {
        // Update counts based on item visibility and selection
        if (item.isSelected) {
          if (item.title === 'Dealership Location') {
            // Set the count of dealership locations based on the number of entries in the item value
            this.dealershipLocationCount = item.isVisible ? item.value.split(';').length : 0;
          } else if (item.title === 'Truck Location') {
            // Update truck locations only if the item is visible
            this.truckLocations = item.isVisible ? item.value : '';
          }
        
          // Increase the selected filters count if the item is visible
          if (item.isVisible) {
            this.selectedFiltersCount++;
          }
        } else {
          // Reset values if the item is not selected
          if (item.title === 'Dealership Location') {
            this.dealershipLocationCount = 0;
          } else if (item.title === 'Truck Location') {
            this.truckLocations = '';
          }
        }
      });
    });
  }
  displayMinMaxValue(minValue,maxValue)
  {
    return Number(minValue).toLocaleString('de-DE')+' - '+Number(maxValue).toLocaleString('de-DE');
    }    
    checkAndSplit(value: string | any): any[] {
        if (typeof value === 'string' && value.includes(';')) {
            return value.split(';');
        }
        return [value];  // Always return an array
    }
}
