import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom, Observable, Subscription, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../auth/token.service';
import { TranslateService } from '@ngx-translate/core';
import { Location,DOCUMENT } from '@angular/common';
import { LocalesService } from '~/core/store/locales/locales.service';
import { Store } from '@ngxs/store';
import { LocalesStateInterface } from '~/core/store/locales/locales.model';
import { AppState } from '~/core/store';
import { observableToBeFn, subscriptionLogsToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { RegionalSettingsService } from '~/core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '~/core/declaration/declaration';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  tokenService: TokenService;
  langInited: boolean = true;
  s0: Subscription;
  region: string;
  langCode: string;
  regionEnum = regionEnum;
  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private location: Location,
    private localesService: LocalesService,
    private store: Store,
    private regionSettingService: RegionalSettingsService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.tokenService = new TokenService(httpClient, location, localesService);
    this.initSubscriptions();
  }
  async initSubscriptions() {
    // const langCode = this.getCurrentLanguage();
    const txSiteUrls = new Set([
      'volvousedtrucks.co.uk',
      'tradeandexport.volvotrucks.com',
      'tradeandexporttrucks.co.uk',
      'tradeandexporttrucks.com',
      'usedtrucks.volvonorthandscotland.co.uk',
      'localhost'
    ]);
    
    switch (window.location.hostname.replace('www.', '')) {
      case environment.ukSiteUrl:
        this.region = regionEnum.UK;
        this.langCode = this.getCurrentLanguage();
        break;
      case environment.txGreenSiteUrl:
        this.region = regionEnum.TX;
        this.langCode = this.getCurrentLanguage();
        break;
      case environment.selectedSiteUrl:
        this.region = regionEnum.Selected;
        this.langCode = this.getCurrentLanguage();
        break;
      default:
        if (txSiteUrls.has(window.location.hostname.replace('www.', ''))) {
          this.region = regionEnum.TX;
          this.langCode = this.getCurrentLanguage();
        }
        break;
    }

    await this.tokenService.load(true);
    if (!this.s0) {
      this.s0 = this.getLanguage(this.langCode, this.tokenService.getToken()).subscribe((data) => {
        this.translateService.setTranslation(this.langCode, data, true);
        this.langInited = true;
      });
    }
  }

  getCurrentLanguage(): string {
    try {
      const langCode = this.store.selectSnapshot<LocalesStateInterface>((state: AppState) => state.language).lang
      .curLang;
    return langCode.id; 
    } catch (error) {
      
    }
  }

  public getLanguage(langCode: string, token: string = undefined): Observable<any> {
    if (!token) {
      
      return this.httpClient.get(environment.apiUrl + 'Languages/' + langCode);
    } else {
      let headers = {
        requestingsite: 'Selected',
        authorization: 'Bearer ' + this.tokenService.getToken(),
        'Cache-Control': 'no-store, no-cache'
      };
      
      return this.httpClient.get(environment.apiUrl + 'Languages/' + langCode, { headers: headers });
    }
  }

  getCurrentRegion(): string {
    const regionCode = this.store.selectSnapshot<LocalesStateInterface>((state: AppState) => state.language).region
      .curRegion;
    return regionCode.id;
  }

  async loadTrans(lang: string = undefined) {

  }

  translateText(fromLang: string, toLang: string, text: string): Observable<any> {
    var transalateObj = new ItemTransalateDto()
    transalateObj.fromLang = fromLang,
      transalateObj.toLang = toLang,
      transalateObj.text = text

    return this.httpClient.post<any>(environment.apiUrl + 'Google/transalate', transalateObj, { observe: 'response' });
    }

    translateEnglishtoCurrentLang(text: string): Observable<any> {
        var transalateObj = new ItemTransalateDto()
        transalateObj.fromLang = 'en',
            transalateObj.toLang = this.getLanguageCode(this.getCurrentLanguage()),
            transalateObj.text = text        
        return this.httpClient.post<any>(environment.apiUrl + 'Google/transalate', transalateObj, { observe: 'response' });
    }
    getLanguageCode(langCode: string) {
        let inputLang = langCode
        switch (langCode) {
            case "cz":
                inputLang = "cs";
                break;
            case "dk":
                inputLang = "da";
                break;
            case "at":
                inputLang = "de";
                break;
            case "ba":
                inputLang = "bs";
                break;
            case "rs":
                inputLang = "sr";
                break;
            case "be-nl":
                inputLang = "nl";
                break;
            case "be-fr":
                inputLang = "fr";
                break;
            case "se":
                inputLang = "sv";
                break;
            case "ch-de":
                inputLang = "de";
                break;
            case "ch-fr":
                inputLang = "fr";
                break;
            case "ch-it":
                inputLang = "it";
                break;
            case "si":
                inputLang = "sl";
                break;
            default:
                inputLang = inputLang
                break;
        }
        return inputLang;        
    }
}

export class ItemTransalateDto {
  public fromLang: string;
  public toLang: string;
  public text: string;
}
