import {distinctUntilChanged, filter, map, pairwise, share, throttleTime} from 'rxjs/operators';
import {AfterViewInit, Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {fromEvent, Subscription} from 'rxjs';

import {GlobalUtils} from '~/core/utils/global-utils/global-utils';
import {toggleOpacity, VisibilityState} from '~/animations';


enum Direction {
    Hide = 'Hide',
    Show = 'Show'
}

@AutoUnsubscribe()
@Component({
    selector: 'volvo-scroll-top',
    templateUrl: './scroll-top.component.html',
    styleUrls: ['./scroll-top.component.scss'],
    animations: [toggleOpacity]
})
export class ScrollTopComponent implements AfterViewInit, OnDestroy, OnInit {
    private s1: Subscription;
    private s2: Subscription;
    private visibility = false;

    @HostBinding('@toggleOpacity')
    get toggleOpacity(): VisibilityState {
        return this.visibility ? VisibilityState.Visible : VisibilityState.Hidden;
    }

    constructor(
        private globalUtils: GlobalUtils
    ) {
    }

    ngAfterViewInit(): void {
        this.toggleComponentOnScroll();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    onScrollToTop(): void {
        this.globalUtils.gtaEvent('scrollTop');

        if (this.globalUtils.isBrowser()) {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    private toggleComponentOnScroll(): void {
        if (this.globalUtils.isBrowser()) {
            const componentInvisibleUntil = innerHeight;

            const scroll$ = fromEvent(window, 'scroll').pipe(
                throttleTime(10),
                map(() => window.pageYOffset),
                pairwise(),
                map(([y1, y2]): Direction => {
                    return y2 >= componentInvisibleUntil ? Direction.Hide : Direction.Show;
                }),
                distinctUntilChanged(),
                share()
            );

            const scrollHide$ = scroll$.pipe(
                filter((direction) => direction === Direction.Hide)
            );

            const scrollShow$ = scroll$.pipe(
                filter((direction) => direction === Direction.Show)
            );


            this.s1 = scrollHide$.subscribe(() => (this.visibility = true));
            this.s2 = scrollShow$.subscribe(() => (this.visibility = false));
        }
    }

}
