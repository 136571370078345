import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Meta} from '@angular/platform-browser';

declare const window: any;

@Injectable({
    providedIn: 'root'
})
export class GlobalUtils {
    constructor(
        @Inject(PLATFORM_ID) private platformId: string,
        private meta: Meta,
    ) {
    }

    isBrowser() {
        return isPlatformBrowser(this.platformId);
    }

    gtaEvent(event: string): void {
        if (this.isBrowser() && window.dataLayer) {
            window.dataLayer.push({pageTitle: location.pathname, event});
        }
    }

    setMetaTags(meta: { [key: string]: string }): void {
        // tslint:disable-next-line:forin
        for (const metaKey in meta) {
            const tagKey = metaKey === 'title' || metaKey === 'description' ? 'name' : 'property';
            this.meta.updateTag({[tagKey]: metaKey, content: meta[metaKey]});
        }
    }
}

