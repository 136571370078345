<div class="tabMobSidePadding">
    <section class="volvo" *ngIf="this.region!==regionEnum.TX">
        <div class="volvo-volvoDiv" id="selectedapproved">
            <div class="volvo-imgdiv">
                <div *ngFor="let image of imageObject">
                    <img class="volvo-volvoImg" [src]="image.image" alt=""/>
                </div>
            </div>
            <div class="volvo-mobile">
                <drag-scroll #slider (indexChanged)="onIndexChanged($event)">
                    <div drag-scroll-item scrollbar-hidden="true" *ngFor="let image of imageObject">
                        <img class="volvo-volvoImg" [src]="image.image" alt=""/>
                    </div>
                </drag-scroll>
                <div class="volvo-buttondiv">
                    <button (click)="slider.moveLeft()" class="volvo-dragscroll">
                        <i class="fa fa-circle" [ngClass]="
              toogleClass ? 'volvo-dragscroll-grey4' : 'volvo-dragscroll-grey1'
            "></i>
                    </button>
                    <button (click)="slider.moveRight()" class="volvo-dragscroll">
                        <i class="fa fa-circle" [ngClass]="
              !toogleClass ? 'volvo-dragscroll-grey4' : 'volvo-dragscroll-grey1'
            "></i>
                    </button>
                </div>
            </div>
            <div class="volvo-title">
                <span>{{ "uk/pages/services/volvoSelected" | lowercase | translate }}</span>
            </div>
            <div class="volvo-text">
                <span>{{ "uk/pages/services/volvoSelected/text1" | lowercase | translate }}</span>
            </div>
            <div class="volvo-text">
                <span class="volvo-text-medium">
                    {{ "uk/pages/services/volvoSelected/text2" | lowercase | translate }}
                </span>{{ "uk/pages/services/volvoSelected/text3" | lowercase | translate }}
            </div>
            <div class="volvo-text">
                <span>
                    {{ "uk/pages/services/volvoSelected/text4" | lowercase | translate }}
                </span>
            </div>
            <div class="volvo-text">
                <div class="volvo-text-listdiv">
                    <ul class="volvo-text-medium" *ngFor="let list of approvedList">
                        <span>{{ list.type | lowercase | translate }}</span>
                        <li>
                            <span>{{ list.name | lowercase | translate }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="volvo-disClaimer">
                <span>
                    {{ "uk/pages/services/volvoSelected/disclaimer" | lowercase | translate }}
                </span>
            </div>
            <div class="volvo-text" id="export">
                <button class="navigation-button faqmargin" (click)="routePage()">
                    <span>
                        <div>
                            <div>
                                <span class="navigation-button-text">
                                    {{ "uk/components/footer/faq" | lowercase | translate }}
                                </span>
                            </div>
                            <div>
                                <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')" alt="Read more"/>
                            </div>
                        </div>
                    </span>
                </button>

                <a href="assets/files/UT Volvo Selected Fact Sheet - April 2024.pdf" target="_blank">
                    <button class="navigation-button">
                        <div>
                            <div>
                                <span class="navigation-button-text">
                                    {{ "uk/pages/services/selectedfact" | lowercase | translate }}
                                </span>
                            </div>
                            <div>
                                <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')" alt="Read more"/>
                            </div>
                        </div>
                    </button>
                </a>

                <a href="assets/files/UT Volvo Approved Fact Sheet - April 2024.pdf" target="_blank">
                    <button class="navigation-button">
                        <div>
                            <div>
                                <span class="navigation-button-text">
                                    {{ "uk/pages/services/approvedfact" | lowercase | translate }}
                                </span>
                            </div>
                            <div>
                                <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')" alt="Read more"/>
                            </div>
                        </div>
                    </button>
                </a>
            </div>
        </div>
        <div>

        </div>
    </section>
    <section class="volvo" *ngIf="this.region===regionEnum.TX">
        <div class="txvolvoDiv">
            <div>
                <img [src]="assetService.getAssetUrl('assets/pages/services/txservice.webp')">
            </div>
            <div class="txvolvoDiv-title">
                <span>{{ "uk/pages/services/volvoselected" | lowercase | translate }}</span>
            </div>
            <div class="txvolvoDiv-text">
                <span [innerHTML]="'uk/pages/services/volvoselected/text1' | lowercase | translate"></span>
            </div>
            <div>
                <div class="tximgdiv txpadding">
                    <div *ngFor="let obj of exportObject">
                        <img [src]="obj.image" />
                        <div class="tximgdiv-divWidth">
                            <div class="txvolvoDiv-text-medium">{{obj.title | lowercase | translate}}</div>
                            <div class="txtext" id="customisation" [innerHTML]="obj.text | lowercase | translate"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
    <volvo-footer></volvo-footer>
