<section class="faq">
  <div class="faq-div">
    <div class="faq-div-title">
      <span>
          {{ "uk/pages/faq/title" | lowercase | translate }} 
      </span>
    </div>
    <div class="faq-div-matPanel">
      <mat-accordion class="VolvoAccordionFAQ" multi="true" *ngIf="this.region!==this.regionEnum.TX" >
        <mat-expansion-panel *ngFor="let faq of faqGroup">
          <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
            <mat-panel-title class="faq-div-text" > {{faq.title | lowercase | translate}}</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-accordion  multi="true">
              <mat-expansion-panel *ngFor="let faq1 of faq.description">
                  <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*" >
                      <mat-panel-title class="faq-div-text1"> {{faq1.title | lowercase | translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                     <!-- <div class="faq-div-text-grey4">{{faq1.text | translate}}</div> -->
                     <div [innerHTML]="faq1.text | translate" class="faq-div-text-grey4"></div> 
              </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>       
      </mat-accordion>
      <mat-accordion class="VolvoAccordionFAQ" multi="true" *ngIf="this.region===this.regionEnum.TX" >
                <mat-expansion-panel *ngFor="let faq of txfaqGroup">
                    <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*" >
                        <mat-panel-title class="faq-div-text1"> {{faq.title | lowercase | translate}}</mat-panel-title>
                      </mat-expansion-panel-header>
                       <!-- <div class="faq-div-text-grey4">{{faq1.text | translate}}</div> -->
                       <div [innerHTML]="faq.text| lowercase | translate" class="faq-div-text-grey4"></div> 
                </mat-expansion-panel>
            </mat-accordion>
    </div>
  </div>
</section>

<volvo-footer></volvo-footer>