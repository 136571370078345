<div class="Truck-container">
  <ng-container *ngIf="!isSkeleton">
    <a [href]="truckUrl" (click)="openTruck($event)" href class="Truck">
      <div id="cf" class="Truck-image" data-cy="vehicle-list">
        <div class="SoldBannerImage-stockpage" *ngIf="truck.isDeleted">{{ 'truck/sold' | lowercase | translate }}</div>
        <img id="image1" *ngIf="truckPicture[1]" class="bottom"
          [src]="imageLoaded?(truckPicture[1] | volvoCdn | escapeUrl):'assets/icons/Imagescomingsoon.png'"
          [alt]="getAltText()" (error)="handleMissingImage($event,1)" />
        <img id="image2" *ngIf="!truckPicture[1] && truckPicture[0]" class="bottom"
          [src]="imageLoaded?(truckPicture[0] | volvoCdn | escapeUrl):'assets/icons/Imagescomingsoon.png'"
          [alt]="getAltText()" (error)="handleMissingImage($event,10)" />
        <img id="image3" *ngIf="truckPicture[0]" class="top"
          [src]="imageLoaded?(truckPicture[0] | volvoCdn | escapeUrl):'assets/icons/Imagescomingsoon.png'"
          (error)="handleMissingImage($event,0)" [alt]="getAltText()"/>
      </div>

      <div *ngIf="cardTypeValue === TruckCardTypes.card && !homePageCardValue" class="Truck-box">
        <div (click)="openTruckPage($event)" class="Truck-titles" data-cy="vehicle-title">
          <h3 class="Truck-title Truck-titleModal" data-testId="truck-title" mat-raised-button
            *ngIf="truck?.specification.make !=='Volvo'"
            matTooltip="{{ truck?.specification.make | hasValue:'':'' }} {{ truck?.specification.model | hasValue:'':'' }}"
            matTooltipPosition="above">
            {{ truck?.specification.make | hasValue:'':'' }} {{ truck?.specification.model | hasValue:'':'' }}
          </h3>
          <h3 class="Truck-title Truck-titleModal" data-testId="truck-title" mat-raised-button
            *ngIf="truck?.specification.make ==='Volvo'"
            matTooltip="{{ truck?.specification.make | hasValue:'':'' }} {{ truck?.specification.model | hasValue:'':'' }} {{truck?.specification.horsePower | hasValue:'':''}}"
            matTooltipPosition="above">
            {{ truck?.specification.make | hasValue:'':'' }} {{ truck?.specification.model | hasValue:'':'' }}
            {{truck?.specification.horsePower
            | hasValue:'':''}}
          </h3>
          <h3 class="Truck-title Truck-titleask Truck-title--blue"
            *ngIf="truck?.ShowPrice && this.curLang.id != 'de' && !truck?.isDeleted && isSelectedSite" data-testId="truck-price"
            data-cy="truck-price">
            <span *ngIf="regionalSettings.zarPriceEnabled; else otherRegion">{{ truck?.pricing?.priceExclVat?.value |
              number: '1.0-0':'de' | hasValue: 'R'}}</span>
            <ng-template #otherRegion>{{ truck?.pricing?.priceExclVatEuro | number: '1.0-0':'de' | hasValue: '€' }}
            </ng-template>
          </h3>
          <h3 class="Truck-title Truck-titleask Truck-title--blue"
            *ngIf="truck?.ShowPrice && this.curLang.id == 'de' && !truck?.isDeleted && isSelectedSite" data-testId="truck-price"
            data-cy="truck-price">
            <span *ngIf="regionalSettings.zarPriceEnabled; else otherRegions">{{ truck?.pricing?.priceExclVat?.value |
              number: '1.0-0':'de' | hasValue: 'R'}}</span>
            <ng-template #otherRegions>{{ truck?.pricing?.priceExclVatEuro | number: '1.0-0':'de' | hasValue: '€' }}
            </ng-template>
          </h3>
          <h3 mat-raised-button matTooltip="{{ 'truck/askPrice' | lowercase | translate }}" matTooltipPosition="above"
            class="Truck-title Truck-titleask Truck-title--blue" *ngIf="((!truck?.ShowPrice && isSelectedSite) || (region == regionEnum.TX)) && !truck?.isDeleted"
            data-testId="truck-price" data-cy="truck-price">
            {{ 'truck/askPrice' | lowercase | translate }}
          </h3>
        </div>
        <span class="Truck-titleask Truck-title--blue" *ngIf="truck?.advertisementCategory=='Remaining Warranty' && region !=regionEnum.TX && !isSelectedSite">{{truck?.advertisementCategory}}</span>
        <span class="Truck-titleask Truck-title--blue" *ngIf="truck?.advertisementCategory!='Remaining Warranty' && region !=regionEnum.TX && !isSelectedSite">{{ 'uk/brandname' | lowercase | translate }}
          {{truck?.advertisementCategory}}</span>

        <div [ngClass]="
          truck?.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.EUR && truck?.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.ZAR && truck?.ShowPrice
            ? 'Truck-location'
            : 'Truck-location'
        ">
          <div style="display: flex" data-cy="vehicle-location">
            <div *ngIf="isSelectedSite || region===regionEnum.TX" class="Truck-location-icon">
              <i class="fas fa-map-marker-alt Truck-location-icon-icon-fa"></i>&nbsp;&nbsp;
            </div>
            <div *ngIf="isSelectedSite || region===regionEnum.TX" class="Truck-location-city" data-cy="Truck-location-country">
                <span data-cy="Truck-location-city">
                  <span
                    *ngIf="truck?.stockLocation?.country !== truck?.stockLocation?.region && !regionalSettings.singleCountryRegion">
                    {{ truck?.stockLocation?.countryCodeISO2 ? (truck?.stockLocation?.countryCodeISO2 | lookupcountry) :
                    (truck?.stockLocation?.country | hasValue)}} - </span>
                  {{ truck?.stockLocation?.city | hasValue }}
                </span>
            </div>
          </div>

          <!-- <div
            *ngIf="truck?.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.EUR && truck?.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.ZAR && truck?.ShowPrice && isSelectedSite
            && truck.isDeleted != true"
            class="Truck-locationCurrency" data-cy="local-currency">
            <small class="Truck-locationCurrencyText">{{ 'globalMiss/priceLocalCurrency' | lowercase | translate }}:
            </small>
            <b>{{ truck?.pricing?.priceExclVat?.value | number: '1.0-0':'de' }} {{
              truck?.pricing?.priceExclVat?.currencyIsoCode3
              }}</b>
          </div> -->
        </div>
        <div *ngIf="isTrailer" class="TruckGrid-Info">
          <div class="TruckGrid-InfoCol">
            <div class="TruckGrid-InfoItem" data-cy="truck-structuretype">
              <b>{{ 'specification/type' | lowercase | translate }}: </b>
              <span>{{ truck?.specification.superstructure.type | hasValue }}</span>
            </div>
            <div class="TruckGrid-InfoItem" data-cy="truck-model">
              <b>{{ 'specification/model' | lowercase | translate }}:</b>
              <span>{{ truck?.specification.model | hasValue }}</span>
            </div>
          </div>
          <div class="TruckGrid-InfoCol">
            <div class="TruckGrid-InfoItem" data-cy="truck-make">
              <b>{{ 'specification/make' | lowercase | translate }}:</b>
              <span>{{ truck?.specification.make | hasValue }}</span>
            </div>
            <div class="TruckGrid-InfoItem">
              <b>{{ 'specification/description' | lowercase | translate }}:</b>
              <span>{{ truck?.specification.superstructure.superstructure ?
                (truck?.specification.superstructure?.superstructure
                | lookup) : ('specification/askSalesPerson' | lowercase | translate) }}
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="!isTrailer">
          <div class="TruckGrid-Info" *ngIf="!isOtherCategory; else otherCategoty;">
            <div class="TruckGrid-InfoCol">
              <div class="TruckGrid-InfoItem" data-cy="vehicle-axleconfiguration">
                <span>{{ 'specification/axleConfig' | lowercase | translate }}: </span>
                <span>{{ truck?.specification.axleConfiguration | lookup }}</span>
              </div>
              <div class="TruckGrid-InfoItem" data-cy="vehicle-mileage">
                <span>{{ 'specification/mileage' | lowercase | translate }}: </span>
                <span>{{ truck?.specification.mileageKm?.toLocaleString('de-DE') | hasValue }} km</span>
              </div>
              <div class="TruckGrid-InfoItem" data-cy="vehicle-batteryCapacity" *ngIf="truck.specification.isElectric">
                <span>{{ 'specification/batteryCapacity' | lowercase | translate }}: </span>
                <span>
                  {{ truck?.specification.batteryCapacityKWh?.engPhrase | hasValue: 'kWh' }}
                </span>
              </div>
              <div *ngIf="truck?.specification.batteryCapacityKWh?.engPhrase" class="TruckGrid-InfoItem" data-cy="vehicle-regyear">
                <span>{{ 'specification/regYear' | lowercase | translate }}: </span>
                <span>{{ truck?.specification.dateFirstRegistration | date: 'yyyy' | hasValue }}</span>
              </div>
            </div>

            <div *ngIf="!truck?.specification.batteryCapacityKWh?.engPhrase" class="TruckGrid-InfoCol" style="text-align: right;">
              <div class="TruckGrid-InfoItem" data-cy="vehicle-regyear">
                <span>{{ 'specification/regYear' | lowercase | translate }}: </span>
                <span>{{ truck?.specification.dateFirstRegistration | date: 'yyyy' | hasValue }}</span>
              </div>
              <div class="TruckGrid-InfoItem" data-cy="vehicle-horsePower" *ngIf="!truck?.specification.isElectric">
                <span>{{ 'specification/engine' | lowercase | translate }}:</span>
                <span>
                  {{ truck?.specification.horsePower | hasValue: 'hp' }}
                </span>
              </div>
            </div>
          </div>
          <ng-template #otherCategoty>
            <div class="TruckGrid-Info">
              <div class="TruckGrid-InfoCol ">
                <div class="TruckGrid-InfoItem" data-cy="vehicle-make">
                  <span>{{ 'specification/make' | lowercase | translate }}: </span>
                  <span>{{ truck?.specification.make | hasValue }}</span>
                </div>
                <div class="TruckGrid-InfoItem" data-cy="vehicle-model">
                  <span>{{ 'specification/model' | lowercase | translate }}: </span>
                  <span>{{ truck?.specification.model | hasValue }}</span>
                </div>
                <div class="TruckGrid-InfoItem" data-cy="vehicle-modelYear">
                  <span>{{ 'specification/modelYear' | lowercase | translate }}: </span>
                  <span>{{ truck?.specification.modelYear | hasValue }}</span>
                </div>
              </div>
            </div>
          </ng-template>

          <div class="TruckGrid-Info disclaimer" *ngIf="truck?.specification.isElectric">
            {{'specification/batterycapacity/disclaimer'| lowercase | translate}}
          </div>
        </div>
      </div>

      <!--Home page cards-->
      <div *ngIf="cardTypeValue === TruckCardTypes.card && homePageCardValue" class="Truck-box">
        <div (click)="openTruckPage($event)" class="Truck-titleHome" data-cy="vehicle-title">
          <h3 class="Truck-titleHome Truck-titleModal" data-testId="truck-title" mat-raised-button
            *ngIf="truck?.specification.make !=='Volvo'"
            matTooltip="{{ truck?.specification.make | hasValue:'':'' }} {{ truck?.specification.model | hasValue:'':'' }}"
            matTooltipPosition="above">
            {{ truck?.specification.make | hasValue:'':'' }} {{ truck?.specification.model | hasValue:'':'' }}
          </h3>
          <h3 class="Truck-titleHome Truck-titleModal" data-testId="truck-title" mat-raised-button
            *ngIf="truck?.specification.make ==='Volvo'"
            matTooltip="{{ truck?.specification.make | hasValue:'':'' }} {{ truck?.specification.model | hasValue:'':'' }} {{truck?.specification.horsePower | hasValue:'':''}}"
            matTooltipPosition="above">
            {{ truck?.specification.make | hasValue:'':'' }} {{ truck?.specification.model | hasValue:'':'' }}
            {{truck?.specification.horsePower
            | hasValue:'':''}}
          </h3>
        </div>

        <div [ngClass]="
          truck?.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.EUR && truck?.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.ZAR && truck?.ShowPrice
            ? 'Truck-location'
            : 'Truck-location' 
        ">
          <div style="display: flex;" data-cy="vehicle-location" *ngIf="isSelectedSite || region===regionEnum.TX">
            <div class="Truck-location-icon">
              <i class="fas fa-map-marker-alt Truck-location-icon-icon-fa"></i>&nbsp;&nbsp;
            </div>
            <div *ngIf="isSelectedSite || region===regionEnum.TX" class="Truck-location-city" data-cy="Truck-location-country">
                <span data-cy="Truck-location-city">
                  <span
                    *ngIf="truck?.stockLocation?.country !== truck?.stockLocation?.region && !regionalSettings.singleCountryRegion">
                    {{ truck?.stockLocation?.countryCodeISO2 ? (truck?.stockLocation?.countryCodeISO2 | lookupcountry) :
                    (truck?.stockLocation?.country | hasValue)}} - </span>
                  {{ truck?.stockLocation?.city | hasValue }}
                </span>
            </div>
          </div>
          <span class="Truck-location-city" *ngIf="truck?.advertisementCategory=='Remaining Warranty' && region !=regionEnum.TX && !isSelectedSite">{{truck?.advertisementCategory}}</span>
          <span class="Truck-location-city" *ngIf="truck?.advertisementCategory!='Remaining Warranty' && region !=regionEnum.TX && !isSelectedSite">{{ 'uk/brandname' | lowercase | translate }}
            {{truck?.advertisementCategory}}</span>
        </div>
        <div (click)="openTruckPage($event)" class="Truck-titleHomeSub" data-cy="vehicle-title">
          <h3 class="Truck-titleHomeSub Truck-titleask Truck-title--homepage"
            *ngIf="truck?.ShowPrice && this.curLang.id != 'de' && !truck?.isDeleted && isSelectedSite" data-testId="truck-price"
            data-cy="truck-price">
            <span *ngIf="regionalSettings.zarPriceEnabled; else otherRegion">{{ truck?.pricing?.priceExclVat?.value |
              number: '1.0-0':'de' | hasValue: 'R'}}</span>
            <ng-template #otherRegion>{{ truck?.pricing?.priceExclVatEuro | number: '1.0-0':'de' | hasValue: '€' }}
            </ng-template>
          </h3>
          <h3 class="Truck-titleHomeSub Truck-titleask Truck-title--homepage"
            *ngIf="truck?.ShowPrice && this.curLang.id == 'de' && !truck?.isDeleted && isSelectedSite" data-testId="truck-price"
            data-cy="truck-price">
            <span *ngIf="regionalSettings.zarPriceEnabled; else otherRegions">{{ truck?.pricing?.priceExclVat?.value |
              number: '1.0-0':'de' | hasValue: 'R'}}</span>
            <ng-template #otherRegions>{{ truck?.pricing?.priceExclVatEuro | number: '1.0-0':'de' | hasValue: '€' }}
            </ng-template>
          </h3>
          <h3 mat-raised-button matTooltip="{{ 'truck/askPrice' | lowercase | translate }}" matTooltipPosition="above"
            class="Truck-titleHomeSub Truck-titleask Truck-title--homepage" *ngIf="!truck?.ShowPrice && !truck?.isDeleted && isSelectedSite"
            data-testId="truck-price" data-cy="truck-price">
            {{ 'truck/askPrice' | lowercase | translate }}
          </h3>
          
          <div *ngIf="!isSelectedSite && region!==regionEnum.TX" data-cy="vehicle-offerType">
            <div > 
              <span>{{ 'uk/specification/warranty' | lowercase | translate }}: </span>
              <span *ngIf="truck?.advertisementCategory=='Remaining Warranty'">Remaining factory
                  warranty</span>
              <span *ngIf="truck?.advertisementCategory=='Selected'">24 months (Extendable)</span>
              <span *ngIf="truck?.advertisementCategory=='Approved'">12 months (Extendable)</span>
              <span *ngIf="truck?.advertisementCategory=='Economy'">6 months</span>
            </div>   
          </div>
          
          <div *ngIf="region===regionEnum.TX" class="Truck-titleHomeSub" data-cy="vehicle-regyear">
            {{ 'specification/regYear' | lowercase | translate }}:
            <span>{{ truck?.specification.dateFirstRegistration | date: 'yyyy' | hasValue }}</span>
          </div>
        </div>
      </div>

      <div *ngIf="cardTypeValue === TruckCardTypes.list" class="Truck-boxList">
        <div (click)="openTruckPage($event)" class="Truck-titles" data-cy="vehicle-title">
          <h3 class="Truck-title">
            <span data-testId="truck-title" class="addPadding">{{ truck?.specification.make | hasValue: '':'' }}{{
              truck?.specification.model | hasValue: '':'' }}<br /><span class="subTitle">{{
                advertisementTitle}}</span></span>
            <span *ngIf="regionalSettings.zarPriceEnabled && !truck?.isDeleted; else others">
              <span class="wsnw CPrimary" *ngIf="truck?.ShowPrice && this.curLang.id != 'de'" data-testId="truck-price">
                {{ truck?.pricing?.priceExclVat?.value | number: '1.0-0':'de' | hasValue: 'R' }}
              </span>
              <span class="wsnw CPrimary" *ngIf="truck?.ShowPrice && this.curLang.id == 'de'" data-testId="truck-price">
                {{ truck?.pricing?.priceExclVat?.value | number: '1.0-0':'de' | hasValue: 'R' }}
              </span>
            </span>
            <ng-template #others>
              <span class="wsnw CPrimary" *ngIf="truck?.ShowPrice && this.curLang.id != 'de' && !truck?.isDeleted 
              && isSelectedSite"
                data-testId="truck-price">
                {{ truck?.pricing?.priceExclVatEuro | number: '1.0-0':'de' | hasValue: '€' }}
              </span>
              <span class="wsnw CPrimary" *ngIf="truck?.ShowPrice && this.curLang.id == 'de' && isSelectedSite 
              && !truck?.isDeleted"
                data-testId="truck-price">
                {{ truck?.pricing?.priceExclVatEuro | number: '1.0-0':'de' | hasValue: '€' }}
              </span>
            </ng-template>
            <span class="wsnw CPrimary" *ngIf="((!truck?.ShowPrice && isSelectedSite) || (region == regionEnum.TX)) && !truck?.isDeleted" data-testId="truck-price">{{
              'truck/askPrice' | lowercase | translate }}
            </span>
            <span class="wsnw CPrimary" *ngIf="truck?.advertisementCategory=='Remaining Warranty' && region !=regionEnum.TX && !isSelectedSite">{{truck?.advertisementCategory}}</span>
            <span class="wsnw CPrimary" *ngIf="truck?.advertisementCategory!='Remaining Warranty' && region !=regionEnum.TX && !isSelectedSite">{{ 'uk/brandname' | lowercase | translate }}
              {{truck?.advertisementCategory}}</span>
          </h3>
        </div>

        <div class="Truck-location">
          <div style="display: flex" data-cy="vehicle-location">
            <div class="Truck-location-icon">
              <i class="fas fa-map-marker-alt Truck-location-icon-icon-fa"></i>&nbsp;&nbsp;
            </div>
            <div *ngIf="!isSelectedSite" class="Truck-location-city" data-cy="Truck-location-country">
              <span data-cy="Truck-location-city">
                {{ truck?.stockLocation?.city | hasValue }}
              </span>
            </div>
            <div *ngIf="isSelectedSite" class="Truck-location-city" data-cy="Truck-location-country">
              
                <span data-cy="Truck-location-city">
                  <span
                    *ngIf="truck?.stockLocation?.country !== truck?.stockLocation?.region && !regionalSettings.singleCountryRegion">
                    {{ truck?.stockLocation?.countryCodeISO2 ? (truck?.stockLocation?.countryCodeISO2 | lookupcountry) :
                    (truck?.stockLocation?.country | hasValue) }} - </span>
                  {{ truck?.stockLocation?.city | hasValue }}
                </span>
              
            </div>
          </div>
          <div *ngIf="truck?.pricing?.priceExclVat?.CurrencyCode !== CurrencyCode.EUR && truck?.ShowPrice"
            class="Truck-locationCurrency">
            <b>{{ truck?.pricing?.priceExclVat?.text | number: '1.0-0':'de' }} {{
              truck?.pricing?.priceExclVat?.CurrencyIsoCode3
              }}
            </b>
          </div>
        </div>

        <div *ngIf="isTrailer" class="Truck-info">
          <div class="Truck-infoItem">
            <div class="Truck-infoItemEl" data-cy="truck-make">
              <b>{{ 'specification/make' | lowercase | translate }}:</b>
              <span>{{ truck?.specification.make | hasValue }}</span>
            </div>
            <div class="Truck-infoItemEl" data-cy="truck-model">
              <b>{{ 'specification/model' | lowercase | translate }}:</b>
              <span>{{ truck?.specification.model | hasValue }}</span>
            </div>
            <div class="Truck-infoItemEl" data-cy="truck-licenseplate">
              <b>{{ 'specification/trailerId' | lowercase | translate }}: </b>
              <span>{{ truck?.specification.licensePlate | hasValue }}</span>
            </div>
          </div>

          <div class="Truck-infoItem">
            <div class="Truck-infoItemEl" data-cy="truck-structuretype">
              <b>{{ 'specification/type' | lowercase | translate }}:</b>
              <span>{{ truck?.specification?.superstructure?.type | hasValue }}</span>
            </div>
            <div class="Truck-infoItemEl">
              <b>{{ 'specification/description' | lowercase | translate }}:</b>
              <span>{{ truck?.specification.superstructure?.superstructure ?
                (truck?.specification?.superstructure?.superstructure
                | lookup) : ('specification/askSalesPerson' | lowercase | translate) }}
              </span>
            </div>
            <div class="Truck-infoItemEl" data-cy="vehicle-regyear">
              <b>{{ 'specification/regYear' | lowercase | translate }}:</b>
              <span>{{ truck?.specification.dateFirstRegistration | date: 'yyyy-MM-dd' | hasValue }}</span>
            </div>
          </div>
        </div>

        <div *ngIf="!isTrailer && isSelectedSite">
          <div class="Truck-info" *ngIf="!isOtherCategory; else other;">
            <div class="Truck-infoItem" *ngIf="truck?.specification.isElectric">
              <div class="Truck-infoItemEl" data-cy="vehicle-batteryCapacity">
                <b>{{ 'specification/batteryCapacity' | lowercase | translate }}:</b>
                <span>{{ truck?.specification.batteryCapacityKWh?.engPhrase | hasValue: 'kWh' }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-electricMotor">
                <b>{{ 'specification/electricMotor' | lowercase | translate }}:</b>
                <span>{{ truck?.specification.electricMotor?.engPhrase | hasValue: '' }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-emission">
                <b>{{ 'specification/euronorm' | lowercase | translate }}:</b>
                <span>{{ truck?.specification.emission | hasValue: '' }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="truck-structuretype">
                <b>{{'specification/superstructure'| lowercase | translate}} {{ 'specification/type' | lowercase |
                  translate }}: </b>
                <span>{{ truck?.specification.superstructure.type | hasValue }}</span>
              </div>
            </div>
            <div class="Truck-infoItem" *ngIf="!truck?.specification.isElectric">
              <div class="Truck-infoItemEl" data-cy="vehicle-horsePower">
                <b>{{ 'specification/engine' | lowercase | translate }}:</b>
                <span>{{ truck?.specification.horsePower | hasValue: 'hp' }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-emission">
                <b>{{ 'specification/euronorm' | lowercase | translate }}:</b>
                <span>{{ truck?.specification.emission | hasValue }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-fuelCapacity">
                <b>{{ 'specification/fuelcapacity' | lowercase | translate }}:</b>
                <span>{{ truck?.specification.fuelCapacity | hasValue: 'L' }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-gearbox">
                <b>{{ 'specification/gearBox' | lowercase | translate }}:</b>
                <span>{{ truck?.specification.gearBox | hasValue }}</span>
              </div>
            </div>

            <div class="Truck-infoItem">
              <div class="Truck-infoItemEl" data-cy="vehicle-regyear">
                <b>{{ 'specification/regYear' | lowercase | translate }}:</b>
                <span>{{ truck?.specification.dateFirstRegistration | date: 'yyyy' | hasValue }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-mileage">
                <b>{{ 'specification/mileage' | lowercase | translate }}:</b>
                <span>{{ truck?.specification.mileageKm?.toLocaleString('de-DE') | hasValue }} km</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-axleconfiguration">
                <b>{{ 'specification/axleConfig' | lowercase | translate }}:</b>
                <span>{{ truck?.specification.axleConfiguration | lookup }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-additionalBrakes" *ngIf="!truck?.specification.isElectric">
                <b>{{ 'specification/additionalBrakes' | lowercase | translate }}:</b>
                <span>{{ truck?.specification.additionalBrakes | lookup }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-additionalBrakes" *ngIf="truck?.specification.isElectric">
                <b>{{ 'specification/additionalBrakes' | lowercase | translate }}:</b>
                <span style="white-space: nowrap;">Power regeneration</span>
              </div>
            </div>
          </div>
          <ng-template #other>
            <div class="Truck-infoItem Truck-noBorderRight">
              <div class="Truck-infoItemEl" data-cy="truck-make">
                <b>{{ 'specification/make' | lowercase | translate }}:</b>
                <span>{{ truck?.specification.make | hasValue }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="truck-model">
                <b>{{ 'specification/model' | lowercase | translate }}:</b>
                <span>{{ truck?.specification.model | hasValue }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="truck-modelYear">
                <b>{{ 'specification/modelYear' | lowercase | translate }}: </b>
                <span>{{ truck?.specification.modelYear | hasValue }}</span>
              </div>
            </div>
          </ng-template>
          <div class="disclaimer" *ngIf="truck?.specification.isElectric">
            {{'specification/batterycapacity/disclaimer'| lowercase | translate}}
          </div>
        </div>
        <div *ngIf="!isSelectedSite" class="Truck-info">
          <div class="Truck-infoItem width" >
              <div class="Truck-infoItemEl" data-cy="vehicle-axleconfiguration">
                  {{ 'specification/axleConfig' | lowercase | translate }}:
                  <span>{{ truck?.specification.axleConfiguration?.engPhrase }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-engine" *ngIf="!truck?.specification.isElectric">
                  {{ 'uk/specification/engine' | lowercase | translate }}:
                  <span>{{ truck?.specification.horsePower | hasValue: 'hp' }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-electricMotor" *ngIf="truck?.specification.isElectric">
                  {{ 'specification/electricMotor' | lowercase | translate }}:
              <span>{{ truck?.specification.electricMotor?.engPhrase | hasValue: '' }}</span>
            </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-cabType">
                  {{ 'uk/specification/cab' | lowercase | translate }}:
                  <span>{{ truck?.specification.cabType?.engPhrase | hasValue }}</span>
              </div>
          </div>
          <div class="Truck-infoItem">
              <div class="Truck-infoItemEl" data-cy="vehicle-mileage">
                  {{ 'specification/mileage' | lowercase | translate }}:
                  <span>{{ truck?.specification.mileageKm?.toLocaleString('en-GB') | hasValue }} km</span>
              </div>
              <div *isSelectedSite class="Truck-infoItemEl" data-cy="vehicle-regyear">
                  {{ 'specification/regYear' | lowercase | translate }}:
                  <span>{{ truck?.specification.dateFirstRegistration | date: 'yyyy' | hasValue }}</span>
              </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-regyear"*ngIf="!isSelectedSite">
                {{ 'specification/regYear' | lowercase | translate }}:
                <span>{{ truck?.specification.dateFirstRegistration | date: 'yyyy' | hasValue }}</span>
            </div>
              <div class="Truck-infoItemEl" data-cy="vehicle-offerType">
                  <div *ngIf="region!==regionEnum.TX"> 
                          {{ 'uk/specification/warranty' | lowercase | translate }}:
                          <span *ngIf="truck?.advertisementCategory=='Remaining Warranty'">Remaining factory
                              warranty</span>
                          <span *ngIf="truck?.advertisementCategory=='Selected'">24 months (Extendable)</span>
                          <span *ngIf="truck?.advertisementCategory=='Approved'">12 months (Extendable)</span>
                          <span *ngIf="truck?.advertisementCategory=='Economy'">6 months</span>
                  </div>   
              </div>
          </div>
        </div>
      </div>
    </a>
  </ng-container>
  <ng-container *ngIf="isSkeleton" class="Truck IsSkeleton">
    <div class="Truck-image" *ngIf="cardSmallValue">
      <img class="Skeleton truckImgBgColor" src="" />
    </div>
    <div class="TruckSkeleton" *ngIf="cardSmallValue">
      <div style="display:flex;">
        <div class="TruckSkeleton-model">
        </div>
        <div class="TruckSkeleton-price">
        </div>
      </div>
      <div style="display:flex;" class="TruckSkeleton-margin">
        <div class="TruckSkeleton-icon">
        </div>
        <div class="TruckSkeleton-location">
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-container *ngIf="isSkeleton">
  <a href *ngIf="!cardSmallValue">
    <div class="listSkeleton" *ngIf="cardTypeValue === TruckCardTypes.list">
      <div style="display:flex;">
        <div class="listSkeleton-imageDiv"></div>
        <div class="listDiv">
          <div style="display:flex;">
            <div class="listSkeleton-modelDiv"></div>
            <div class="listSkeleton-priceDiv"></div>
          </div>
          <div class="listSkeleton-locationDiv"></div>
          <div class="listSkeleton-content" style="display:flex;">
            <div>
              <div class="listRow">
                <div class="listSkeleton-engineDiv"></div>
                <div class="listSkeleton-engineValDiv"></div>
              </div>
              <div class="listRow">
                <div class="listSkeleton-euronormDiv"></div>
                <div class="listSkeleton-engineValDiv"></div>
              </div>
              <div class="listRow">
                <div class="listSkeleton-fuelDiv"></div>
                <div class="listSkeleton-engineValDiv"></div>
              </div>
              <div class="listRow">
                <div class="listSkeleton-euronormDiv"></div>
                <div class="listSkeleton-engineValDiv"></div>
              </div>
            </div>
            <div class="verticalLine"></div>
            <div>
              <div class="listRow">
                <div class="listSkeleton-euronormDiv"></div>
                <div class="listSkeleton-engineValDiv"></div>
              </div>
              <div class="listRow">
                <div class="listSkeleton-euronormDiv"></div>
                <div class="listSkeleton-engineValDiv"></div>
              </div>
              <div class="listRow">
                <div class="listSkeleton-fuelDiv"></div>
                <div class="listSkeleton-engineValDiv"></div>
              </div>
              <div class="listRow">
                <div class="listSkeleton-euronormDiv"></div>
                <div class="listSkeleton-engineValDiv"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="cardTypeValue === TruckCardTypes.card" class="listSkeleton">
      <div class="listSkeleton-imageDiv"></div>
      <div>
        <div style="display:flex;">
          <div class="listSkeleton-modelDiv"></div>
          <div class="listSkeleton-priceDiv"></div>
        </div>
        <div class="listSkeleton-locationDiv"></div>
        <div style="display:flex;justify-content: space-between;">
          <div>
            <div class="listSkeleton-euronormDiv"></div>
            <div class="listSkeleton-engineValDiv"></div>
          </div>
          <div>
            <div class="listSkeleton-euronormDiv"></div>
            <div class="listSkeleton-engineValDiv"></div>
          </div>
        </div>
        <div style="display:flex;justify-content: space-between;">
          <div>
            <div class="listSkeleton-euronormDiv"></div>
            <div class="listSkeleton-engineValDiv"></div>
          </div>
          <div>
            <div class="listSkeleton-euronormDiv"></div>
            <div class="listSkeleton-engineValDiv"></div>
          </div>
        </div>
      </div>
    </div>
  </a>
</ng-container>