import { PipeTransform, Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Pipe({
    name: 'translateLower',
})
export class TranslateLower implements PipeTransform {
    constructor(private translate: TranslateService) { }

    transform(value: string): string {
        let translated = this.translate.instant(value.toLowerCase());

        return translated;
    }
}
