import { LocalesStateInterface } from "./locales.model";

export class SetLocales {
    static readonly type = "[SetLocales]: action";

    constructor(public payload: LocalesStateInterface) {}
}

export class InitLanguage {
    static readonly type = "[InitLanguage]: action";

    constructor() {}
}
