<section class="Stock">
    <!--trade n export section-->
    <div *isTXSite class="Stock-links" id="txServiceLinks">
        <button *ngFor="let link of txLinks; let i = index"
                class="Stock-linkBtn"
                (click)="routePage(link)">
            <span class="stock-button" data-cy="ourStocks-links">
                <div class="Stock-linkBtn-Wrapper">
                    <div class="Stock-linkBtn-Wrapper-fontDiv">
                        <span class="Stock-linkBtn-Wrapper-stockSpan">{{"uk/pages/readMore" | lowercase | translate}}</span>
                        <span class="Stock-linkBtn-Wrapper-stockInnerSpan">
                            {{ link.label | lowercase | translate }}
                        </span>
                    </div>
                    <div>
                        <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')" alt="Read more">
                    </div>
                </div>
            </span>
        </button>
    </div>
    <!--UK n NS section-->
    <div *isUKNSSite class="Stock-links" id="uknsServiceLinks">
        <button *ngFor="let link of uknsLinks; let i = index"
                class="Stock-linkBtn"
                (click)="routePage(link)">
            <span class="stock-button" data-cy="ourStocks-links">
                <div class="Stock-linkBtn-Wrapper">
                    <div class="Stock-linkBtn-Wrapper-fontDiv">
                        <span class="Stock-linkBtn-Wrapper-stockSpan">{{"uk/pages/readMore" | lowercase | translate}}</span>
                        <span class="Stock-linkBtn-Wrapper-stockInnerSpan">
                            {{ link.label | lowercase | translate }}
                        </span>
                    </div>
                    <div>
                        <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')" alt="Read more">
                    </div>
                </div>
            </span>
        </button>
    </div>
    <!--Selected section-->
    <div *isSelectedSite class="Stock-links" id="selectedServiceLinks">
        <button *ngFor="let link of selectedLinks; let i = index"
                class="Stock-linkBtn"
                (click)="routePage(link)">
            <span class="stock-button" data-cy="ourStocks-links">
                <div class="Stock-linkBtn-Wrapper">
                    <div class="Stock-linkBtn-Wrapper-fontDiv">
                        <span class="Stock-linkBtn-Wrapper-stockSpan">{{"uk/pages/readMore" | lowercase | translate}}</span>
                        <span class="Stock-linkBtn-Wrapper-stockInnerSpan">
                            {{ link.label | lowercase | translate }}
                        </span>
                    </div>
                    <div>
                        <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')" alt="Read more">
                    </div>
                </div>
            </span>
        </button>
    </div>
</section>
