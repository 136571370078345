import { Component, OnDestroy, OnInit } from "@angular/core";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { MatDialog,MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { FiltersPopupComponent } from "~/popups/filters-popup/filters-popup.component";
import {
    SortOptions,
    SearchStateFilter,
} from "~/core/store/search/search.model";

import { SearchService } from "~/core/store/search/search.service";
import { ScreenUtils } from '~/core/utils/screen-utils/screen-utils';
import { NotificationPopupComponent } from '../../../popups/notification-popup/notification-popup.component';
import { ToggleFeatureService } from '~/core/services/togglefeature/togglefeature.service';
import { AssetService } from "~/core/services/assets/asset.service";
@AutoUnsubscribe()
@Component({
    selector: "volvo-stock-options-mobile",
    templateUrl: "./stock-options-mobile.component.html",
    styleUrls: ["./stock-options-mobile.component.scss"],
})
export class StockOptionsMobileComponent implements OnInit, OnDestroy {
    selectedFiltersCount: number = 0;
    private s1: Subscription;
    notificationFeature: boolean;
    notificationRef: MatDialogRef<NotificationPopupComponent, any>;
    isAnySelected: boolean = false;
    constructor(
        private searchService: SearchService,
        private dialog: MatDialog,
        public togglefeature: ToggleFeatureService,
        public assetService: AssetService
    ) {
        this.initSubscriptions();
    }

    async ngOnInit() {
        //this.notificationFeature = await this.togglefeature.getFeatureToggle('Notifications');
        this.notificationFeature = true;
    }

    ngOnDestroy(): void { }

    onFiltersClick(): void {
        this.dialog.open(FiltersPopupComponent, {
            width: "680px",
            panelClass: "volvo-popup-fs",
        });
    }



    private initSubscriptions(): void {
        this.s1 = this.searchService.filter$.subscribe(
            (searchOptions: SearchStateFilter) => {
                this.selectedFiltersCount = 0;
                Object.values(searchOptions.steps).forEach((item) => {
                    if (item.isSelected && item.isVisible) {
                        this.selectedFiltersCount++;
                    }
                });
                if (this.selectedFiltersCount === 0) {
                    if (this.notificationRef) {
                      this.notificationRef.close();
                    }
                  }
            }
        );
    }
    onSetNotificationClick() {
        if (this.selectedFiltersCount > 0) this.isAnySelected = true;
        else this.isAnySelected = false;
        if (!this.isAnySelected) {
            this.dialog.open(NotificationPopupComponent, {
                panelClass: 'volvo-no-filter-popup',
                data: { isFilterSelected : this.isAnySelected }
            });
        } else {
            var position = "";
            if (ScreenUtils.isTablet()) {
                position = "top";
            }
            this.notificationRef =this.dialog.open(NotificationPopupComponent, {
                panelClass: 'volvo-notification-popup',
                position: { top: position },
                disableClose: true,
                data: { isFilterSelected : this.isAnySelected }
            });
        }
    }
}
