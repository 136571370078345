import { Injectable } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnlineStatusService {
  online$: Observable<Event>;
  offline$: Observable<Event>;

  constructor() {
    this.online$ = fromEvent(window, 'online');
    this.offline$ = fromEvent(window, 'offline');

    if(!navigator.onLine){
      
    }
  }
}
