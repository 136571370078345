<i (click)="onClose()" class="PopupOLD-close material-icons">close</i>

<div class="PopupOLD">
  <h3 class="PopupOLD-title">{{ 'popups/phoneUs/title' | lowercase | translate }}</h3>
  <h5 class="PopupOLD-subtitle">{{ 'popups/phoneUs/subtitle' | lowercase | translate }}</h5>

  <h3 class="PopupOLD-phone">
    <volvo-phone phone="{{ data.truck?.salesperson.telephoneNumber }}"></volvo-phone>
  </h3>

  <div class="PopupOLD-info">
    <div *ngIf="data.truck?.pictures && data.truck.pictures[0]" class="PopupOLD-infoImage">
      <img [src]="data.truck.pictures[0].filename | volvoCdn | escapeUrl" volvoLazyImage [alt]="data.truck?.make" />
    </div>

    <div class="PopupOLD-infoBox">
      <h6 class="PopupOLD-infoText">
        {{ 'specification/truckId' | lowercase | translate }}:
        <b>{{ data.truck.specification.licensePlate || '----' }}</b>
      </h6>
      <h3 class="PopupOLD-infoTitle">
        {{ data.truck?.specification.make || '----' }} - {{ data.truck?.specification.model || '----' }}
      </h3>
      <h3 class="PopupOLD-infoPrice">
        {{ data.truck?.pricing?.priceExclVatEuro | number: '1.0-0':'de' | hasValue: '€' }}
      </h3>
      <small class="Truck-locationCurrencyText">{{ 'globalMiss/priceLocalCurrency' | lowercase | translate }}:</small>

      <h4 class="PopupOLD-infoPriceLocal">
        <b>{{ data.truck?.pricing?.priceExclVat?.value | number: '1.0-0':'de' }}
          {{ data.truck?.pricing?.priceExclVat?.currencyIsoCode3 }}</b>
      </h4>
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="PopupOLD-form">
    <div class="PopupOLD-input">
      <h6 class="PopupOLD-inputTitle">{{ 'form/fullName' | lowercase | translate }}</h6>
      <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
        <input formControlName="name" matInput />
      </mat-form-field>
    </div>

    <div class="PopupOLD-input">
      <h6 class="PopupOLD-inputTitle">{{ 'form/phoneNumber' | lowercase | translate }}</h6>
      <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
        <input formControlName="phone" matInput />
      </mat-form-field>
    </div>

    <div class="PopupOLD-input">
      <h6 class="PopupOLD-inputTitle">{{ 'form/message' | lowercase | translate }} ({{ 'form/optional' | lowercase |
        translate }})
      </h6>
      <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
        <textarea formControlName="message" matInput rows="5"></textarea>
      </mat-form-field>
    </div>

    <div class="PopupOLD-input">
      <h6 class="PopupOLD-inputTitle">{{ 'form/financing' | lowercase | translate }}</h6>
      <mat-checkbox formControlName="financing" class="big">
        <div class="PopupOLD-checkBoxText">{{ 'form/financingCheckbox' | lowercase | translate }}</div>
      </mat-checkbox>
    </div>

    <div *ngIf="form.get('financing').value" class="MessageUs-Financing">
      <div class="PopupOLD-form">
        <div class="PopupOLD-input">
          <h6 class="PopupOLD-inputTitle">{{ 'form/companyName' | lowercase | translate }}</h6>
          <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
            <input formControlName="financingCompanyName" matInput />
          </mat-form-field>
        </div>

        <div class="PopupOLD-input">
          <h6 class="PopupOLD-inputTitle">{{ 'form/city' | lowercase | translate }}</h6>
          <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
            <input formControlName="financingCity" matInput />
          </mat-form-field>
        </div>

        <div class="PopupOLD-input">
          <h6 class="PopupOLD-inputTitle">{{ 'form/country' | lowercase | translate }}</h6>
          <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
            <input formControlName="financingCountry" matInput />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="PopupOLD-privacy tal">
      <mat-checkbox (click)="recaptcha()" formControlName="privacy" class="big"></mat-checkbox>
      {{ 'form/contactingVolvoPrivacyPolicy' | lowercase | translate }}
      <a href="{{ checkbox }}" target="_blank" class="PopupOLD-link">
        {{ 'form/privacyPolicy' | lowercase | translate }}
      </a>
    </div>

    <div *ngIf="showRecaptcha" class="PopupOLD-input" style="margin-bottom: 15px; display: inline-block">
      <re-captcha (resolved)="resolved($event)" siteKey="{{ recaptchaToken }}" required></re-captcha>
    </div>

    <div class="PopupOLD-submit">
      <button [disabled]="form.invalid || !reCaptchaRes" type="submit" mat-button color="primary" class="BigBtn">
        {{ 'form/send' | lowercase | translate }}
      </button>
    </div>
  </form>
</div>
