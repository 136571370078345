import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
// Scroll service which will execute after any route event, this will help us scroll to the element by getting the id of element from path(query string)
export class ScrollService {

    constructor(private router: Router) { }

    scrollToElement(): void {
        const url = new URL(window.location.href);
        const elementId = url.searchParams.get('path');
        const element = document.getElementById(elementId);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
            // Smooth scroll to the position of the element
            window.scrollTo({
                top: elementPosition,
                behavior: 'smooth'
            });
        } 
    }

    listenToRouterEvents(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // Call scrollToElement after a short delay to ensure DOM is updated
                setTimeout(() => {
                    this.scrollToElement();
                }, 1000);
            }
        });
    }
}
