<header #header class="Header">
    <div class="Header-wp">
      <a [routerLink]="HtmlRoutePages.home | localize" class="Header-logo">
        <img [src]="getAssetUrl('assets/logo.png')" alt="" />
        <span class="spanPadding">
          <b *isSelectedSite>Selected</b>
          <b *isUKSite>Used Truck Centres</b>
          <b *isTXSite>Trade & Export Trucks</b>

          <span *isSelectedSite class="Header-logo-ukSpan">Used Trucks</span>
          <span *isUKSite class="Header-logo-ukSpan">United Kingdom</span>
          <span *isTXSite class="Header-logo-ukSpan">UK & Worldwide</span>
        </span>
      </a>

      <div *ngIf="navLinks" class="Header-nav">
        <nav mat-tab-nav-bar color="warn">
          <a mat-tab-link *ngFor="let link of navLinks" href="{{link.path | localize}}"
          [routerLink]="link.path | localize " [active]="rla.isActive"
           routerLinkActive #rla="routerLinkActive" class="Header-navLink" data-cy="header-navlinks" [ngClass]="this.region===regionEnum.TX?'Header-navLink-txnavlink':''">
            {{ link.label | lowercase | translate }}
          </a>
        </nav>
      </div>
      <div class="Header-rightBox">
        <volvo-language-select *isSelectedSite></volvo-language-select>
        <div class="Header-mobBtns">
          <button (click)="onMenuClick()" class="BtnIcon" mat-button data-cy="btn-menu">
            <img class="menuDimension" [src]="assetService.getAssetUrl('assets/pages/home/MenuHamburger.jpg')" alt="Menu"/>
          </button>
        </div>
      </div>
    </div>
  <volvo-sub-header [subHeader]="activeSubheader" (scrollTo)="scrollToElement($event)" *ngIf="activeSubheader" >
  </volvo-sub-header>
</header>
