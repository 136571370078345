<section class="volvo">
        <div class="volvo-volvoDiv">
          <div class="volvo-imgDiv">
            <img
              src="assets/pages/buyersGuide/volvoFL.webp"
              class="volvo-imgDiv-volvoImg" alt="volvo FL"
            />
          </div>
          <div class="volvo-title">
            <span>{{ "uk/pages/buyersGuide/volvoFL" | lowercase | translate }}</span>
          </div>
          <div class="volvo-text">
            <span>{{ "uk/pages/buyersGuide/volvoFL/text1" | lowercase | translate }}</span>
          </div>
          <div class="volvo-text">
              <span class="volvo-text-medium">{{
                "uk/pages/buyersGuide/volvoFL/text2" | lowercase | translate
              }}</span
              >{{ "uk/pages/buyersGuide/volvoFL/text3" | lowercase | translate }}
            </div>
          <div class="volvo-text">
            <span>
              {{ "uk/pages/buyersGuide/volvoFL/text4" | lowercase | translate }}
            </span>
          </div>
          <div class="volvo-text">
            <span class="volvo-text-medium">{{
              "uk/pages/buyersGuide/volvoFL/text5" | lowercase | translate
            }}</span
            >{{ "uk/pages/buyersGuide/volvoFL/text6" | lowercase | translate }}
          </div>
        </div>
      </section>
      <volvo-footer></volvo-footer>
