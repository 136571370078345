import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { Subscription } from 'rxjs';

import { LeaseSliders } from '~/pages/truck-page/truck-page-lease/truck-page-lease.component';
import { LocalesStateLang, LocalesStateLangList } from '~/core/store/locales/locales.model';
import { MailApiService } from '~/core/api/mail-api/mail-api.service';
import { LocalesService } from '~/core/store/locales/locales.service';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { VfsApiService } from '~/core/api/vfs-api/vfs-api.service';
import { PostMailBody } from '~/core/api/mail-api/mail-api.model';
import { VfsApiRes } from '~/core/api/vfs-api/vfs-api.model';
import { ApiUtils } from '~/core/utils/api-utils/api-utils';
import { CurrencyCode, Truck } from '~/core/store/trucks/trucks.model';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { CounterService } from '~/core/services/counter/counter-service';
import { AssetService } from '~/core/services/assets/asset.service';
import { countries } from '~/core/constants/country';
import { ScreenUtils } from '~/core/utils/screen-utils/screen-utils';
import { InputValidator } from '~/popups/inputValidator';
import { LocalesStateCountryList } from '~/core/store/locales/locales.model';
export interface CalculatorDialogData {
  truck: Truck;
  leaseSliders: LeaseSliders;
}
class VfsPopupUserActions {
  vfsClose: boolean = false;
  vfsSend: boolean = false;
  public constructor(init?: Partial<VfsPopupUserActions>) {
    Object.assign(this, init);
  }
}
@AutoUnsubscribe()
@Component({
  selector: 'volvo-calculator-popup',
  templateUrl: './calculator-popup.component.html',
  styleUrls: ['./calculator-popup.component.scss'],
})
export class CalculatorPopupComponent implements OnInit, OnDestroy {
  noSpecialCharRegEx = /^[\p{L}\- \.\?!@'\r\n,0-9\(\)]+$/u;

  form = this.fb.group({
    name: ['', [Validators.required, Validators.pattern(this.noSpecialCharRegEx), InputValidator.noWhitespace]],
    phone: ['', [Validators.required, Validators.pattern(/^\+?\d+$/)]],
    email: ['', [Validators.email, Validators.required]],
    financing: [false],
    financingCompanyName: [''],
    financingCity: [''],
    financingCountry: [''],
    message: ['', [Validators.pattern(this.noSpecialCharRegEx)]],
    privacy: [false, Validators.requiredTrue],
    truckId: [''],
    truckUrl: [''],
    licensePlate: [''],
    truckdescription: [''],
    truckPrice: [''],
    downPayment: [''],
    contractDuration: [''],
    monthlyPayment: [''],
    token: [''],
    country: ['', [Validators.required]],
    companyName: ['', [Validators.required, Validators.pattern(this.noSpecialCharRegEx), InputValidator.noWhitespace]],
    companyAddress: ['', [Validators.required, Validators.pattern(this.noSpecialCharRegEx), InputValidator.noWhitespace]]
  });

  lang: LocalesStateLangList;
  isBrowser: boolean;
  financePaymentLoading = true;
  totalFinancePayment: number;
  leaseSliders: LeaseSliders;
  showRecaptcha: boolean = false;
  recaptchaToken: string;
  checkbox: string;
  reCaptchaRes: string;
  curLang: LocalesStateLangList;

  truckPrice: number;
  private s0: Subscription;

  CurrencyCode = CurrencyCode;
  currencySymbol: string;
  regionalSettings: RegionSettings;
  isSelectedSite: boolean = false;
  countryDropDown: any;
  isValidPhoneno: boolean = true;
  isPhone: boolean = false;
  showFullText: boolean = false;
  invalidInputs: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CalculatorDialogData,
    public dialogRef: MatDialogRef<CalculatorPopupComponent>,
    private fb: UntypedFormBuilder,
    private mailApiService: MailApiService,
    private localesService: LocalesService,
    private globalUtils: GlobalUtils,
    private sanitizer: DomSanitizer,
    private vfsApiService: VfsApiService,
    store: Store,
    private counterService: CounterService,
    public assetService: AssetService,
  ) {
    this.leaseSliders = cloneDeep(this.data.leaseSliders);
    this.isBrowser = this.globalUtils.isBrowser();

    const region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === region)[0]);
    if (this.regionalSettings?.site === 'volvoselected')
      this.isSelectedSite = true;

    this.countryDropDown = countries;
    if (this.countryDropDown.filter(x => x.id === 'other').length === 0) {
      this.countryDropDown.push({ label: 'Other', id: 'other' });
    }

    if (region.toLowerCase() === 'eu') {
      this.countryDropDown = this.countryDropDown.filter(x => x.id !== 'za');
    }
    else {
      this.countryDropDown = this.countryDropDown.filter(x => x.id === 'za' || x.id === 'other');
    }
    this.initSubscriptions();

    this.setTruckValues();

    this.recaptchaToken = environment.recaptchaSitekey;
  }

  ngOnInit(): void {
    this.isPhone = ScreenUtils.isPhone();
    this.onCalc();
    this.onSliderMenuClick();
  }

  ngOnDestroy(): void { }

  onSliderMenuClick(): void {
    // Fix bug with wrong slider max value
    // TODO: find better solution
    window.setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
  }

  onClose(): void {
    this.logUserActions("vfsClose");
    this.dialogRef.close();
  }

  logUserActions(action: string) {
    let userActions;
    if (this.data.truck) {
      switch (action) {
        case "vfsClose":
          userActions = new VfsPopupUserActions({ vfsClose: true });
          break;
        case "vfsSend":
          userActions = new VfsPopupUserActions({ vfsSend: true });
          break;
      }
      this.counterService.postRequestQuotePopUpUserActions("Truck Ad", this.data.truck.truckId, this.data.truck.stockLocation.country, userActions);
    }
  }
  private setTruckValues(): void {
    if (this.data.truck) {
      this.form.get('truckId').setValue(this.data.truck.stockNumber);
      this.form.get('licensePlate').setValue(this.data.truck.specification.licensePlate);
      this.form.get('truckdescription').setValue(this.data.truck.MetaDescription);
    }

    if (this.globalUtils.isBrowser()) {
      this.form.get('truckUrl').setValue(window.location.href);
    }
  }

  onSlideStart(): void {
    this.financePaymentLoading = true;
  }

  async onCalc(): Promise<void> {
    if (this.regionalSettings.zarPriceEnabled)
      this.truckPrice = this.data.truck.pricing.priceExclVat.value;
    else
      this.truckPrice = this.data.truck.pricing.priceExclVatEuro;

    this.vfsApiService
      .getTotalFinancePayment({
        down_payment: this.getDownPaymentPercent(),
        no_of_terms: this.leaseSliders.contractDuration.value,
        sales_price: this.truckPrice,
        truck_age: new Date().getUTCFullYear() - new Date(this.data.truck.specification.dateFirstRegistration).getUTCFullYear(),
        truck_make: this.data.truck.specification.make,
        site: this.regionalSettings.site,
        region: this.regionalSettings.region,
        truck_id: this.data.truck.specification.licensePlate,
        stockCountry: this.data.truck.stockLocation.country,
        stockCountryIsoCode: this.data.truck.stockLocation.countryCodeISO2
      })
      .subscribe((res: VfsApiRes) => {
        if (res && res.totalFinancePayment) {
          this.totalFinancePayment = res.totalFinancePayment;
          this.financePaymentLoading = false;
        }
      });
  }

  resolved(captchaResponse: string, res) {
    this.reCaptchaRes = captchaResponse;
  }
  recaptcha() {
    this.showRecaptcha = !this.showRecaptcha;
  }

  async onSubmit(): Promise<void> {
    if (this.form.valid && this.reCaptchaRes) {
      this.invalidInputs = true;
      const { name, phone, email, companyName, companyAddress, country, subscribe } = this.form.value;

      if (subscribe) {
        await this.mailApiService.postSubscribe({
          name,
          phone,
          email,
          companyName,
          companyAddress,
          country,
          mail: {
            body: `<p>To confirm your subscription please click this <a  href='http://{{url}}'>{{url}}</a  href='http://{{url}}'></p>`,
            subject: `Please confirm your newsletter subscription`,
          },
        });
      }

      const body = this.transformDataForServer(this.form.value);
      await this.mailApiService.postMailVfs(body);
      this.logUserActions("vfsSend");
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        event: 'FormsubmittedCalculator',
      });
    }
    else {
      this.invalidInputs = true;
    }
  }

  private getDownPaymentPercent(): number {
    return this.leaseSliders.downPayment.value;
  }

  private initSubscriptions(): void {
    if (this.regionalSettings.zarPriceEnabled)
      this.currencySymbol = 'R';
    else
      this.currencySymbol = '€'

    this.s0 = this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
      this.curLang = lang.curLang;
      this.checkbox = '/' + this.curLang.id + '/privacy-policy';
    });
  }

  private transformDataForServer(data) {
    // TODO: ask someone to make nice API for this
    return {
      salesEmail: "", salesPersonName: "",
      name: this.sanitizer.sanitize(SecurityContext.NONE, data.name).trim(),
      phone: data.phone,
      email: data.email,
      companyName: this.sanitizer.sanitize(SecurityContext.NONE, data.companyName).trim(),
      companyAddress: this.sanitizer.sanitize(SecurityContext.NONE, data.companyAddress).trim(),
      country: data.country,
      truckid: data.truckId,
      // leasecountry: this.lang,
      message: this.sanitizer.sanitize(SecurityContext.NONE, data.message).trim(),
      token: this.reCaptchaRes,
      mailId: 'VFS',
      truckdescription: data.truckdescription,
      truckUrl: data.truckUrl,
      licensePlate: data.licensePlate,
      truckPrice: `${this.truckPrice}`,
      downPayment: `${this.leaseSliders.downPayment.value}`,
      contractDuration: `${this.leaseSliders.contractDuration.value}`,
      monthlyPayment: `${this.totalFinancePayment}`,
      site: this.regionalSettings.site,
      region: this.regionalSettings.region
    };
  }

  toggleReadMore() {
    this.showFullText = !this.showFullText;
  }

  checkFormValidation() {
    if (!this.form.valid || !this.reCaptchaRes)
      this.invalidInputs = true;
    else
      this.invalidInputs = false;
  }

  trimInput(controlName: string): void {
    const control = this.form.get(controlName);
    if (control && control.value) {
      control.setValue(control.value.trim());
    }
  }

  preventNonNumeric(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', '+'];
    if (!allowedKeys.includes(event.key) && !/^\d$/.test(event.key)) {
      event.preventDefault();
    }
  }
}
