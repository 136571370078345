import { Action, State, StateContext } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';

import { GetBucketAggregations } from '~/core/store/buckets/buckets.actions';
import { SearchApiService } from '~/core/api/search-api/search-api.service';
import { BucketsStateInterface } from '~/core/store/buckets/buckets.model';
import { SearchResponse } from '~/core/store/search/search.model';
import { Injectable } from '@angular/core';

@State<BucketsStateInterface>({
  name: 'buckets',
  defaults: {
    buckets: null,
  },
})
@Injectable()
export class BucketsState {
  constructor(private http: HttpClient, private searchApiService: SearchApiService) { }

  @Action(GetBucketAggregations)
  getBucketAggregations({ getState, patchState }: StateContext<BucketsStateInterface>) {
    return this.searchApiService.getSearch().subscribe((res: SearchResponse) => {
      const state: BucketsStateInterface = getState();
      patchState({
        buckets: {
          ...state.buckets,
          ...res.aggregations,
        },
      });
    });
  }
}
