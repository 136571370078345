<section class="faq">
  <div class="faq-div">
    <div class="faq-div-title">
      <span
        >{{ "uk/components/footer/faq" | lowercase | translate }} -
        {{ "uk/pages/services/volvoEconomy" | lowercase | translate }}</span
      >
    </div>
    <div class="faq-div-matPanel">
      <mat-accordion class="VolvoAccordionFAQ" multi="true">
        <mat-expansion-panel *ngFor="let faq of faqList">
          <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
            <mat-panel-title class="faq-div-text">
              <span>{{ faq.title | translate }}</span></mat-panel-title
            >
          </mat-expansion-panel-header>
          <div class="faq-div-text-grey4">
            <span>{{ faq.description | translate }}</span>
          </div>
          <div
            class="faq-div-text-grey4 faq-div-text-complaint"
            *ngIf="faq.title === 'uk/pages/services/faq/title10'"
          >
            <span>{{ "uk/pages/services/faq/title10/text2" | lowercase | translate }}</span>
            <span>{{ "uk/pages/services/faq/title10/text3" | lowercase | translate }}</span>
            <span>{{ "uk/pages/services/faq/title10/text4" | lowercase | translate }}</span>
            <span>{{ "uk/pages/services/faq/title10/text5" | lowercase | translate }}</span>
            <span>{{ "uk/pages/services/faq/title10/text6" | lowercase | translate }}</span>
            <span>{{ "uk/pages/services/faq/title10/text7" | lowercase | translate }}</span>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</section>
<volvo-footer></volvo-footer>
