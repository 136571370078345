<section class="PrivacyPolicy">
  <div class="PrivacyPolicy-wp">
    <h1 [innerHTML]="'privacyPolicy/title' | lowercase | translate" class="PrivacyPolicy-title"></h1>
    <div [innerHTML]="'privacyPolicy/text-1' | lowercase | translate" class="PrivacyPolicy-text"></div>

    <div *ngIf="lang.id === 'ru'" [innerHTML]="'privacyPolicy/text-2' | lowercase | translate"
      class="PrivacyPolicy-text">
    </div>
    <div *ngIf="lang.id === 'ru'" [innerHTML]="'privacyPolicy/text-3' | lowercase | translate"
      class="PrivacyPolicy-text">
    </div>
  </div>

  <div class="PrivacyPolicy-data">
    <a class="PrivacyPolicy-data-rights" [attr.href]="privacyHref"
      [innerHTML]="'privacyPolicy/rights' | lowercase | translate" target="_blank"></a>
  </div>
</section>


<volvo-footer></volvo-footer>
