<div class="SearchTopFilters">
  <button (click)="onDealershipClick()" mat-button class="BtnAuto SearchTopFilters-item">
    <i class="far fa-map-marker-alt"></i>&nbsp;{{ 'popups/dealership/title' | lowercase | translate }}
  </button>
  <button [matMenuTriggerFor]="vehicleType" #vehicleTypeTrigger="matMenuTrigger" mat-button
    class="BtnAuto SearchTopFilters-item">
    {{ 'globalMiss/vehicleType' | lowercase | translate }}
  </button>
  <button [matMenuTriggerFor]="make" #makeTrigger="matMenuTrigger" mat-button class="BtnAuto SearchTopFilters-item">
    {{ 'specification/make' | lowercase | translate }}
  </button>
  <button [matMenuTriggerFor]="model" #modelTrigger="matMenuTrigger" mat-button class="BtnAuto SearchTopFilters-item">
    {{ 'specification/model' | lowercase | translate }}
  </button>
  <button (click)="onSliderMenuClick()" [matMenuTriggerFor]="mileage" mat-button class="BtnAuto SearchTopFilters-item">
    {{ 'specification/mileage' | lowercase | translate }}
  </button>
  <button (click)="onSliderMenuClick()" [matMenuTriggerFor]="price" mat-button class="BtnAuto SearchTopFilters-item">
    {{ 'globalMiss/price' | lowercase | translate }}
  </button>
</div>

<mat-menu #vehicleType="matMenu" class="AutoMenu">
  <div (click)="$event.stopPropagation()" class="SearchTopFilters-menu">
    <button (click)="onFilterChange(SearchStepKeys.majorCategory, 'All'); vehicleTypeTrigger.closeMenu()" mat-button
      class="BtnAuto SearchTopFilters-TypeButton tac">
      {{ 'global/all' | lowercase | translate }} {{ 'globalMiss/vehicleType' | lowercase | translate }}
    </button>
    <button *ngFor="let item of search.steps.majorCategory.list"
      (click)="onFilterChange(SearchStepKeys.majorCategory, item.id); vehicleTypeTrigger.closeMenu()" mat-button
      class="BtnAuto SearchTopFilters-TypeButton">
      <span class="SearchTopFilters-TypeButtonImg"><img *ngIf="item.icon" [src]="item.icon" [alt]="item.name"
          volvoLazyImage /></span>
      <span class="SearchTopFilters-TypeButtonName">{{ item.name === 'Semi Trailer' ? ('globalMiss/semiTrailer' |
        lowercase | translate) : null }}
      </span>
      <span class="SearchTopFilters-TypeButtonName">{{ item.name === 'Truck' ? ('globalMiss/truck' | lowercase |
        translate) :
        null }}
      </span>
      <span class="SearchTopFilters-TypeButtonName">{{ item.name === 'Trailer' ? ('globalMiss/trailer' | lowercase |
        translate)
        : null }}
      </span>
      <span class="SearchTopFilters-TypeButtonName">{{ item.name === 'Tractor' ? ('globalMiss/tractor' | lowercase |
        translate)
        : null }}
      </span>
    </button>
  </div>
</mat-menu>

<mat-menu #make="matMenu" class="AutoMenu">
  <div (click)="$event.stopPropagation()" class="SearchTopFilters-menu">
    <button (click)="onFilterChange(SearchStepKeys.model, 'All'); makeTrigger.closeMenu()" mat-button
      class="BtnAuto SearchTopFilters-TypeButton">
      {{ 'search/filter/makes' | lowercase | translate }}
    </button>
    <button *ngFor="let item of buckets?.make?.buckets"
      (click)="onFilterChange(SearchStepKeys.make, item.key); makeTrigger.closeMenu()" mat-button
      class="BtnAuto SearchTopFilters-TypeButton">
      {{ item.key }}
    </button>
  </div>
</mat-menu>

<mat-menu #model="matMenu" class="AutoMenu">
  <div (click)="$event.stopPropagation()" class="SearchTopFilters-menu">
    <button (click)="onFilterChange(SearchStepKeys.model, 'All'); modelTrigger.closeMenu()" mat-button
      class="BtnAuto SearchTopFilters-TypeButton">
      {{ 'search/filter/models' | lowercase | translate }}
    </button>
    <button *ngFor="let item of buckets?.model?.buckets"
      (click)="onFilterChange(SearchStepKeys.model, item.key); modelTrigger.closeMenu()" mat-button
      class="BtnAuto SearchTopFilters-TypeButton">
      {{ item.key }}
    </button>
  </div>
</mat-menu>

<mat-menu #mileage="matMenu" class="AutoMenu">
  <div (click)="$event.stopPropagation()" class="SearchTopFilters-menu IsSlider">
    <volvo-filter-slider [step]="search.steps[SearchStepKeys.mileageKm]" [aggregations]="buckets?.mileageKm"
      (filterChange)="onSliderChange($event, SearchStepKeys.mileageKm)">
    </volvo-filter-slider>
  </div>
</mat-menu>

<mat-menu #price="matMenu" class="AutoMenu">
  <div (click)="$event.stopPropagation()" class="SearchTopFilters-menu IsSlider">
    <volvo-filter-slider [step]="search.steps[SearchStepKeys.priceExclVatEuro]"
      [aggregations]="buckets?.priceExclVatEuro"
      (filterChange)="onSliderChange($event, SearchStepKeys.priceExclVatEuro)">
    </volvo-filter-slider>
  </div>
</mat-menu>
