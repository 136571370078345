<div *ngIf="isSelectedSite; else others">
  <div class="TruckPageLease" *ngIf="!disableVFS">
    <div class="TruckPageLease-title">{{ 'components/truckPageLease/title' | lowercase | translate }}</div>
    <div class="TruckPageLease-slider">
      <div class="TruckPageLease-sliderTitle">{{ 'components/truckPageLease/slider/titleA' | lowercase | translate }}:
      </div>
      <div class="TruckPageLease-sliderPrice">{{ currencySymbol }} {{leaseSliders.downPayment.value | number: '1.0-0':'de'
        }}</div>
      <ngx-slider [(value)]="leaseSliders.downPayment.value" *ngIf="isBrowser" (start)="onSlideStart()"
        [options]="leaseSliders.downPayment.options" (userChangeEnd)="onCalc()"></ngx-slider>
    </div>
    <div class="TruckPageLease-slider">
      <div class="TruckPageLease-sliderTitle">{{ 'components/truckPageLease/slider/titleB' | lowercase | translate }}
      </div>
      <div class="TruckPageLease-sliderPrice">
        {{leaseSliders.contractDuration.value }} {{ 'component/truckPageLease/months' | lowercase | translate }}
      </div>
      <ngx-slider [(value)]="leaseSliders.contractDuration.value" [options]="leaseSliders.contractDuration.options"
        (userChangeEnd)="onCalc()"></ngx-slider>
    </div>
    <div>
      <div class="TruckPageLease-sliderTitle">{{ 'components/truckPageLease/lease/title' | lowercase | translate }}:
      </div>
      <div class="TruckPageLease-sliderTitle" style="color: #141414;">
        {{ currencySymbol }} {{ totalFinancePayment | number:
        '1.0-0':'de' }} *
      </div>
      <div class="TruckPageLease-leasePrice">
        <!-- <ng-container *ngIf="financePaymentLoading; else totalFinancePaymentLoaded"><i class="fas fa-spinner fa-pulse"></i></ng-container> -->
        <ng-template>{{ currencySymbol }} {{ totalFinancePayment | number: '1.0-0':'de' }} </ng-template>
      </div>

      <div *ngIf="!defaultDisclaimer" class="TruckPageLease-info">*{{ 'components/truckPageLease/slider/disclamer' | lowercase | translate }}</div>
      <div *ngIf="defaultDisclaimer" class="TruckPageLease-info">*The calculation is an indicative monthly cost based on the geographical location of the dealership.</div>
      <div class="TruckPageLease-footer">
        <button (click)="onLeaseClick()" mat-raised-button class="TruckPageLease-requestButton volvofontmedium">
          {{ 'components/truckPageLease/requestQuote' | lowercase | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #others>
  <div class="TruckPageLease" *ngIf="!disableVFS">
    <div class="TruckPageLease-title">{{ 'uk/truck/vfs/title' | lowercase | translate }}</div>
    <div class="TruckPageLease-slider">
      <div class="TruckPageLease-sliderTitle">{{ 'uk/truck/vfs/deposit' | lowercase | translate }}:</div>
      <div class="TruckPageLease-sliderPrice">£{{ depositValue | number:'':'en-GB' }} + all of the VAT</div>
      <ngx-slider [(value)]="depositValue" [options]="depositOptions" (userChange)="onCalc()"></ngx-slider>
    </div>
    <div class="TruckPageLease-slider">
      <div class="TruckPageLease-sliderTitle">{{ 'uk/truck/vfs/agreementterm' | lowercase | translate }}:</div>
      <div class="TruckPageLease-sliderPrice">
        {{ monthsValue }} months
      </div>
      <ngx-slider
        [(value)]="monthsValue"
        [options]="monthsOptions"
        (userChange)="onCalc()"></ngx-slider>
    </div>
    <div class="TruckPageLease-lease">
      <div class="TruckPageLease-sliderTitle">{{ 'uk/truck/vfs/weeklyprice' | lowercase | translate }}: </div>
      <div class="TruckPageLease-sliderTitle" style="color: #53565A;">
        £{{ totalFinancePayment | number:'':'en-GB'}} *
      </div>
      <div class="TruckPageLease-leasePrice">
        <!-- <ng-container *ngIf="financePaymentLoading; else totalFinancePaymentLoaded"><i class="fas fa-spinner fa-pulse"></i></ng-container> -->
        <!-- <ng-template>£{{ totalFinancePayment }} </ng-template> -->
      </div>
      
      <div class="TruckPageLease-info readMeStyle">{{ 'uk/truck/vfs/indicativetruckprice' | lowercase | translate }}
        <span class="TruckPageLease-info-readme" (click)="onLeaseClick()">Read more</span>
      </div>
      <!-- <div class="TruckPageLease-footer">
        <button (click)="onLeaseClick()" mat-raised-button class="BigBtn" color="primary">
          {{ 'uk/components/truckPageLease/requestQuote' | lowercase | translate }}
        </button>
      </div> -->
    </div>
  </div>
</ng-template>