import { Component, OnInit } from '@angular/core';
import { Gallery, GalleryItem, ImageItem } from "@ngx-gallery/core";
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'volvo-what-we-have',
  templateUrl: './what-we-have.component.html',
  styleUrls: ['../who-we-are/who-we-are.component.scss']
})
export class WhatWeHaveComponent implements OnInit {
  items: GalleryItem[];
  
  constructor(public gallery: Gallery,private translateService: TranslateService,private metaTagService: Meta) { }

  settings = {
    licenseKey: "2199D8AD-8EEE4A3F-B9E911E7-3DD5070F",
    counter: false,
    plugins: [lgZoom,lgThumbnail],
    download: false,
    zoom: true
  };

  ngOnInit(): void {
    this.items = imageObject.map(item =>
      new ImageItem({ src: item.srcUrl, thumb: item.previewUrl, caption: item.caption})
    );
    
    this.basicLightboxExample();

    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/aboutus/whatwehavebeen/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/aboutus/whatwehavebeen/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/aboutus/whatwehavebeen/altImage'.toLowerCase())
    });
  }

  basicLightboxExample() {
    this.gallery.ref().load(this.items);
  }
}

const imageObject = [
  {
    srcUrl: 'assets/pages/aboutUs/Customer/NSimage9.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/NSimage9.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This Volvo FH truck was specially prepared with the Swedish livery for showcasing at this year’s Truckfest Scotland 2023 in Edinburgh. It generated a lot of interest and is now sold to Premier Truck.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/NSimage8.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/NSimage8.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Volvo FH 540 tractor unit delivered to Hillhouse Group. Sold on a 24-month Driveline-Pro warranty.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/NSimage7.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/NSimage7.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">One of two Volvo FH 460 Globetrotter’s was fully painted and liveried for B Mundell Ltd. Sold on a 24-month Driveline-Pro warranty.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/NSimage6.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/NSimage6.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Volvo FH 500 Dual Clutch Globetrotter tag delivered to Rhino Express.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/NSimage5.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/NSimage5.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Volvo FH 500 Globetrotter sold to a new customer – AM Transport, Campbeltown in Argll. Sold with a 24-month Driveline-Pro warranty.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/NSimage4.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/NSimage4.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Volvo FH 540 delivered to Keith Wilson, sold on a 12-month Driveline-Pro warranty.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/NSimage3.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/NSimage3.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Volvo FMX 8x4 tipper supplied to Hamilton Tarmac and sold on a 24-month Driveline-Pro warranty by Jamie Derrick.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/NSimage2.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/NSimage2.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Gordon Reid took delivery of this Volvo FH, supplied with 36 month Driveline-Pro warranty on a 36 month Volvo Blue Contract.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/NSimage1.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/NSimage1.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Supplied to James Y Burns in the Scottish borders. New paint and livery in customer colours. First Volvo in his fleet for many years.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image52.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image52.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This customer was looking for a brick & block truck for a new business venture and after searching online, he found one of our 2013 Volvo FM 450 6x2s perfect for his operation.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image53.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image53.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Volvo FE 320 curtainsider with brand new curtains and a tail-lift fitted during final preparation.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image54.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image54.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Ipswich-based customer is the proud owner of this four-year-old Volvo FH, recently handed over from our Volvo Approved range.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image55.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image55.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This smart Volvo FH - the first of two vehicles supplied to longstanding customer in Leighton Buzzard.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image56.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image56.webp',
    caption:''
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image57.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image57.webp',
    caption:''
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image1.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image1.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This customer has taken delivery of this smart black Volvo FH 500 Globetrotter XL tractor unit from the Volvo Approved range.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image2.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image2.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Two ex-McLaren Racing Volvo FHs</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image3.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image3.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">2016 Volvo FM 450 4x2 tractor unit supplied to a driver training school customer.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image4.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image4.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This tidy Volvo FH has been supplied with a six-month Volvo basic driveline warranty as standard - optionally extended by the customer to give a full year\'s cover.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image5.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image5.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This customer was looking for a new truck to move bricks and blocks after his current vehicle began to let him down.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image6.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image6.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This customer wanted to add a Volvo FH tractor unit to their fleet and this black Volvo FH proved to be perfect.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image7.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image7.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">What a fleet! '
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image8.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image8.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Two Volvo FHs delivered to this customer.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image9.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image9.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">A second 2020 ex-McLaren Racing Volvo FH Globetrotter XL tractor unit handed over to repeat customer.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image10.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image10.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">It\'s not one but two Volvo FM 450 6x2 prime movers joining the extensive fleet.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image11.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image11.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">2013 Volvo FM 450 Globetrotter with Atlas crane and tri-axle trailer to support a new work contract.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image12.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image12.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Built to an incredibly high factory spec, this left-hand drive 4x2 proved just the ticket for their fleet. And perfect for a customer which prides itself on providing comprehensive vehicle storage & delivery solutions across Europe.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image13.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image13.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Another two ex-McLaren Racing Volvo FH 4x2 tractor units delivered to regular customer.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image14.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image14.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">A second Volvo FM 4x2 Globetrotter handed over to this customer.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image15.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image15.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">First ex-McLaren 20-plate Volvo FH 540 supplied to a brand new customer.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image16.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image16.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This Volvo FM 6x2 tractor unit delivered to a valued customer on a two-year contract hire deal.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image17.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image17.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Volvo FM with drawbar trailer to this customer - first Volvo truck.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image18.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image18.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This tidy Volvo FL handed over, which will be used to transport classic cars all over Europe.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image19.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image19.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Euro 6 Volvo FM - fresh from a re-spray to match their fleet colours, together with Bluetooth prep and pre-wiring for beacons and rooflights.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image20.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image20.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Another happy customer'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image21.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image21.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">We were delighted to handover a third Volvo FH tractor unit to this customer.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image22.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image22.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This customer was looking to replace an older vehicle and came across this example online, which will be now used exclusively on a local builders merchant contract.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image23.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image23.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">“I absolutely love our latest twin FHs from Volvo Used Trucks. There was no question of where we were going to buy from, it was the best choice for our business.”</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image24.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image24.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Great to see this Volvo FH supplied from our Volvo Approved stock, with additional lighting and accessories.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image25.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image25.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Another happy customer with an ex-McLaren Racing truck.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image26.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image26.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Truck is averaging 11.4mpg…10.3mpg with a decker, what more could you possibly want.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image27.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image27.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">The Redditch-based business took delivery of five Volvo FM 4x2 tractor units on a two-year contract hire deal.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image28.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image28.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This Volvo FH 500 6x2 handed over to this customer to join their fleet.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image29.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image29.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This smart Volvo FH 500 6x2 tractor unit handed over to this early morning customer.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image30.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image30.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">2017 Volvo FH 500 6x2 tractor unit handed over to this customer.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image31.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image31.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This customer struggled to find a block-carrying truck locally in Ireland. He came across this Volvo FM 450 6x2 brick and block truck online.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image32.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image32.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This customer was looking for a good value Volvo FM 4x2 to accommodate a new business win. After searching online he found this 2016 Volvo FM 450 with just 422,000 km on the clock.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image33.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image33.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This tidy Volvo FE curtainsider handed over to this Leicester based customer, from our Volvo Approved range.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image34.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image34.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Volvo FH 500 from our Volvo Approved range to new customer, complete with a full service history.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image35.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image35.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">How good does this Volvo FH16 25 year special edition look?'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image36.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image36.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Delighted to welcome this new customer to Volvo Used Trucks Economy family.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image37.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image37.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This customer collects his latest Volvo FH 500 Globetrotter 6x2 tractor unit from our Cambridge depot.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image38.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image38.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Delighted to supply this Volvo FH LNG.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image39.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image39.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This customer\'s first Volvo FM 6x2 tractor unit to kick off his business.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image40.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image40.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Asked why he chose Volvo Used Trucks for his order, this customer tells us: “Because Volvo is just the best and most reliable truck on market!”</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image41.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image41.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">ex-McLaren Racing Volvo FH Globetrotter XL delivered to return customer.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image42.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image42.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Check out this transformation - this Volvo FM had the box body removed and a plant body with beavertail and fold-forward ramps installed.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image43.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image43.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Those top spec ex-McLaren Racing tractor units look good!</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image44.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image44.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This tidy Volvo FH 460 Globetrotter 6x2 tractor unit was selected after being prepared to exact specification - resprayed fully in white, with a roof-mounted lightbar consisting of four spotlights and two amber beacons.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image45.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image45.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This customer bought a Volvo FH 460 tractor unit after purchasing an FM to join his fleet.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image46.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image46.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">This Volvo FH supplied with a 12-month Volvo Blue Contract.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image47.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image47.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Volvo FM Globetrotter from our Volvo Approved range, supplied with a 24-month Volvo driveline warranty.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image48.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image48.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Low mileage (81,000km) Volvo FE 320 rigid from our Volvo Approved range sold.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image49.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image49.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">When this customer decided it was time for another Volvo, they opted for something a little bit special!</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image50.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image50.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">Fantastic to deliver this Volvo FH 500 Globetrotter 6x2 tag axle tractor unit.</div>'
  },
  {
    srcUrl: 'assets/pages/aboutUs/Customer/image51.webp',
    previewUrl: 'assets/pages/aboutUs/Customer/image51.webp',
    caption:'<div style="font-family: \'Volvo Novum Regular\'">We were extremely impressed with both the ease of purchasing and aftersales support provided to us by Steve Worts.</div>'
  },
];
