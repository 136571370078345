import { UntypedFormBuilder, Validators } from "@angular/forms";
import { Component, Input, OnInit } from "@angular/core";

import { MailApiService } from "~/core/api/mail-api/mail-api.service";
import { SearchStateFilter } from "~/core/store/search/search.model";
import { SearchService } from "~/core/store/search/search.service";
import { HtmlRoutePages } from "~/app-routing.model";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { LocalesService } from "~/core/store/locales/locales.service";
@Component({
    selector: "volvo-stock-empty",
    templateUrl: "./stock-empty.component.html",
    styleUrls: ["./stock-empty.component.scss"],
})
export class StockEmptyComponent implements OnInit {
    @Input() search: SearchStateFilter;
    @Input() site: string;

    public dealershipCountries: {};
    public selectedCountry: {} = null;
    public showCities: {} = null;
    public showContactForm: boolean = false;

    public dealershipCities = [
        { id: "lt", cities: ["Klaipeda", "Kaunas", "Vilnius", "Šiauliai"] },
        { id: "cz", cities: ["Čestlice", "Olomouc"] },
        {
            id: "dk",
            cities: [
                "Taastrup",
                "Aalborg",
                "Aarhus",
                "Holstebro",
                "Randers",
                "Viborg",
            ],
        },
    ];

    private s0: Subscription;
    private region: string;

    noSpecialCharRegEx = /^[\p{L}\- \.\?!'\r\n,0-9\(\)]+$/u;

    emptyStockForm = this.fb.group({
        email: ["", [Validators.email, Validators.required]],
        location: ["", [Validators.required]],
        city: [""],
        message: ['', [Validators.pattern(this.noSpecialCharRegEx)]],
        privacy: [false, Validators.pattern("true")],
    });
    activatedRoute: any;
    qf: any;

    constructor(
        public searchService: SearchService,
        private mailApiService: MailApiService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private localizeRouterService: LocalizeRouterService,
        private localesService: LocalesService
    ) { }

    ngOnInit(): void {
        this.dealershipCountries = [
            { label: "Czech Republic", id: "cz" },
            { label: "Denmark", id: "dk" },
            { label: "Lithuania", id: "lt" },
        ];

        this.initSubscriptions();
    }

    onEmptyStockFormSubmit(): void {
        if (this.emptyStockForm.valid) {
            const body = this.transformDataForServer(this.emptyStockForm.value);
            this.mailApiService.postMailOutOfStock(body);
        }
    }

    public onSelect(event): void {
        let selectCountry = this.dealershipCities.find(
            (i) => i.id === this.selectedCountry
        );
        this.showCities = selectCountry.cities;
    }

    gotoContact(): void {
        this.router.navigate([
            this.localizeRouterService.translateRoute(HtmlRoutePages.contact),
        ]);
    }

    resetAllFilters(): void {
        this.searchService.resetSearch();
        this.router.navigate(
            [this.localizeRouterService.translateRoute(HtmlRoutePages.search)],
            {
                relativeTo: this.activatedRoute,
                queryParams: { qf: this.qf },
                queryParamsHandling: "merge", // remove to replace all query params by provided
            }
        );
    }

    private transformDataForServer(data) {
        return {
            dealershipcountry: data.location,
            dealership: data.city,
            email: data.email,
            region: this.region,
            site: "volvoselected",
            other: [
                {
                    name: "Message",
                    value: data.message,
                },
                {
                    name: "Privacy accepted",
                    value: data.privacy,
                },
            ],
        };
    }

    private initSubscriptions() {
        this.s0 = this.localesService.region$
            .subscribe(region => {
                this.region = region.curRegion.id;
            });

    }
}
