import { TruckCollationStateInterface } from '~/core/store/truck-collation/truck-collation.model';
import { TruckCollationState } from '~/core/store/truck-collation/truck-collation.state';
import { DealershipsStateInterface } from '~/core/store/dealerships/dealerships.model';
import { DealershipsState } from '~/core/store/dealerships/dealerships.state';
import { BucketsStateInterface } from '~/core/store/buckets/buckets.model';
import { LocalesStateInterface } from '~/core/store/locales/locales.model';
import { SearchStateInterface } from '~/core/store/search/search.model';
import { TrucksStateInterface } from '~/core/store/trucks/trucks.model';
import { LocalesState } from '~/core/store/locales/locales.state';
import { BucketsState } from '~/core/store/buckets/buckets.state';
import { SearchState } from '~/core/store/search/search.state';
import { TrucksState } from '~/core/store/trucks/trucks.state';
import { CountrySettingsStateInterface, RegionalSettingsStateInterface } from './regionalSettings/regionalSettings.model';
import { CountrySettingsState, RegionalSettingsState } from './regionalSettings/regionalSettings.state';
import { VfsState } from './vfs/vfs.state';

export interface AppState {
    buckets: BucketsStateInterface;
    language: LocalesStateInterface;
    search: SearchStateInterface;
    trucks: TrucksStateInterface;
    truckCollation: TruckCollationStateInterface;
    dealerships: DealershipsStateInterface;
    regionalSettings: RegionalSettingsStateInterface;
    countrySettings: CountrySettingsStateInterface;
}

export enum AppStateList {
    buckets = 'buckets',
    language = 'language',
    search = 'search',
    trucks = 'trucks',
    truckCollation = 'truckCollation',
    dealerships = 'dealerships',
    regionalSettings = 'regionalSettings',
    countrySettings = 'countrySettings'
}

export const VolvoState = [
    SearchState,
    LocalesState,
    BucketsState,
    TruckCollationState,
    DealershipsState,
    TrucksState,
    RegionalSettingsState,
    CountrySettingsState,
    VfsState
];
