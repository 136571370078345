import { Component, Input, OnInit } from '@angular/core';
import { Truck } from '~/core/store/trucks/trucks.model';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { regionEnum } from '~/core/declaration/declaration';
import { RegionalSettingsService } from '~/core/store/regionalSettings/regionalSettings.service';
import { AppState } from '~/core/store';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { Store } from '@ngxs/store';
@Component({
  selector: 'volvo-new-in-stock',
  templateUrl: './new-in-stock.component.html',
  styleUrls: ['./new-in-stock.component.scss'],
})
export class NewInStockComponent implements OnInit {
  isLoaded = false;
  leftNavDisabled = false;
  rightNavDisabled = false;
  region: string;
  regionEnum = regionEnum;
  userNavigationText = 'New in stock';
  @Input() set trucks(trucks: Truck[]) {
    if (trucks) {
      if(this.region)
      {
        this.region===regionEnum.TX?this.bestTrucks = trucks.filter(res => res.advertisementCategory === 'Trade and Export'):this.bestTrucks = trucks;
        this.isLoaded = true;
      }
    }
  }

  @Input() title: string;
  @Input() isTruckPage: boolean;

  bestTrucks: Truck[];
  site: string;
  isSelectedSite: boolean;
  regionalSettings: RegionSettings;
  constructor(public globalUtils: GlobalUtils,private regionalSettingsService: RegionalSettingsService, public store: Store) {
    const region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === region)[0]);
    

    if(this.regionalSettings){
      this.region = this.regionalSettings?.region;
      if(this.regionalSettings?.site === "volvoselected")
        this.isSelectedSite = true;
    }
  }

  ngOnInit(): void {
   
  }

  ngAfterViewInit(): void {
    if (this.bestTrucks) {
      this.isLoaded = true;
    }
  }


  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }
}
