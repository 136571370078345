import { PipeTransform, Pipe } from '@angular/core';
import { Translation } from '../store/trucks/trucks.model.base';
@Pipe({
  name: 'lookup',
})
export class LookupPipe implements PipeTransform {
  transform(value: any, payload: any, hasValueSuffix = '', nullValue = '----'): string {
    const iso2Code = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
    var potential = [];
    var resolved = '';

    if (!value) {
      return nullValue;
    }

    if (iso2Code === 'en' || iso2Code === 'en-us' || iso2Code === 'en-za' || iso2Code === 'en-gb' || iso2Code === 'en-tx') {
      resolved = value ? value.engPhrase : 'nullValue';
      if (resolved === 'nullValue') {
        
      }
    } else {
      if (!value.translation) {
        console.warn({
          Message: 'No translations available',
          Lookup: value.engPhrase,
          LangCode: iso2Code,
        });
        return value.engPhrase;
      }
      try {
        potential = value.translation.filter(
          (t) => t.languageCodeIso3 === this.langCodes.filter((l) => l.iso1 === iso2Code)[0].iso3
        );
        resolved = potential.length > 0 ? potential[0].value : value.engPhrase;

        // 
        //   Lookup: value,
        //   LangCode: iso2Code,
        //   ISO3: this.langCodes.filter((l) => l.iso1 === iso2Code)[0].iso3,
        //   Potential: potential,
        //   Resolved: resolved,
        // });

      } catch (error) {
        console.error({
          Message: "Error looking up UTAH translation",
          Lookup: value,
          LangCode: iso2Code,
          error: error
        });
      }
    }

    return resolved;
  }

  langCodes = [
    {
      name: 'Schweiz',
      iso1: 'ch-de',
      iso3: 'ger',
    },
    {
      name: 'Suisse',
      iso1: 'ch-fr',
      iso3: 'fre',
    },
    {
      name: 'Belgique',
      iso1: 'be-fr',
      iso3: 'fre',
    },
    {
      name: 'België',
      iso1: 'be-nl',
      iso3: 'dut',
    },
    {
      name: 'Österreich',
      iso1: 'at',
      iso3: 'ger',
    },
    {
      name: 'Abkhazian',
      iso1: 'ab',
      iso3: 'abk',
    },
    {
      name: 'Afar',
      iso1: 'aa',
      iso3: 'aar',
    },
    {
      name: 'Afrikaans',
      iso1: 'af',
      iso3: 'afr',
    },
    {
      name: 'Akan',
      iso1: 'ak',
      iso3: 'aka',
    },
    {
      name: 'Albanian',
      iso1: 'sq',
      iso3: 'alb',
    },
    {
      name: 'Amharic',
      iso1: 'am',
      iso3: 'amh',
    },
    {
      name: 'Arabic',
      iso1: 'ar',
      iso3: 'ara',
    },
    {
      name: 'Aragonese',
      iso1: 'an',
      iso3: 'arg',
    },
    {
      name: 'Armenian',
      iso1: 'hy',
      iso3: 'arm',
    },
    {
      name: 'Assamese',
      iso1: 'as',
      iso3: 'asm',
    },
    {
      name: 'Avaric',
      iso1: 'av',
      iso3: 'ava',
    },
    {
      name: 'Avestan',
      iso1: 'ae',
      iso3: 'ave',
    },
    {
      name: 'Aymara',
      iso1: 'ay',
      iso3: 'aym',
    },
    {
      name: 'Azerbaijani',
      iso1: 'az',
      iso3: 'aze',
    },
    {
      name: 'Bambara',
      iso1: 'bm',
      iso3: 'bam',
    },
    {
      name: 'Basque',
      iso1: 'eu',
      iso3: 'baq',
    },
    {
      name: 'Belarusian',
      iso1: 'be',
      iso3: 'bel',
    },
    {
      name: 'Bengali',
      iso1: 'bn',
      iso3: 'ben',
    },
    {
      name: 'Bihari languages',
      iso1: 'bh',
      iso3: 'bih',
    },
    {
      name: 'Bislama',
      iso1: 'bi',
      iso3: 'bis',
    },
    {
      name: 'Bosnian',
      iso1: 'ba',
      iso3: 'bos',
    },
    {
      name: 'Breton',
      iso1: 'br',
      iso3: 'bre',
    },
    {
      name: 'Bulgarian',
      iso1: 'bg',
      iso3: 'bul',
    },
    {
      name: 'Burmese',
      iso1: 'my',
      iso3: 'bur',
    },
    {
      name: 'Catalan, Valencian',
      iso1: 'ca',
      iso3: 'cat',
    },
    {
      name: 'Chamorro',
      iso1: 'ch',
      iso3: 'cha',
    },
    {
      name: 'Chechen',
      iso1: 'ce',
      iso3: 'che',
    },
    {
      name: 'Chichewa, Chewa, Nyanja',
      iso1: 'ny',
      iso3: 'nya',
    },
    {
      name: 'Chinese',
      iso1: 'zh',
      iso3: 'chi',
    },
    {
      name: 'Chuvash',
      iso1: 'cv',
      iso3: 'chv',
    },
    {
      name: 'Cornish',
      iso1: 'kw',
      iso3: 'cor',
    },
    {
      name: 'Corsican',
      iso1: 'co',
      iso3: 'cos',
    },
    {
      name: 'Cree',
      iso1: 'cr',
      iso3: 'cre',
    },
    {
      name: 'Croatian',
      iso1: 'hr',
      iso3: 'hrv',
    },
    {
      name: 'Czech',
      iso1: 'cz',
      iso3: 'cze',
    },
    {
      name: 'Danish',
      iso1: 'da',
      iso3: 'dan',
    },
    {
      name: 'Danish',
      iso1: 'dk',
      iso3: 'dan',
    },
    {
      name: 'Divehi, Dhivehi, Maldivian',
      iso1: 'dv',
      iso3: 'div',
    },
    {
      name: 'Dutch,�Flemish',
      iso1: 'nl',
      iso3: 'dut',
    },
    {
      name: 'Dzongkha',
      iso1: 'dz',
      iso3: 'dzo',
    },
    {
      name: 'English',
      iso1: 'en',
      iso3: 'eng',
    },
    {
      name: 'English',
      iso1: 'en-gb',
      iso3: 'eng-gb',
    },
    {
      name: 'English',
      iso1: 'en-tx',
      iso3: 'eng-tx',
    },
    {
      name: 'Esperanto',
      iso1: 'eo',
      iso3: 'epo',
    },
    {
      name: 'Estonian',
      iso1: 'ee',
      iso3: 'est',
    },
    {
      name: 'Faroese',
      iso1: 'fo',
      iso3: 'fao',
    },
    {
      name: 'Fijian',
      iso1: 'fj',
      iso3: 'fij',
    },
    {
      name: 'Finnish',
      iso1: 'fi',
      iso3: 'fin',
    },
    {
      name: 'French',
      iso1: 'fr',
      iso3: 'fre',
    },
    {
      name: 'Fulah',
      iso1: 'ff',
      iso3: 'ful',
    },
    {
      name: 'Galician',
      iso1: 'gl',
      iso3: 'glg',
    },
    {
      name: 'Georgian',
      iso1: 'ka',
      iso3: 'geo',
    },
    {
      name: 'German',
      iso1: 'de',
      iso3: 'ger',
    },
    {
      name: 'Greek, Modern (1453�)',
      iso1: 'el',
      iso3: 'gre',
    },
    {
      name: 'Guarani',
      iso1: 'gn',
      iso3: 'grn',
    },
    {
      name: 'Gujarati',
      iso1: 'gu',
      iso3: 'guj',
    },
    {
      name: 'Haitian, Haitian Creole',
      iso1: 'ht',
      iso3: 'hat',
    },
    {
      name: 'Hausa',
      iso1: 'ha',
      iso3: 'hau',
    },
    {
      name: 'Hebrew',
      iso1: 'he',
      iso3: 'heb',
    },
    {
      name: 'Herero',
      iso1: 'hz',
      iso3: 'her',
    },
    {
      name: 'Hindi',
      iso1: 'hi',
      iso3: 'hin',
    },
    {
      name: 'Hiri Motu',
      iso1: 'ho',
      iso3: 'hmo',
    },
    {
      name: 'Hungarian',
      iso1: 'hu',
      iso3: 'hun',
    },
    {
      name: 'Indonesian',
      iso1: 'id',
      iso3: 'ind',
    },
    {
      name: 'Interlingue, Occidental',
      iso1: 'ie',
      iso3: 'ile',
    },
    {
      name: 'Irish',
      iso1: 'ga',
      iso3: 'gle',
    },
    {
      name: 'Igbo',
      iso1: 'ig',
      iso3: 'ibo',
    },
    {
      name: 'Inupiaq',
      iso1: 'ik',
      iso3: 'ipk',
    },
    {
      name: 'Ido',
      iso1: 'io',
      iso3: 'ido',
    },
    {
      name: 'Icelandic',
      iso1: 'is',
      iso3: 'ice',
    },
    {
      name: 'Italian',
      iso1: 'it',
      iso3: 'ita',
    },
    {
      name: 'Italian',
      iso1: 'ch-it',
      iso3: 'ita-ch',
    },
    {
      name: 'Inuktitut',
      iso1: 'iu',
      iso3: 'iku',
    },
    {
      name: 'Japanese',
      iso1: 'ja',
      iso3: 'jpn',
    },
    {
      name: 'Javanese',
      iso1: 'jv',
      iso3: 'jav',
    },
    {
      name: 'Kalaallisut, Greenlandic',
      iso1: 'kl',
      iso3: 'kal',
    },
    {
      name: 'Kannada',
      iso1: 'kn',
      iso3: 'kan',
    },
    {
      name: 'Kanuri',
      iso1: 'kr',
      iso3: 'kau',
    },
    {
      name: 'Kashmiri',
      iso1: 'ks',
      iso3: 'kas',
    },
    {
      name: 'Kazakh',
      iso1: 'kk',
      iso3: 'kaz',
    },
    {
      name: 'Central Khmer',
      iso1: 'km',
      iso3: 'khm',
    },
    {
      name: 'Kikuyu',
      iso1: 'ki',
      iso3: 'kik',
    },
    {
      name: 'Kinyarwanda',
      iso1: 'rw',
      iso3: 'kin',
    },
    {
      name: 'Kirghiz',
      iso1: 'ky',
      iso3: 'kir',
    },
    {
      name: 'Komi',
      iso1: 'kv',
      iso3: 'kom',
    },
    {
      name: 'Kongo',
      iso1: 'kg',
      iso3: 'kon',
    },
    {
      name: 'Korean',
      iso1: 'ko',
      iso3: 'kor',
    },
    {
      name: 'Kurdish',
      iso1: 'ku',
      iso3: 'kur',
    },
    {
      name: 'Kuanyama',
      iso1: 'kj',
      iso3: 'kua',
    },
    {
      name: 'Latin',
      iso1: 'la',
      iso3: 'lat',
    },
    {
      name: 'Luxembourgish',
      iso1: 'lb',
      iso3: 'ltz',
    },
    {
      name: 'Ganda',
      iso1: 'lg',
      iso3: 'lug',
    },
    {
      name: 'Limburgan',
      iso1: 'li',
      iso3: 'lim',
    },
    {
      name: 'Lingala',
      iso1: 'ln',
      iso3: 'lin',
    },
    {
      name: 'Lao',
      iso1: 'lo',
      iso3: 'lao',
    },
    {
      name: 'Lithuanian',
      iso1: 'lt',
      iso3: 'lit',
    },
    {
      name: 'Luba-Katanga',
      iso1: 'lu',
      iso3: 'lub',
    },
    {
      name: 'Latvian',
      iso1: 'lv',
      iso3: 'lav',
    },
    {
      name: 'Manx',
      iso1: 'gv',
      iso3: 'glv',
    },
    {
      name: 'Macedonian',
      iso1: 'mk',
      iso3: 'mac',
    },
    {
      name: 'Malagasy',
      iso1: 'mg',
      iso3: 'mlg',
    },
    {
      name: 'Malay',
      iso1: 'ms',
      iso3: 'may',
    },
    {
      name: 'Malayalam',
      iso1: 'ml',
      iso3: 'mal',
    },
    {
      name: 'Maltese',
      iso1: 'mt',
      iso3: 'mlt',
    },
    {
      name: 'Maori',
      iso1: 'mi',
      iso3: 'mao',
    },
    {
      name: 'Marathi',
      iso1: 'mr',
      iso3: 'mar',
    },
    {
      name: 'Marshallese',
      iso1: 'mh',
      iso3: 'mah',
    },
    {
      name: 'Mongolian',
      iso1: 'mn',
      iso3: 'mon',
    },
    {
      name: 'Nauru',
      iso1: 'na',
      iso3: 'nau',
    },
    {
      name: 'Navajo, Navaho',
      iso1: 'nv',
      iso3: 'nav',
    },
    {
      name: 'North Ndebele',
      iso1: 'nd',
      iso3: 'nde',
    },
    {
      name: 'Nepali',
      iso1: 'ne',
      iso3: 'nep',
    },
    {
      name: 'Ndonga',
      iso1: 'ng',
      iso3: 'ndo',
    },
    {
      name: 'Norwegian Bokm�l',
      iso1: 'nb',
      iso3: 'nob',
    },
    {
      name: 'Norwegian Nynorsk',
      iso1: 'nn',
      iso3: 'nno',
    },
    {
      name: 'Norwegian',
      iso1: 'no',
      iso3: 'nor',
    },
    {
      name: 'Sichuan Yi',
      iso1: 'ii',
      iso3: 'iii',
    },
    {
      name: 'South Ndebele',
      iso1: 'nr',
      iso3: 'nbl',
    },
    {
      name: 'Occitan',
      iso1: 'oc',
      iso3: 'oci',
    },
    {
      name: 'Ojibwa',
      iso1: 'oj',
      iso3: 'oji',
    },
    {
      name: 'Oromo',
      iso1: 'om',
      iso3: 'orm',
    },
    {
      name: 'Oriya',
      iso1: 'or',
      iso3: 'ori',
    },
    {
      name: 'Ossetian',
      iso1: 'os',
      iso3: 'oss',
    },
    {
      name: 'Punjabi',
      iso1: 'pa',
      iso3: 'pan',
    },
    {
      name: 'Pali',
      iso1: 'pi',
      iso3: 'pli',
    },
    {
      name: 'Persian',
      iso1: 'fa',
      iso3: 'per',
    },
    {
      name: 'Polish',
      iso1: 'pl',
      iso3: 'pol',
    },
    {
      name: 'Pashto',
      iso1: 'ps',
      iso3: 'pus',
    },
    {
      name: 'Portuguese',
      iso1: 'pt',
      iso3: 'por',
    },
    {
      name: 'Quechua',
      iso1: 'qu',
      iso3: 'que',
    },
    {
      name: 'Romansh',
      iso1: 'rm',
      iso3: 'roh',
    },
    {
      name: 'Rundi',
      iso1: 'rn',
      iso3: 'run',
    },
    {
      name: 'Romanian',
      iso1: 'ro',
      iso3: 'rum',
    },
    {
      name: 'Russian',
      iso1: 'ru',
      iso3: 'rus',
    },
    {
      name: 'Sanskrit',
      iso1: 'sa',
      iso3: 'san',
    },
    {
      name: 'Sardinian',
      iso1: 'sc',
      iso3: 'srd',
    },
    {
      name: 'Sindhi',
      iso1: 'sd',
      iso3: 'snd',
    },
    {
      name: 'Samoan',
      iso1: 'sm',
      iso3: 'smo',
    },
    {
      name: 'Sango',
      iso1: 'sg',
      iso3: 'sag',
    },
    {
      name: 'Serbian',
      iso1: 'rs',
      iso3: 'srp',
    },
    {
      name: 'Gaelic',
      iso1: 'gd',
      iso3: 'gla',
    },
    {
      name: 'Shona',
      iso1: 'sn',
      iso3: 'sna',
    },
    {
      name: 'Slovak',
      iso1: 'sk',
      iso3: 'slo',
    },
    {
      name: 'Slovenian',
      iso1: 'si',
      iso3: 'slv',
    },
    {
      name: 'Somali',
      iso1: 'so',
      iso3: 'som',
    },
    {
      name: 'Southern Sotho',
      iso1: 'st',
      iso3: 'sot',
    },
    {
      name: 'Spanish',
      iso1: 'es',
      iso3: 'spa',
    },
    {
      name: 'Sundanese',
      iso1: 'su',
      iso3: 'sun',
    },
    {
      name: 'Swahili',
      iso1: 'sw',
      iso3: 'swa',
    },
    {
      name: 'Swati',
      iso1: 'ss',
      iso3: 'ssw',
    },
    {
      name: 'Swedish',
      iso1: 'se',
      iso3: 'swe',
    },
    {
      name: 'Tamil',
      iso1: 'ta',
      iso3: 'tam',
    },
    {
      name: 'Telugu',
      iso1: 'te',
      iso3: 'tel',
    },
    {
      name: 'Tajik',
      iso1: 'tg',
      iso3: 'tgk',
    },
    {
      name: 'Thai',
      iso1: 'th',
      iso3: 'tha',
    },
    {
      name: 'Tigrinya',
      iso1: 'ti',
      iso3: 'tir',
    },
    {
      name: 'Tibetan',
      iso1: 'bo',
      iso3: 'tib',
    },
    {
      name: 'Turkmen',
      iso1: 'tk',
      iso3: 'tuk',
    },
    {
      name: 'Tagalog',
      iso1: 'tl',
      iso3: 'tgl',
    },
    {
      name: 'Tswana',
      iso1: 'tn',
      iso3: 'tsn',
    },
    {
      name: 'Tonga',
      iso1: 'to',
      iso3: 'ton',
    },
    {
      name: 'Turkish',
      iso1: 'tr',
      iso3: 'tur',
    },
    {
      name: 'Tsonga',
      iso1: 'ts',
      iso3: 'tso',
    },
    {
      name: 'Tatar',
      iso1: 'tt',
      iso3: 'tat',
    },
    {
      name: 'Twi',
      iso1: 'tw',
      iso3: 'twi',
    },
    {
      name: 'Tahitian',
      iso1: 'ty',
      iso3: 'tah',
    },
    {
      name: 'Uighur',
      iso1: 'ug',
      iso3: 'uig',
    },
    {
      name: 'Ukrainian',
      iso1: 'uk',
      iso3: 'ukr',
    },
    {
      name: 'Urdu',
      iso1: 'ur',
      iso3: 'urd',
    },
    {
      name: 'Uzbek',
      iso1: 'uz',
      iso3: 'uzb',
    },
    {
      name: 'Venda',
      iso1: 've',
      iso3: 'ven',
    },
    {
      name: 'Vietnamese',
      iso1: 'vi',
      iso3: 'vie',
    },
    {
      name: 'Volap�k',
      iso1: 'vo',
      iso3: 'vol',
    },
    {
      name: 'Walloon',
      iso1: 'wa',
      iso3: 'wln',
    },
    {
      name: 'Welsh',
      iso1: 'cy',
      iso3: 'wel',
    },
    {
      name: 'Wolof',
      iso1: 'wo',
      iso3: 'wol',
    },
    {
      name: 'Western Frisian',
      iso1: 'fy',
      iso3: 'fry',
    },
    {
      name: 'Xhosa',
      iso1: 'xh',
      iso3: 'xho',
    },
    {
      name: 'Yiddish',
      iso1: 'yi',
      iso3: 'yid',
    },
    {
      name: 'Yoruba',
      iso1: 'yo',
      iso3: 'yor',
    },
    {
      name: 'Zhuang',
      iso1: 'za',
      iso3: 'zha',
    },
    {
      name: 'Zulu',
      iso1: 'zu',
      iso3: 'zul',
    },
  ];
}
