import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {Injectable} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';

import {AddTruck} from '~/core/store/truck-collation/truck-collation.actions';
import {Truck} from '~/core/store/trucks/trucks.model';


@Injectable({providedIn: 'root'})
export class TruckCollationService {

    @Select(state => state.truckCollation.trucks)
    public trucks$: Observable<Truck[]>;

    @Select(state => state.truckCollation.totalTrucks)
    public totalTrucks$: Observable<number>;


    @Dispatch()
    public addTruck(payload: Truck) {
        return new AddTruck(payload);
    }
}
