import { Component, Input, OnDestroy, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { MatDialog } from '@angular/material/dialog';

import { CalculatorPopupComponent } from '~/popups/calculator-popup/calculator-popup.component';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { VfsApiService } from '~/core/api/vfs-api/vfs-api.service';
import { VfsApiRes } from '~/core/api/vfs-api/vfs-api.model';
import { Truck } from '~/core/store/trucks/trucks.model';
import { Subscription } from 'rxjs';
import { LocalesService } from '~/core/store/locales/locales.service';
import { VfsService } from '~/core/store/vfs/vfs.service';
import { Store } from '@ngxs/store';
import { LocalesStateCountry } from '~/core/store/locales/locales.model';
import { AppState } from '~/core/store';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { CounterService } from '~/core/services/counter/counter-service';
import { ChangeContext, Options, PointerType } from '@angular-slider/ngx-slider';

export interface LeaseSliders {
  downPayment: LeaseSlider;
  contractDuration: LeaseSlider;
  optionToPurchase: LeaseSlider;
}

export interface LeaseSlider {
  value: number;
  options: Options
}

@AutoUnsubscribe()
@Component({
  selector: 'volvo-truck-page-lease',
  templateUrl: './truck-page-lease.component.html',
  styleUrls: ['./truck-page-lease.component.scss'],
})
export class TruckPageLeaseComponent implements OnInit, OnDestroy {
  @Input() truck: Truck;
  @Output() sliderValueChange = new EventEmitter<number[]>();
  isBrowser = false;
  financePaymentLoading = true;

  s0: Subscription;
  defaultValue: number;
  // ?sales_price=1&down_payment=12&no_of_terms=1&residual_value=1
  leaseSliders: LeaseSliders = {
    downPayment: {
      value: 10,
      options: {
        // Down payment: [Default 10% Minimum 10% Maximum 50%]
        floor: 10,
        ceil: 50,
        step: 100,
        disabled: false,
        hidePointerLabels: true,
        hideLimitLabels: true,
        showSelectionBar: true
      },
    },
    contractDuration: {
      value: 48,
      options: {
        // Term selection: [Minimum 24 months, Default 24 months, Maximum 60 months, 6 months step]
        floor: 12,
        ceil: 60,
        step: 12,
        disabled: false,
        hidePointerLabels: true,
        hideLimitLabels: true,
        showSelectionBar: true
      },
    },
    optionToPurchase: {
      value: 0,
      options: {
        floor: 0,
        ceil: 0,
        step: 1,
        disabled: true,
        hidePointerLabels: true,
        hideLimitLabels: true,
        showSelectionBar: true
      },
    },
  };

  totalFinancePayment: number;
  disableVFS = true;
  truckPrice: number;
  currencySymbol: string;
  regionalSettings: RegionSettings;
  isSelectedSite: boolean = false;

  depositValue: number = 5000;
  depositOptions: Options = {
    floor: 5000,
    ceil: 30000,
    step: 1000,
    hideLimitLabels: true,
    hidePointerLabels: true,
  };

  monthsValue: number = 24;
  monthsOptions: Options = {
    floor: 12,
    ceil: 60,
    step: 12,
    hideLimitLabels: true,
    hidePointerLabels: true,
  };

  defaultDisclaimer: boolean = false;
  constructor(
    public dialog: MatDialog,
    private globalUtils: GlobalUtils,
    private vfsApiService: VfsApiService,
    public localesService: LocalesService,
    public vfsService: VfsService,
    private store: Store,
    private counterService: CounterService) {
    this.isBrowser = this.globalUtils.isBrowser();
    const country = this.store.selectSnapshot<LocalesStateCountry>((state: AppState) => state.language.country);

    const region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === region)[0]);
    
    if(this.regionalSettings?.site === 'volvoselected') 
      this.isSelectedSite = true;

    if (this.isSelectedSite && (
      
      country.curCountry.id === 'ba' ||
      country.curCountry.id === 'ru')
    ) {
      this.disableVFS = true;
    } else {
      this.disableVFS = false;
    }

    if(!country.curCountry?.id){
      this.defaultDisclaimer = true;
    }
    
    this.initSubscriptions();
  }

  ngOnInit(): void { }

  //react to changes on a parent component
  ngOnChanges(changes: SimpleChanges): void {
    this.setInitialDownPayment();
    this.onCalc(true);
    const countryObj = this.store.selectSnapshot<LocalesStateCountry>((state: AppState) => state.language.country);

    for (const prop in changes) {
      if (changes.hasOwnProperty(prop)) {
        var RegDate =
          this.truck.specification.dateFirstRegistration !== undefined
            ? new Date(this.truck.specification.dateFirstRegistration).getFullYear()
            : 100;

        if (prop === 'truck') {
          if (this.truck.specification.make !== 'Volvo') {
            this.disableVFS = true;
          } else if (!this.truck.ShowPrice) {
            this.disableVFS = true;
          } else if (new Date().getFullYear() - RegDate >= 7) {
            this.disableVFS = true;
          } else if (this.truck.pricing && this.truck.pricing.priceExclVatEuro < 1000) {
            this.disableVFS = true;
          } else {
            if (this.isSelectedSite && (
              countryObj.curCountry.id === 'ba' ||
              countryObj.curCountry.id === 'ru')
            ) {
              this.disableVFS = true;
            } else {
              this.disableVFS = false;
            }
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.s0.unsubscribe;
  }

  onLeaseClick(): void {
    if (this.truck) {
      var buttonClick = this.counterService.returnButtonClickObj("VFS Quote", this.truck.truckId);
      this.counterService.postEmailPhoneButtonClicks("Truck Ad", buttonClick);
      //this.counterService.postVfsRequestQuote("Truck Ad","Request a quote",this.truck.stockNumber); //log only when show number is clicked
    }

    this.dialog.open(CalculatorPopupComponent, {
      panelClass: 'volvo-popup-vfs',
      autoFocus: false,
      data: {
        truck: this.truck,
        leaseSliders: this.leaseSliders,
      },
    });
  }

  onSlideStart(): void {
    this.financePaymentLoading = true;
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      event: 'CalculatorInteractions',
    });
  }

  async onCalc(defaultFlag: boolean = false): Promise<void> {
    if (this.regionalSettings.zarPriceEnabled)
      this.truckPrice = this.truck.pricing.priceExclVat.value;
    else
      this.truckPrice = this.truck.pricing.priceExclVatEuro;

    const stockCountry = this.truck.stockLocation?.country;
    const stockCountryIsoCode = this.truck.stockLocation?.countryCodeISO2;
    if(this.isSelectedSite){
      this.vfsApiService
        .getTotalFinancePayment({
          down_payment: this.getDownPaymentPercent(),
          no_of_terms: this.leaseSliders.contractDuration.value,
          sales_price: this.truck.pricing && this.truckPrice ? this.truckPrice : 0,
          truck_age: new Date().getUTCFullYear() - new Date(this.truck.specification.dateFirstRegistration).getUTCFullYear(),
          truck_make: this.truck.specification.make,
          truck_id: this.truck.specification.licensePlate,
          site: "volvoselected",
          region: this.regionalSettings.region,
          stockCountry: stockCountry,
          stockCountryIsoCode: stockCountryIsoCode
        })
        .subscribe((res: VfsApiRes) => {
          if (res && res.totalFinancePayment) {
            this.totalFinancePayment = res.totalFinancePayment;
            this.financePaymentLoading = false;
            if (defaultFlag) {
              this.defaultValue = this.totalFinancePayment;
            }
            this.sliderValueChange.emit([this.leaseSliders.contractDuration.value, this.leaseSliders.downPayment.value, this.totalFinancePayment, this.defaultValue]);
          }
        });
    }
    else{
      this.vfsApiService
      .getTotalFinancePayment({
        down_payment: this.getDownPaymentPercent(),
        no_of_terms: this.monthsValue,
        sales_price: this.truck.pricing && this.truck.pricing.priceExclVatEuro ? this.truck.pricing.priceExclVatEuro : 0,
        truck_age: new Date().getUTCFullYear() - new Date(this.truck.specification.dateFirstRegistration).getUTCFullYear(),
        truck_make: this.truck.specification.make,
        truck_id: this.truck.specification.licensePlate,
        site: this.regionalSettings.site,
        region: this.regionalSettings.region,
        stockCountry: stockCountry,
        stockCountryIsoCode: stockCountryIsoCode
      })
      .subscribe((res: VfsApiRes) => {
        if (res && res.totalFinancePayment) {
          const totalFinancePaymentRoundup = Math.floor(res.totalFinancePayment / 4);
          
          this.totalFinancePayment = this.roundOffDeposit(totalFinancePaymentRoundup, 5)
          
          this.financePaymentLoading = false;
          if(defaultFlag)
            this.defaultValue = this.totalFinancePayment
          
          this.sliderValueChange.emit([this.monthsValue,this.depositValue,this.totalFinancePayment,this.defaultValue]);
        }
      });
    }
  }

  onSlide() {
    this.getDownPaymentPercent();
    this.vfsService.setVfsStatus({ showVfsInPrintout: true });
  }

  private getDownPaymentPercent(): number {
    let result;
    if(this.isSelectedSite) {
      const val = this.leaseSliders.downPayment.value;
      const floor = this.leaseSliders.downPayment.options.floor;
      const ceil = this.leaseSliders.downPayment.options.ceil;
      const round = Math.floor(val / 100) * 100;
      result = round;
      // if (result <= this.leaseSliders.downPayment.options.floorOriginal)
      //   result = this.leaseSliders.downPayment.options.floorOriginal;
      // if (Math.abs(result - this.leaseSliders.downPayment.options.ceilOriginal) < 100)
      //   result = this.leaseSliders.downPayment.options.ceilOriginal;

      this.leaseSliders.downPayment.value = result;
    }
    else{
      const val = this.depositValue;
      const round = this.roundOffDeposit(val, 1000);
      result = round;
      if (result <= this.depositOptions.floor)
        result = this.roundOffDeposit(this.depositOptions.floor, 1000);
      this.depositValue = this.roundOffDeposit(result, 1000);
    }
    return result;
  }

  private setInitialDownPayment(): void {
    const floorDownPaymentPercent = 10 / 100;
    const ceilDownPaymentPercent = 50 / 100;
    var floor, ceil;
    if (this.truck.pricing) {
      if(this.isSelectedSite){
        if (this.regionalSettings.zarPriceEnabled) {
          floor = this.truck.pricing.priceExclVat.value * floorDownPaymentPercent;
          ceil = this.truck.pricing.priceExclVat.value * ceilDownPaymentPercent;
        }
        else {
          floor = this.truck.pricing.priceExclVatEuro * floorDownPaymentPercent;
          ceil = this.truck.pricing.priceExclVatEuro * ceilDownPaymentPercent;
        }

        this.leaseSliders.downPayment.value = floor;
        // this.leaseSliders.downPayment.options.ceilOriginal = ceil;
        // this.leaseSliders.downPayment.options.floorOriginal = floor;
        this.leaseSliders.downPayment.options.ceil = Math.floor(ceil / 100) * 100;
        this.leaseSliders.downPayment.options.floor = Math.floor(floor / 100) * 100;
      }
      else{
        const floor = this.truck.pricing.priceExclVatEuro * floorDownPaymentPercent;
        const ceilDownPaymentPercent = 50 / 100;
        const ceil = this.truck.pricing.priceExclVatEuro * ceilDownPaymentPercent;
  
         this.depositValue = this.roundOffDeposit(floor, 1000) ;
        this.depositOptions.ceil = Math.floor(ceil / 100) * 100;
        this.depositOptions.floor = this.roundOffDeposit(floor, 1000);
      }
    }
    
    
  }

  initSubscriptions() {
    if (this.regionalSettings.zarPriceEnabled)
      this.currencySymbol = 'R';
    else
      this.currencySymbol = '€'

    this.s0 = this.vfsService.vfsStatus$.subscribe(result => {

      if (!result) return;
      
    })
  }

  private roundOffDeposit (value, factor): number {
    const quotient = value / factor;
    const res = Math.round(quotient) * factor;
    return res;
 };
}
