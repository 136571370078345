import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { StateReset } from 'ngxs-reset-plugin';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';

import { GetAggregations, GetSearch, ResetStep, SetSearchFromUrl, UpdateSearch, UpdateStep } from '~/core/store/search/search.actions';
import { SearchStateFilter, SearchStateParams, UpdateStepOptions } from '~/core/store/search/search.model';
import { BucketAggregations } from '~/core/store/buckets/buckets.model';
import { SearchState } from '~/core/store/search/search.state';
import { Truck } from '~/core/store/trucks/trucks.model';

@Injectable({ providedIn: 'root' })
export class SearchService {
  @Select((state) => state.search.trucksList)
  public trucksList$: Observable<Truck[]>;

  @Select((state) => state.search.trucksListOthers)
  public trucksListOthers$: Observable<Truck[]>;

  @Select((state) => state.search.filter)
  public filter$: Observable<SearchStateFilter>;

  @Select((state) => state.search.params)
  public params$: Observable<SearchStateParams>;

  @Select((state) => state.search.aggregations)
  public aggregations$: Observable<BucketAggregations>;

  @Select((state) => state.search.totalTrucks)
  public totalTrucks$: Observable<number>;

  @Select((state) => state.search.grandTotalTrucks)
  public grandTotalTrucks$: Observable<number>;

  @Select((state) => state.search.totalOtherTrucks)
  public totalOtherTrucks$: Observable<number>;

  @Select((state) => state.search.loaded)
  public loaded$: Observable<boolean>;

  @Dispatch()
  public getSearch(payload: SearchStateParams) {
    return new GetSearch(payload);
  }

  @Dispatch()
  public getAggregations(payload: SearchStateParams) {
    return new GetAggregations(payload);
  }

  @Dispatch()
  public setSearchFromUrl(payload: SearchStateParams) {
    return new SetSearchFromUrl(payload);
  }

  @Dispatch()
  public updateSearch(payload: SearchStateFilter) {
    return new UpdateSearch(payload);
  }

  @Dispatch()
  public resetStep(payload: string, chipToRemove: any) {
    return new ResetStep(payload, chipToRemove);
  }

  @Dispatch()
  public updateStep(payload: UpdateStepOptions) {
    return new UpdateStep(payload);
  }

  @Dispatch()
  public resetSearch() {
    return new StateReset(SearchState);
  }
}
