import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SalesTeam } from 'src/app/pages/contact-page/contact-page.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { Subscription } from 'rxjs';
import { HtmlRoutePages } from '../../../app-routing.model';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-connect-page',
  templateUrl: './volvo-connect-page.component.html',
  styleUrls: ['./volvo-connect-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VolvoConnectPageComponent implements OnInit {
  HtmlRoutePages: HtmlRoutePages;
  isMobile: boolean;
  leftNavDisabled = false;
  rightNavDisabled = false;
  leftNavDisabledVolvoConnect= false;
  rightNavDisabledVolvoConnect = false;
  constructor(private translateService: TranslateService, private metaTagService: Meta,
    public localesService: LocalesService,
    private breadCrumbService: BreadcrumbserviceService,
    public assetService: AssetService
  ) {
   }
  ngOnInit(): void {
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/services/volvoconnect/metatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/services/volvoconnect/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/services/volvoconnect/altImage'.toLowerCase())
    });
    this.metaTagService.updateTag({
        name: 'keywords',
        content: this.translateService.instant('uk/services/volvoconnect/metakeywords'.toLowerCase())
    });

    //this.breadCrumbService.updateBreadcrumb('Services' , 'Volvo Connect', HtmlRoutePages.services);
  }
  volvoConnectList = [ 
    {
      url: 'assets/pages/services/UpTime.webp',
      title: 'uk/pages/services/volvoconnect/imgtitle1',
      text: 'uk/pages/services/volvoconnect/imgtitle1/text1',
    },
    {
      url: 'assets/pages/services/Productivity.webp',
      title: 'uk/pages/services/volvoconnect/imgtitle2',
      text: 'uk/pages/services/volvoconnect/imgtitle2/text1',
    },
    {
      url: 'assets/pages/services/Performance.webp',
      title: 'uk/pages/services/volvoconnect/imgtitle3',
      text: 'uk/pages/services/volvoconnect/imgtitle3/text1',

    }];
    volvoConnectImgList=[{
      url: 'assets/pages/services/customer.webp',
      title: 'uk/pages/services/volvoconnect/imgtitle4',
      text: 'uk/pages/services/volvoconnect/imgtitle4/text1',

    },
    {
      url: 'assets/pages/services/volvo-connect.webp',
      title: 'uk/pages/services/volvoconnect/imgtitle5',
      text: 'uk/pages/services/volvoconnect/imgtitle5/text1',

    },
    {
      url: 'assets/pages/services/marketplace.webp',
      title: 'uk/pages/services/volvoconnect/imgtitle6',
      text: 'uk/pages/services/volvoconnect/imgtitle6/text1',

    },
    {
      url: 'assets/pages/services/developer.webp',
      title: 'uk/pages/services/volvoconnect/imgtitle7',
      text: 'uk/pages/services/volvoconnect/imgtitle7/text1',

    }
  ];
  salesTeamMangementDetails: SalesTeam[] = [
    {
      imageUrl: 'assets/pages/services/Rachael-Botcher.webp',
      imageName: 'Rachel Botcher',
      imageDesignation: 'uk/pages/services/volvoconnect/team1',
      imageCentre: '',
      imageEmail: 'rachael.botcher@volvo.com',
      imageMobile:  '07729 115541',
    },
    {
      imageUrl: 'assets/pages/services/Lynn-Davies.webp',
      imageName: 'Lynn Davies',
      imageDesignation: 'uk/pages/services/volvoconnect/team2',
      imageCentre: '',
      imageEmail: 'lynn.davies@volvo.com',
      imageMobile:  '07712 303255',
    },
    {
      imageUrl: 'assets/pages/services/Louise-Baker.webp',
      imageName: 'Louise Baker',
      imageDesignation: 'uk/pages/services/volvoconnect/team3',
      imageCentre: '',
      imageEmail: 'louise.baker@volvo.com',
      imageMobile:  '07548 095391',
    },
    {
      imageUrl: 'assets/pages/services/Thomas-McDarmaid.webp',
      imageName: 'Thomas Mcdarmaid',
      imageDesignation: 'uk/pages/services/volvoconnect/team4',
      imageCentre: '',
      imageEmail: 'thomas.mcdarmaid@volvo.com',
      imageMobile:  '07858 369482',
    },
  ]
  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }
  leftBoundStatVolvoConnect(reachesLeftBound: boolean) {
    this.leftNavDisabledVolvoConnect = reachesLeftBound;
  }

  rightBoundStatVolvoConnect(reachesRightBound: boolean) {
    this.rightNavDisabledVolvoConnect = reachesRightBound;
  }
}
