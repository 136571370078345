<div [ngClass]="dynamicClassFlag? 'ContactCentre mapHWCentre' : 'ContactCentre mapHW'">
    <div class="ContactCentre-left">
        <div class="ContactCentre-title">{{ centre }} </div>
        <div class="ContactCentre-info">
            <div class="ContactCentre-text">{{ street }}</div>
            <div class="ContactCentre-text">
                <span *ngIf="city">
                    {{ city }} 
                </span>
                <span *ngIf="city && centreName && region===regionEnum.UK">
                    ,
                </span> 
                <span *ngIf="centreName  && region===regionEnum.UK">
                    {{centreName}}
                </span>
            </div>
            <div class="ContactCentre-text">{{ country }}</div>
            <div class="ContactCentre-text">{{ postalCode }}</div>
        </div>
    </div>
    <div class="ContactCentre-right">
        <div #mapContainer id="map"></div> 
    </div>
</div>