import { Component, Input, OnInit } from '@angular/core';
import { Truck } from '~/core/store/trucks/trucks.model';
import { Axle } from '~/core/store/trucks/trucks.model.base';

@Component({
  selector: 'volvo-axle',
  templateUrl: './axle.component.html',
  styleUrls: ['./axle.component.scss'],
})
export class AxleComponent implements OnInit {
  @Input() truck: Truck;
  tyreTread: Axle[];
  axleLayout: string;
  axleLayoutId: string;
  pictureId: string;
  constructor() {
  }

  tyreTable: any;

  ngOnInit(): void {
    this.axleLayout = this.truck.specification.axleConfiguration.engPhrase;
    this.axleLayoutId = this.truck.specification.axleConfigurationLayout;
    this.pictureId = this.axleLayoutId;
    this.tyreTread = this.truck.specification.tyreTread;
  }
}
