// TODO Remove all any and replace with proper models

import { Action, State, StateContext, Store } from '@ngxs/store';
import { HttpClient, HttpResponse } from '@angular/common/http';

//import { LatestTrucksRes, Truck, TruckRes, TrucksStateInterface } from '~/core/store/trucks/trucks.model';
import { GetLatestTrucks, GetTruckById, SetActiveTruck, SetEmailFlag } from '~/core/store/trucks/trucks.actions';
import { environment } from '../../../../environments/environment';
import { Truck, EmailFlagStateInterface } from './trucks.model';
import { debug } from 'console';
import { LocalesStateRegion } from '../locales/locales.model';
import { AppState } from '..';
import { Injectable, NgZone } from '@angular/core';
import { TrucksService } from '~/core/store/trucks/trucks.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router } from '@angular/router';
import { RegionalSettingsService } from '../regionalSettings/regionalSettings.service';
import { HtmlRoutePages } from '~/app-routing.model';

@State<any>({
  name: 'trucks',
  defaults: {
    activeTruck: null,
    truckById: null,
    latestTrucks: null,
  },
})

@State<EmailFlagStateInterface>({
  name: 'emailFlagState',
  defaults: {
    emailFlag: false
  },
})

@Injectable()
export class TrucksState {
  // private truckPath = 'truck/';
  private latestTrucksPath = 'Collections/latest';
  // private newestTrucksPath = 'Collections/newest?langCode=en&site=';
  // private oldestTrucksPath = 'Collections/oldest?langCode=en&site=';
  site: string;
  region: string;

  static updateTrucksResponse(truck: any[]): any[] {
    return truck.map((item: any) => this.updateTruckResponse(item));
  }

  static updateTruckResponse(truck: any): any {
    console.log({ Truck: truck });
    return {
      ...truck,
    };
  }

  regionObj: LocalesStateRegion = {
    curRegion: { label: "Europe", id: "EU" },
    list: [
      { label: "Europe", id: "EU" },
      { label: "South Africa", id: "ZA" },
    ]
  }

  langRegion: any;
  emailFlag: boolean = false;
  constructor(
    private http: HttpClient,
    private store: Store,
    private trucksService: TrucksService,
    private regionalSettingsService: RegionalSettingsService,
    private localizeRouterService: LocalizeRouterService,
    private router: Router,
    private zone: NgZone
  ) { }

  @Action(SetActiveTruck)
  setActiveTruck({ getState, patchState }: StateContext<any>, { payload }: SetActiveTruck) {
    patchState({
      activeTruck: payload,
    });
  }

  getCurrentLanguage(): string {
    var lang = this.store.selectSnapshot<string>((state: AppState) => state.language.lang.curLang.id);
    return lang;
  }
  getCurrentRegion(): string {
    var region = this.store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    return region;
  }
  getCurrentCountry(): string {
    var country = this.store.selectSnapshot<string>((state: AppState) => state.language.country.curCountry.id);
    return country;
  }

  @Action(GetTruckById)
  getTruckById({ getState, patchState }: StateContext<any>, { payload }: GetTruckById) {
    this.region = this.getCurrentRegion();

    if (sessionStorage.getItem('email') === 'true') {
      this.emailFlag = true;
    }

    if (this.region === 'EU' || this.region === 'ZA') {
      const countryCode = this.getCurrentCountry();
      const langCode = this.getCurrentLanguage();
      const site = "volvoselected"

      const requestObject = {
        id: payload,
        countryCode: countryCode,
        site: site,
        region: this.region,
        langCode: langCode,
        emailFlag: this.emailFlag,
        campaignId: sessionStorage.getItem('campaignId'),
        campaignSource: sessionStorage.getItem('campaignSource'),
        campaignMedium: sessionStorage.getItem('campaignMedium'),
        userNavigation: sessionStorage.getItem('userNavigation')
      };

      return this.http.post(environment.apiUrl + 'truck/by-id',
        requestObject)
        .subscribe((res: any) => {
          patchState({
            truckById: TrucksState.updateTruckResponse(res),
          });
        },
          (error) => {
            console.error(error);
          });
    } else {
      // payload = payload + "/ukwebportal/" + this.region + "/en" + "/" + this.emailFlag; //langCode is passed as "en" for both UK and N&S and country(user country) is passed as UK and NS respectively(Redo on merge).

      const result = this.http.post(environment.apiUrl + 'truck/by-id',
        {
          id: payload,
          countryCode: "UK",
          site: "volvoselected",
          region: this.region,
          langCode: "en",
          emailFlag: this.emailFlag,
          campaignId: sessionStorage.getItem('campaignId'),
          campaignSource: sessionStorage.getItem('campaignSource'),
          campaignMedium: sessionStorage.getItem('campaignMedium'),
          userNavigation: sessionStorage.getItem('userNavigation')
        }).toPromise().then((res: any) => {

          const truck = TrucksState.updateTruckResponse(res);
          patchState({
            truckById: truck,
          });
          return result;
        }).catch((err) => {
          this.zone.run(() => this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.home)]))
        });
    }

  }

  @Action(GetLatestTrucks)
  getLatestTrucks({ patchState }: StateContext<any>) {
    return this.http.get(environment.apiUrl + this.latestTrucksPath).subscribe((res: any) => {
      patchState({
        latestTrucks: TrucksState.updateTrucksResponse(res.hits),
      });
    });
  }

  getSiteName() {
    this.regionalSettingsService.getPortalName$.subscribe((res: any) => {
      this.site = res;
    });
  }
  getRegion() {
    this.regionalSettingsService.getRegion$.subscribe((res: any) => {
      this.region = res;
    });
  }
}
