import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SetVfsStatus } from './vfs.actions';

import { VfsStateInterface } from './vfs.model';

@Injectable()
@State<VfsStateInterface>({
  name: 'vfsState',
  defaults: {
    showVfsInPrintout: false
  },
})
@Injectable()
export class VfsState {
  @Action(SetVfsStatus)
  setVfsStatus({ patchState }: StateContext<VfsStateInterface>, { payload }: SetVfsStatus) {
    
    
    return patchState(payload);
  }
}