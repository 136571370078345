import { Component, OnInit} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LocalesService } from '~/core/store/locales/locales.service';
import { HtmlRoutePages } from '../../../app-routing.model';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
@Component({
  selector: 'volvo-customisation-page',
  templateUrl: './customisation-page.component.html',
  styleUrls: ['./customisation-page.component.scss']
})
export class CustomisationPageComponent implements OnInit{ 
  HtmlRoutePages: HtmlRoutePages;
  constructor(private translateService: TranslateService,private metaTagService: Meta,
    private breadCrumbService: BreadcrumbserviceService,
    public localesService: LocalesService,) {}
  ngOnInit(): void {
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/services/customisation/metatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/services/customisation/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/services/customisation/altImage'.toLowerCase())
    });    

    //this.breadCrumbService.updateBreadcrumb('Services' , 'Customisation', HtmlRoutePages.services);
  }
  
  customisationList = [
    {
      type: 'uk/pages/services/customisation/title1',
      name: 'uk/pages/services/customisation/title1/text1',
    },
    {
      type: 'uk/pages/services/customisation/title2',
      name: 'uk/pages/services/customisation/title2/text1',
    },
    {
      type: 'uk/pages/services/customisation/title3',
      name: 'uk/pages/services/customisation/title3/text1',
    },
    {
      type: 'uk/pages/services/customisation/title4',
      name: 'uk/pages/services/customisation/title4/text1',
    }
  ];

}
