<i (click)="onClose()" class="PopupOLD-close material-icons">close</i>

<div *ngIf="product" class="PopupOLD">
  <h3 class="ProductDetails-title">
    {{ 'specification/superstructure' | lowercase | translate }}
  </h3>

  <div *ngIf="
      product.superstructure_make ||
      product.superstructure_model ||
      product.superstructure_loadHandling ||
      product.superstructureType ||
      product.superstructure_numberOfCompartments ||
      product.superstructure_numberOfPallets ||
      product.superstructure_rearDoors ||
      product.superstructure_sideDoors
    " class="Table">
    <div class="Table-box">
      <div *ngIf="product.superstructure_make" class="Table-row">
        <div>{{ 'specification/make' | lowercase | translate }}:</div>
        <div>
          <b>{{ product.superstructure_make }}</b>
        </div>
      </div>
      <div *ngIf="product.superstructure_model" class="Table-row">
        <div>{{ 'specification/model' | lowercase | translate }}:</div>
        <div>
          <b>{{ product.superstructure_model }}</b>
        </div>
      </div>
      <div *ngIf="product.superstructure_loadHandling && product.superstructure_loadHandling[curLang.apiResLang]"
        class="Table-row">
        <div>{{ 'superstructure/loadHandling' | lowercase | translate }}</div>
        <div>
          <b>{{ product.superstructure_loadHandling[curLang.apiResLang] }}</b>
        </div>
      </div>
      <div *ngIf="product.superstructureType" class="Table-row">
        <div>{{ 'specification/type' | lowercase | translate }}:</div>
        <div>
          <b>{{ product.superstructureType }}</b>
        </div>
      </div>
      <div *ngIf="product.superstructure_numberOfCompartments" class="Table-row">
        <div>{{ 'superstructure/loadHandlingCompartment' | lowercase | translate }}</div>
        <div>
          <b>{{ product.superstructure_numberOfCompartments }}</b>
        </div>
      </div>
      <div *ngIf="product.superstructure_numberOfPallets" class="Table-row">
        <div>{{ 'superstructure/loadHandlingPallets' | lowercase | translate }}:</div>
        <div>
          <b>{{ product.superstructure_numberOfPallets }}</b>
        </div>
      </div>
      <div *ngIf="product.superstructure_rearDoors && product.superstructure_rearDoors[curLang.apiResLang]"
        class="Table-row">
        <div>{{ 'superstructure/loadHandlingRear' | lowercase | translate }}:</div>
        <div>
          <b>{{ product.superstructure_rearDoors[curLang.apiResLang] }}</b>
        </div>
      </div>

      <div *ngIf="product.superstructure_sideDoors && product.superstructure_sideDoors[curLang.apiResLang]"
        class="Table-row">
        <div>{{ 'superstructure/loadHandlingSide' | lowercase | translate }}</div>
        <div>
          <b>{{ product.superstructure_sideDoors[curLang.apiResLang] }}</b>
        </div>
      </div>
    </div>

    <div *ngIf="
        product.superstructure_measurements_internalLengthMm ||
        product.superstructure_measurements_internalWidthMm ||
        product.superstructure_measurements_internalHeightMm
      ">
      <h3 class="ProductDetails-title">
        {{ 'specification/measurements' | lowercase | translate }}
      </h3>

      <div class="Table">
        <div class="Table-box">
          <div *ngIf="product.superstructure_measurements_internalLengthMm" class="Table-row">
            <div>
              {{ 'specification/internalLength' | lowercase | translate }}
              (mm):
            </div>
            <div>
              <b>{{ product.superstructure_measurements_internalLengthMm }}</b>
            </div>
          </div>
          <div *ngIf="product.superstructure_measurements_internalWidthMm" class="Table-row">
            <div>
              {{ 'specification/internalWidth' | lowercase | translate }}
              (mm):
            </div>
            <div>
              <b>{{ product.superstructure_measurements_internalWidthMm }}</b>
            </div>
          </div>
          <div *ngIf="product.superstructure_measurements_internalHeightMm" class="Table-row">
            <div>
              {{ 'specification/internalHeight' | lowercase | translate }}
              (mm):
            </div>
            <div>
              <b>{{ product.superstructure_measurements_internalHeightMm }}</b>
            </div>
          </div>
          <div *ngIf="product.superstructure_measurements_externalLengthMm" class="Table-row">
            <div>{{ 'superstructure/measurementsExternalLength' | lowercase | translate }}</div>
            <div>
              <b>{{ product.superstructure_measurements_externalLengthMm }}</b>
            </div>
          </div>
          <div *ngIf="product.superstructure_measurements_externalWidthMm" class="Table-row">
            <div>{{ 'superstructure/measurementsExternalWidth' | lowercase | translate }}</div>
            <div>
              <b>{{ product.superstructure_measurements_externalWidthMm }}</b>
            </div>
          </div>
          <div *ngIf="product.superstructure_measurements_externalHeightMm" class="Table-row">
            <div>{{ 'superstructure/measurementsExternalHeight' | lowercase | translate }}</div>
            <div>
              <b>{{ product.superstructure_measurements_externalHeightMm }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="
        product.superstructure_tailLift_make ||
        product.superstructure_tailLift_capacity ||
        product.superstructure_tailLift_modelYear
      ">
      <h3 class="ProductDetails-title">
        {{ 'specification/tailLift' | lowercase | translate }}
      </h3>

      <div class="Table">
        <div class="Table-box">
          <div *ngIf="product.superstructure_tailLift_make && product.superstructure_tailLift_make[curLang.apiResLang]"
            class="Table-row">
            <div>{{ 'specification/make' | lowercase | translate }}:</div>
            <div>
              <b>{{ product.superstructure_tailLift_make[curLang.apiResLang] }}</b>
            </div>
          </div>
          <div *ngIf="
              product.superstructure_tailLift_capacity && product.superstructure_tailLift_capacity[curLang.apiResLang]
            " class="Table-row">
            <div>{{ 'specification/capacity' | lowercase | translate }}:</div>
            <div>
              <b>{{ product.superstructure_tailLift_capacity[curLang.apiResLang] }}</b>
            </div>
          </div>
          <div *ngIf="product.superstructure_tailLift_modelYear" class="Table-row">
            <div>{{ 'specification/modelYear' | lowercase | translate }}:</div>
            <div>
              <b>{{ product.superstructure_tailLift_modelYear }}</b>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="
          product.superstructure_crane_dateLegalInspection ||
          product.superstructure_crane_extensions ||
          product.superstructure_crane_make ||
          product.superstructure_crane_other
        ">
        <h3 class="ProductDetails-title">
          {{ 'superstructure/crane' | lowercase | translate }}
        </h3>

        <div class="Table">
          <div class="Table-box">
            <div *ngIf="product.superstructure_crane_dateLegalInspection" class="Table-row">
              <div>
                {{ 'superstructure/craneDate' | lowercase | translate }}
              </div>
              <div>
                <b>{{ product.superstructure_crane_dateLegalInspection }}</b>
              </div>
            </div>
            <div *ngIf="
                product.superstructure_crane_extensions && product.superstructure_crane_extensions[curLang.apiResLang]
              " class="Table-row">
              <div>{{ 'superstructure/craneOther' | lowercase | translate }}</div>
              <div>
                <b>{{ product.superstructure_crane_extensions[curLang.apiResLang] }}</b>
              </div>
            </div>
            <div *ngIf="product.superstructure_crane_make && product.superstructure_crane_make[curLang.apiResLang]"
              class="Table-row">
              <div>{{ 'superstructure/make' | lowercase | translate }}</div>
              <div>
                <b>{{ product.superstructure_crane_make[curLang.apiResLang] }}</b>
              </div>
            </div>
            <div *ngIf="product.superstructure_crane_other && product.superstructure_crane_other[curLang.apiResLang]"
              class="Table-row">
              <div>{{ 'superstructure/craneOther' | lowercase | translate }}</div>
              <div>
                <b>{{ product.superstructure_crane_other[curLang.apiResLang] }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="
          product.superstructure_temperatureControl_aggregateType ||
          product.superstructure_temperatureControl_dateLegalInspection ||
          product.superstructure_temperatureControl_make ||
          product.superstructure_temperatureControl_modelYear ||
          product.superstructure_temperatureControl_position
        ">
        <h3 class="ProductDetails-title">
          {{ 'superstructure/TempControl' | lowercase | translate }}
        </h3>

        <div class="Table">
          <div class="Table-box">
            <div *ngIf="
                product.superstructure_temperatureControl_aggregateType &&
                product.superstructure_temperatureControl_aggregateType[curLang.apiResLang]
              " class="Table-row">
              <div>
                {{ 'superstructure/temperaturecontrol/aggregatetype' | lowercase | translate }}
              </div>
              <div>
                <b>{{ product.superstructure_temperatureControl_aggregateType[curLang.apiResLang] }}</b>
              </div>
            </div>
            <div *ngIf="
                product.superstructure_temperatureControl_dateLegalInspection &&
                product.superstructure_temperatureControl_dateLegalInspection[curLang.apiResLang]
              " class="Table-row">
              <div>{{ 'superstructure/TempControlDate' | lowercase | translate }}</div>
              <div>
                <b>{{ product.superstructure_temperatureControl_dateLegalInspection[curLang.apiResLang] }}</b>
              </div>
            </div>
            <div *ngIf="
                product.superstructure_temperatureControl_make &&
                product.superstructure_temperatureControl_make[curLang.apiResLang]
              " class="Table-row">
              <div>{{ 'superstructure/TempControlMake' | lowercase | translate }}</div>
              <div>
                <b>{{ product.superstructure_temperatureControl_make[curLang.apiResLang] }}</b>
              </div>
            </div>
            <div *ngIf="
                product.superstructure_temperatureControl_modelYear &&
                product.superstructure_temperatureControl_modelYear[curLang.apiResLang]
              " class="Table-row">
              <div>{{ 'superstructure/TempControlYear' | lowercase | translate }}</div>
              <div>
                <b>{{ product.superstructure_temperatureControl_modelYear[curLang.apiResLang] }}</b>
              </div>
            </div>
            <div *ngIf="
                product.superstructure_temperatureControl_position &&
                product.superstructure_temperatureControl_position[curLang.apiResLang]
              " class="Table-row">
              <div>{{ 'superstructure/TempControlMounting' | lowercase | translate }}</div>
              <div>
                <b>{{ product.superstructure_temperatureControl_position[curLang.apiResLang] }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
