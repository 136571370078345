import { RegionSettings } from "./regionalSettings.model";

export class GetRegionalSettings {
    subscribe(arg0: (settings: any) => void) {
        throw new Error("Method not implemented.");
    }
    static readonly type = "[GetRegionalSettings]: action";
}

export class GetCountrySettings {
    static readonly type = "[GetCountrySettings]: action";
}

export class InitRegionalSettings {
    static readonly type = "[InitRegionalSettings]: action";
}

export class InitCountrySettings {
    static readonly type = "[InitCountrySettings]: action";
}

export class GetRegion {
    static readonly type = '[GetRegion]: action';

    constructor() { }
}
export class SetRegion {
    static readonly type = '[SetRegion]: action';

    constructor(public payload: string) {

    }
}
