<div *ngIf="region!==regionEnum.TX">
  <section class="youTubeVideoStyle" *ngIf="showVideo; else others">
    <div>
      <div *ngFor="let slide of videoObject; let i = index" [ngClass]="i===1?'paddingRight':''">
        <iframe width="100%" height="100%" [src]="slide.video | safe: 'resourceUrl'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen [ngClass]="isIframeLoaded[i] ? 'video-height' : 'skeletonYoutube'" (load)="isIframeLoaded()"></iframe>
          <div class="content">
            {{slide.text| lowercase | translate}}
          </div>
      </div>
    </div>
    <div class="contentview"> 
        <a class="contentview" href='https://www.youtube.com/@VolvoTrucksUK' target="_blank"><img [src]="assetService.getAssetUrl('assets/icons/youtube.svg')" alt="{{ 'pages/home/youtube/view' | lowercase | translate }}">{{"pages/home/youtube/view"| lowercase | translate}} </a>
    </div>
  </section>
  <ng-template #others>
    <section class="video">
      <div class="video-videoCarousel">
        <ngb-carousel #myimageCarousel [showNavigationIndicators]="false" [showNavigationArrows]="false">
          <ng-template ngbSlide *ngFor="let slide of imageObject; let i = index">
            <div class="wrapper">
              <img [src]="slide.image" class="imageDimension imagePointer" [routerLink]="HtmlRoutePages.vehicleOffers | translate | localize" [queryParams]="{ path: slide.imagePath }"
                *ngIf="slide.imagePath !== 'pdf' && slide.imagePath !== ''" alt="">
              <img [src]="slide.image" class="imageDimension" *ngIf="slide.imagePath === ''" alt="">
              <a *ngIf="slide.imagePath === 'pdf'" target="_blank" [href]="slide.path">
                <img *ngIf="slide.imagePath === 'pdf'" [src]="slide.image" class="imageDimension" alt="">
              </a>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </section>
  </ng-template>
</div>

<section *ngIf="region===regionEnum.TX">
  <div style="text-align: center;">
    <img src='assets\pages\home\TX\WebBanner.webp' alt="">
  </div>
</section>