import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lookupcountry'
})
export class LookupcountryPipe implements PipeTransform {

  transform(value: string): string {
    var resolved = '';
    if (!value) {
      return null;
    }
    resolved = this.countryCodes.filter((c) => c.iso2 === value)[0].name;
    return resolved;
  }
  countryCodes = [
    {
      name: 'Austria',
      iso2: 'AT',
    },
    {
      name: 'Belgium',
      iso2: 'BE',
    },
    {
      name: 'Bosnia and Herzegovina',
      iso2: 'BA',
    },
    {
      name: 'Bulgaria',
      iso2: 'BG',
    },
    {
      name: 'Croatia',
      iso2: 'HR',
    },
    {
      name: 'Czech Republic',
      iso2: 'CZ',
    },
    {
      name: 'Denmark',
      iso2: 'DK',
    },
    {
      name: 'Estonia',
      iso2: 'EE',
    },
    {
      name: 'Finland',
      iso2: 'FI',
    },
    {
      name: 'France',
      iso2: 'FR',
    },
    {
      name: 'Germany',
      iso2: 'DE',
    },
    {
      name: 'Hungary',
      iso2: 'HU',
    },
    {
      name: 'Italy',
      iso2: 'IT',
    },
    {
      name: 'Latvia',
      iso2: 'LV',
    },
    {
      name: 'Lithuania',
      iso2: 'LT',
    },
    {
      name: 'Macedonia',
      iso2: 'MK',
    },
    {
      name: 'Netherlands',
      iso2: 'NL',
    },
    {
      name: 'Norway',
      iso2: 'NO',
    },
    {
      name: 'Poland',
      iso2: 'PL',
    },
    {
      name: 'Romania',
      iso2: 'RO',
    },
    {
      name: 'Russia',
      iso2: 'RU',
    },
    {
      name: 'Serbia',
      iso2: 'RS',
    },
    {
      name: 'Slovakia',
      iso2: 'SK',
    },
    {
      name: 'Slovenia',
      iso2: 'SI',
    },
    {
      name: 'Spain',
      iso2: 'ES',
    },
    {
      name: 'Sweden',
      iso2: 'SE',
    },
    {
      name: 'Switzerland',
      iso2: 'CH',
    },
    {
      name: 'South Africa',
      iso2: 'ZA',
    },
    {
      name: 'United Kingdom',
      iso2: 'GB',
    },
  ]
}
