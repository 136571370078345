<button mat-button class="Lang DeskTopTablet" (click)="onLangChange()" data-cy="choose-language">
    <i class="fas fa-globe"></i>
    <span class="Lang-label" *ngIf="(localesService.country$ | async)?.curCountry.label">{{(localesService.country$ |
        async).curCountry.label}} / {{(localesService.lang$ | async).curLang.label}}</span>
    <span class="Lang-label" *ngIf="!(localesService.country$ | async)?.curCountry.label">{{(localesService.region$ |
        async).curRegion.label}} / {{(localesService.lang$ | async).curLang.label}}</span>
</button>
<div class="Lang Mobile">
    <a (click)="onLangChange();closeMenu();" routerLinkActive="Active">
        <i class="fas fa-globe" style="color:#141414;padding: 5px 5px 5px 0;"></i>
        <span class="Lang-label" style="padding-bottom: 7px;"
            *ngIf="(localesService.country$ | async).curCountry.label">{{(localesService.country$ |
            async).curCountry.label}} / {{(localesService.lang$ | async).curLang.label}}</span>
        <span class="Lang-label" style="padding-bottom: 7px;"
            *ngIf="!(localesService.country$ | async).curCountry.label">{{(localesService.region$ |
            async).curRegion.label}} / {{(localesService.lang$ | async).curLang.label}}</span>
    </a>
</div>