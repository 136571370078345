import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HtmlRoutePages } from "~/app-routing.model"

@Injectable({
  providedIn: 'root'
})

export class CanonicalService {

  constructor(@Inject(DOCUMENT) private dom) { }

  alternateList: { langCode: string, langHref: string }[] = [
    { langCode: "lt", langHref: "https://www.volvoselected.com/lt" },
    { langCode: "ru", langHref: "https://www.volvoselected.com/ru" },
    { langCode: "da", langHref: "https://www.volvoselected.com/dk" },
    { langCode: "cs", langHref: "https://www.volvoselected.com/cz" },
    { langCode: "fr", langHref: "https://www.volvoselected.com/fr" },
    { langCode: "es", langHref: "https://www.volvoselected.com/es" },
    { langCode: "nb", langHref: "https://www.volvoselected.com/no" },
    { langCode: "it", langHref: "https://www.volvoselected.com/it" },
    { langCode: "fi", langHref: "https://www.volvoselected.com/fi" },
    { langCode: "pl", langHref: "https://www.volvoselected.com/pl" },
    { langCode: "hu", langHref: "https://www.volvoselected.com/hu" },
    { langCode: "sk", langHref: "https://www.volvoselected.com/sk" },
    { langCode: "sl", langHref: "https://www.volvoselected.com/si" },
    { langCode: "de-at", langHref: "https://www.volvoselected.com/at" },
    { langCode: "ro", langHref: "https://www.volvoselected.com/ro" },
    { langCode: "bs", langHref: "https://www.volvoselected.com/ba" },
    { langCode: "mk", langHref: "https://www.volvoselected.com/mk" },
    { langCode: "rs", langHref: "https://www.volvoselected.com/rs" },
    { langCode: "hr", langHref: "https://www.volvoselected.com/hr" },
    { langCode: "de", langHref: "https://www.volvoselected.com/de" },
    { langCode: "nl-be", langHref: "https://www.volvoselected.com/be-nl" },
    { langCode: "fr-be", langHref: "https://www.volvoselected.com/be-fr" },
    { langCode: "nl", langHref: "https://www.volvoselected.com/nl" },
    { langCode: "bg", langHref: "https://www.volvoselected.com/bg" },
    { langCode: "sv", langHref: "https://www.volvoselected.com/se" },
    { langCode: "et", langHref: "https://www.volvoselected.com/ee" },
    { langCode: "lv", langHref: "https://www.volvoselected.com/lv" },
    { langCode: "fr-ch", langHref: "https://www.volvoselected.com/ch-fr" },
    { langCode: "de-ch", langHref: "https://www.volvoselected.com/ch-de" },
    { langCode: "it-ch", langHref: "https://www.volvoselected.com/ch-it" },
    { langCode: "en", langHref: "https://www.volvoselected.com" }
  ];

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    let subdirectory = "";

    //Create canonical element
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', canURL);

    //If canonical and alternate already exists, remove them to create new ones. 
    const canonicalSelector = document.head.querySelector("link[rel=canonical]");
    const alternateSelector = document.head.querySelectorAll("link[rel=alternate]");
    if (canonicalSelector) {
      canonicalSelector.remove();
    }
    if (alternateSelector) {
      alternateSelector.forEach(element => {
        element.remove();
      });
    }

    //Splits "https://localhost:4200/about-us" into "https:","", "localhost:4200", "about-us", 
    let urlParts = canURL.split("/").filter(el => { return el != "" });

    //Find the page and keep path+query for appending in alternatelist urls
    Object.values(HtmlRoutePages).forEach(subdirectories => {
      urlParts.forEach((urlPart, index) => {
        let checkUrlPart = "/" + urlPart.split("?")[0];
        if ((checkUrlPart) == subdirectories) {
          subdirectory = "/" + urlParts.slice(index, urlParts.length).join("/");
        }
      });
    })

    //Insertion in dom
    for (const key of this.alternateList) {
      const langLink: HTMLLinkElement = this.dom.createElement('link');
      langLink.setAttribute('rel', 'alternate');
      langLink.setAttribute('hreflang', key.langCode);
      langLink.setAttribute('href', key.langHref + subdirectory);
      this.dom.head.insertBefore(langLink, this.dom.head.childNodes[0]);
    }
    this.dom.head.insertBefore(link, this.dom.head.childNodes[0]);
  }

}