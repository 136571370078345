import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'escapeUrl', pure: false})
export class EscapeUrlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(imageString: string): SafeStyle {
        return this.sanitizer.bypassSecurityTrustUrl(imageString);
    }
}
