import { Component, OnInit, Input } from '@angular/core';
import { SalesTeam } from '../contact-page.model';
@Component({
  selector: 'volvo-sales-person',
  templateUrl: './sales-person.component.html',
  styleUrls: ['./sales-person.component.scss']
})
export class SalesPersonComponent implements OnInit {
  isMasterContactPageFlag: boolean = false;
  @Input() salesTeamData: SalesTeam[];
  @Input() isMasterContactPage: boolean;
  constructor() {}
  ngOnInit(): void {
    if(this.isMasterContactPage)
      {
        this.isMasterContactPageFlag = true;
      }
  }
  
 }
