import { EscapeUrlPipe } from '~/core/pipes/escape-url.pipe';
import { VolvoCdnPipe } from '~/core/pipes/volvo-cdn.pipe';
import { HasValuePipe } from '~/core/pipes/has-value.pipe';
import { FilterPipe } from '~/core/pipes/filter.pipe';
import { SafePipe } from '~/core/pipes/safe.pipe';
import { ReversePipe } from '~/core/pipes/reverse.pipe';
import { TruncatePipe } from '~/core/pipes/limit.pipe';
import { LookupPipe } from '~/core/pipes/lookup.pipe';
import { TranslateMany } from '~/core/pipes/translateMany.pipe';
import { TranslateLower } from '~/core/pipes/translateLower.pipe';
import { SortByPipe } from '~/core/pipes/sort-by.pipe';
import { CapitalizePipe } from '~/core/pipes/capitalize.pipe';
import { LookupcountryPipe } from '~/core/pipes/lookupcountry.pipe';
import { AsSliderPipe, AsStringPipe } from '~/core/pipes/typePipes';

export const VolvoPipes = [
  VolvoCdnPipe,
  EscapeUrlPipe,
  FilterPipe,
  SafePipe,
  HasValuePipe,
  ReversePipe,
  TruncatePipe,
  LookupPipe,
  LookupcountryPipe,
  TranslateMany,
  TranslateLower,
  SortByPipe,
  CapitalizePipe,
  AsSliderPipe,
  AsStringPipe
];
