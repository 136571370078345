import { PipeTransform, Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Pipe({
  name: 'translateMany',
})
export class TranslateMany implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(value: string): string {
    const parts = value.split(';');
    const path = parts[0].substring(0, parts[0].lastIndexOf('/'));
    var result: string[] = [];

    parts.forEach(p => {
      let current = p.includes(path) ? p : path + '/' + p
      let translated = this.translate.instant(current.toLowerCase());

      result.push(translated);
    });

    return result.join(';');
  }
}


