<div class="tabMobSidePadding">
    <volvo-contact-skeleton *ngIf="isLoading"></volvo-contact-skeleton>
    <section class="truckCentre">
        <div class="truckCentre tabMobHeroMargin">
            <img [src]="assetService.getAssetUrl('assets/pages/contactus/Wellingborough.webp')" [hidden]="isLoading" (load)="onImageLoad()" class="truckCentre-centreImg">
        </div>
    </section>
    <div [hidden]="isLoading">
        <section class="banburryCentre">
            <div class="banburryCentre-title">
                <h1>
                    {{"uk/pages/contact/wellingboroughTruckCentre" | lowercase | translate}}
                </h1>
            </div>
            <div class="banburryCentre-text">
                {{"uk/pages/contact/wellingboroughText"  | lowercase | translate}}
            </div>
        </section>
        <section class="banburryCentre">
            <div class="banburryCentre-flex">
                <div class="banburryCentre-salesTeam">
                    <div>
                        <volvo-sales-person [salesTeamData]="salesTeamMangementDetails"></volvo-sales-person>
                    </div>
                </div>
                <div class="banburryCentre-map">
                    <volvo-map-centre [centre]="mapCentre" [dynamicClassFlag]="true"></volvo-map-centre>
                </div>
            </div>
        </section>
    </div>
    </div>
    <volvo-footer></volvo-footer>
