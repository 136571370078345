import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import {
  Company,
  DealerPlaceResult,
  GetDealershipsPayload,
  SetDealershipsPayload,
  UserLocation,
} from '~/core/store/dealerships/dealerships.model';
import { GetDealerships, GetStocklocations, GetUserLocation, SetDealerships } from '~/core/store/dealerships/dealerships.actions';

@Injectable({ providedIn: 'root' })
export class DealershipsService {
  @Select((state) => state.dealerships.dealerships)
  public dealerships$: Observable<DealerPlaceResult[]>;

  @Select((state) => state.dealerships.dealershipsCountries)
  public dealershipsCountries$: Observable<string[]>;

  @Select((state) => state.dealerships.userLocation)
  public userLocation$: Observable<UserLocation>;

  @Select((state) => state.stocklocations)
  public stocklocations$: Observable<Company[]>;

  @Dispatch()
  public getDealerships() {
    return new GetDealerships();
  }

  @Dispatch()
  public setDealerships(payload: DealerPlaceResult[]) {
    return new SetDealerships(payload);
  }

  @Dispatch()
  public getStocklocations() {
    return new GetStocklocations();
  }

  @Dispatch()
  public getUserLocation() {
    return new GetUserLocation();
  }
}
