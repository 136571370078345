import { SearchQuery } from 'src/app/core/models/search-query.model';

export class NotificationQuery {
  SearchQuery: SearchQuery;
  NotificationData: NotificationData;
}
export class NotificationData {
  Name: string;
  Email: string;
  Phone: string;
  Instantly: boolean = false;
  Daily: boolean = false;
  Weekly: boolean = false;
  Active: boolean = true;
  LangCode: string;
  Region: string;
  Site: string;
  EmailNotification: boolean = false;
  SmsNotification: boolean = false;
  PushNotification: boolean = false;
  Country: string;
  Company: string;
  Url:string;
  Postcode: string;
  Response1 : string="";
  Response2 : string="";
  Response3 : string="";
  Response4 : string="";
  Response5 : string="";
  Response6 : string="";
  OtherDescription:string="";
}
