<div class="tabMobSidePadding">
    <volvo-contact-skeleton *ngIf="isLoading"></volvo-contact-skeleton>

    <section class="truckCentre">
        <div class="truckCentre tabMobHeroMargin">
            <img [hidden]="isLoading" (load)="onImageLoad()" (error)="onError()" src="../../../assets/pages/contactus/ns/edinburgh.webp" class="truckCentre-centreImg" alt="Edinburgh">
        </div>
    </section>
    <div [hidden]="isLoading">
        <section class="banburryCentre" id="tradeexport">
            <div class="banburryCentre-title">
                <h1>
                    {{ "uk/pages/contact/edinburghCentre" | lowercase | translate }}
                </h1>
            </div>
            <div class="banburryCentre-text" [innerHTML]="'uk/pages/contact/edinburghCentreText' | lowercase | translate">
            </div>
        </section>
        <section class="banburryCentre">
            <div class="banburryCentre-flex">
                <div class="banburryCentre-salesTeam">
                    <volvo-sales-person [salesTeamData]="salesTeamMangementDetails"></volvo-sales-person>
                </div>
                <div class="banburryCentre-map">
                    <volvo-map-centre [centre]="mapCentre"></volvo-map-centre>
                </div>
            </div>
        </section>
    </div>
    <volvo-footer></volvo-footer>
</div>
