import { Action, State, StateContext } from '@ngxs/store';

import { TruckCollationStateInterface } from '~/core/store/truck-collation/truck-collation.model';
import { AddTruck } from '~/core/store/truck-collation/truck-collation.actions';
import { Injectable } from '@angular/core';

@State<TruckCollationStateInterface>({
  name: 'truckCollation',
  defaults: {
    trucks: [],
    totalTrucks: 0,
  },
})
@Injectable()
export class TruckCollationState {
  constructor() { }

  @Action(AddTruck)
  addTruck({ getState, patchState }: StateContext<TruckCollationStateInterface>, { payload }: AddTruck) {
    const state: TruckCollationStateInterface = getState();

    const truckIndex = state.trucks.findIndex((item) => item.stockNumber === payload.stockNumber);
    const updatedTrucks = [...state.trucks];

    if (truckIndex < 0) {
      // this is new truck
      updatedTrucks.push(payload);
    } else {
      // if we have this truck then remove it
      updatedTrucks.splice(truckIndex, 1);
    }

    patchState({
      trucks: updatedTrucks,
      totalTrucks: updatedTrucks.length,
    });
  }
}
