<div class="StockSearch">
  <!-- <div class="StockSearch-input">
        <volvo-search-input [redirectOnSearch]="false"></volvo-search-input>
    </div> -->
  <!-- <volvo-stock-options class="Stock-stockOptions" [isVehicleFound]="true"></volvo-stock-options> -->
  <volvo-search-chip
    [fromNotification]="false"
    *ngIf="showChips"
    [@toggleHeight]
    class="StockSearch-chip"
  ></volvo-search-chip>
</div>
