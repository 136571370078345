import { Component, OnDestroy, OnInit } from "@angular/core";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

import {
    SearchStateFilter,
    SearchStep,
    SearchStepKeys,
} from "~/core/store/search/search.model";
import { DealershipPopupComponent } from "~/popups/dealership-popup/dealership-popup.component";
import { BucketAggregations } from "~/core/store/buckets/buckets.model";
import { BucketsService } from "~/core/store/buckets/buckets.service";
import { SearchService } from "~/core/store/search/search.service";

@AutoUnsubscribe()
@Component({
    selector: "volvo-stock-top-filters",
    templateUrl: "./stock-top-filters.component.html",
    styleUrls: ["./stock-top-filters.component.scss"],
})
export class StockTopFiltersComponent implements OnInit, OnDestroy {
    SearchStepKeys = SearchStepKeys;

    search: SearchStateFilter;
    buckets: BucketAggregations;

    private s1: Subscription;
    private s2: Subscription;

    constructor(
        public dialog: MatDialog,
        private searchService: SearchService,
        private bucketsService: BucketsService
    ) {
        this.initSubscriptions();
    }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    onSliderMenuClick(): void {
        // Fix bug with wrong slider max value
        // TODO: find better solution, maybe get rid of the menu dropdown
        window.setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 150);
    }

    onDealershipClick(): void {
        this.dialog.open(DealershipPopupComponent, {
            panelClass: "DealershipPopup",
        });
    }

    onFilterChange(key: SearchStepKeys, value: string): void {
        this.searchService.updateStep({
            key,
            value: { ...this.search.steps[key], value },
            expandingFilter: '',
            reloadFlag: false
        });
    }

    onSliderChange(value: SearchStep, key: string): void {
        this.searchService.updateStep({ key, value, reloadFlag: false, expandingFilter: '' });
    }

    private initSubscriptions(): void {
        this.s1 = this.searchService.filter$.subscribe(
            (searchOptions: SearchStateFilter) => {
                this.search = searchOptions;
            }
        );

        this.s2 = this.bucketsService.buckets$.subscribe(
            (buckets: BucketAggregations) => {
                if (buckets) {
                    this.buckets = buckets;
                }
            }
        );
    }
}
